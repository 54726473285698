import React, { useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { isEqual, isUndefined } from "lodash";
import { findById } from "core/helpers/category";
import { filterConfiguration } from "config/category/filters";
import filterActions from "app/state/redux/data/filters/actions";

const ListingEffectComponent = memo(
  ({
    data,
    allCategories,
    category_id,
    addFilteredData,
    addInitialCategoryData,
    category,
    reducePageData,
    reduceNewProductData,
    variables,
    pageNumber,
    pageSize,
  }) => {
    const dispatch = useDispatch();

    useEffect(() => {
      if (!isUndefined(data) && !isUndefined(data.products)) {
        if (
          isUndefined(category) ||
          (!isUndefined(category) && category.id !== category_id)
        ) {
          let filters = {};
          Object.keys(filterConfiguration).map((key) => {
            let item = filterConfiguration[key];
            return (filters[item.attribute_code] = { ...item });
          });
          addInitialCategoryData({
            ...findById(category_id, allCategories.children),
            initialFilters: filters,
          });

          if (data?.products?.aggregations !== undefined) {
            dispatch(filterActions.addFilteredData(data.products));
          }
          reducePageData({
            totalCount: data.products.total_count,
            pageInfo: data.products.page_info,
            loading: true,
          });
        } else {
          reducePageData({
            totalCount: data.products.total_count,
            pageInfo: data.products.page_info,
            loading: false,
          });

          if (data?.products?.aggregations !== undefined) {
            dispatch(filterActions.addFilteredData(data.products));
          }

          if (Object.keys(variables).length === 0) {
            reduceNewProductData({ ...data.products });
          } else {
            reduceNewProductData({ items: [...data.products.items] });
          }
        }
      }
    }, [
      data,
      addFilteredData,
      addInitialCategoryData,
      allCategories,
      category,
      category_id,
      reducePageData,
      reduceNewProductData,
      pageNumber,
      pageSize,
    ]);

    return <></>;
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default ListingEffectComponent;
