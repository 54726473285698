import React from "react";
import { useLocation } from "react-router-dom";
import CatalogDetail from "./catalog-details";

const CatalogDetailDataContainer = (props) => {
  const location = useLocation();
  const path = location.pathname;
  const catalogId = path
    .slice(path.indexOf("id_"), path.length)
    .replace(/id_/, "");

  let selectedCatalog =
    props.data === undefined
      ? " "
      : props.data.getCatalog.catalogs.find((catalog) => {
          if (catalog.catalogs_id === catalogId) {
            return catalog;
          } else return null;
        });

  return <CatalogDetail selectedCatalog={selectedCatalog} />;
};

export default CatalogDetailDataContainer;
