import React from "react";
import { connect } from "react-redux";
import { default as commonActions } from "core/state/redux/data/common/actions";
import LoginStateContainer from "./loginForm-state-container";
import customerActions from "core/state/redux/data/customer/actions";
import { withApollo } from "react-apollo";

const LoginReduxContainer = (props) => {
  const customer = props;
  let token = false;
  if (
    typeof customer !== "undefined" &&
    typeof customer.data !== "undefined" &&
    customer.data.token !== false
  ) {
    token = customer.data.token;
  }
  return <LoginStateContainer {...props} token={token} />;
};

const mapStateToProps = (state) => ({
  data: state.customer,
  customer: state.customer.data,
  loading: state.customer.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callbackFunction) => {
      dispatch(commonActions.lock(callbackFunction));
    },
    login: (username, password, cartToken, callbackFunction) => {
      dispatch(customerActions.loginCustomer(username, password, cartToken));
    },
    createEmptyCart: () => {
      dispatch(customerActions.createEmptyCart());
    },
    getCartInformation: () => {
      dispatch(customerActions.getCartInformation());
    },
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(LoginReduxContainer)
);
