import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import WishlistCoreStateContainer from "./wishlistCore-state-container";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import { Redirect } from "react-router-dom";

const WishlistCoreReduxContainer = (props) => {
  const [getLoggedIn] = useCustomer();
  if (getLoggedIn()) {
    return <WishlistCoreStateContainer {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

const mapStateToProps = (state) => ({
  customer: state.customer.data,
  locked: state.common.locked,
  wishlistNumberOfItems: state.wishlist.data.wishlistItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (data) => {
      dispatch(commonActions.sendEmail(data));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistCoreReduxContainer);
