import { getOptions } from "core/helpers/category";

const MULTI_FILTER_TYPE = "multi";
const COLOR_FILTER_TYPE = "color";

export const filterConfiguration = {
  product_collection: {
    is_filter: true,
    attribute_code: "product_collection",
    label: "Collection",
    type: MULTI_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: "",
  },
  color: {
    is_filter: true,
    attribute_code: "color",
    label: "Color",
    type: MULTI_FILTER_TYPE,
    frontend_type: COLOR_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: "",
  },
  finish_color: {
    is_filter: true,
    attribute_code: "finish_color",
    label: "Color",
    type: MULTI_FILTER_TYPE,
    frontend_type: COLOR_FILTER_TYPE,
    values: getOptions,
    valueChangeHandler: "",
  },
};
