import React, { useState } from "react";
import { MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import Content from "./content";

const FindARepComponentContainer = (props) => {
  let { countries, industries, states, countriesAndStates } = props;
  const [countrySelect, setCountrySelect] = useState("");
  const [selectState, setSelectState] = useState("");
  const [industrySelect, setIndustrySelect] = useState("");
  const [canSearch, setCanSearch] = useState(false);

  const setSelectStateFn = (evt) => {
    if (evt !== selectState) {
      setCanSearch(false);
    }

    setSelectState(evt);
  };
  const setIndustrySelectFn = (evt) => {
    if (evt !== industrySelect) {
      setCanSearch(false);
    }

    setIndustrySelect(evt);
  };
  const setSelectCountryFn = (evt) => {
    if (evt !== countrySelect) {
      setCanSearch(false);
    }

    setCountrySelect(evt);
  };

  return (
    <MDBCol lg="9" className="find-a-rep-main-content">
      <MDBCol size="12">
        <Toolbar
          industries={industries}
          countries={countries}
          states={states}
          countrySelect={countrySelect}
          setCountrySelect={setCountrySelect}
          selectState={selectState}
          setSelectState={setSelectState}
          industrySelect={industrySelect}
          setIndustrySelect={setIndustrySelect}
          setSelectStateFn={setSelectStateFn}
          setIndustrySelectFn={setIndustrySelectFn}
          setSelectCountryFn={setSelectCountryFn}
          setCanSearch={setCanSearch}
          countriesAndStates={countriesAndStates}
        />
      </MDBCol>
      <MDBCol
        size="12"
        className="border-bottom find-a-rep-search find-a-rep-column "
      >
        <h6 className="wow slideInUp">Search Results</h6>
      </MDBCol>
      <MDBCol size="12" className="find-a-rep-column content-column">
        <Content
          countrySelect={countrySelect}
          selectState={selectState}
          industrySelect={industrySelect}
          canSearch={canSearch}
        />
      </MDBCol>
    </MDBCol>
  );
};

export default FindARepComponentContainer;
