// Action Creators
import types from "./types";

const changePageNumber = (pageNumber) => {
  return {
    type: types.CHANGE_PAGE_NUMBER,
    pageNumber: pageNumber,
  };
};

const clearFilters = () => {
  return {
    type: types.CLEAR_FILTERS,
  };
};

const changePageSize = (size) => {
  return {
    type: types.CHANGE_PAGE_SIZE,
    size: size,
  };
};

const addFilter = (filter) => {
  return {
    type: types.ADD_FILTER,
    filter: filter,
  };
};

const removeFilter = (filter) => {
  return {
    type: types.REMOVE_FILTER,
    filter: filter,
  };
};

const addFilteredData = (data) => {
  return {
    type: types.ADD_FILTERED_DATA,
    data: data,
  };
};

const addInitialCategoryData = (data) => {
  return {
    type: types.ADD_INITIAL_CATEGORY_DATA,
    data: data,
  };
};

const changeSortFilter = (sort) => {
  return {
    type: types.CHANGE_SORT_FILTER,
    sort: sort,
  };
};

const reducePageData = (pageData) => {
  return {
    type: types.REDUCE_PAGE_DATA,
    pageData: pageData,
  };
};

const reduceNewProductData = (payload) => {
  return {
    type: types.REDUCE_NEW_PRODUCT_DATA,
    payload: payload,
  };
};

const setLoading = (payload) => {
  return {
    type: types.SET_LOADING,
    payload: payload,
  };
};

const changeTotalPages = (totalPages) => {
  return {
    type: types.CHANGE_TOTAL_PAGES,
    totalPages: totalPages,
  };
};

export default {
  changePageNumber,
  clearFilters,
  changePageSize,
  addFilter,
  removeFilter,
  addFilteredData,
  addInitialCategoryData,
  changeSortFilter,
  reducePageData,
  reduceNewProductData,
  setLoading,
  changeTotalPages,
};
