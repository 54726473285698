import React from "react";

export const sliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  arrows: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 0,
  rows: 5,
  slidesPerRow: 1,
  adaptiveHeight: true,
  variableWidth: false,
  customPaging: () => <div className={"slider-custom-dot"} />,
  appendDots: (dots) => (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <ul className={"list-custom-dots"} style={{ margin: "1rem 0 0 1rem" }}>
        {dots}
      </ul>
    </div>
  ),
};
