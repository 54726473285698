import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Pagination from "../../pagination";
import PageView from "../../pageView";
import ReactLoader from "app/layout/loader/loader";
import useSite from "app/state/hooks/siteHooks/useSite";

const ListingToolbarComponent = (props) => {
  let total_count = props.totalCount;
  const [, getSiteCode] = useSite();

  if (props.dataLoading)
    return (
      <ReactLoader
        color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
        width={50}
        height={50}
        type={`ThreeDots`}
      />
    );
  return (
    <MDBRow className="listing-toolbar-container">
      {props.data?.items?.length > 0 ? (
        <>
          <MDBCol className="total-products-container">
            <p className="total_products_count_label mt-4 mb-4">
              {total_count}
              {total_count === 1 ? " Item" : " Items"}
            </p>
          </MDBCol>
          <MDBCol className="pagination-view-container">
            <MDBRow className="pagination-view-row">
              <PageView
                {...props}
                position={props.position && props.position}
                total_count={total_count}
              />
              <Pagination
                {...props}
                position={props.position && props.position}
              />
            </MDBRow>
          </MDBCol>
        </>
      ) : (
        <></>
      )}
    </MDBRow>
  );
};
export default ListingToolbarComponent;
