import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import {
  pushFilterValueToURL,
  removeFilterValueFromURL,
  getSearchParameters,
} from "app/layout/filters/functions";
import filterActions from "app/state/redux/data/filters/actions";

const BrandSwitchComponent = ({ filter, isSwitch, index }) => {
  const history = useHistory();
  const siteCode = useSelector((state) => state.site.siteCode);
  const dispatch = useDispatch();

  let searchParameters;

  useEffect(() => {
    searchParameters = getSearchParameters();

    let filterIsActive = false;
    Object.values(searchParameters).map((parameter, index) => {
      let parameterKey = Object.keys(searchParameters)[index];
      if (parameterKey === filter.attribute_code) {
        if (typeof filter.options[index] !== "undefined") {
          if (parameter.toString() === filter.options[index].value)
            filterIsActive = true;
        }
      }
    });

    setActive(filterIsActive);
  }, [history.location.search]);

  const [active, setActive] = useState(false);

  const handleFilterChange = () => {
    searchParameters = getSearchParameters();

    if (!active) {
      dispatch(filterActions.changePageNumber(1));
      pushFilterValueToURL(
        searchParameters,
        filter,
        1,
        isSwitch,
        filter.options[index].value
      );
    } else {
      removeFilterValueFromURL(
        searchParameters,
        filter,
        filter.options,
        isSwitch,
        filter.options[index].value
      );
    }
    setActive(!active);
  };

  return (
    <label htmlFor={filter.label}>
      <span className={filter.options[index].label}>
        {filter.options[index].label}
      </span>
      <Switch
        offColor="#E2E2E2"
        onColor="#E2E2E2"
        offHandleColor="#A0A0A0"
        onHandleColor={siteCode?.includes("norwell") ? `#b9571b` : `#c1263b`}
        height={15}
        width={35}
        checked={active}
        onChange={() => {
          handleFilterChange();
        }}
        uncheckedIcon={false}
        checkedIcon={false}
        draggable={false}
        id={filter.label}
        activeBoxShadow={
          siteCode?.includes("norwell")
            ? "0px 0px 2px 3px #fff"
            : "0px 0px 2px 3px #fff"
        }
        className={`${
          !active ? "switch-filter-not-active" : "switch-filter-active"
        } `}
      />
    </label>
  );
};

export default BrandSwitchComponent;
