import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import { gql } from "graphql.macro";
const REMOVE_ITEM_FROM_WISHLIST = gql`
  mutation RemoveItemFromWishlist($wishlist_item_id: Int) {
    removeWishlistItem(input: $wishlist_item_id)
  }
`;
export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: REMOVE_ITEM_FROM_WISHLIST,
      variables: action,
    });

    let wishlists = { ...store.getState().customer.data.wishlist };
    if (data) {
      let items = [...wishlists.items];
      let filtered = items.filter((item) => {
        return item.id !== action.wishlist_item_id;
      });
      wishlists.multiwishlist_data.map((multi, index) => {
        let multi_filter = multi.items.filter((item) => {
          return item !== action.wishlist_item_id;
        });
        wishlists.multiwishlist_data[index].items = [...multi_filter];
      });
      wishlists.items = [...filtered];
      store.dispatch(customerActions._reduceWishlist(wishlists));
      // store.dispatch(customerActions.getCustomerInformation());
      store.dispatch(
        messagesActions.addMessage(
          `successfully removed product from wishlist`,
          "success",
          "wishlist-popup"
        )
      );
    }
    store.dispatch(commonActions.unlock("success"));
  } catch (error) {
    // store.dispatch(
    //   messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    // );
    store.dispatch(
      messagesActions.addMessage(
        error.graphQLErrors[0].debugMessage,
        "danger",
        "wishlist-popup"
      )
    );
    store.dispatch(commonActions.unlock("fail"));
  }
};
