import React from "react";
import lodash from "lodash";
import Icon from "app/assets/icon/icon";
export const RangeFilter = React.memo(
  (props) => {
    let { filter } = props;
    return (
      <p>
        <span
          className={"close-btn"}
          onClick={(e) => {
            props.removeFilter("price");
          }}
        >
          <Icon icon="removeFilterButtonGray" />
        </span>
        ${filter[Object.keys(filter)[0]].filter_value} - $
        {filter[Object.keys(filter)[1]].filter_value}
      </p>
    );
  },
  (prevProps, nextProps) => {
    return lodash.isEqual(prevProps, nextProps);
  }
);

export default RangeFilter;
