import React from "react";
import { connect } from "react-redux";
import GalleryDetail from "./galleryDetail-query-container";

const GalleryDetailReduxContainer = (props) => {
  return <GalleryDetail {...props} />;
};

const mapStateToProps = (state) => ({
  galleries: state.queries.data.galleries,
});

export default connect(mapStateToProps, null)(GalleryDetailReduxContainer);
