import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";
import Hotspot from "app/layout/hotspot";
import HotspotSingleView from "app/layout/hotspot/hotspot";
import GalleryControls from "./galleryControls";
import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";
import MobileGalleryControls from "./mobileGalleryControls";
import { useLocation } from "react-router-dom";
import { sliderSettings } from "./settings";
import themeConfiguration from "config/themeConfiguration";

const GalleryDetail = (props) => {
  let {
    selectedGallery,
    previousGallery,
    nextGallery,
    hotspots,
    norwellGalleries,
    ilexGalleries,
  } = props;

  function renderHTML(text) {
    return {
      __html: `${text}`,
    };
  }

  const selectedGalleryMainImage =
    selectedGallery?.gallery_items.length > 0
      ? {
          image: selectedGallery?.gallery_items[0].image,
          name: selectedGallery?.gallery_items[0].name,
        }
      : { image: selectedGallery?.image, name: selectedGallery?.name };

  const [hotspot, setHotspot] = useState({
    ...hotspots?.[0],
    ...selectedGalleryMainImage,
  });

  const [activeImg, setaActiveImg] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let slider1 = null;
  let slider2 = null;

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, []);

  // useEffect(() => {
  //   setHotspot(hotspots?.[0]);

  //   // setaActiveImg(null);
  // }, [hotspots]);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const location = useLocation();
  const currentPage =
    location && location.state && location.state.currentPage
      ? location.state.currentPage
      : 1;

  const currentPosts =
    location && location.state && location.state.currentPosts
      ? location.state.currentPosts
      : selectedGallery &&
        selectedGallery.category &&
        selectedGallery.category[0] &&
        selectedGallery.category[0].name === "Norwell Lighting"
      ? norwellGalleries
      : ilexGalleries;

  const slickSettingsVerticalMain = {
    arrows: true,
    slidesToShow: 1,
    infinite: true,
    speed: 0,
    draggable: false,
    swipe: false,

    afterChange: (newIndex) => {
      setHotspot((prevState) => ({
        ...prevState,
        image: selectedGallery.gallery_items[newIndex]?.image,
        name: selectedGallery.gallery_items[newIndex]?.name,
      }));
    },

    prevArrow: (
      <div className="holder">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            icon={isMobileOrTablet ? "left_arrow_black" : "black_arrow_down"}
          />
        </div>
      </div>
    ),

    nextArrow: (
      <div className="holder">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            icon={isMobileOrTablet ? "right_arrow_black" : "black_arrow_down"}
          />
        </div>
      </div>
    ),
  };
  return (
    <>
      <MDBContainer className="gallery-detail-container">
        {!isMobileOrTablet && (
          <MDBRow className="breadcrumbs">
            <Link to={"/"}>
              <p>Home</p>
            </Link>
            <p className="dash">-</p>
            <Link to={"/gallery"}>
              <p>Gallery</p>
            </Link>
            <p className="dash">-</p>
            <Link>
              <p>{selectedGallery?.name}</p>
            </Link>
          </MDBRow>
        )}
        <MDBRow className="gallery-title flex-sm-column">
          <h3 className="wow fadeInUp">{selectedGallery?.name}</h3>
          {console.log(`selectedGallery`, selectedGallery)}
          <p
            className="wow fadeInUp gallery-description"
            dangerouslySetInnerHTML={renderHTML(
              selectedGallery?.description !== null
                ? selectedGallery?.description
                : ""
            )}
          ></p>
        </MDBRow>
        <MDBRow className="back-to-gallery">
          <MDBCol className="col-4 p-0">
            {" "}
            <Link
              to={{
                pathname: "/gallery",
                state: {
                  currentPage: currentPage,
                  currentPosts: currentPosts,
                },
              }}
            >
              <h6 className="wow fadeInLeft">
                <Icon icon={"small_black_arrow_left"} /> Back
              </h6>
            </Link>
          </MDBCol>
          <MDBCol className="col-4 p-0"></MDBCol>
          <MDBCol className="col-4 p-0"></MDBCol>
        </MDBRow>
        <MDBRow className="hotspot-wrapper">
          {/* {!isMobileOrTablet && (
            <GalleryControls
              galleryInPopover={previousGallery}
              position={"left"}
            />
          )} */}

          <MDBCol lg="12" className="gallery-image main-slider">
            {selectedGallery?.gallery_items.length > 0 ? (
              <Slider
                {...slickSettingsVerticalMain}
                asNavFor={nav1}
                ref={(slider) => (slider2 = slider)}
              >
                {selectedGallery?.gallery_items.map((item, index) => {
                  return <Hotspot key={index} {...hotspot} />;
                })}
              </Slider>
            ) : (
              <Hotspot {...hotspot} />
            )}
          </MDBCol>

          {/* {isMobileOrTablet ? (
            <MobileGalleryControls
              previousGallery={previousGallery}
              nextGallery={nextGallery}
            />
          ) : (
            nextGallery && (
              <GalleryControls
                galleryInPopover={nextGallery}
                position={"right"}
              />
            )
          )} */}
          {selectedGallery?.gallery_items?.length > 0 && (
            <div className="slider-holder col-lg-12">
              <Slider
                asNavFor={nav2}
                ref={(slider) => (slider1 = slider)}
                {...sliderSettings(selectedGallery, setHotspot)}
              >
                {selectedGallery.gallery_items.map((item, index) => {
                  return (
                    <img
                      onClick={() => {
                        slider1.slickGoTo(index);
                        // setaActiveImg(index);
                        // setHotspot((prevState) => ({
                        //   ...prevState,
                        //   image: item.image,
                        //   name: item.name,
                        // }));
                        // window.scrollTo({
                        //   top: 0,
                        //   left: 0,
                        //   behavior: "smooth",
                        // });
                      }}
                      key={index}
                      src={themeConfiguration.magento_url + item.image}
                      className={`thumbnail-img ${
                        activeImg === index ? "active-img" : ""
                      }`}
                      alt={item.name}
                    />
                  );
                })}
              </Slider>
            </div>
          )}
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default GalleryDetail;
