import React from "react";
import { MDBCol, MDBRow } from "mdbreact";
import uuid from "react-uuid";

const CustomPagination = (props) => {
  let { position, postsPerPage, totalPosts, currentPage, setCurrentPage } =
    props;
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  let pagesArray = [];
  for (let i = 1; i <= totalPages; i++) {
    pagesArray.push(i);
  }
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <MDBRow>
      <MDBCol className="page-pagination-container">
        <MDBRow className="pagination-component">
          <MDBCol size="2" className={"left-arrow-container"}>
            <span
              onClick={() => {
                if (currentPage > 1) paginate(currentPage - 1);
              }}
            ></span>
          </MDBCol>
          <MDBCol className="page-number-container">
            <p className="current-page">{currentPage + " OF " + totalPages}</p>
            <ul className={"page-number-list " + position}>
              {pagesArray.map((page) => {
                return (
                  <li key={uuid()} onClick={() => paginate(page)}>
                    {page}
                  </li>
                );
              })}
            </ul>
          </MDBCol>
          <MDBCol size="2" className={"right-arrow-container"}>
            <span
              onClick={() => {
                if (currentPage < totalPages) paginate(currentPage + 1);
              }}
            ></span>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default CustomPagination;
