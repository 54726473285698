import apolloClient from "core/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { messagesActions } from "core/state/redux/data/messages";
import { customerActions } from "../../redux/data/customer";
import { useDispatch } from "react-redux";
import { useWishlist } from "./wishlistHookAddRemove";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import useErrorHandling from "../coreHooks/useErrorHandling";

function useWishlistAddToCart() {
  const { fetchWishlistData } = useWishlist();
  const [, unlock] = useLocking();
  const { sanitizeGraphQLMessage } = useErrorHandling();

  let dispatch = useDispatch();

  const addToCartWishlist = async (request, showNotification = true) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addItemToCart"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(customerActions.getCartInformation());
      if (showNotification) {
        dispatch(messagesActions.addMessage("Item added to cart", "success"));
      }

      unlock("success");
      return "Successfully added wishlist item in cart.";
    } catch (err) {
      unlock("fail");
      console.log(err.message);
      return sanitizeGraphQLMessage(err.message);
    }
  };

  const addAllToCart = async (request, showNotification = true) => {
    try {
      await apolloClient.mutate({
        mutation: queryLoader("addAllToCart"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(customerActions.getCartInformation());
      if (showNotification) {
        dispatch(
          messagesActions.addMessage(
            "Items successfully added to cart",
            "success"
          )
        );
      }
      unlock("success");
      return true;
    } catch (err) {
      unlock("fail");
      return false;
    }
  };

  const deleteItemsFromWishlist = async (request, showNotification = true) => {
    try {
      await apolloClient.mutate({
        mutation: queryLoader("deleteItemsFromWishlist"),
        variables: request,
        fetchPolicy: "no-cache",
      });
      fetchWishlistData(true);
      dispatch(
        messagesActions.addMessage("Items successfully removed", "success")
      );
      unlock("success");
      return true;
    } catch (err) {
      unlock("fail");
      return false;
    }
  };

  return { addToCartWishlist, addAllToCart, deleteItemsFromWishlist };
}
export { useWishlistAddToCart };
