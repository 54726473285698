// define project specific reducers
// import testReducer from "./data/test";
import siteReducer from "./data/site";
import customerReducer from "./data/customer";
import filtersReducer from "./data/filters";
import queriesReducer from "./data/queries";

const projectReducers = {
  // test: testReducer,
  site: siteReducer,
  filters: filtersReducer,
  customer: customerReducer,
  queries: queriesReducer,
};

export default projectReducers;
