import React from "react";
import gql from "graphql-tag";
import BannerSlider from "./bannerSlider-data-container";
import GraphqlWrapper from "app/layout/graphQlWrapper";

const BANNER_SLIDER = gql`
  query slider($name: String!, $store_id: String!) {
    slider(name: $name, store_id: $store_id) {
      name
      autoplay
      slides {
        order_number
        name
        media
        button_url
        has_overlay
        overlay_title
        overlay_text
        overlay_cta_label
        overlay_cta_link
      }
    }
  }
`;

const BannerSliderQueryContainer = (props) => {
  const site =
    typeof props.site !== "undefined" &&
    typeof props.site.siteCode !== "undefined" &&
    props.site.siteCode === "norwell_default"
      ? "2"
      : "1";

  return (
    <GraphqlWrapper
      query={BANNER_SLIDER}
      variables={{
        id: "banner_slider",
        name: "HomePage",
        store_id: site,
      }}
      saveQuery={true}
    >
      <BannerSlider {...props} />
    </GraphqlWrapper>
  );
};

export default BannerSliderQueryContainer;
