import React from "react";
import { MDBView, MDBMask } from "mdbreact";
import themeConfiguration from "config/themeConfiguration";

const PressItem = (props) => {
  let { gallery, openModal, setOpenModal, setActiveGallery } = props;

  return (
    <>
      {gallery && (
        <div
          className="press-page-item"
          onClick={() => {
            setActiveGallery(gallery);
            setOpenModal(!openModal);
          }}
        >
          <MDBView waves>
            <img
              src={themeConfiguration.magento_url + gallery.image}
              alt={"press-page-image " + gallery.name}
            />
            <MDBMask className="flex press-page-item-mask" overlay="">
              <p className="white-text">{gallery.name}</p>
            </MDBMask>
          </MDBView>
        </div>
      )}
    </>
  );
};

export default PressItem;
