import React, { useState } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import PressItem from "./pressItem";
import uuid from "react-uuid";
import PressItemModal from "./pressItemModal";
import { useMediaQuery } from "react-responsive";
import Pagination from "app/layout/customPagination";

const Press = (props) => {
  let { pressGalleries } = props;
  const [activeGallery, setActiveGallery] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const totalPosts = pressGalleries.length;
  const [posts] = useState(pressGalleries);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts && posts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <MDBCol className="press-page-container">
      {!isMobile && (
        <MDBRow className="breadcrumbs-container ">
          <Breadcrumbs />
        </MDBRow>
      )}
      <MDBRow className="title-container" fluid>
        <h1 className="title wow slideInUp">Press</h1>
      </MDBRow>
      {pressGalleries.length > 12 && (
        <Pagination
          position={"top"}
          postsPerPage={postsPerPage}
          totalPosts={totalPosts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfLastPost={indexOfLastPost}
        />
      )}
      <MDBRow>
        <MDBCol className={"press-page-items-container"}>
          {currentPosts &&
            currentPosts.map((gallery, index) => {
              return (
                <PressItem
                  gallery={gallery}
                  key={uuid()}
                  index={index}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  setActiveGallery={setActiveGallery}
                />
              );
            })}
        </MDBCol>
      </MDBRow>
      {pressGalleries.length > 12 && (
        <Pagination
          position={"bottom"}
          postsPerPage={postsPerPage}
          totalPosts={totalPosts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          indexOfLastPost={indexOfLastPost}
        />
      )}
      <PressItemModal
        activeGallery={activeGallery}
        setActiveGallery={setActiveGallery}
        openModal={openModal}
        setOpenModal={setOpenModal}
        pressGalleries={pressGalleries}
      />
    </MDBCol>
  );
};

export default Press;
