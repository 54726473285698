import React from "react";
import { connect } from "react-redux";
import RegisterStateContainer from "./registerForm-state-container";
import { customerActions } from "core/state/redux/data/customer";

const RegisterReduxContainer = (props) => {
  return <RegisterStateContainer {...props} />;
};
const mapStateToProps = (state) => ({
  site: state.site,
});

const mapDispatchToProps = (dispatch) => {
  return {
    registerCustomerNew: (request) => {
      dispatch(customerActions.registerB2CCustomer(request));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterReduxContainer);
