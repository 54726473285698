import React from "react";
import Icon from "app/assets/icon/icon";
import useSite from "app/state/hooks/siteHooks/useSite";

const Alert = (props) => {
  const [, getSiteCode] = useSite();
  return (
    <div className="session-messages-inner">
      <div className={"session " + props.variant}>
        {props.variant === "success" ? (
          props.favorited ? (
            <Icon
              className="favorited-wishlist-icon"
              icon={
                getSiteCode() === "norwell_default"
                  ? "norwell_wishlist_full"
                  : "ilex_wishlist_full"
              }
            />
          ) : (
            <Icon icon={"session_message_success"} />
          )
        ) : (
          <Icon icon={"session_message_error"} />
        )}
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default Alert;
