import React, { useState } from "react";
import {
  MDBBox,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import { validate, shouldMarkError } from "./validation";
import Input from "app/layout/input";
import { useSelector } from "react-redux";
import SessionMessages from "app/layout/sessionMessages";
import useLocking from "core/state/hooks/commonHooks/useLocking";

const ShareWishlist = ({ wishlistId, wishlists, sendEmailAction }) => {
  const [lock] = useLocking();
  const siteCode = useSelector((state) => state.site.siteCode);
  const [toggleModal, setToggleModal] = useState(true);
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setToggleModal(!toggleModal);
  };
  const setDescriptionFn = (e) => {
    setDescription(e.target.value);
  };
  let errors = validate(email);

  let currentSiteTemplate = siteCode?.includes("norwell") ? `16` : `15`;

  let finalWishlistId = wishlistId;
  if (finalWishlistId === 0) {
    finalWishlistId = wishlists[0].id;
  }

  const sendWishlistEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    lock({
      always: () => {
        setLoading(true);
      },
      success: () => {
        setLoading(false);
        setDescription("");
        setEmail("");
        setTouched({ ...touched, email: false });
      },
      fail: () => {
        setLoading(false);
      },
    });
    sendEmailAction({
      input: {
        attributes: [
          { code: "email", value: email },
          { code: "description", value: description },
          { code: "wishlistId", value: finalWishlistId },
        ],
        template: currentSiteTemplate,
        fileBase64: "",
        fileName: "",
        fileType: "",
        return_form: false,
        is_quote: false,
      },
    });
  };

  return (
    <MDBContainer id={`share-project-modal`}>
      <MDBModal
        className={`modal-wrapper`}
        isOpen={toggleModal}
        centered
        toggle={(e) => {
          toggle();
        }}
      >
        <MDBModalHeader className="modal-title-wrapper">
          <MDBBox tag="p">
            Share Project
            <Icon icon="x_mark_white" onClick={() => toggle()} />
          </MDBBox>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer fluid>
            <MDBRow>
              <MDBCol md="12" className="email-input-wrapper">
                <Input
                  wrapperClass="light"
                  placeholder={`${
                    shouldMarkError("email", errors, touched)
                      ? "Email is required"
                      : "Email"
                  }`}
                  required={true}
                  type={"text"}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className={
                    shouldMarkError("email", errors, touched)
                      ? "invalid"
                      : "" + email !== "" && "valid"
                  }
                  onBlur={(e) => {
                    setTouched({ ...touched, email: true });
                  }}
                  shouldMarkError={shouldMarkError("email", errors, touched)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12">
                <div className="textarea-container">
                  <Input
                    value={description !== undefined ? description : ""}
                    onChange={setDescriptionFn}
                    className="textarea"
                    type={"textarea"}
                    placeholder="Message"
                    maxLength="250"
                    wrapperClass={"light"}
                  />
                  <label className="textarea-length">
                    {description.length + "/250"}
                  </label>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12">
                <Button
                  disabled={loading && loading}
                  onClick={(e) => {
                    if (errors.email) {
                      setTouched({ ...touched, email: true });
                    } else {
                      sendWishlistEmail(e);
                    }
                  }}
                >
                  {loading ? "Processing..." : "Share project"}
                </Button>
                <p className="error-messages">
                  <SessionMessages target="sendEmail" />
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
export default ShareWishlist;
