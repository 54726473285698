import apolloClient from "app/graphql/apolloClient";
import { end, pendingTask } from "react-redux-spinner";
import { default as queryActionTypes } from "../types";
import messagesActions from "core/state/redux/data/messages/actions";
import commonActions from "../../common/actions";
import queryActions from "../actions";
import { default as graphQlConfig } from "config/graphQlConfig";

const REMOVE_QUERY = queryActionTypes.REMOVE_QUERY;
const ADD_QUERY_SUCCESS = queryActionTypes.ADD_QUERY_SUCCESS;

export default async (store, action) => {
  if (!action.query || !action.queryType) {
    store.dispatch(
      messagesActions.addMessage("Invalid email or password", "danger")
    );
    store.dispatch(commonActions.unlock());
    return;
  }

  let queryType = action.queryType;
  let query = action.query;
  let variables = action.variables;
  let data;

  try {
    if (queryType === graphQlConfig.types.query) {
      data = await apolloClient.query({
        query: query,
        variables: variables,
        poolInterval: 1000,
        onCompleted: () => {},
      });
    }

    if (queryType === graphQlConfig.types.mutation) {
      data = await apolloClient.mutate({
        mutation: query,
        variables: variables,
      });
    }

    if (!data) {
      console.log("GraphQL response", data);
      throw new Error("Something went wrong.");
    }

    if (variables.identifier) {
      store.dispatch({
        type: ADD_QUERY_SUCCESS,
        [pendingTask]: end,
        data: data,
        id: variables.identifier + "-" + variables.id,
        url: variables.url,
      });
    } else {
      store.dispatch({
        type: ADD_QUERY_SUCCESS,
        [pendingTask]: end,
        data: data,
        id: variables.id,
        url: variables.url,
      });
    }

    if (variables.identifier) {
      store.dispatch({
        type: REMOVE_QUERY,
        id: variables.identifier + "-" + variables.id,
        url: variables.url,
      });
    } else {
      store.dispatch({
        type: REMOVE_QUERY,
        id: variables.id,
        url: variables.url,
      });
    }

    return new Promise(function (resolve, reject) {
      resolve(data["data"]);
    });
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", 4000)
    );
    store.dispatch(commonActions.unlock());
    store.dispatch(queryActions.removeQuery());
    return;
  }

  // if (store.getState().pendingTasks > 0) {
  //     store.dispatch({
  //         type: REMOVE_QUERY,
  //         [pendingTask]: end, // Make sure you embrace `pendingTask` in brackets [] to evaluate it,
  //         // Any additional key/values may be included here
  //     });
  // }
};
