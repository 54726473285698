import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { CATALOGS } from "./queries";
import ReactLoader from "app/layout/loader";
import useSite from "app/state/hooks/siteHooks/useSite";
import CatalogRequestFormStateContainer from "./catalog-request-form-state-container";

const CatalogRequestQueryContainer = (props) => {
  const { loading, error, data } = useQuery(CATALOGS, {
    fetchPolicy: "cache-first",
  });
  const [, getSiteCode] = useSite();
  if (loading) {
    return (
      <ReactLoader
        color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
        width={50}
        height={50}
        type={`ThreeDots`}
      />
    );
  }

  if (error) return `Error! ${error}`;

  return <CatalogRequestFormStateContainer {...props} data={data} />;
};

export default CatalogRequestQueryContainer;
