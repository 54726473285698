import React, { memo } from "react";
import SearchResults from "./searchResults";
import { useQuery } from "react-apollo-hooks";
import { PRODUCTS, PRODUCTS_CUSTOMER } from "./queries";
import _ from "lodash";
import { useMediaQuery } from "react-responsive";
const SearchResultsQueryContainer = memo(
  ({
    search,
    allCategories,
    closeSearch,
    mobileonclick,
    changeSearch,
    setDisplaySearch,
    token,
    tmpSearch,
  }) => {
    const mobileOrTablet = useMediaQuery({ maxWidth: 1024 });
    let finalQuery = null;
    finalQuery = token !== false ? PRODUCTS_CUSTOMER : PRODUCTS;
    const { data, loading, error } = useQuery(finalQuery, {
      variables: {
        search: `${tmpSearch}`,
        pageSize: mobileOrTablet ? "2" : "4",
      },
      fetchPolicy: "cache-first",
    });

    if (loading)
      return (
        <SearchResults
          data={{ products: { items: [] } }}
          search={search}
          allCategories={allCategories}
          closeSearch={closeSearch}
          loading={loading}
          setDisplaySearch={setDisplaySearch}
          tmpSearch={tmpSearch}
        />
      );
    if (error) return "Error!";

    return (
      <SearchResults
        data={data}
        search={search}
        allCategories={allCategories}
        closeSearch={closeSearch}
        loading={loading}
        mobileonclick={mobileonclick}
        changeSearch={changeSearch}
        setDisplaySearch={setDisplaySearch}
        tmpSearch={tmpSearch}
      />
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default SearchResultsQueryContainer;
