import React from "react";
import { connect } from "react-redux";
import ReturnPolicyFormStateContainer from "./returnPolicyForm-state-container";
import commonActions from "app/state/redux/data/common/actions";

const ReturnPolicyFormReduxContainer = (props) => {
  return <ReturnPolicyFormStateContainer {...props} />;
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (data) => {
      dispatch(commonActions.sendEmail(data));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callbackFunction) => {
      dispatch(commonActions.lock(callbackFunction));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReturnPolicyFormReduxContainer);
