import React from "react";
import HeritageOfQuality from "./heritageOfQuality";
import useSite from "app/state/hooks/siteHooks/useSite";
const HeritageOfQualityDataContainer = (props) => {
  const [, getSiteCode] = useSite();
  let homepageGalleries =
    typeof props !== undefined &&
    props &&
    props.data &&
    typeof props.data.getAllGalleryData !== undefined
      ? props.data.getAllGalleryData
      : false;

  homepageGalleries =
    homepageGalleries &&
    homepageGalleries.filter((gallery) => gallery.on_home === "true");
  homepageGalleries =
    homepageGalleries &&
    homepageGalleries.filter(
      (gallery) =>
        gallery &&
        gallery.category !== undefined &&
        gallery.category.length > 0 &&
        gallery.category[0] &&
        gallery.category[0].name ===
          (getSiteCode() === "norwell_default"
            ? "Norwell Lighting"
            : "ILEX Custom Metalcraft")
    );

  homepageGalleries =
    typeof homepageGalleries !== undefined &&
    homepageGalleries &&
    homepageGalleries.slice(0, 4);

  return <HeritageOfQuality {...props} galleries={homepageGalleries} />;
};

export default HeritageOfQualityDataContainer;
