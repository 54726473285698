import React from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import CmsBlock from "app/layout/cmsBlock";
import HeritageOfQuality from "app/pages/home/heritageOfQuality";
import CustomForm from "./customForm";
import Icon from "app/assets/icon/icon";
import CustomPageSlider from "./customPageSlider/customPageSlider";
import { useMediaQuery } from "react-responsive";

const Custom = (props) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      <div className="custom-page-image-wrapper">
        <CmsBlock id="custom_page_hero_image" dangHtml={true} />
      </div>
      <MDBContainer className="custom-page-wrapper">
        {!isMobileOrTablet && (
          <MDBRow fluid className="breadcrumbs-container">
            <Breadcrumbs />
          </MDBRow>
        )}
        <MDBContainer className="title-custom-page">
          <h1 className="wow slideInDown">Custom Capabilities</h1>
        </MDBContainer>

        <MDBContainer className="custom-page-text-above-image wow slideInLeft">
          <label className="line-in-front-of-text"></label>
          <CmsBlock id="custom_page_text_above_image" dangHtml={true} />
        </MDBContainer>
        <MDBRow className="wrapper-four-cols">
          <MDBCol className="custom-page-col wow slideInUp" md="3">
            <Icon icon={"sketch_to_cad"} />

            <CmsBlock id="custom_page_first_col" dangHtml={true} />
          </MDBCol>
          <MDBCol className="custom-page-col  wow slideInUp delay-1s" md="3">
            <Icon icon={"quality_assurance"} />
            <CmsBlock id="custom_page_second_col" dangHtml={true} />
          </MDBCol>

          <MDBCol className="custom-page-col  wow slideInUp delay-2s" md="3">
            <Icon icon={"competetive_pricing"} />
            <CmsBlock id="custom_page_third_col" dangHtml={true} />
          </MDBCol>
          <MDBCol className="custom-page-col  wow slideInUp delay-3s" md="3">
            <Icon icon={"expert_knowledge"} />
            <CmsBlock id="custom_page_fourth_col" dangHtml={true} />
          </MDBCol>
        </MDBRow>
        <div className="custom-page-slider-mobile">
          <CustomPageSlider />
        </div>
      </MDBContainer>
      <div>
        <HeritageOfQuality />
      </div>
      <MDBContainer className="request-a-quote-wrapper">
        <MDBRow>
          <MDBCol md="6">
            <CmsBlock id="custom_page_request_a_quote_image" dangHtml={true} />
          </MDBCol>
          <MDBCol md="6">
            <CustomForm />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Custom;
