import { commonActions } from "../../redux/data/common";
import { useDispatch, useSelector } from "react-redux";

function useCustomer() {
  const customer = useSelector((state) => state.customer.data);
  let dispatch = useDispatch();

  const getCustomer = () => {
    // logic to return customer information from redux
    return customer;
  };

  const isLoggedIn = () => {
    if (customer.token === false) {
      return false;
    }

    return customer.token;
  };

  return [getCustomer, isLoggedIn];
}

export default useCustomer;
