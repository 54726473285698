import React from "react";
import { withRouter } from "react-router-dom";
import ProductDataContainer from "./product-data-container";
import { useQuery } from "@apollo/react-hooks";
import { PRODUCT } from "./queries";

const ProductQueryContainer = (props) => {
  let { productSku } = props;

  let filter = { filters: { sku: { eq: productSku } } };

  let FINAL_QUERY = PRODUCT;
  const { loading, error, data } = useQuery(FINAL_QUERY, {
    variables: filter,
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return <ProductDataContainer {...props} data={{}} loading={loading} />;
  }

  if (error) {
    return <></>;
  }

  return (
    <>
      <ProductDataContainer {...props} data={data} loading={loading} />
    </>
  );
};

export default withRouter(ProductQueryContainer);
