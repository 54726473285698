// logic is inversed, true means that an error exists

import { emailValidation } from "app/config/customer/validationRules";

export const validate = (firstName, lastName, email, phone, typeOfProject) => {
  return {
    email: email.length === 0 || ValidateEmail(email),
    firstName: firstName.length === 0,
    lastName: lastName.length === 0,
    phone: phone.length === 0,
    typeOfProject: typeOfProject.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
