import React from "react";
import CategoryProductsQueryContainer from "./category-products-query-container";

const CategoryQueryContainer = (props) => {
  return (
    <CategoryProductsQueryContainer
      {...props}
      setFilters={props.setFilters}
      filters={props.filters}
    />
  );
};

export default CategoryQueryContainer;
