import React from "react";
import uuid from "react-uuid";
import history from "core/state/redux/history";
import { Link } from "react-router-dom";

const MenuDesktop = (props) => {
  let { navbarRef, menuItems, site } = props;
  return (
    <div ref={navbarRef} className="header-menu">
      <div className={`navbar`}>
        {Object.keys(menuItems).map((element, index) => {
          let currentMenuItem = menuItems[index];

          if (menuItems[index]?.include_in_menu === 0) {
            return <></>;
          }
          return (
            <div
              className={`nav-link`}
              id={`nav-dropdown-${index}`}
              key={uuid()}
            >
              <div
                className={`text-decoration-none main-nav-link`}
                onClick={() => {
                  history.push(`/${currentMenuItem.url_path}`);
                }}
              >
                {currentMenuItem.name}
              </div>
              <ul className="navigation-submenu-container">
                {currentMenuItem.children.map((child, index) => {
                  return (
                    <li
                      style={{
                        animationDelay: (index + 1) * 60 + "ms",
                      }}
                    >
                      <Link
                        key={uuid()}
                        className="navigation-submenu-link"
                        to={`/${child.url_path}`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        {child.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
        <div className={`nav-link`}>
          <div
            onClick={() => history.push(`/gallery`)}
            className={`text-uppercase text-decoration-none main-nav-link`}
          >
            GALLERY
          </div>
        </div>
        <div className={`nav-link`}>
          <div
            onClick={() => history.push(`/catalog`)}
            className={`text-uppercase text-decoration-none main-nav-link`}
          >
            CATALOG
          </div>
        </div>
        {site === "ilex_default" && (
          <div className={`nav-link`}>
            <div
              onClick={() => history.push(`/custom`)}
              className={`text-uppercase text-decoration-none main-nav-link`}
            >
              CUSTOM
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MenuDesktop;
