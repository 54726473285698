import React from "react";
import { MDBModal, MDBModalBody, MDBContainer } from "mdbreact";
import CustomerRequestForm from "./catalog-request-form";

const CatalogRequestPopupModal = (props) => {
  let { catalogRequest, toggle } = props;

  return (
    <>
      <MDBContainer className="catalog-request-modal-wrapper">
        <MDBModal
          isOpen={catalogRequest}
          toggle={toggle}
          className={"modal-wrapper catalog-request-modal"}
          centered
        >
          <MDBModalBody>
            <CustomerRequestForm toggle={toggle} />
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    </>
  );
};

export default CatalogRequestPopupModal;
