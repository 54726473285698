import React, { useState } from "react";
import useSite from "app/state/hooks/siteHooks/useSite";
import Icon from "app/assets/icon/icon";
import ReactLoader from "app/layout/loader";

const MainImage = ({
  item,
  index,
  finish,
  isMobileOrTablet,
  is_new,
  popupGallery,
  setPopupGallery,
  setActiveImage,
  setActiveImageIndex,
}) => {
  const [, getSiteCode] = useSite();
  const [zoom, setZoom] = useState({
    backgroundPosition: "0% 0%",
  });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setZoom({ backgroundPosition: `${x}% ${y}%` });
  };
  const imageSource = finish ? item.image : item.url;

  return (
    <>
      <div
        className={"figure " + isMobileOrTablet}
        onMouseMove={(e) => !isMobileOrTablet && handleMouseMove(e)}
        style={{
          cursor: "pointer",
          backgroundImage: `url(${imageSource})`,
          ...zoom,
        }}
      >
        <label className={`product-new-label`}>
          {is_new ? (
            <Icon
              icon={
                getSiteCode() === "norwell_default"
                  ? "norwell_new_label"
                  : "ilex_new_label"
              }
            />
          ) : (
            <></>
          )}
        </label>
        {imageSource === "" ? (
          <ReactLoader
            color={"#3c424d"}
            width={50}
            height={50}
            type={`Oval`}
            key={"loader-items"}
          />
        ) : (
          <>
            <img
              onClick={() => {
                if (!isMobileOrTablet) {
                  setPopupGallery(!popupGallery);
                  setActiveImage(imageSource);
                  setActiveImageIndex(index);
                }
              }}
              className={
                imageSource &&
                imageSource.includes("placeholder") &&
                "placeholder"
              }
              key={`product-media-gallery-item-${index}`}
              src={
                imageSource && imageSource.includes("placeholder")
                  ? "/placeholder.png"
                  : imageSource
              }
              alt={finish ? item.title : item.label}
            />
            {!isMobileOrTablet && (
              <Icon
                onClick={() => setPopupGallery(!popupGallery)}
                icon="search"
                className="search-icon"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MainImage;
