import { emailValidation } from "app/config/customer/validationRules";

export const validate = (contactEmail) => {
  return {
    contactEmail: contactEmail.length === 0 || ValidateEmail(contactEmail),
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];
  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
