import React, { memo, useState } from 'react';
import ImageContainer from './imageContainer';
import themeConfiguration from 'config/themeConfiguration';
import lodash from 'lodash';
import ImageLoader from 'react-load-image';
import ReactLoader from 'app/layout/loader';

function Preloader(props) {
  return (
    <div style={{ width: '100%' }}>
      <ReactLoader />
    </div>
  );
}

const Image = memo(
  (props) => {
    let source = '';
    if (typeof props.source === 'undefined') return '';
    if (!lodash.isUndefined(props.backend) && props.backend === true) {
      if (props.backend_public) {
        source =
          themeConfiguration.magento_url +
          themeConfiguration.media_url +
          props.source;
      } else {
        source = themeConfiguration.magento_url + props.source;
      }
    } else {
      source = props.source?.includes('http') ? props.source : props.source;
    }

    return (
      <ImageContainer {...props}>
        {/* <img
          src={source}
          style={props.style}
          alt={props.image_alt}
          loading={"lazy"}
          className={props.className}
          effect="blur"
          style={{ width: "100%" }}
          placeholderSrc={props.placeholderimage}
        /> */}
        <ImageLoader src={source}>
          <img className={props.className} />
          <div>Error!</div>
          <Preloader />
        </ImageLoader>
      </ImageContainer>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Image;
