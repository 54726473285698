import React from "react";
import Icon from "app/assets/icon/icon";

const Input = (props) => {
  let additionalAttributes = {};
  if (props.type === "textarea")
    return (
      <textarea
        style={props.style}
        placeholder={props.placeholder}
        name={props.name}
        className={`input-field input-textarea input-${props.className}`}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onClick={props.onClick}
        onBlur={props.onBlur}
        value={props.value}
        maxLength={props.maxLength}
      ></textarea>
    );
  return (
    <>
      {!props.min && !props.max && (
        <div className={`${props.wrapperClassName}`}>
          <div className="qty-click qty-click-minus" onClick={props.minusClick}>
            <Icon icon={"minusQty"} />
          </div>
          <input
            {...additionalAttributes}
            id={props.id}
            style={props.style}
            name={props.name}
            type={props.type}
            className={`input-field input-${props.className}`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
            onFocus={props.onFocus}
            onKeyPress={props.onKeyPress}
            onKeyDown={props.onKeyDown}
            pattern={props.pattern}
            tabIndex={props.tabIndex}
            maxLength={props.maxLength}
            onInput={props.onInput}
            onBlur={props.onBlur}
          />
          <div className="qty-click qty-click-plus" onClick={props.plusClick}>
            <Icon icon={"addQty"} />
          </div>
        </div>
      )}
      {props.min && props.max && (
        <div className={`${props.wrapperClassName}`}>
          <div className="qty-click" onClick={props.minusClick}>
            <Icon icon={"minusQty"} />
          </div>
          <input
            style={props.style}
            id={props.id}
            name={props.name}
            type={props.type}
            className={`input-field-range input-field ${props.className}`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            onClick={props.onClick}
            onFocus={props.onFocus}
            min={props.min}
            max={props.max}
            step={props.step}
            tabIndex={props.tabIndex}
            ref={props.ref}
            onBlur={props.onBlur}
          />
          <div className="qty-click" onClick={props.plusClick}>
            <Icon icon={"addQty"} />
          </div>
        </div>
      )}
      {props.icon && (
        <span className="icon">
          <Icon icon={props.icon} />
        </span>
      )}
      {props.required && (
        <span
          className={
            typeof props.shouldMarkError === "undefined"
              ? "required"
              : "required with-icons"
          }
        >
          {typeof props.shouldMarkError !== "undefined" ? (
            props.shouldMarkError ? (
              <Icon icon="error_icon" className="error-icon" />
            ) : props.value === "" ? (
              "Required"
            ) : (
              <Icon icon="check_mark_icon_black" className="valid-icon" />
            )
          ) : props.value === "" ? (
            "Required"
          ) : (
            <Icon icon="check_mark_icon_black" className="valid-icon" />
          )}
        </span>
      )}
    </>
  );
};

export default Input;
