import React from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Collapsible from "react-collapsible";
import CmsBlock from "app/layout/cmsBlock";
import Icon from "app/assets/icon/icon";
import useSite from "app/state/hooks/siteHooks/useSite";
import { useMediaQuery } from "react-responsive";
import SeoHelmet from "app/layout/seoHelmet";

const TermsAndConditions = (props) => {
  let termsData = props.data.getTermsConditions;
  const [, getSiteCode] = useSite();
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  return (
    <>
      <SeoHelmet title="Terms and Conditions" url={`/terms-and-conditions`} />
      <div className="privacy-policy-image-wrapper">
        <CmsBlock id="privacy_policy_image" dangHtml={true} />
      </div>
      <MDBContainer className="terms-and-conditions-wrapper">
        {!isMobileOrTablet && (
          <MDBRow fluid className="breadcrumbs-container">
            <Breadcrumbs />
          </MDBRow>
        )}
        <h1 className="wow slideInUp">Terms and Conditions</h1>
        <hr />
        <div className="collapsible-wrapper ">
          {termsData.map((item) => (
            <>
              <Collapsible
                triggerWhenOpen={
                  <div className="title-and-icon-wrapper">
                    {getSiteCode() === "norwell_default" ? (
                      <Icon icon={"arrow_up_orange_full"} />
                    ) : (
                      <Icon icon={"arrow_up_red_full"} />
                    )}
                    <h5>{` ${item.title}`}</h5>
                  </div>
                }
                trigger={
                  <div className="title-and-icon-wrapper">
                    <Icon icon="arrow_down_full" />
                    <h5>{` ${item.title}`}</h5>
                  </div>
                }
              >
                <p>{item.content}</p>
              </Collapsible>
              <hr />
            </>
          ))}
        </div>
      </MDBContainer>
    </>
  );
};
export default TermsAndConditions;
