import { contactFormSubmit } from "./contactFormSubmit";
export const handleKeyDown = (
  e,
  selectedCompany,
  companyName,
  firstName,
  lastName,
  email,
  subject,
  message,
  setTouched,
  errors,
  lock,
  setLoading,
  selectedCompanyValidation,
  setSelectedCompanyValidation,
  messageValidation,
  setMessageValidation,
  sendEmail,
  site,
  setSelectedCompany,
  setCompanyName,
  setFirstName,
  setLastName,
  setEmail,
  setSubject,
  setMessage
) => {
  if (e.key === "Enter") {
    contactFormSubmit(
      e,
      selectedCompany,
      companyName,
      firstName,
      lastName,
      email,
      subject,
      message,
      setTouched,
      errors,
      lock,
      setLoading,
      selectedCompanyValidation,
      setSelectedCompanyValidation,
      messageValidation,
      setMessageValidation,
      sendEmail,
      site,
      setSelectedCompany,
      setCompanyName,
      setFirstName,
      setLastName,
      setEmail,
      setSubject,
      setMessage
    );
  }
};
