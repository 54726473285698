import React from "react";
import { useMediaQuery } from "react-responsive";
import { MDBContainer, MDBRow } from "mdbreact";
import OurCategoriesMobile from "./ourCategoriesMobile/ourCategoriesMobile";
import CategoryItem from "./categoryItem";

const OurCategories = (props) => {
  const site = props.site.siteCode;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const categories =
    props && props.onHomeCategories.length > 6
      ? props.onHomeCategories.slice(0, 6)
      : props.onHomeCategories;

  return (
    <>
      <MDBContainer className="our-categories-container">
        <MDBRow className="our-categories-title-container">
          <p className="subtitle wow fadeInDown">
            <span className="subtitle-line"></span>FIND OUT MORE
          </p>
          <h1 className="wow fadeInUp">
            {site === "ilex_default" ? <span> Our </span> : "Our "}
            CATEGORIES
          </h1>
        </MDBRow>
        {isTabletOrMobile ? (
          <OurCategoriesMobile
            {...props}
            site={site}
            onHomeCategories={props.onHomeCategories}
          />
        ) : (
          <MDBRow lg="12" className="our-categories-items-container">
            {categories &&
              categories.map((category, index) => {
                return (
                  <CategoryItem
                    key={`slider-slide-item-${index}`}
                    category={category}
                    site={site}
                  />
                );
              })}
          </MDBRow>
        )}
      </MDBContainer>
    </>
  );
};
export default OurCategories;
