import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { ReactComponent as NorwellLogo } from "app/assets/icon/norwell_switcher_logo.svg";
import { ReactComponent as IlexLogo } from "app/assets/icon/ilex_switcher_logo.svg";
import themeConfiguration from "config/themeConfiguration";

class Pdf extends React.Component {
  constructor(props) {
    super(props);

    this.state = { images: [] };
  }
  componentDidMount() {
    this.props.products.map((item) => {
      let image = new Image();
      image.src = item.product.image.url;
      this.setState((state) => {
        const images = [...state.images, image];

        return {
          images: images,
        };
      });

      return item;
    });
  }

  render() {
    const showPdfPersonal = this.props.showPdfPersonal;
    const contactName = this.props.contactName;
    const contactEmail = this.props.contactEmail;
    const contactCompany = this.props.contactCompany;
    const contactPhone = this.props.contactPhone;
    const pdfMessage = this.props.pdfMessage;
    const pdfFormat = this.props.pdfFormat;
    const products = this.props.products;
    const attributeOptions = this.props.attributeOptions;
    let hasDimensions = false;
    let colSize = 12;
    let prodDisplay = "column";
    let heading = 2;
    let imageHeight = "400px";
    let imageWidth = "600px";

    let descriptionBullets = false;
    switch (pdfFormat) {
      case 1:
        colSize = 12;
        prodDisplay = "column";
        imageHeight = "initial";
        imageHeight = "400px";
        imageWidth = "600px";
        heading = 3;
        descriptionBullets = true;
        break;
      case 2:
        colSize = 6;
        prodDisplay = "row";
        imageHeight = "200px";
        imageWidth = "300px";
        heading = 3;
        break;
      case 4:
        colSize = 6;
        heading = 4;
        prodDisplay = "row";
        imageHeight = "115px";
        imageWidth = "175px";
        break;
      default:
        colSize = 12;
        descriptionBullets = true;
    }
    let pages = chunkArray(products, pdfFormat);
    // Mapping products per page
    let sizeOptions = [];
    if (typeof attributeOptions !== "undefined") {
      sizeOptions = attributeOptions.attribute_options;
    }

    return (
      <MDBContainer fluid className="pdf-one-container bg-white">
        {pages.map((page) => {
          return (
            <MDBRow
              style={{
                height: "100%",
                pageBreakAfter: "auto",
                position: "relative",
              }}
            >
              <MDBCol>
                <MDBRow>
                  <MDBCol>
                    <a href={`${themeConfiguration.app_url}`}>
                      <MDBRow
                        style={{
                          height: "55px",
                          backgroundColor: "#363B45",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <MDBCol
                          md="4"
                          style={{
                            backgroundColor: "#5D6679",
                            paddingTop: "18px",
                            paddingLeft: "50px",
                            paddingRight: "20px",
                            borderRight: "1px solid #ffffff",
                          }}
                        >
                          <NorwellLogo {...this.props} />
                        </MDBCol>
                        <MDBCol
                          md="8"
                          style={{
                            marginLeft: "20px",
                            marginTop: "18px",
                          }}
                        >
                          <IlexLogo {...this.props} />
                        </MDBCol>
                      </MDBRow>
                    </a>
                  </MDBCol>
                </MDBRow>
                {showPdfPersonal === true && (
                  <MDBContainer style={{ marginTop: "3rem" }}>
                    <h4
                      style={{
                        lineHeight: "16px",
                        marginLeft: "100px",
                        color: "#1D232C",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Bold",
                      }}
                    >
                      {contactEmail.length === 0 &&
                      contactName.length === 0 &&
                      contactCompany.length === 0 &&
                      contactPhone.length === 0
                        ? ""
                        : "Details"}
                    </h4>
                    <p
                      style={{
                        lineHeight: "16px",
                        marginLeft: "100px",
                        color: "#1D232C",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                      }}
                    >
                      {contactName}
                    </p>
                    <p
                      style={{
                        lineHeight: "16px",
                        marginLeft: "100px",
                        color: "#1D232C",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                      }}
                    >
                      {contactCompany}
                    </p>
                    <p
                      style={{
                        lineHeight: "16px",
                        marginLeft: "100px",
                        color: "#1D232C",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                      }}
                    >
                      {contactEmail.length === 0
                        ? ""
                        : "Email: " + contactEmail}
                    </p>
                    <p
                      style={{
                        lineHeight: "20px",
                        marginLeft: "100px",
                        color: "#1D232C",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                      }}
                    >
                      {contactPhone}
                    </p>
                  </MDBContainer>
                )}
                <MDBRow className="pdf-one-main">
                  <MDBCol size="12">
                    <MDBRow
                      style={
                        pdfFormat === 1
                          ? {
                              marginLeft: "90px",
                              marginRight: "90px",
                            }
                          : {
                              display: "grid",
                              gridColumnGap: "10rem",
                              gridRowGap: "3rem",
                              gridTemplateColumns:
                                pdfFormat === 2 ? "1fr" : "1fr 1fr",
                              marginLeft: "90px",
                              marginRight: "90px",
                              marginTop: showPdfPersonal ? "10px" : "50px",
                            }
                      }
                    >
                      {page.map((product, index) => {
                        if (
                          typeof product.product !== "undefined" &&
                          typeof product.product.height !== "undefined" &&
                          typeof product.product.depth !== "undefined" &&
                          typeof product.product.width !== "undefined"
                        ) {
                          hasDimensions = true;
                        }

                        return (
                          <>
                            {pdfFormat === 4 ? (
                              <div
                                style={{
                                  display: "inline-block",
                                  marginTop: "10px",
                                }}
                              >
                                <img
                                  style={{
                                    border: "3px solid #EAEAEA",
                                    marginLeft: "10px",
                                    height: imageHeight,
                                    width: imageWidth,
                                    objectFit: "contain",
                                    padding: "5px",
                                  }}
                                  src={product.product.image.url}
                                  alt={`product`}
                                />
                                <p
                                  style={{
                                    marginLeft: "10px",
                                    color: "#1D232C",
                                    fontSize: "14px",
                                    fontFamily: "Montserrat-Regular",
                                    lineHeight: "14px",
                                  }}
                                >
                                  SKU {product.product.sku}
                                </p>
                                <p
                                  style={{
                                    marginLeft: "10px",
                                    color: "#1D232C",
                                    fontSize: "16px",
                                    fontFamily: "Montserrat-Bold",
                                    lineHeight: "22px",
                                    maxWidth: "250px",
                                  }}
                                >
                                  {product.product.name}
                                </p>
                                <p
                                  style={{
                                    marginLeft: "10px",
                                    color: "#1D232C",
                                    fontSize: "14px",
                                    fontFamily: "Montserrat-regular",
                                    lineHeight: "18px",
                                    marginTop: "0",
                                  }}
                                >
                                  Height: {product.product.height} Width:{" "}
                                  {product.product.width}
                                </p>
                                <p
                                  style={{
                                    marginBottom: "0px",
                                    marginLeft: "10px",
                                    color: "#1D232C",
                                    fontSize: "16px",
                                    fontFamily: "Montserrat-Bold",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {product.product.brand === "5510"
                                    ? "Ilex"
                                    : "Norwell"}
                                </p>
                              </div>
                            ) : (
                              <MDBRow
                                style={{
                                  display: "flex",
                                  flexDirection: prodDisplay,
                                  marginTop: showPdfPersonal ? "10px" : "50px",
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <MDBCol
                                  md={colSize}
                                  style={{ marginRight: "30px" }}
                                >
                                  <img
                                    style={{
                                      border: "3px solid #EAEAEA",
                                      margin: 0,
                                      marginTop: "10px",
                                      height: imageHeight,
                                      width: imageWidth,
                                      objectFit: "contain",
                                      padding: "5px",
                                    }}
                                    src={product.product.image.url}
                                    alt={`product`}
                                  />
                                </MDBCol>
                                <MDBCol
                                  md={colSize}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop:
                                      pdfFormat === 2 ? "10px" : "20px",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: 0,
                                      color: "#1D232C",
                                      fontSize: "14px",
                                      fontFamily: "Montserrat-Regular",
                                    }}
                                  >
                                    SKU {product.product.sku}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      color: "#1D232C",
                                      fontSize: "24px",
                                      lineHeight: "42px",
                                      fontFamily: "Montserrat-Bold",
                                    }}
                                  >
                                    {product.product.name}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      color: "#1D232C",
                                      fontSize: "16px",
                                      fontFamily: "Montserrat-regular",
                                    }}
                                  >
                                    {product.product.height &&
                                      "Height: " + product.product.height}{" "}
                                    {product.product.height &&
                                      product.product.width &&
                                      " x "}
                                    {product.product.width &&
                                      "Width: " + product.product.width}
                                  </p>
                                  <p
                                    style={{
                                      margin: 0,
                                      marginBottom: "40px",
                                      marginTop: "10px",
                                      color: "#1D232C",
                                      fontSize: "16px",
                                      fontFamily: "Montserrat-Bold",
                                    }}
                                  >
                                    {product.product.brand === "5510"
                                      ? "Ilex"
                                      : "Norwell"}
                                  </p>
                                </MDBCol>
                              </MDBRow>
                            )}
                          </>
                        );
                      })}
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
                {pdfMessage && (
                  <MDBContainer
                    style={{
                      position: "absolute",
                      bottom: "80px",
                      width: "100%",
                    }}
                  >
                    <hr
                      style={{
                        border: "1px solid #EAEAEA",
                        marginLeft: "100px",
                        marginRight: "100px",
                        marginTop: pdfFormat === 2 ? "100px" : "30px",
                      }}
                    />
                    <p
                      style={{
                        marginRight: "100px",
                        marginLeft: "100px",
                        color: "#1D232C",
                        fontSize: "16px",
                        fontFamily: "Montserrat-Regular",
                      }}
                    >
                      {pdfMessage}
                    </p>
                  </MDBContainer>
                )}
                <MDBContainer
                  style={{
                    position: "fixed",
                    bottom: "0",
                    width: "100%",
                    backgroundColor: "#363B45",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      color: "#ffffff",
                      fontFamily: "Montserrat-Bold",
                    }}
                  >
                    www.ilex.com
                  </p>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          );
        })}
      </MDBContainer>
    );

    function chunkArray(myArray, chunk_size) {
      var index = 0;
      var arrayLength = myArray.length;
      var tempArray = [];

      for (index = 0; index < arrayLength; index += chunk_size) {
        let myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
      }

      return tempArray;
    }
  }
}

export default Pdf;
