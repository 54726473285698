export default {
  // ilex_dev: {
  //   siteUrl: "https://ilexpwa.joladev2.com",
  //   siteCode: "ilex_dev",
  // },
  // norwell_dev: {
  //   siteUrl: "https://norwellpwa.joladev2.com",
  //   siteCode: "norwell_dev",
  // },
  norwell: {
    siteUrl: "https://www.norwellinc.com",
    siteCode: "norwell_default",
  },
  ilex: {
    siteUrl: "https://www.ilexlight.com",
    siteCode: "ilex_default",
  },
};
