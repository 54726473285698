import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

export default (props, activeSlide, setActiveSlide) => {
  return {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    dotsClass: props.dotsClass,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    lazyLoad: "progressive",
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    afterChange: (index) => {
      setActiveSlide(index);
    },
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <MDBRow
        id="slider-indicators-container"
        style={{
          padding: "15px",
          textAlign: "center",
        }}
      >
        <MDBCol className="indicators-container">
          <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
            {dots}
          </ul>
        </MDBCol>
        <MDBCol className="slide-title-container">
          <h6>
            {props.data && props.data.slider.slides[activeSlide].overlay_title}
          </h6>
        </MDBCol>
      </MDBRow>
    ),
  };
};
