import React, { useState } from "react";
import SidebarItemSlider from "./sidebarItemSlider";
import { useMediaQuery } from "react-responsive";
import Icon from "app/assets/icon/icon";
import { getActiveFilters } from "app/pages/catalog/category/sidebar/functions";
import BrandSwitchFilter from "app/layout/filters/brandSwitchFilter";

const SidebarItem = (props) => {
  let { isSwitch, filter } = props;
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  let activeFilters = getActiveFilters(props.filters?.data?.aggregations);
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0);

  return (
    <>
      {isMobileOrTablet && filter.attribute_code === "brand" ? (
        filter.options.map((option, index) => {
          return (
            <BrandSwitchFilter
              key={index}
              filterOption={option}
              filter={filter}
              isSwitch={true}
              index={index}
            />
          );
        })
      ) : (
        <>
          <div
            className={
              isMobileOrTablet && toggleDropdown
                ? "filter-label mobile active"
                : isMobileOrTablet
                ? "filter-label mobile"
                : "filter-label"
            }
            onClick={() => setToggleDropdown(!toggleDropdown)}
          >
            {filter.label}
            {isMobileOrTablet && (
              <div className="selected-filters-container">
                {activeFiltersNumber > 0 && (
                  <p className="number-of-selected-filters">
                    {activeFiltersNumber} Selected
                  </p>
                )}

                <Icon
                  icon={toggleDropdown ? "black_arrow_up" : "black_arrow_down"}
                />
              </div>
            )}
          </div>
          <div
            className={
              isMobileOrTablet && toggleDropdown
                ? "multi-filter-data-wrapper mobile active " +
                  filter.attribute_code
                : isMobileOrTablet
                ? "multi-filter-data-wrapper mobile " + filter.attribute_code
                : "multi-filter-data-wrapper " + filter.attribute_code
            }
          >
            {filter.attribute_code === "brand" ? (
              filter.options.map((option, index) => {
                return (
                  <BrandSwitchFilter
                    key={index}
                    filterOption={option}
                    filter={filter}
                    isSwitch={true}
                    index={index}
                  />
                );
              })
            ) : (
              <SidebarItemSlider
                isSwitch={isSwitch}
                filter={filter}
                activeFilters={activeFilters}
                activeFiltersNumber={activeFiltersNumber}
                setActiveFiltersNumber={setActiveFiltersNumber}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SidebarItem;
