// Customer Middleware

import types from "./types";
import loginCustomer from "./middleware/loginCustomer";
import _setCustomerToken from "./middleware/_setCustomerToken";
import logoutCustomer from "./middleware/logoutCustomer";
import getCustomerInformation from "./middleware/getCustomerInformation";
import setCustomerInformation from "./middleware/setCustomerInformation";
import registerB2CCustomer from "./middleware/registerB2CCustomer";
import registerB2BCustomer from "./middleware/registerB2BCustomer";
import createEmptyCart from "./middleware/createEmptyCart";
import setCartToken from "./middleware/setCartToken";
import getCartInformation from "./middleware/getCartInformation";
import addItemToCart from "./middleware/addItemsToCart";
import removeItemFromCart from "./middleware/removeItemFromCart";
import updateCart from "./middleware/updateCart";
import clearCart from "./middleware/clearCart";
import updateCustomerInformation from "./middleware/updateCustomerInformation";
import newsletterCustomerSubscribe from "./middleware/newsletterCustomerSubscribe";
import createWishlist from "./middleware/createWishlist";
import deleteWishlist from "./middleware/deleteWishlist";
import renameWishlist from "./middleware/renameWishlist";
import addProductToWishlist from "./middleware/addProductToWishlist";
import removeItemFromWishlist from "./middleware/removeItemFromWishlist";
import copyWishlistItem from "./middleware/copyWishlistItem";
import setGuestEmailOnCart from "./middleware/cart/setGuestEmailOnCart";
import setBillingAddressOnCart from "./middleware/cart/setBillingAddressOnCart";
import setShippingAddressOnCart from "./middleware/cart/setShippingAddressOnCart";
import setShippingMethodsOnCart from "./middleware/cart/setShippingMethodsOnCart";
import shareWishlist from "./middleware/shareWishlist";
import editCustomerAddress from "./middleware/editCustomerAddress";
import forgotCustomerPassword from "./middleware/forgotCustomerPassword";
import resetCustomerPassword from "./middleware/resetCustomerPassword";
import placeOrder from "./middleware/cart/placeOrder";
import setPaymentMethodOnCart from "./middleware/cart/setPaymentMethodOnCart";
import applyCouponToCart from "./middleware/cart/applyCouponToCart";
import removeCouponFromCart from "./middleware/cart/removeCouponFromCart";
import setPoNumber from "./middleware/cart/setPoNumber";
import setOrderNotes from "./middleware/cart/setOrderNotes";
import createCustomerAddress from "./middleware/createCustomerAddress";
import addRecentlyViewedProduct from "./middleware/addRecentlyViewedProduct";

const customerMiddleware = (store) => {
  return (next) => {
    return async (action) => {
      next(action);
      switch (action.type) {
        case types.REGISTER_B2C_CUSTOMER:
          return registerB2CCustomer(store, action);

        case types.REGISTER_B2B_CUSTOMER:
          return registerB2BCustomer(store, action);

        case types.LOGIN_CUSTOMER:
          return loginCustomer(store, action);

        case types.LOGOUT_CUSTOMER:
          return logoutCustomer(store, action);

        case types._SET_CUSTOMER_TOKEN:
          return _setCustomerToken(store, action);

        case types.GET_CUSTOMER_INFORMATION:
          return getCustomerInformation(store, action);

        case types.UPDATE_CUSTOMER_INFORMATION:
          return updateCustomerInformation(store, action);

        case types.SET_CUSTOMER_INFORMATION:
          return setCustomerInformation(store, action);

        case types.CREATE_EMPTY_CART:
          return createEmptyCart(store, action);

        case types.SET_CART_TOKEN:
          return setCartToken(store, action);

        case types.GET_CART_INFORMATION:
          return getCartInformation(store, action);

        case types.ADD_PRODUCT_TO_CART:
          return addItemToCart(store, action);

        case types.REMOVE_PRODUCT_FROM_CART:
          return removeItemFromCart(store, action);

        case types.UPDATE_CART:
          return updateCart(store, action);

        case types.CLEAR_CART:
          return clearCart(store, action);

        case types.NEWSLETTER_CUSTOMER_SUBSCRIBE:
          return newsletterCustomerSubscribe(store, action);

        case types.CREATE_WISHLIST:
          return createWishlist(store, action);

        case types.DELETE_WISHLIST:
          return deleteWishlist(store, action);

        case types.RENAME_WISHLIST:
          return renameWishlist(store, action);

        case types.ADD_PRODUCT_TO_WISHLIST:
          return addProductToWishlist(store, action);

        case types.REMOVE_ITEM_FROM_WISHLIST:
          return removeItemFromWishlist(store, action);

        case types.COPY_WISHLIST_ITEM:
          return copyWishlistItem(store, action);

        case types.SET_GUEST_EMAIL_ON_CART:
          return setGuestEmailOnCart(store, action);

        case types.SET_BILLING_ADDRESS_ON_CART:
          return setBillingAddressOnCart(store, action);

        case types.SET_SHIPPING_ADDRESSES_ON_CART:
          return setShippingAddressOnCart(store, action);

        case types.SET_SHIPPING_METHODS_ON_CART:
          return setShippingMethodsOnCart(store, action);

        case types.SHARE_WISHLIST:
          return shareWishlist(store, action);

        case types.EDIT_CUSTOMER_ADDRESS:
          return editCustomerAddress(store, action);

        case types.FORGOT_CUSTOMER_PASSWORD:
          return forgotCustomerPassword(store, action);

        case types.RESET_CUSTOMER_PASSWORD:
          return resetCustomerPassword(store, action);

        case types.PLACE_ORDER:
          return placeOrder(store, action);

        case types.SET_PAYMENT_METHOD_ON_CART:
          return setPaymentMethodOnCart(store, action);

        case types.APPLY_COUPON_TO_CART:
          return applyCouponToCart(store, action);

        case types.REMOVE_COUPON_FROM_CART:
          return removeCouponFromCart(store, action);

        case types.CREATE_CUSTOMER_ADDRESS:
          return createCustomerAddress(store, action);

        case types.SET_PO_NUMBER:
          return setPoNumber(store, action);

        case types.SET_ORDER_NOTES:
          return setOrderNotes(store, action);

        case types.ADD_RECENTLY_VIEWED_PRODUCT:
          return addRecentlyViewedProduct(store, action);

        default:
          return;
      }
    };
  };
};

export default customerMiddleware;
