export const finishesSliderSettings = (
  data,
  isMobileOrTablet,
  setActiveFinish
) => {
  return {
    dots: false,
    infinite:
      data.finishes && data.finishes.length > 4
        ? isMobileOrTablet
          ? false
          : true
        : false,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    dotsClass: data.dotsClass,
    slidesToScroll: 1,
    swipeToSlide: false,
    initialSlide: 0,
    lazyLoad: "progressive",
    focusOnSelect: false,
    vertical: isMobileOrTablet ? false : true,
    verticalSwiping: isMobileOrTablet ? false : true,
    linear: true,
    afterChange: (index) => {
      setActiveFinish(index);
    },
  };
};
