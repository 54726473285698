import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { substringToUppercase } from "./function";

const NorwellSpecTable = (props) => {
  let { specification } = props;
  let newSpecification =
    specification && specification[0] && Object.entries(specification[0]);
  newSpecification =
    newSpecification &&
    newSpecification.filter(
      (item) =>
        item[1] !== null && item[1] !== "false" && item[0] !== "__typename"
    );

  const [, setSliderNav] = useState(null);
  let sliderSpec = null;
  useEffect(() => {
    setSliderNav(sliderSpec);
  }, []);

  return (
    <>
      {typeof props.specification === "undefined" ? (
        <div className="skeleton-wrapper">
          <SkeletonTheme
            className="test"
            color="#5d6679"
            highlightColor="#8d97ad"
          >
            <p>
              <Skeleton count={5} />
            </p>
          </SkeletonTheme>
          <SkeletonTheme
            className="test"
            color="#5d6679"
            highlightColor="#8d97ad"
          >
            <p>
              <Skeleton count={5} />
            </p>
          </SkeletonTheme>
        </div>
      ) : (
        <div className="spec-table-slider">
          {newSpecification &&
            newSpecification.map((attribute) => {
              let title = attribute[0].replace(/_/g, " ");
              title = title.replace(/\w\S*/g, function (txt) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              });

              if (title === "Add Prod Info") {
                title = "Product information";
              }
              if (title.toLowerCase() === "uletl listed") {
                title = "UL/ETL Listed";
              }

              title = substringToUppercase(title, "Led");
              title = substringToUppercase(title, "Ada");
              title = substringToUppercase(title, "Tto");
              return (
                <MDBRow className="attribute-item">
                  <MDBCol size="5">
                    <p>{title || <Skeleton />}</p>
                  </MDBCol>
                  <MDBCol size="7">
                    <p>{attribute[1] || <Skeleton />}</p>
                  </MDBCol>
                </MDBRow>
              );
            })}
        </div>
      )}
    </>
  );
};

export default NorwellSpecTable;
