import { emailValidation } from "app/config/customer/validationRules";

export const validate = (email, password) => {
  return {
    email: email.length === 0 || ValidateEmail(email),
    password: password.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
