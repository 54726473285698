import apolloClient from "core/graphql/apolloClient";
import customerActions from "../actions";
import commonActions from "../../common/actions";
import messagesActions from "core/state/redux/data/messages/actions";
import { gql } from "graphql.macro";

const DELETE_WISHLIST_QUERY = gql`
  mutation DeleteWishlist($id: Int) {
    deleteWishlist(input: $id)
  }
`;
export default async (store, action) => {
  try {
    let wishlists = { ...store.getState().customer.data.wishlist };
    // if (parseInt(wishlists.id) !== action.id) {
    const { data } = await apolloClient.mutate({
      mutation: DELETE_WISHLIST_QUERY,
      variables: action,
    });

    if (data && data.deleteWishlist !== null) {
      let found = wishlists.multiwishlist_data.filter(({ id }) => {
        return id === action.id;
      });

      let newItems = wishlists.items.filter(({ id }) => {
        return !found[0].items.find((x) => x === parseInt(id));
      });

      let filtered = wishlists.multiwishlist_data.filter(({ id }) => {
        return id !== action.id;
      });
      wishlists.items = [...newItems];
      wishlists.multiwishlist_data = [...filtered];
      store.dispatch(customerActions._reduceWishlist(wishlists));
      store.dispatch(
        messagesActions.addMessage(`successfully deleted project `, "success")
      );
    }
    store.dispatch(commonActions.unlock("success"));
    // } else {
    //   store.dispatch(
    //     messagesActions.addMessage("unable to delete main wishlist", "danger")
    //   );
    // }
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.graphQLErrors[0].debugMessage, "danger")
    );
    store.dispatch(commonActions.unlock("fail"));
  }
};
