import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

const Accountinformation = (props) => {
  return (
    <MDBRow className="mb-md-5 mb-sm-5 mb-xs-5">
      <MDBCol lg="12">
        <MDBRow>
          <MDBCol lg="6" className="mb-lg-4 acc-dashboard-section">
            <MDBRow>
              <MDBCol lg="12">
                <h4 className="gray medium">Contact Information</h4>
              </MDBCol>
              <MDBCol lg="12">
                <p className="light-gray">
                  {props.customer.firstname} {props.customer.lastname}
                  <br />
                  {props.customer.email}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol lg="6" className="mb-lg-4 acc-dashboard-section">
            <MDBRow>
              <MDBCol lg="12">
                <h4 className="gray medium">Newsletter</h4>
              </MDBCol>
              <MDBCol lg="12">
                <p className="light-gray">
                  {props.customer.is_subscribed
                    ? "You are subscribed to our newsletter"
                    : "Your aren't subscribed to our newsletter"}
                </p>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <MDBRow className="links-wrapper">
          <MDBCol lg="6">
            <Link to="/customer/information" className={"link-elaine"}>
              Edit
            </Link>
            <Link
              className={`link-elaine ml-5`}
              to={{
                pathname: "/customer/information",
                state: { changePassword: true },
              }}
            >
              Change Password
            </Link>
          </MDBCol>
          <MDBCol lg="6" className="newsletter-edit">
            <Link to="/customer/newsletter">Edit</Link>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default Accountinformation;
