import React from "react";
import Hotspot from "./hotspot";
import { useQuery } from "@apollo/react-hooks";
import { isEmpty } from "core/helpers/functions";
import { gql } from "graphql.macro";

const newQuery = gql`
  query Products(
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      filter: $filters
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      total_count
      items {
        id
        name
        sku
        url_key
        image {
          label
          url
        }
      }
    }
  }
`;
const HotspotQueryLoader = (props) => {
  let dots = [];
  let skuArray = [];
  let contentArray = props.content ? JSON.parse(props.content) : null;
  if (contentArray !== null && contentArray !== undefined) {
    contentArray.map((dot) => {
      if (dot.sku !== "") skuArray.push(dot.sku);
      dots.push({
        top: dot.top,
        left: dot.left,
        display_type: dot.display_type,
        sku: dot.sku,
        addInfo: dot.addInfo,
      });
      return dots;
    });
  }
  const { data, loading, error } = useQuery(newQuery, {
    context: {
      headers: {
        store: "norwell_default",
      },
    },
    variables: { filters: { sku: { in: skuArray } } },
  });
  const urlKeyQuery = useQuery(newQuery, {
    variables: { filters: { url_key: { in: skuArray } } },
  });

  if (loading) return <></>;
  if (error) return <></>;

  if (!isEmpty(skuArray)) {
    if (data !== undefined) {
      if (data.products.total_count !== 0) {
        data.products.items.map((info) => {
          let found = null;
          dots.find((value, index) => {
            if (value.sku === info.sku) {
              found = index;
            }
          });
          dots[found].image = info.image.url;
          dots[found].name = info.name;
          dots[found].content = info.name;
          dots[found].url = info.url_key;
        });
      } else {
        if (urlKeyQuery.loading) return <></>;
        urlKeyQuery.data.products.items.map((info) => {
          let found = null;
          dots.find((value, index) => {
            if (value.sku === info.sku) {
              found = index;
            }
          });
          dots[found].image = info.image.url;
          dots[found].name = info.name;
          dots[found].content = info.name;
          dots[found].url = info.url_key;
        });
      }
    }
  }

  return (
    <>
      <Hotspot dots={dots} {...props} />
    </>
  );
};

export default HotspotQueryLoader;
