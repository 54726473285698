import React from "react";
import { createRequestFromFilters, createSortVariable } from "./functions";
import _ from "lodash";
import WishlistShareListing from "./wishlistShareListing/wishlistShareListing";

const WishlistShareFilterQueryContainer = (props) => {
  let { filters, wishlistskus } = props;
  let variables = {};
  let request = createRequestFromFilters(filters.items, wishlistskus);
  let sort = createSortVariable(filters.sort);
  let pageSize = 12;

  if (!_.isEmpty(request)) {
    if (request.filter === 0) {
      variables = {
        filters: request.filter,
        pageSize:
          filters && filters.data ? filters.data.page_info.page_size : pageSize,
        currentPage:
          typeof props.pageNumber !== "undefined" &&
          typeof props.pageNumber === "number"
            ? props.pageNumber
            : 1,
      };
      variables["filters"]["sku"]["in"] = wishlistskus;
      variables["sort"] = sort;
    } else {
      variables = {
        filters: request.filter,
        sort: sort,
        pageSize:
          filters && filters.data ? filters.data.page_info.page_size : pageSize,
        currentPage:
          typeof props.pageNumber !== "undefined" &&
          typeof props.pageNumber === "number"
            ? props.pageNumber
            : 1,
      };
    }
  }

  return <WishlistShareListing {...props} />;
};

export default WishlistShareFilterQueryContainer;
