import React from "react";
import { connect } from "react-redux";
import WishlistPageListing from "./wishlistPageListing";

const WishlistPageListingReduxContainer = (props) => {
  return <WishlistPageListing {...props} />;
};

const mapStateToProps = (state) => ({
  attributeOptions:
    state.queries !== undefined
      ? state.queries.data !== undefined
        ? typeof state.queries.data.attributes_metadata_information !==
          "undefined"
          ? state.queries.data.attributes_metadata_information.data
              .customAttributeMetadata.items[0]
          : []
        : []
      : [],
});

const mapDispatchToProps = () => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistPageListingReduxContainer);
