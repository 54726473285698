import Homepage from "app/pages/home";
import Gallery from "app/pages/gallery";
import GalleryDetail from "app/pages/galleryDetail";
import Login from "app/pages/customer/login";
import Typography from "app/pages/typography";
import AboutUs from "app/pages/static/about-us";
import ContactPage from "app/pages/static/contact";
import WhereToBuy from "app/pages/static/whereToBuy";
import FindRep from "app/pages/static/find-a-rep";
import ShopOnline from "app/pages/static/shop-online";
import QuoteRequest from "app/pages/quoteRequest";
import QuoteSuccess from "app/pages/quoteRequest/quoteSuccess";
import Customer from "app/pages/customer/account";
import SearchResults from "app/pages/catalog/category";
import WishlistShare from "app/pages/static/wishlist-share";
import Favorites from "app/pages/customer/account/pages/wishlistCore";
import Catalogs from "app/pages/catalog/catalogPage";
import CatalogDetails from "app/pages/catalog/catalogPage/catalog-detail";
import Inventory from "app/pages/inventory";
import PrivacyPolicy from "app/pages/static/privacyPolicy";
import ReturnPolicy from "app/pages/static/returnPolicy";
import Press from "app/pages/static/press";
import TermsAndConditions from "app/pages/static/termsAndConditions";
import Custom from "app/pages/static/custom";
import ResetPasswordPage from "app/pages/customer/resetPassword/resetPasswordPage";
import SiteMap from "app/pages/siteMap";

export default {
  home: { component: Homepage, exact: false },
  gallery: { component: Gallery, exact: false },
  "gallery-detail": { component: GalleryDetail, exact: false },
  login: { component: Login, exact: false },
  typography: { component: Typography, exact: false },
  customer: { component: Customer, exact: false },
  "quote-request": { component: QuoteRequest, exact: true },
  "quote-success": { component: QuoteSuccess, exact: true },
  "search-results": { component: SearchResults, exact: false },

  //static pages
  "where-to-buy": { component: WhereToBuy, exact: true },
  "where-to-buy/shop-online": { component: ShopOnline, exact: true },
  "find-a-rep": { component: FindRep, exact: true },
  "find-a-rep/shop-online": { component: ShopOnline, exact: true },
  "contact-us": { component: ContactPage, exact: false },
  "about-us": { component: AboutUs, exact: false },
  "favorites/wishlist-share": { component: WishlistShare, exact: false },
  favorites: { component: Favorites, exact: false },
  catalog: { component: Catalogs, exact: false },
  "catalog-details": {
    component: CatalogDetails,
    exact: false,
  },
  inventory: { component: Inventory, exact: false },
  "privacy-policy": { component: PrivacyPolicy, exact: false },
  "return-policy": { component: ReturnPolicy, exact: false },
  press: { component: Press, exact: false },
  "terms-and-conditions": {
    component: TermsAndConditions,
    exact: false,
  },
  custom: { component: Custom, exact: false },
  "reset-password": { component: ResetPasswordPage, exact: false },
  "site-map": { component: SiteMap, exact: false },
};

export { default as ErrorPage } from "app/pages/error";

export { default as TemporaryPage } from "app/pages/temporary";

export const DefaultPage = Homepage;
