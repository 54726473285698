import React from 'react';
import { MDBInput } from 'mdbreact';

const CheckButton = ({ onChange, className, wrapperClass, ...inputProps }) => {
  return (
    <div onClick={onChange} className={className}>
      <MDBInput {...inputProps} className={`check ${wrapperClass}`}>
        <span className={wrapperClass}></span>
        {inputProps.children}
      </MDBInput>
    </div>
  );
};

export default CheckButton;
