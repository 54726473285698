import React from "react";
import PressItemModal from "./pressItemModal";

const PressItemModalDataContainer = (props) => {
  let {
    activeGallery,
    setActiveGallery,
    openModal,
    setOpenModal,
    pressGalleries,
  } = props;

  return (
    <PressItemModal
      selectedGallery={activeGallery}
      setSelectedGallery={setActiveGallery}
      openModal={openModal}
      setOpenModal={setOpenModal}
      pressGalleries={pressGalleries}
    />
  );
};

export default PressItemModalDataContainer;
