import { handleSubmit } from "./handleSubmit";
export const handleKeyDown = (
  e,
  setTouched,
  errors,
  setLoading,
  subscribe,
  email,
  firstName,
  lastName,
  whatDescribesYou,
  whatDescribesYouValidation,
  setWhatDescribesYouValidation,
  setFirstName,
  setLastName,
  setEmail,
  setWhatDescribesYou,
  lock
) => {
  if (e.key === "Enter") {
    handleSubmit(
      e,
      setTouched,
      errors,
      setLoading,
      subscribe,
      email,
      firstName,
      lastName,
      whatDescribesYou,
      whatDescribesYouValidation,
      setWhatDescribesYouValidation,
      setFirstName,
      setLastName,
      setEmail,
      setWhatDescribesYou,
      lock
    );
  }
};
