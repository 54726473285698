import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import { filterActions } from "core/state/redux/data/filters";
import ListingComponent from "./listing-component-container";

const ListingComponentReduxContainer = (props) => {
  return (
    <ListingComponent
      {...props}
      dataLoading={props.dataLoading}
      setDataLoading={props.setDataLoading}
    />
  );
};

const mapStateToProps = (state) => ({
  data: state.filters?.data,
  category: state.filters?.category,
  pageNumber:
    typeof state.filters?.pageNumber !== "undefined"
      ? state.filters.pageNumber
      : 1,
  pageInfo:
    typeof state.filters?.page_data !== "undefined"
      ? state.filters.page_data.pageInfo
      : "",
  totalCount:
    typeof state.filters?.page_data !== "undefined"
      ? state.filters.page_data.totalCount
      : 0,
  pageSize:
    typeof state.filters?.page_data !== "undefined"
      ? state.filters?.page_data?.pageInfo?.page_size
      : 32,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    changePageSize: (size) => {
      // dispatch(filterActions.changePageNumber(1));
      dispatch(filterActions.changePageSize(size));
    },
    changePageNumber: (pageNumber) => {
      dispatch(filterActions.changePageNumber(pageNumber));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingComponentReduxContainer);
