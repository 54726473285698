import React, { useState } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import ProductMediaGallerySlider from "./productMediaGallerySlider";
import ProductSpecification from "./productSpecification";
import Wishlist from "app/layout/wishlist";
import ProductStandardsForFiltering from "./productStandardsForFiltering";

const ProductInfo = (data) => {
  let { loading } = data;
  const [activeFinish, setActiveFinish] = useState(0);
  const [showPromoMessage, setShowPromoMessage] = useState(true);
  console.log("data", data);
  return (
    <>
      {data && (
        <MDBCol className="product-info-container">
          <MDBRow>
            <MDBCol size="12" className="product-info-header">
              <h3 className="product-name">{data.name && data.name}</h3>
              <h6
                className={
                  `product-sku` + (data.arriving_date ? ` arriving_date` : ``)
                }
              >
                SKU {data.sku && data.sku}{" "}
                {data.arriving_date && (
                  <span>Arriving date: {data.arriving_date}</span>
                )}
              </h6>
            </MDBCol>
            <MDBCol size="12" className="wishlist-control-container">
              {data && data.standards_for_filtering && (
                <ProductStandardsForFiltering
                  {...data.standards_for_filtering}
                  is_new={data.is_new && data.is_new}
                />
              )}
              {data.sku && (
                <Wishlist product={{ ...data }}>
                  <Icon className="wishlist-icon" icon="wishlist_empty_heart" />
                </Wishlist>
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow className="product-media-gallery-container">
            <MDBCol className="product-media-gallery-col">
              <ProductMediaGallerySlider
                {...data}
                activeFinish={activeFinish}
                setActiveFinish={setActiveFinish}
              />
            </MDBCol>
          </MDBRow>
          {!loading && data.product_options_info && (
            <MDBRow
              className={
                "product-promo-message wow fadeInUp " + showPromoMessage
              }
            >
              <MDBCol>
                {showPromoMessage && (
                  <p>
                    {data.product_options_info}
                    <Icon
                      icon="active_filter_dismiss"
                      onClick={() => setShowPromoMessage(!showPromoMessage)}
                    />
                  </p>
                )}
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow className="spec-bar-container">
            <MDBCol className="spec-bar">
              <ProductSpecification {...data} activeFinish={activeFinish} />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      )}
    </>
  );
};

export default ProductInfo;
