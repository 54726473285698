import React from "react";
import { ReactComponent as Favorites } from "./ico_favorites.svg";
import { ReactComponent as FacebookIcon } from "./facebook.svg";
import { ReactComponent as PinterestIcon } from "./pinterest.svg";
import { ReactComponent as LeftArrowBlack } from "./left_arrow.svg";
import { ReactComponent as RightArrowBlack } from "./right_arrow.svg";
import { ReactComponent as AddressIconOrange } from "./address_icon_orange.svg";
import { ReactComponent as InfoIconOrange } from "./info_icon_orange.svg";
import { ReactComponent as PhoneIconOrange } from "./phone_icon_orange.svg";
import { ReactComponent as RightArrowWhite } from "./button_arrow_white.svg";
import { ReactComponent as Search } from "./ico_search.svg";
import { ReactComponent as AccountIcon } from "./ico_myacc.svg";
import { ReactComponent as HamburgerOpen } from "./hamburger_open.svg";
import { ReactComponent as HamburgerClose } from "./hamburger_close.svg";
import { ReactComponent as MobileArrow } from "./mob_white_arrow.svg";
import { ReactComponent as WishlistEmptyHeart } from "./wishlist_empty_heart.svg";
import { ReactComponent as BlackArrowDown } from "./black_arrow_down.svg";
import { ReactComponent as BlackArrowUp } from "./black_arrow_up.svg";
import { ReactComponent as SpecificationIcon } from "./specifications_icon.svg";
import { ReactComponent as GlobeIcon } from "./globe_icon.svg";
import { ReactComponent as XMarkWhite } from "./x_mark_white.svg";
import { ReactComponent as QuoteIcon } from "./quote_icon.svg";
import { ReactComponent as DownloadIcon } from "./download_icon.svg";
import { ReactComponent as SmallBlackArrowLeft } from "./small_black_arrow_left.svg";
import { ReactComponent as HotspotIconOpen } from "./hotspot_icon_open.svg";
import { ReactComponent as HotspotIconClosed } from "./hotspot_icon_closed.svg";
import { ReactComponent as WhiteLeftArrow } from "./left_arrow_white.svg";
import { ReactComponent as WhiteRightArrow } from "./right_arrow_white.svg";
import { ReactComponent as OneSelected } from "./1-selected.svg";
import { ReactComponent as TwoSelected } from "./2-selected.svg";
import { ReactComponent as FourSelected } from "./4-selected.svg";
import { ReactComponent as EightSelected } from "./8-selected.svg";
import { ReactComponent as OneDisabled } from "./1-disabled.svg";
import { ReactComponent as TwoDisabled } from "./2-disabled.svg";
import { ReactComponent as FourDisabled } from "./4-disabled.svg";
import { ReactComponent as EightDisabled } from "./8-disabled.svg";
import { ReactComponent as RemoveItemIcon } from "./remove_item_icon.svg";
import { ReactComponent as LED_Icon } from "./LED_icon.svg";
import { ReactComponent as IN_Icon } from "./IN_icon.svg";
import { ReactComponent as ADA_Icon } from "./ADA_icon.svg";
import { ReactComponent as CFL_Icon } from "./CFL_icon.svg";
import { ReactComponent as CFTR_Icon } from "./CFTR_icon.svg";
import { ReactComponent as FLUO_Icon } from "./FLUO_icon.svg";
import { ReactComponent as HAL_Icon } from "./HAL_icon.svg";
import { ReactComponent as FiltersIcon } from "./filters_icon.svg";
import { ReactComponent as FiltersSort } from "./filters_sort.svg";
import { ReactComponent as ErrorIcon } from "./error_icon_red.svg";
import { ReactComponent as CheckMarkBlack } from "./check_mark_icon_black.svg";
import { ReactComponent as SliderLeftArrowWhite } from "./left_arrow_white.svg";
import { ReactComponent as SliderRightArrowWhite } from "./right_arrow_white.svg";
import { ReactComponent as ReportIcon } from "./ico_report.svg";
import { ReactComponent as ReportIconIlex } from "./ico_report_ilex.svg";
import { ReactComponent as IconRemoveAll } from "./ico_remove_all.svg";
import { ReactComponent as IconPinOrange } from "./ico_pin_orange.svg";
import { ReactComponent as IconPinWhite } from "./ico_pin_white.svg";
import { ReactComponent as SketchToCad } from "./sketch_to_cad.svg";
import { ReactComponent as QualityAssurance } from "./quality_assurance.svg";
import { ReactComponent as CompetitivePricing } from "./competetive_pricing.svg";
import { ReactComponent as ExpertKnowledge } from "./expert_knowledge.svg";
import { ReactComponent as ArrowUpFullOrange } from "./arrow_up_orange_full.svg";
import { ReactComponent as ArrowDownFull } from "./arrow_down_full.svg";
import { ReactComponent as UploadFile } from "./upload-file.svg";
import { ReactComponent as LeftArrowGray } from "./left_arrow_gray.svg";
import { ReactComponent as RightArrowGray } from "./right_arrow_gray.svg";
import { ReactComponent as DarkLogoIlex } from "./dark_logo_ilex.svg";
import { ReactComponent as DarkLogoNorwell } from "./dark_logo_norwell.svg";
import { ReactComponent as ShareWishlistToEmail } from "./share_wishlist_to_email.svg";
import { ReactComponent as RemoveAllWishlist } from "./ico_remove_all_wishlist.svg";
import { ReactComponent as RightArrowOrange } from "./right_arrow_orange.svg";
import { ReactComponent as SessionMessageError } from "./session_messages_error.svg";
import { ReactComponent as SessionMessageSuccess } from "./session_message_success.svg";
import { ReactComponent as ExpandIcon } from "./expand_icon.svg";

//norwell icons
import { ReactComponent as SwitcherNorwellLogo } from "./norwell_switcher_logo.svg";
import { ReactComponent as FooterNorwellLogo } from "./logo_footer_norwell.svg";
import { ReactComponent as NorwellWishlistHeartEmpty } from "./norwell_wishlist_empty.svg";
import { ReactComponent as NorwellWishlistHeartFull } from "./norwell_wishlist_full.svg";
import { ReactComponent as NorwellNewLabel } from "./norwell_new_plp.svg";
import { ReactComponent as OrangeArrowDown } from "./arrow_down_icon_orange.svg";
import { ReactComponent as OrangeArrowUp } from "./arrow_up_icon_orange.svg";

//ilex icons
import { ReactComponent as FooterIlexLogo } from "./logo_footer_ILEX.svg";
import { ReactComponent as SwitcherIlexLogo } from "./ilex_switcher_logo.svg";
import { ReactComponent as IlexWishlistHeartEmpty } from "./ilex_wishlist_empty.svg";
import { ReactComponent as IlexWishlistHeartFull } from "./ilex_wishlist_full.svg";
import { ReactComponent as IlexNewLabel } from "./ilex_new_plp.svg";
import { ReactComponent as RedArrowDown } from "./arrow_down_icon_red.svg";
import { ReactComponent as RedArrowUp } from "./arrow_up_icon_red.svg";
import { ReactComponent as ArrowUpFullRed } from "./arrow_up_red_full.svg";
import { ReactComponent as ActiveFilterDismiss } from "./active_filter_dismiss.svg";
import { ReactComponent as PhoneIconIlex } from "./phone_icon_ilex.svg";
import { ReactComponent as RepCardLocation } from "./rep_card_location.svg";
import { ReactComponent as RepCardPhone } from "./rep_card_phone.svg";
import { ReactComponent as RepCardEmail } from "./rep_card_email.svg";
import { ReactComponent as RepCardLink } from "./rep_card_link.svg";
import { ReactComponent as FindRepCountryArrow } from "./find-rep-country-arrow.svg";

const icons = {
  favorites: Favorites,
  facebook: FacebookIcon,
  pinterest: PinterestIcon,
  left_arrow_black: LeftArrowBlack,
  right_arrow_black: RightArrowBlack,
  right_arrow_orange: RightArrowOrange,
  address_icon_orange: AddressIconOrange,
  info_icon_orange: InfoIconOrange,
  phone_icon_orange: PhoneIconOrange,
  right_arrow_white: RightArrowWhite,
  search: Search,
  account_icon: AccountIcon,
  hamburger_open: HamburgerOpen,
  hamburger_close: HamburgerClose,
  mobile_white_arrow: MobileArrow,
  wishlist_empty_heart: WishlistEmptyHeart,
  black_arrow_down: BlackArrowDown,
  black_arrow_up: BlackArrowUp,
  specification_icon: SpecificationIcon,
  globe_icon: GlobeIcon,
  x_mark_white: XMarkWhite,
  quote_icon: QuoteIcon,
  download_icon: DownloadIcon,
  active_filter_dismiss: ActiveFilterDismiss,
  small_black_arrow_left: SmallBlackArrowLeft,
  hotspot_icon_open: HotspotIconOpen,
  hotspot_icon_closed: HotspotIconClosed,
  white_left_arrow: WhiteLeftArrow,
  white_right_arrow: WhiteRightArrow,
  oneSelected: OneSelected,
  twoSelected: TwoSelected,
  fourSelected: FourSelected,
  eightSelected: EightSelected,
  oneDisabled: OneDisabled,
  twoDisabled: TwoDisabled,
  fourDisabled: FourDisabled,
  eightDisabled: EightDisabled,
  remove_item_icon: RemoveItemIcon,
  LED: LED_Icon,
  INCANDESCENT: IN_Icon,
  ADA: ADA_Icon,
  FLUORESCENT: FLUO_Icon,
  HALOGEN_LAMPS: HAL_Icon,
  CFL: CFL_Icon,
  CFTR: CFTR_Icon,
  filters_icon: FiltersIcon,
  filters_sort: FiltersSort,
  error_icon: ErrorIcon,
  check_mark_icon_black: CheckMarkBlack,
  slider_left_arrow_white: SliderLeftArrowWhite,
  slider_right_arrow_white: SliderRightArrowWhite,
  report_ico: ReportIcon,
  report_ico_ilex: ReportIconIlex,
  ico_remove_all: IconRemoveAll,
  icon_pin_orange: IconPinOrange,
  icon_pin_white: IconPinWhite,
  sketch_to_cad: SketchToCad,
  quality_assurance: QualityAssurance,
  competetive_pricing: CompetitivePricing,
  expert_knowledge: ExpertKnowledge,
  arrow_down_full: ArrowDownFull,
  arrow_up_orange_full: ArrowUpFullOrange,
  upload_file: UploadFile,
  left_arrow_gray: LeftArrowGray,
  right_arrow_gray: RightArrowGray,
  dark_logo_ilex: DarkLogoIlex,
  dark_logo_norwell: DarkLogoNorwell,
  share_wishlist_to_email: ShareWishlistToEmail,
  remove_all_wishlist: RemoveAllWishlist,
  session_message_error: SessionMessageError,
  session_message_success: SessionMessageSuccess,
  expand_icon: ExpandIcon,

  //norwell icons
  switcher_norwell_logo: SwitcherNorwellLogo,
  footer_norwell_logo: FooterNorwellLogo,
  norwell_new_label: NorwellNewLabel,
  norwell_wishlist_empty: NorwellWishlistHeartEmpty,
  norwell_wishlist_full: NorwellWishlistHeartFull,
  arrow_down_icon_orange: OrangeArrowDown,
  arrow_up_icon_orange: OrangeArrowUp,

  //ilex icons
  switcher_ilex_logo: SwitcherIlexLogo,
  footer_ilex_logo: FooterIlexLogo,
  ilex_new_label: IlexNewLabel,
  ilex_wishlist_empty: IlexWishlistHeartEmpty,
  ilex_wishlist_full: IlexWishlistHeartFull,
  arrow_up_red_full: ArrowUpFullRed,
  arrow_down_icon_red: RedArrowDown,
  arrow_up_icon_red: RedArrowUp,
  phone_icon_ilex: PhoneIconIlex,
  rep_card_location: RepCardLocation,
  rep_card_phone: RepCardPhone,
  rep_card_email: RepCardEmail,
  rep_card_link: RepCardLink,
  find_rep_country_arrow: FindRepCountryArrow,
};
const Icon = (props) => {
  const ReturnIcon = icons[props.icon];

  return icons.hasOwnProperty(props.icon) ? <ReturnIcon {...props} /> : "";
};

export default Icon;
