import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import Accountinformation from "./accountinformation";
import TitleDescription from "app/layout/titleDescription/titleDescription";
import SeoHelmet from "app/layout/seoHelmet";
const Dashboard = (props) => {
  return (
    <>
      <SeoHelmet title="Account Dashboard" url={`/customer`} />
      <MDBContainer className="account-dashboard-container mb-5">
        <MDBRow>
          <TitleDescription title="Account Dashboard" />
        </MDBRow>
        <MDBRow className="account-dashboard">
          <MDBCol lg="12">
            <Accountinformation {...props} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Dashboard;
