import React, { memo } from "react";
import PropTypes from "prop-types";
import { isEqual, isUndefined } from "lodash";
import themeConfiguration from "config/themeConfiguration";
import Helmet from "react-helmet";
import useSite from "app/state/hooks/siteHooks/useSite";

const SeoHelmet = memo(
  ({ title, url, description, image, card, useAppName }) => {
    const [, getSiteCode] = useSite();
    let newTitle = undefined;
    if (getSiteCode() === "norwell_default") newTitle = "Norwell";
    else newTitle = "Ilex";
    if (isUndefined(newTitle) || isUndefined(url)) return <></>;
    let realTitle =
      title !== ""
        ? typeof useAppName !== "undefined" && useAppName === true
          ? title + " - " + newTitle
          : title + " - " + newTitle
        : newTitle;
    let finalUrl = themeConfiguration.app_url + url;

    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{realTitle}</title>
        <meta name="description" content={description} />

        {/** facebook */}
        <meta property="og:title" content={realTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={finalUrl} />

        {/** twitter */}
        <meta name="twitter:title" content={realTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta
          name="twitter:card"
          content={typeof card === "undefined" ? "summary_large_image" : card}
        />

        <link rel="canonical" href={finalUrl} />
      </Helmet>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

SeoHelmet.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

export default SeoHelmet;
