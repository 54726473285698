import React from "react";
import { connect } from "react-redux";
import Footer from "./footer";

const FooterReduxContainer = (props) => {
  return <Footer {...props} />;
};

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, null)(FooterReduxContainer);
