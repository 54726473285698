import React, { useState } from "react";
import Checkbox from "app/layout/checkbox";
import { MDBCol } from "mdbreact";

const CatalogCheckbox = (props) => {
  const [checked, setChecked] = useState(false);
  function removeElement(array, elem) {
    var index = array.findIndex((x) => x.code === elem.code);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  function addElement(array, elem) {
    var index = array.findIndex((x) => x.code === elem.code);
    if (index === -1) {
      array.push(elem);
    }
  }
  return (
    <MDBCol lg="6">
      <div
        className={`checkbox-wrapper`}
        onClick={() => {
          if (!checked) {
            addElement(props.checkedCatalogs, {
              code: props.catalog.title,
              value: props.catalog.catalogs_id,
            });
          } else
            removeElement(props.checkedCatalogs, {
              code: props.catalog.title,
              value: props.catalog.catalogs_id,
            });
        }}
      >
        <Checkbox
          checked={checked}
          setChecked={setChecked}
          checkbox_id={props.catalog.title + "-" + props.index}
          theme={"dark"}
        />
        <p>{props.catalog.title}</p>
      </div>
    </MDBCol>
  );
};

export default CatalogCheckbox;
