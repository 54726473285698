import React, { memo, useRef, useCallback, useEffect, useState } from "react";
import SearchInput from "./searchInput";
import SearchResults from "./searchResults";
import _ from "lodash";

const Search = memo(
  ({ open, setOpen, mobileonclick, searchVal, setSearchVal }) => {
    const ref = useRef(null);
    const onClickOutside = useCallback((e) => {
      if (!ref.current.contains(e.target)) setOpen(false);
    }, []);
    const [tmpSearch, setTmpSearch] = useState("");

    useEffect(() => {
      document.addEventListener("click", onClickOutside);
      return () => {
        document.removeEventListener("click", onClickOutside);
      };
    }, []);

    return (
      <div
        fluid
        id={`search-container`}
        className={`${open ? "show" : "hide"}`}
        ref={ref}
      >
        <SearchInput
          searchVal={searchVal}
          setSearchVal={setSearchVal}
          setDisplaySearch={setOpen}
          tmpSearch={tmpSearch}
          setTmpSearch={setTmpSearch}
        />
        <SearchResults
          mobileonclick={mobileonclick}
          tmpSearch={tmpSearch}
          setDisplaySearch={setOpen}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Search;
