import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import { filterActions } from "core/state/redux/data/filters";
import ToolbarStateContainer from "./toolbar-state-container";
import { isUndefined } from "core/helpers/functions";

const ToolbarReduxContainer = (props) => {
  if (isUndefined(props.data)) return <></>;
  return <ToolbarStateContainer {...props} dataLoading={props.dataLoading} />;
};

const mapStateToProps = (state) => ({
  data: state.filters.data,
  category: state.filters.category,
  filters: state.filters,
  pageNumber:
    typeof state.filters.pageNumber !== "undefined"
      ? state.filters.pageNumber
      : 1,
  pageInfo: state.filters.page_data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    },
    changeSortFilter: (sort) => {
      dispatch(filterActions.changeSortFilter(sort));
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
    changePageSize: (size) => {
      dispatch(filterActions.changePageSize(size));
    },
    changePageNumber: (pageNumber) => {
      dispatch(filterActions.changePageNumber(pageNumber));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToolbarReduxContainer);
