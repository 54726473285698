import React from "react";
import { connect } from "react-redux";
import { default as commonActions } from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import { withApollo } from "react-apollo";
import ResetPasswordPageStateContainer from "./resetPasswordPage-state-container";
import { messagesActions } from "core/state/redux/data/messages";

const ResetPasswordPageReduxContainer = (props) => {
  return <ResetPasswordPageStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  loading: state.customer.loading,
  token: state.customer.data.token,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (token, password, confirmPassword) => {
      dispatch(commonActions.lock());
      dispatch(
        customerActions.resetCustomerPassword(token, password, confirmPassword)
      );
    },
    resetPasswordSuccess: (value) => {
      dispatch(customerActions.resetCustomerPasswordSuccess(value));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPageReduxContainer)
);
