import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

export default (showDots, items) => {
  return {
    dots: showDots,
    infinite: items && items.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 3.4,
    arrows: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.75,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <MDBRow
        id="slider-indicators-container"
        style={{
          padding: "15px",
          textAlign: "center",
        }}
      >
        <MDBCol className="indicators-container">
          <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
            {dots}
          </ul>
        </MDBCol>
      </MDBRow>
    ),
  };
};
