import React, { useState } from "react";
import { MDBCol, MDBRow, MDBContainer, MDBBox } from "mdbreact";
import Button from "core/components/button";
import Input from "app/layout/input";
import { Alert } from "reactstrap";
import TitleDescription from "app/layout/titleDescription/titleDescription";
import { validate, shouldMarkError } from "./validation";
import { useLocation } from "react-router-dom";
import Checkbox from "app/layout/checkbox";
import Icon from "app/assets/icon/icon";

const Information = (props) => {
  let {
    // Getters
    changeEmail,
    changePassword,
    firstname,
    lastname,
    email,
    password,
    newpassword,
    newpasswordconf,
    //Setters
    setChangeEmail,
    setChangePassword,
    setFirstname,
    setLastname,
    setEmail,
    setPassword,
    setNewpassword,
    setNewpasswordconf,
  } = props;
  let [alert, setAlert] = useState("");
  let errors = validate(
    changeEmail,
    changePassword,
    firstname,
    lastname,
    email,
    password,
    newpassword,
    newpasswordconf
  );
  let location = useLocation();
  if (typeof location.state !== "undefined")
    if (location.state.changePassword) {
      setChangePassword(true);
    }
  const [touched, setTouched] = useState({});
  return (
    <MDBContainer className="account-information-container">
      <MDBRow>
        <TitleDescription title="Edit Account Information" />
      </MDBRow>
      <MDBRow className="account-information border-bottom">
        <MDBCol lg="6" md="12" className={`d-flex flex-column `}>
          <h3 className="gray medium">Account Information</h3>
          <MDBBox size="12" className={`information-left-input-wrapper`}>
            <Input
              wrapperClass={"light"}
              className={`custom-input ${
                shouldMarkError("firstname", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("firstname", errors, touched)
                  ? "First Name is required*"
                  : "First Name*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, firstname: true });
              }}
              placeholder={`First Name`}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={firstname}
              onChange={(e) => {
                setFirstname(e.target.value);
              }}
            />
            <Input
              wrapperClass={"light"}
              className={`custom-input ${
                shouldMarkError("lastname", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("lastname", errors, touched)
                  ? "Last Name is required*"
                  : "Last Name*"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, lastname: true });
              }}
              placeholder={`Last Name`}
              group
              type="text"
              validate
              error="wrong"
              success="right"
              value={lastname}
              onChange={(e) => {
                setLastname(e.target.value);
              }}
            />
          </MDBBox>
          <MDBBox size="12" className={`information-left-check-wrapper`}>
            <div className="checkbox-wrapper">
              <Checkbox
                checked={changeEmail}
                setChecked={setChangeEmail}
                checkbox_id="change_email"
                theme={"light"}
              />
              <p>Change Email</p>
            </div>
            <div className="checkbox-wrapper">
              <Checkbox
                checked={changePassword}
                setChecked={setChangePassword}
                checkbox_id="change_password"
                theme={"light"}
              />
              <p>Change Password</p>
            </div>
          </MDBBox>
        </MDBCol>
        <MDBCol lg="6" md="12">
          {(changeEmail || changePassword) && (
            <h3 className="gray heavy large">{props.secondTitle}</h3>
          )}
          {changeEmail && (
            <>
              <Input
                wrapperClass={"light"}
                className={`custom-input ${
                  shouldMarkError("email", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("email", errors, touched)
                    ? "Email address is required*"
                    : "Email address*"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, email: true });
                }}
                placeholder={`Email`}
                group
                type="text"
                validate
                error="wrong"
                success="right"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </>
          )}
          {(changePassword || changeEmail) && (
            <Input
              wrapperClass={"light"}
              className={`custom-input ${
                shouldMarkError("password", errors, touched)
                  ? "validation-error"
                  : ""
              }`}
              label={`${
                shouldMarkError("password", errors, touched)
                  ? "Current password is required*"
                  : "Current password"
              }`}
              onBlur={(e) => {
                setTouched({ ...touched, password: true });
              }}
              placeholder={`Current Password`}
              group
              type="password"
              validate
              error="wrong"
              success="right"
              value={password}
              onChange={(e) => {
                setAlert("");
                setPassword(e.target.value);
              }}
            />
          )}
          {changePassword && (
            <>
              <Input
                wrapperClass={"light"}
                className={`custom-input ${
                  shouldMarkError("newpassword", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("newpassword", errors, touched)
                    ? "New Password is required"
                    : "New Password"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, newpassword: true });
                }}
                placeholder={`New Password`}
                group
                type="password"
                validate
                error="wrong"
                success="right"
                value={newpassword}
                onChange={(e) => {
                  setAlert("");
                  setNewpassword(e.target.value);
                }}
              />
              <Input
                wrapperClass={"light"}
                className={`custom-input ${
                  shouldMarkError("newpasswordconf", errors, touched)
                    ? "validation-error"
                    : ""
                }`}
                label={`${
                  shouldMarkError("newpasswordconf", errors, touched)
                    ? "Confirm New Password is required*"
                    : "Confirm New Password"
                }`}
                onBlur={(e) => {
                  setTouched({ ...touched, newpasswordconf: true });
                }}
                placeholder={`Confirm New Password`}
                group
                type="password"
                validate
                error="wrong"
                success="right"
                value={newpasswordconf}
                onChange={(e) => {
                  setAlert("");
                  setNewpasswordconf(e.target.value);
                }}
              />
            </>
          )}
        </MDBCol>
      </MDBRow>

      {alert !== "" && (
        <Alert color="danger" isOpen={true} toggle={() => setAlert("")}>
          {alert}
        </Alert>
      )}

      <MDBRow>
        <MDBCol lg="4">
          <Button
            color="primary"
            className={"btn-with-icon big with-arrow"}
            onClick={(e) => {
              e.preventDefault();
              let email_request = {};
              let password_request = {};
              let validate = undefined;
              if (changeEmail) {
                email_request = {
                  email: props.email,
                  password: password,
                };
              }
              if (changePassword) {
                if (newpasswordconf === newpassword) validate = true;
                else validate = false;
                password_request = {
                  currentPassword: password,
                  newPassword: newpassword,
                };
              }
              if (validate === undefined) {
                props.onSubmit({
                  firstname: props.firstname,
                  lastname: props.lastname,
                  ...email_request,
                  ...password_request,
                });
              }
              if (validate === true) {
                props.onSubmit({
                  firstname: props.firstname,
                  lastname: props.lastname,
                  ...email_request,
                  ...password_request,
                });
              }
              if (validate === false) {
                setAlert("new password and confirm new password do not match");
              }
            }}
          >
            {" "}
            Save
            <Icon icon="right_arrow_white" />
          </Button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Information;
