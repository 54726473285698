import React from "react";
import { connect } from "react-redux";
import PageView from "./pageView";

const PageViewReduxContainer = (props) => {
  return <PageView {...props} />;
};

const mapStateToProps = (state) => ({
  pageInformation: state?.filters?.page_data?.pageInfo,
  totalCount: state?.filters?.page_data?.totalCount,
});

export default connect(mapStateToProps, null)(PageViewReduxContainer);
