import React, { useEffect } from "react";
import { MDBCol, MDBRow, MDBBtn, MDBContainer } from "mdbreact";
import Input from "app/layout/input";
import { validate, shouldMarkError } from "./validation";
import { handleLogin } from "./handleLogin";
import { handleKeyDown } from "./handleKeyDown";
import { useMediaQuery } from "react-responsive";
import SessionMessages from "core/components/sessionMessages";
import Button from "core/components/button";

const Login = (props) => {
  let {
    customer,
    email,
    password,
    setEmail,
    setPassword,
    login,
    component,
    loading,
    setLoading,
    touched,
    setTouched,
    toggleModal,
    setToggleModal,
    status,
    setStatus,
    toggleResetPasswordModal,
    setToggleResetPassword,
    setToggleRegister,
  } = props;

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
  let cartToken =
    typeof customer.data !== "undefined" &&
    typeof customer.data.cartToken !== "undefined"
      ? customer.data.cartToken
      : undefined;

  useEffect(() => {
    if (status === true) {
      setToggleModal(!toggleModal);
    }
  }, [status]);
  let errors = validate(email, password);

  return (
    <MDBContainer className="login-form">
      <MDBRow className="login-form-row">
        <MDBCol className="login-form-container">
          <h5>Welcome Back</h5>
          <p>Please, login to your account</p>
          <Input
            placeholder={`${
              shouldMarkError("email", errors, touched)
                ? "Email is required"
                : "Email"
            }`}
            required={true}
            type={"text"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={
              shouldMarkError("email", errors, touched)
                ? "invalid"
                : "" + email !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, email: true });
            }}
            shouldMarkError={shouldMarkError("email", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("password", errors, touched)
                ? "Password is required"
                : "Password"
            }`}
            value={password}
            required={true}
            type={"password"}
            onChange={(event) => setPassword(event.target.value)}
            className={
              shouldMarkError("password", errors, touched)
                ? "invalid"
                : "" + password !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, password: true });
            }}
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                setTouched,
                errors,
                setLoading,
                props,
                component,
                login,
                email,
                password,
                customer,
                setStatus,
                cartToken
              )
            }
            shouldMarkError={shouldMarkError("password", errors, touched)}
          />
          <p
            className="forgot-password"
            onClick={() => {
              setToggleModal(false);
              // setToggleRegister(false);
              setToggleResetPassword(true);
            }}
          >
            Forgot Password?
          </p>
          <Button
            disabled={loading && true}
            onClick={(event) =>
              handleLogin(
                event,
                setTouched,
                errors,
                setLoading,
                props,
                component,
                login,
                email,
                password,
                customer,
                setStatus,
                cartToken
              )
            }
          >
            {loading ? "Processing..." : "Login"}
          </Button>
          <p className="error-messages">
            <SessionMessages target="login" />
          </p>
          {isTabletOrMobile && (
            <p
              className="register"
              onClick={() => {
                setToggleModal(false);
                setToggleResetPassword(false);
                setToggleRegister(true);
              }}
            >
              Not a user? Please register
            </p>
          )}
        </MDBCol>
      </MDBRow>
      <MDBRow className="reset-password-row">
        <MDBCol className="reset-password-container">
          <h5>Existing Users</h5>
          <p>
            NOTE: If you are already an existing user, please reset your
            password. Since we are using a new interface, this is a required
            step
          </p>
          <MDBBtn
            onClick={() => {
              setToggleModal(false);
              // setToggleRegister(false);
              setToggleResetPassword(true);
            }}
          >
            Reset Your Password
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Login;
