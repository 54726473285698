import React, { useState } from "react";
import useSite from "app/state/hooks/siteHooks/useSite";
import { MDBMask, MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import HotspotCard from "./hotspotCard";

const HotspotMask = (props) => {
  let { dot } = props;
  const [isVisible, setVisible] = useState(false);
  const [, getSiteCode] = useSite();

  return (
    <MDBMask
      style={{
        top: dot.top + "%",
        left: dot.left + "%",
        display: "flex",
        flexDirection: dot.top > 50 ? "column" : "column-reverse",
      }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      onClick={() => setVisible(!isVisible)}
      className={`hotSpot ${
        (isVisible && "hotspot-active") || "hotspot-inactive"
      }`}
    >
      {isVisible && <HotspotCard {...props} />}

      <MDBBox
        className={`${
          dot.top > 50
            ? getSiteCode() + " hotspot-circle top"
            : getSiteCode() + " hotspot-circle bottom"
        }`}
      >
        <div className={`hotspot-icon ${isVisible && "active"}`}>
          {isVisible ? (
            <Icon className={getSiteCode()} icon="hotspot_icon_open" />
          ) : (
            <Icon className={getSiteCode()} icon="hotspot_icon_closed" />
          )}
        </div>
      </MDBBox>
    </MDBMask>
  );
};
export default HotspotMask;
