import React, { useState } from "react";

import ResetPasswordPage from "./resetPasswordPage";

const ResetPasswordPageStateContainer = (props) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const [status, setStatus] = useState(false);
  return (
    <ResetPasswordPage
      props={props}
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      confirmPassword={confirmPassword}
      setConfirmNewPassword={setConfirmNewPassword}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      status={status}
      setStatus={setStatus}
    />
  );
};

export default ResetPasswordPageStateContainer;
