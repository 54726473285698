import React from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import history from "core/state/redux/history";
const QuoteSuccess = () => {
  if (window.location.href.includes("quote-success")) {
    setTimeout(() => {
      history.push("/");
    }, 6000);
  }

  return (
    <MDBContainer className="quote-success-page">
      <MDBRow className="quote-success-image">
        <MDBCol>
          <CmsBlock id="quote_success_page_banner_image" dangHtml={true} />
        </MDBCol>
      </MDBRow>
      <MDBRow className="quote-success-text">
        <MDBCol>
          <CmsBlock id="quote_success_page_text" dangHtml={true} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default QuoteSuccess;
