import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import PagesStateContainer from "./state-container";

const PagesReduxContainer = (props) => {
  return <PagesStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer.data,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagesReduxContainer);
