import React from "react";
import { withRouter } from "react-router-dom";
import ProductQueryContainer from "./product-query-container";
import { ErrorPage } from "app/config/routing/pages/guestPages";

const ProductStateContainer = (props) => {
  let productSku =
    typeof props.data !== "undefined" &&
    typeof props.data.urlResolver !== "undefined" &&
    typeof props.data.urlResolver.sku !== "undefined" &&
    props.data.urlResolver.sku !== null
      ? props.data.urlResolver.sku
      : false;

  if (!productSku) return <ErrorPage />;
  return <ProductQueryContainer {...props} productSku={productSku} />;
};

export default withRouter(ProductStateContainer);
