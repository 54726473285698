import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import CatalogDetails from "./catalog-details-data-container";

const CATALOGS = gql`
  query {
    getCatalog {
      catalogs {
        catalogs_id
        category
        fliphtml5
        image
        pdf
        title
        url
      }
    }
  }
`;

const CatalogQueryContainer = () => {
  return (
    <GraphQlWrapper query={CATALOGS} variables={{ id: "catalogs" }}>
      <CatalogDetails />
    </GraphQlWrapper>
  );
};

export default CatalogQueryContainer;
