import React from "react";
import { connect } from "react-redux";
import ContactFormStateContainer from "./contactForm-state-container";
import commonActions from "app/state/redux/data/common/actions";
import { messagesActions } from "core/state/redux/data/messages";

const ContactFormReduxContainer = (props) => {
  return <ContactFormStateContainer {...props} />;
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (data) => {
      dispatch(commonActions.sendEmail(data));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callbackFunction) => {
      dispatch(commonActions.lock(callbackFunction));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormReduxContainer);
