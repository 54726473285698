import React, { useState } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBBox } from "mdbreact";
import Icon from "app/assets/icon/icon";
import useSite from "app/state/hooks/siteHooks/useSite";
import { Link } from "react-router-dom";
import Wishlist from "app/layout/wishlist";
import { getBrandName } from "../../getBrandName";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import RemoveProduct from "app/pages/customer/account/pages/wishlistCore/itemsWishlist/item/removeProduct";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import LazyLoad from "react-lazyload";
import ReactLoader from "app/layout/loader";

const ProductItemGridView = (props) => {
  let { item, showBrand } = props;
  const [, getSiteCode] = useSite();

  const path = window.location.origin.split(":")[1];
  const [loading, setWishlistLoading] = useState(false);
  const { removeFromWishlist } = useWishlist();
  const [lock] = useLocking();
  const activeWishlistName =
    props && props.activeWishlistName ? props.activeWishlistName : false;

  const wishlistGrid = props && props.wishlistGrid ? props.wishlistGrid : null;
  let wishlistRemoveItems = wishlistGrid && {
    skus: [item.product.sku],
    wishlist: activeWishlistName,
  };

  let category =
    typeof props.category !== "undefined"
      ? props.category
      : typeof item.categories !== "undefined" &&
        typeof item.categories[0] !== "undefined"
      ? item.categories[0]
      : undefined;
  let url_key = "";

  if (wishlistGrid) {
    item = item.product;
  }
  if (showBrand) {
    if (item.brand === "5510") {
      url_key = `${path}/${item.url_key}`;
    } else {
      url_key = `${path}/${item.url_key}`;
    }
  } else {
    if (typeof category !== "undefined") {
      url_key = "/" + category.url_path + "/" + item.url_key;
    } else {
      url_key = item.url_key;
    }
  }

  return (
    <MDBContainer className="product-item-container">
      <MDBRow className="product-item-header-row">
        <MDBCol className={`product-new-label`}>
          {item.is_new ? (
            getSiteCode() === "norwell_default" ? (
              <Icon icon={"norwell_new_label"} />
            ) : (
              <Icon icon={"ilex_new_label"} />
            )
          ) : (
            <></>
          )}
        </MDBCol>
        {!window.location.href.includes("search-results") &&
          props.showWishlist !== false && (
            <MDBCol className={`wishlist-product-widget`}>
              <Wishlist product={{ ...item }}>
                <Icon icon="wishlist_empty_heart" />
              </Wishlist>
            </MDBCol>
          )}
        {wishlistGrid && (
          <ModalConsumer value={{ useWrapper: true }}>
            {({ showModal }) => (
              <>
                <MDBBox
                  className="trash-can-wrapper"
                  disabled={
                    loading === `removing_${wishlistRemoveItems.skus[0]}`
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    showModal(() => {
                      return (
                        <RemoveProduct
                          removeFromWishlist={removeFromWishlist}
                          wishlistRemoveItems={wishlistRemoveItems}
                          lock={lock}
                          loading={loading}
                          setLoading={setWishlistLoading}
                        />
                      );
                    });
                  }}
                >
                  <Icon icon={"remove_item_icon"} />
                </MDBBox>
              </>
            )}
          </ModalConsumer>
        )}
      </MDBRow>
      <MDBRow className="product-item-image-row">
        <MDBCol lg="12" className="product-item-image-column">
          {showBrand ? (
            <a href={url_key}>
              <img
                className={
                  item.image.url.includes("placeholder") && "placeholder"
                }
                src={
                  item.image.url.includes("placeholder")
                    ? "/public/placeholder.png"
                    : item.image && item.image.url
                }
              />
            </a>
          ) : (
            <Link
              onClick={props.onClick}
              to={{
                pathname: url_key,
                state: {
                  sku: item.sku,
                },
              }}
            >
              <LazyLoad
                height={"100%"}
                offset={100}
                placeholder={
                  <ReactLoader
                    color={"#363B45"}
                    width={50}
                    height={50}
                    type={`Oval`}
                    key={"loader-items"}
                  />
                }
              >
                <img
                  className={
                    item.image.url.includes("placeholder") && "placeholder"
                  }
                  src={
                    item.image.url.includes("placeholder")
                      ? "/placeholder.png"
                      : item.image && item.image.url
                  }
                />
              </LazyLoad>
            </Link>
          )}
        </MDBCol>
        <MDBCol lg="12">
          <p>SKU {item.sku}</p>
        </MDBCol>
      </MDBRow>
      <MDBRow className="product-item-info-row">
        <MDBCol lg="12">
          <h5>{item.name}</h5>
        </MDBCol>
        <MDBRow lg="12" className="finishes-container">
          <MDBCol size={"8"} className="finishes-column">
            <p className="finishes">
              {item.finishes_count && item.finishes_count > 1
                ? item.finishes_count + " Finishes Available"
                : item.finishes_count === 1
                ? item.finishes_count + " Finish Available"
                : ""}
            </p>
          </MDBCol>
          {/* {showBrand && ( */}
          <MDBCol size="4" className="brand-column">
            <p className="brand-name">{getBrandName(item.brand)}</p>
          </MDBCol>
          {/* )} */}
        </MDBRow>
      </MDBRow>
    </MDBContainer>
  );
};
export default ProductItemGridView;
