export const sliderDotsSettings = (data) => {
  return {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    arrows: false,
    dotsClass: data.dotsClass,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    lazyLoad: "progressive",
    focusOnSelect: true,
    vertical: true,
    verticalSwiping: true,
    linear: true,
    touchMove: false,
  };
};
