import React from "react";
import Press from "./press";

const PressDataContainer = (props) => {
  const allGalleries =
    typeof props.data !== undefined &&
    props.data &&
    typeof props.data.getAllGalleryData !== undefined
      ? props.data.getAllGalleryData
      : false;

  const pressGalleries =
    allGalleries &&
    allGalleries.filter(
      (gallery) =>
        gallery && gallery.category[0] && gallery.category[0].name === "Press"
    );

  return <Press pressGalleries={pressGalleries} />;
};

export default PressDataContainer;
