import React, { useState } from "react";
import { MDBCol } from "mdbreact";
import Input from "app/layout/input";
import Button from "core/components/button";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import { validate, shouldMarkError } from "./quoteRequestFormValidation";
import { quoteRequestSummary } from "./quoteRequestFormSubmit";
import MaskedInput from "react-text-mask";
import Icon from "app/assets/icon/icon";
import { uploadFileTrigger, prepareFileForUpload } from "./fileUpload-API";
import { useCart } from "app/state/hooks/cartHooks/useCart";

const QuoteRequestSummary = (props) => {
  let {
    email,
    setEmail,
    phone,
    setPhone,
    companyName,
    setCompanyName,
    comments,
    setComments,
    touched,
    setTouched,
    setFileForUpload,
    sendEmail,
    loading,
    setLoading,
    quoteStatus,
    setQuoteStatus,
    productsAndComments,
  } = props;

  const [lock] = useLocking();
  let errors = validate(email, phone, companyName);
  const [encodedFile, setEncodedFile] = useState("");
  let fileBase64 = encodedFile && encodedFile.base64_encoded_data;
  let fileType = encodedFile && encodedFile.type;
  let fileName = encodedFile && encodedFile.name;
  const [, , , getCartInformation] = useCart();
  return (
    <MDBCol lg="3" className="quote-request-summary">
      <div>
        <p>Details</p>
        <div className="masked-input">
          <MaskedInput
            type={"text"}
            mask={[
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            value={phone !== undefined ? phone : ""}
            guide={false}
            onChange={(event) => setPhone(event.target.value)}
            onBlur={(e) => {
              setTouched({ ...touched, phone: true });
            }}
            className={
              shouldMarkError("phone", errors, touched)
                ? "invalid"
                : "" + phone !== "" && "valid"
            }
            placeholder={`${
              shouldMarkError("phone", errors, touched)
                ? "Phone Number is required"
                : "Phone Number"
            }`}
          />
          <label className="required-label">
            {typeof shouldMarkError("phone", errors, touched) ===
            "undefined" ? (
              "Required"
            ) : shouldMarkError("phone", errors, touched) ? (
              <Icon icon="error_icon" />
            ) : (
              <Icon icon="check_mark_icon_black" />
            )}
          </label>
        </div>
        <Input
          placeholder={`${
            shouldMarkError("email", errors, touched)
              ? "Email is required"
              : "Email"
          }`}
          required={true}
          type={"text"}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className={
            shouldMarkError("email", errors, touched)
              ? "invalid"
              : "" + email !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, email: true });
          }}
          shouldMarkError={shouldMarkError("email", errors, touched)}
        />
        <Input
          placeholder={`${
            shouldMarkError("companyName", errors, touched)
              ? "Company Name is required"
              : "Company"
          }`}
          required={true}
          type={"text"}
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
          className={
            shouldMarkError("companyName", errors, touched)
              ? "invalid"
              : "" + companyName !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, companyName: true });
          }}
          shouldMarkError={shouldMarkError("companyName", errors, touched)}
        />
      </div>
      <div>
        <p>Enter comment</p>
        <div className="textarea-container">
          <Input
            className="textarea"
            type={"textarea"}
            placeholder="Comments"
            value={comments}
            onChange={(event) => setComments(event.target.value)}
            maxLength={250}
          />
          <label className="textarea-length">{comments.length + "/250"}</label>
        </div>
      </div>

      <div className="upload-file-wrapper">
        <p>Upload any additional images</p>
        <div className="upload-file-input-wrapper">
          <Input
            disabled
            name="upload-file-input"
            type="text"
            placeholder="Upload file"
            value={encodedFile ? encodedFile.name : ""}
          />
          <input
            type="file"
            hidden
            id="upload-file-id"
            accept=".pdf,.jpg,.tiff"
            className="file-upload-input"
            onChange={(e) => {
              // fileUpload(e, setFileForUpload);
              prepareFileForUpload(
                e.target.files[0],
                setEncodedFile,
                setFileForUpload
              );
            }}
          />
          <Button
            text={"Upload"}
            className="upload-file-btn"
            onClick={(event) => uploadFileTrigger(event)}
          >
            <Icon icon={"upload_file"} />
          </Button>
        </div>
        <p className="upload-file-types">(JPG, PDF, TIFF) max 2MB</p>
      </div>

      <Button
        disabled={loading && true}
        onClick={(e) => {
          quoteRequestSummary(
            e,
            email,
            phone,
            companyName,
            comments,
            sendEmail,
            fileBase64,
            fileType,
            fileName,
            touched,
            setTouched,
            errors,
            lock,
            setLoading,
            quoteStatus,
            setQuoteStatus,
            productsAndComments,
            setPhone,
            setEmail,
            setCompanyName,
            setComments,
            setFileForUpload,
            getCartInformation
          );
        }}
      >
        {loading ? "Sending..." : "Send"}
      </Button>
    </MDBCol>
  );
};
export default QuoteRequestSummary;
