import React from "react";
import { Link } from "react-router-dom";
import Input from "core/components/input";
import Breadcrumbs from "app/layout/breadcrumbs/breadcrumbs";
import {
  MDBTypography,
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBBox,
  MDBIcon,
} from "mdbreact";
import Button from "core/components/button";
import CheckButton from "core/components/checkButton";
import Dropdown from "core/components/dropdown";

const Typography = (props) => {
  return (
    <MDBContainer fluid className='text-center mt-5 pt-5 text-md-left'>
      <MDBRow>
        <MDBCol
          xl='12'
          sm='4'
          size='2'
          style={{
            backgroundColor: "#aeaeae",
          }}
        >
          TEST 2,4,6,8,12
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size='4' style={{ backgroundColor: "#aeaeae" }}>
          TEST SIZE=4
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size='6' style={{ backgroundColor: "#aeaeae" }}>
          TEST SIZE=6
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size='8' style={{ backgroundColor: "#aeaeae" }}>
          TEST SIZE=8
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size='8' style={{ backgroundColor: "#363b45" }}>
          <Link>Link</Link>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md='12'>
          <MDBTypography tag='h1'>Heading One </MDBTypography>
          <MDBTypography tag='h2'>Heading Two </MDBTypography>
          <MDBTypography tag='h3'>Title H3 </MDBTypography>
          <MDBTypography tag='h4'>Title H4 </MDBTypography>
          <MDBTypography tag='h5'>Title H5 </MDBTypography>
          <MDBTypography tag='h6'>Title H6 </MDBTypography>
          <MDBBox tag='p'>Paragraph</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md='12'>
          <MDBBox tag='p'> Color Variables</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag='p'
          className='square m-2'
          style={{
            backgroundColor: "#000000",
          }}
        >
          Black
        </MDBBox>
        <MDBBox
          tag='p'
          className='square m-2'
          style={{
            backgroundColor: "#ffffff",
          }}
        >
          White
        </MDBBox>
        <MDBBox
          tag='p'
          className='square m-2'
          style={{
            backgroundColor: "#595959",
          }}
        >
          Gray
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md='12'>
          <MDBBox tag='p'>Basic Color Variables</MDBBox>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBBox
          tag='p'
          className='square m-2'
          style={{
            backgroundColor: "#e21a1a",
          }}
        >
          Danger Color
        </MDBBox>
      </MDBRow>
      <MDBRow>
        <MDBCol md='12'>
          <Breadcrumbs />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <span style={{ marginRight: 10 }}>
            <Button
              color='primary'
              className={"btn-with-icon"}
              direction={"mr-3"}
              leftIcon={true}
              icon={"arrow-right"}
              text={"Share"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              color='primary'
              className={"btn-with-icon"}
              direction={"mr-3"}
              leftIcon={true}
              icon={"share-alt"}
              text={"Share"}
            />
          </span>
          <span style={{ marginRight: 10 }}>
            <Button
              color='primary'
              outline={true}
              className={"right-arrow black-button"}
              text={"Black"}
              direction={"ml-3"}
              righticon={"true"}
              icon={"arrow-right"}
            />
          </span>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          <div className={`chek-buttons`} style={{ marginBottom: 40 }}>
            <CheckButton
              containerClass={"radio-container check-button"}
              label={"radio"}
              type={"radio"}
              value={"no"}
              checked={"true"}
              wrapperClass={"radio"}
            />
            <CheckButton
              containerClass={"radio-container check-button"}
              label={"radio"}
              type={"radio"}
              value={"no"}
              wrapperClass={"radio"}
            />
            <CheckButton
              containerClass={"radio-container check-button"}
              label={"radio"}
              type={"radio"}
              value={"no"}
              checked={"true"}
              wrapperClass={"radio"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={"true"}
              wrapperClass={"checkmark"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              wrapperClass={"checkmark"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"checkbox-container check-button"}
              checked={"true"}
              wrapperClass={"checkmark gray"}
              label={"check"}
              type={"checkbox"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              containerClass={"switch-container check-button"}
              checked={"true"}
              wrapperClass={"slider"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"switch-container check-button"}
              wrapperClass={"slider"}
              label={"check"}
              type={"checkbox"}
            />
            <CheckButton
              containerClass={"switch-container check-button"}
              checked={"true"}
              wrapperClass={"slider"}
              label={"check"}
              type={"checkbox"}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <CheckButton
              outline={true}
              id='exampleInput'
              size={"lg"}
              label={"Large input"}
              type={"text"}
              value={"no"}
              wrapperClass={""}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <Input
              name={``}
              type={"number"}
              wrapperClass={"quantity-field"}
              inputClass={""}
              // onKeyPress={e => {
              //   onlyNumbers(e);
              // }}
            />
          </div>
          <div style={{ marginBottom: 40 }}>
            <ul className='pagination page-pagination'>
              <li className='page-item active-page-item'>
                <a className='pagination-button' href={"/privacy-policy"}>
                  1
                </a>
              </li>
              <li className='page-item '>
                <a className='pagination-button' href={"/privacy-policy"}>
                  2
                </a>
              </li>
              <li className='page-item'>
                <a className='pagination-button' href={"/privacy-policy"}>
                  3
                </a>
              </li>
              <li className='page-item'>
                <a className='pagination-button' href={"/privacy-policy"}>
                  <MDBIcon icon='caret-right' />
                </a>
              </li>
              <li className='page-item view-all'>
                <a className='pagination-button' href={"/privacy-policy"}>
                  View all
                </a>
              </li>
            </ul>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Button className={"wishlist-btn"} color={"#FF5100"}>
              <MDBIcon far icon='heart' />
              <MDBIcon icon='caret-down' />
            </Button>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Button className={"compare-btn"} color={"#FF5100"}>
              <MDBIcon far icon='clone' />
              <MDBIcon icon='plus' />
            </Button>
          </div>
          <div style={{ marginBottom: 40 }}>
            <Dropdown
              className={"filter-btn"}
              dropdownText={
                <>
                  kids
                  <MDBIcon icon='plus' />
                </>
              }
              dropdownItem={
                <CheckButton
                  containerClass={"checkbox-container check-button"}
                  wrapperClass={"checkmark gray"}
                  label={"check"}
                  type={"checkbox"}
                />
              }
            />
          </div>
          <div>
            <ul className={"custom-list"}>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                similique aliquam, ut suscipit
              </li>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                similique aliquam, ut suscipit
              </li>
              <li>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                similique aliquam, ut suscipit
              </li>
            </ul>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Typography;
