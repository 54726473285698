export default (state, action) => {
  let currentQueries = state.data ? state.data : {};
  let newQueries = { ...currentQueries };
  if (action.id) {
    newQueries[action.id] = { data: action.data.data };
  } else if (action.url) {
    newQueries["dynamic_page"] = {
      data: action.data.data,
      dynamic_id: action.data.data.urlResolver.id,
    };
  }
  return Object.assign({}, state, { data: newQueries });
};
