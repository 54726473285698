import React, { useState } from "react";
import { Link } from "react-router-dom";
import Image from "app/layout/image";
import { isUndefined } from "core/helpers/functions";
import Icon from "app/assets/icon/icon";
import ProductRibbon from "app/layout/productRibbon/productRibbon";

const ProductItem = (props) => {
  const [isInWishList, setIsInWishList] = useState(false);
  const toggleWishlist = () => setIsInWishList(!isInWishList);
  //const isTabOrMob = useMediaQuery({ maxWidth: 767 });
  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  return (
    <div
      className="product-item-content"
      id={props.value}
      key={props.index}
      onClick={(e) => {
        if (!isUndefined(props.onClick)) {
          props.onClick();
        }
      }}
    >
      <div className="product-item-image product-item-part">
        {props.productRibbon && loggedIn && (
          <ProductRibbon val={props.productRibbon} />
        )}
        <Link to={"/" + props.productUrl}>
          <Image
            source={props.productImage.url}
            imgContainer="product-image"
            image_alt={props.productName + " " + props.productImage.label}
          />
        </Link>
      </div>

      <div className="product-item-info product-item-part">
        <p
          className="product-name"
          alt={props.productName}
          title={props.productName}
        >
          {props.productName.length > 30
            ? props.productName.substr(0, 28) + "..."
            : props.productName}
        </p>
      </div>

      <div className="product-item-additional-info product-item-part">
        <p className="product-price">
          <span className="starting-at">starting at </span>
          {"$" + props.productPriceRegularValue}
        </p>
        <p
          className="product-wishlist"
          onClick={(e) => {
            toggleWishlist();
            // props.addSkuToList(wishListOptions[0].value, props.sku);
          }}
        >
          {(isInWishList && <Icon icon={"heartFull"} />) || (
            <Icon icon={"heartEmptyGray"} />
          )}
        </p>
      </div>
    </div>
  );
};
export default ProductItem;
