import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MDBCol } from "mdbreact";

const InvQuo = (props) => {
  const customer = useSelector((state) => state.customer);
  const site = useSelector((state) => state.site);
  const quoteRequestItems =
    props.cart &&
    props.cart.cart &&
    props.cart.cart.items &&
    props.cart.cart.items.length;

  if (site.siteCode.includes("norwell")) {
    return (
      <>
        {customer !== undefined &&
        customer.data !== undefined &&
        customer.data.token &&
        customer.data.is_inventory_visible !== null &&
        customer.data.is_inventory_visible ? (
          <MDBCol
            className={
              "header-top-float-right-inv_quo-wrapper " +
              customer.data.is_inventory_visible
            }
          >
            <Link
              onClick={() => {
                props &&
                  props.setOpen &&
                  props.setOpen(props && props.open && !props.open);
              }}
              to={"/inventory"}
            >
              {"Inventory"}
            </Link>
          </MDBCol>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return (
      <>
        {customer !== undefined &&
        customer.data !== undefined &&
        customer.data.token ? (
          <MDBCol className="header-top-float-right-inv_quo-wrapper">
            <Link
              onClick={() => {
                props &&
                  props.setOpen &&
                  props.setOpen(props && props.open && !props.open);
              }}
              to={"/quote-request"}
            >
              {`Quote Requests ( ${quoteRequestItems} )`}
            </Link>
          </MDBCol>
        ) : (
          <></>
        )}
      </>
    );
  }
};

export default InvQuo;
