import React from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import Icon from "app/assets/icon/icon";
import Slider from "react-slick";

const CustomPageSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <MDBRow
        id="slider-indicators-container"
        style={{
          padding: "15px",
          textAlign: "center",
        }}
      >
        <MDBCol className="indicators-container">
          <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
            {dots}
          </ul>
        </MDBCol>
      </MDBRow>
    ),
  };
  return (
    <>
      <MDBContainer className={"custom-page-mobile-slider-container"}>
        <Slider {...settings}>
          <div>
            <Icon icon={"sketch_to_cad"} />
            <CmsBlock id="custom_page_first_col" dangHtml={true} />
          </div>
          <div>
            <Icon icon={"quality_assurance"} />
            <CmsBlock id="custom_page_second_col" dangHtml={true} />
          </div>
          <div>
            <Icon icon={"competetive_pricing"} />
            <CmsBlock id="custom_page_third_col" dangHtml={true} />
          </div>
          <div>
            <Icon icon={"expert_knowledge"} />
            <CmsBlock id="custom_page_fourth_col" dangHtml={true} />
          </div>
        </Slider>
      </MDBContainer>
    </>
  );
};

export default CustomPageSlider;
