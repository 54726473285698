import React from "react";
import GraphqlWrapper from "core/components/graphQlWrapper";
import queryLoader from "core/graphql/queryLoader";
import Parser from "./Parser";

const query = queryLoader("cmsBlock");

const CmsBlockQueryContainer = (props) => {
  return (
    <GraphqlWrapper query={query} variables={{ id: props.id }} loader={true}>
      <Parser
        dangHtml={props.dangHtml}
        title={props.title}
        cssClass={props.scssClass}
      />
    </GraphqlWrapper>
  );
};

export default CmsBlockQueryContainer;
