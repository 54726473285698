import Icon from "app/assets/icon/icon";
import React from "react";
import {
  removeFilterValueFromURL,
  getSearchParameters,
} from "app/layout/filters/functions";

const ActiveFilterItem = ({ filter }) => {
  const searchParameters = getSearchParameters();
  let isSwitch = false;

  if (!filter.hasOwnProperty("value")) {
    isSwitch = filter.attribute_code?.toString() === "1";
  }

  return (
    <div className="active-filter-item-wrapper">
      <p>{filter.label}</p>
      <div
        className="active-filter-icon"
        onClick={() => {
          removeFilterValueFromURL(
            searchParameters,
            filter,
            filter.value,
            isSwitch
          );
        }}
      >
        <Icon icon="active_filter_dismiss" />
      </div>
    </div>
  );
};

export default ActiveFilterItem;
