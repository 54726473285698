/**
 * Here we map initial state for each Redux state (if necessary)
 * The function name is mappped in'persistedState.js', where the key is the name of the reducer
 * This can be readjusted to possibly use one function to try and find the initial state
 */

import { testInitialState } from "./data/test";
import { siteInitialState } from "./data/site";
import { customerInitialState } from "./data/customer";
import { isEmpty } from "core/helpers/functions";

export const getTestState = (storeState) => {
  let testState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.test)) {
    testState = storeState.test;
  } else {
    testState = testInitialState;
  }
  return testState;
};

export const getSiteState = (storeState) => {
  let siteState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.site)) {
    siteState = storeState.site;
  } else {
    siteState = siteInitialState;
  }
  return siteState;
};

export const getCustomerState = (storeState) => {
  let customerState = {};
  if (!isEmpty(storeState) && !isEmpty(storeState.customer)) {
    customerState = storeState.customer;
  } else {
    customerState = customerInitialState;
  }
  return customerState;
};
