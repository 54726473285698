import React from "react";
import FindRep from "./findRep";
import { uniqBy } from "lodash";

const FindRepDataContainer = ({ data }) => {
  let repLocators = data?.getAllRepLocators;
  let allIndustries = [];
  let allCountries = [];
  let allStates = [];
  let allImages = [];
  if (typeof repLocators !== "undefined") {
    repLocators.map((rep) => {
      if (rep.country !== null && rep.state !== null) {
        let industryArray = rep.industry?.split(/,/g);
        let countryArray = rep.country.split(/,/g);
        let stateArray = rep.state !== null ? rep.state.split(/,/g) : [];
        let imageArray = rep.image.split(/,/g);
        if (typeof industryArray !== "undefined")
          industryArray.map((industry) => {
            allIndustries.push({
              label: industry,
              value: industry,
            });
            return "";
          });
        countryArray.map((country) => {
          allCountries.push({
            label: country,
            value: country,
          });
          return "";
        });
        stateArray.map((state) => {
          allStates.push({
            label: state,
            value: state,
          });
          return "";
        });
        imageArray.map((image) => {
          allImages.push({ image });
          return "";
        });
      }
      return "";
    });
  }
  const sortAlphabetically = (a, b) => {
    let lowercaseA = a.label.toLowerCase();
    let lowercaseB = b.label.toLowerCase();
    return lowercaseA < lowercaseB ? -1 : lowercaseA > lowercaseB ? 1 : 0;
  };

  allIndustries = uniqBy(allIndustries, (industry) => {
    return industry.label;
  });
  allCountries = uniqBy(allCountries, (country) => {
    return country.label;
  });
  allStates = uniqBy(allStates, (state) => {
    return state.label;
  });
  allStates.sort(sortAlphabetically);
  allCountries.sort(sortAlphabetically);
  allIndustries.unshift({ label: "All", value: "All" });
  allIndustries.sort(sortAlphabetically);

  let countriesAndStates = [];

  if (allCountries.length > 0) {
    allCountries.map((country) => {
      let states = [];
      repLocators.map((item) => {
        if (item.country === country.value) {
          let check = states.filter((state) =>
            state.value === item.state ? true : false
          );
          if (check.length === 0) {
            states.push({ label: item.state, value: item.state });
          }
        }
      });
      countriesAndStates.push({ ...country, states: states });
    });
  }

  return (
    <FindRep
      repLocators={repLocators}
      industries={allIndustries}
      countries={allCountries}
      states={allStates}
      images={allImages}
      countriesAndStates={countriesAndStates}
    />
  );
};
export default FindRepDataContainer;
