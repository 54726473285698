import React from "react";
import { MDBRow, MDBCol, MDBBox, MDBContainer } from "mdbreact";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";

const ErrorPage = (props) => {
  return (
    <>
      <MDBContainer className={"error-page"}>
        <MDBRow className="error-page-text">
          <MDBCol md="12">
            <MDBBox tag="h3">Sorry, that page could not be found</MDBBox>
            <ul className="custom-list">
              <li>
                - Double check and see if you typed the URL directly, please
                make sure the spelling is correct.
              </li>
              <li>
                - If you clicked on a link to get here, the link is outdated.
              </li>
            </ul>
            <MDBBox tag="h4">what can you do?</MDBBox>
            <MDBBox tag="p">
              Have no fear, help is near! There are many ways you can get back
              on track.
            </MDBBox>
            <ul className="custom-list">
              <li>- Go back to the previous page.</li>
              <li>
                - Use the search bar at the top of the page to search for your
                products.
              </li>
              <li>- Follow these links to get you back on track!</li>
            </ul>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="8" className={"error-page-buttons"}>
            <Button className="with-arrow" to={"/"}>
              <Icon icon="right_arrow_white" />
              Homepage
            </Button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ErrorPage;
