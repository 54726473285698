import React from "react";
import { connect } from "react-redux";
import Pagination from "./pagination";

const PaginationReduxContainer = (props) => {
  return <Pagination {...props} />;
};

const mapStateToProps = (state) => ({
  pageNumber: state.filters?.page_data?.pageInfo?.current_page,
  totalPages: state.filters?.page_data?.pageInfo?.total_pages,
});

export default connect(mapStateToProps, null)(PaginationReduxContainer);
