import React, { useState } from "react";
import {
  MDBBox,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalHeader,
} from "mdbreact";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import DeleteWishlist from "../deleteWishlist";
import Input from "core/components/input";

const EditWishlist = (props) => {
  const { editWishlist, deleteWishlist } = useWishlist();
  const [toggleEdit, setToggleEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [myName, setMyName] = useState(props.wishlist.name);
  const toggleEditFunc = () => {
    setToggleEdit(!toggleEdit);
  };
  return (
    <MDBContainer id={`edit-wishlist-modal`}>
      <MDBModal isOpen={toggleEdit} toggle={toggleEditFunc} centered>
        <MDBModalHeader className="modal-title-wrapper">
          <MDBBox tag="p">
            Edit Project
            <Icon icon="x_mark_white" onClick={() => toggleEditFunc()} />
          </MDBBox>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer fluid>
            <MDBRow>
              <Input
                placeholder={
                  typeof myName !== "undefined" && typeof myName.length === 0
                    ? "Edit project"
                    : "Project name"
                }
                group
                type="text"
                value={myName}
                maxLength="20"
                onChange={(e) => {
                  setMyName(e.target.value);
                }}
                wrapperClass="light"
              />
            </MDBRow>
            <MDBRow className={`edit-modal-buttons`}>
              <Button
                className="edit-project-save-btn"
                disabled={loading === "save"}
                onClick={(e) => {
                  setLoading("save");
                  let request = {
                    fromName: props.wishlist.name,
                    toName: myName,
                  };
                  editWishlist(request).then(() => {
                    setLoading(false);
                    toggleEditFunc();
                  });
                }}
              >
                {loading === "save" ? "Saving..." : "Save"}
              </Button>

              <MDBCol className="delete-project-btn-wrapper">
                <ModalConsumer value={{ useWrapper: true }}>
                  {({ showModal, hideModal }) => (
                    <MDBBox
                      tag="p"
                      onClick={(e) => {
                        showModal(() => {
                          return (
                            <DeleteWishlist
                              {...props}
                              currentSelectedProject={props.wishlist.name}
                              deleteWishlist={deleteWishlist}
                              toggleEditFunc={toggleEditFunc}
                              setToggleEdit={setToggleEdit}
                            />
                          );
                        });
                      }}
                    >
                      Delete Project
                      <Icon icon={"remove_item_icon"} />
                    </MDBBox>
                  )}
                </ModalConsumer>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
export default EditWishlist;
