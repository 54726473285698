import React from "react";
import gql from "graphql-tag";
import CatalogDataContainer from "./catalog-data-container";
import ReactLoader from "app/layout/loader";
import { useQuery } from "react-apollo-hooks";
import useSite from "app/state/hooks/siteHooks/useSite";
import Error from "app/pages/error";

const CATALOGS = gql`
  query {
    getCatalog {
      catalogs {
        catalogs_id
        category
        fliphtml5
        image
        pdf
        title
        url
      }
    }
  }
`;

const CatalogQueryContainer = () => {
  const { data, loading, error } = useQuery(CATALOGS, {
    variables: { id: "catalogs" },
    fetchPolicy: "cache-first",
  });
  const [, getSiteCode] = useSite();
  if (error) return <Error />;
  if (loading) {
    return (
      <div className="data-loader-container loading">
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
        />
      </div>
    );
  }
  return <CatalogDataContainer data={data} />;
};

export default CatalogQueryContainer;
