import React, { useState } from "react";
import {
  MDBBox,
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Icon from "app/assets/icon/icon";
import { ModalConsumer } from "core/components/modal/ModalContext";
import useSite from "app/state/hooks/siteHooks/useSite";

const DeleteWishlist = (props) => {
  let { deleteWishlist } = props;
  const [toggleModal, setToggleModal] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);

  const toggle = () => {
    setToggleModal(!toggleModal);
  };
  const [, getSiteCode] = useSite();
  return (
    <MDBContainer className="remove-product-modal-wrapper">
      <MDBModal
        className={`modal-wrapper`}
        isOpen={toggleModal}
        centered
        toggle={(e) => {
          toggle();
        }}
      >
        <MDBModalHeader className="are-you-sure-wrapper">
          {getSiteCode() === "norwell_default" ? (
            <Icon icon={"report_ico"} />
          ) : (
            <Icon icon={"report_ico_ilex"} />
          )}
          <p>Are you sure you want to delete this project?</p>
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow className="content-wrapper">
            <MDBCol md="6">
              <MDBBox
                tag="p"
                onClick={(e) => {
                  toggle();
                }}
              >
                Cancel
              </MDBBox>
            </MDBCol>
            <MDBCol md="6">
              <ModalConsumer value={{ useWrapper: true }}>
                {({ showModal }) => (
                  <MDBBox
                    tag="p"
                    disabled={localLoading}
                    onClick={(e) => {
                      setLocalLoading(true);
                      deleteWishlist(props.currentSelectedProject).then(() => {
                        setLocalLoading(false);
                        toggle();
                      });
                    }}
                  >
                    {localLoading ? "Removing..." : "Confirm"}
                  </MDBBox>
                )}
              </ModalConsumer>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};
export default DeleteWishlist;
