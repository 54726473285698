import React, { useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import Icon from "app/assets/icon/icon";
import ProductFinishes from "./finishesSlider";
import { sliderSettings } from "./settings/sliderSettings";
import { sliderDotsSettings } from "./settings/sliderDotsSettings";
import MainImage from "./mainImage";
import ModalPopupGallery from "./modalPopupGallery";

const ProductMediaGallerySlider = (data) => {
  let {
    sliderNav1,
    setSliderNav1,
    sliderNav2,
    setSliderNav2,
    sliderNav3,
    setSliderNav3,
    activeFinish,
    setActiveFinish,
    slider1,
    slider2,
    slider3,
    base_image,
    image_gallery,
    finishes,
    activeImage,
    setActiveImage,
    popupGallery,
    setPopupGallery,
    activeImageIndex,
    setActiveImageIndex,
    loading,
  } = data;
  useEffect(() => {
    setSliderNav3(slider3);
    setSliderNav2(slider2);
    setSliderNav1(slider1);
  }, []);

  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  return (
    <div className="slider-wrapper">
      {/* navigation slider */}
      {!isMobileOrTablet && image_gallery.length > 0 && (
        <MDBRow className={"nav-slider-container " + isMobileOrTablet}>
          <MDBCol
            size="12"
            className={`slider-arrow prev-arrow "  ${
              image_gallery.length < 3 && "disabled"
            }`}
            onClick={() => {
              if (image_gallery.length > 2) {
                slider2.slickPrev();
              }
            }}
          >
            <Icon icon="black_arrow_up" />
          </MDBCol>
          <MDBRow className="nav-slider-row">
            <MDBCol size="12" className="nav-slider-column">
              <Slider
                ref={(slider) => (slider2 = slider)}
                {...sliderDotsSettings(data)}
                className="nav-slider"
              >
                {base_image && (
                  <img
                    onClick={() => {
                      sliderNav1.slickGoTo(0);
                    }}
                    key={`product-media-gallery-dot-item-${0}`}
                    className={
                      base_image.url &&
                      base_image.url.includes("placeholder") &&
                      "placeholder"
                    }
                    src={
                      base_image.url && base_image.url.includes("placeholder")
                        ? "/placeholder.png"
                        : base_image.url
                    }
                    alt={base_image.label}
                  />
                )}
                {image_gallery &&
                  image_gallery
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((item, index) => {
                      return (
                        <img
                          onClick={() => {
                            let newIndex = index + 1;
                            sliderNav1.slickGoTo(newIndex);
                          }}
                          key={`product-media-gallery-dot-item-${index}`}
                          className={
                            item.url &&
                            item.url.includes("placeholder") &&
                            "placeholder"
                          }
                          src={
                            item.url && item.url.includes("placeholder")
                              ? "/placeholder.png"
                              : item.url
                          }
                          alt={item.label}
                          title={item.label}
                        />
                      );
                    })}
              </Slider>
            </MDBCol>
          </MDBRow>
          <MDBCol
            size="12"
            className={`slider-arrow next-arrow  ${
              image_gallery.length < 3 && "disabled"
            }`}
            onClick={() => {
              if (image_gallery.length > 2) {
                slider2.slickNext();
              }
            }}
          >
            <Icon icon="black_arrow_down" />
          </MDBCol>
        </MDBRow>
      )}
      {/* main image slider */}
      <Slider
        asNavFor={sliderNav2}
        ref={(slider) => (slider1 = slider)}
        {...sliderSettings(data, isMobileOrTablet)}
        className="product-media-gallery-slider"
      >
        {base_image && (
          <MainImage
            item={base_image}
            index={0}
            finish={false}
            isMobileOrTablet={isMobileOrTablet}
            is_new={data.is_new && data.is_new}
            popupGallery={popupGallery}
            setPopupGallery={setPopupGallery}
            activeImage={activeImage}
            setActiveImage={setActiveImage}
            activeImageIndex={activeImageIndex}
            setActiveImageIndex={setActiveImageIndex}
          />
        )}
        {image_gallery &&
          image_gallery.map((item, index) => {
            return (
              <MainImage
                item={item}
                index={index + 1}
                finish={false}
                isMobileOrTablet={isMobileOrTablet}
                is_new={data.is_new && data.is_new}
                popupGallery={popupGallery}
                setPopupGallery={setPopupGallery}
                activeImage={activeImage}
                setActiveImage={setActiveImage}
                activeImageIndex={activeImageIndex}
                setActiveImageIndex={setActiveImageIndex}
              />
            );
          })}
        {finishes &&
          finishes.map((item, index) => {
            return (
              <MainImage
                item={item}
                index={index + image_gallery.length + 1}
                finish={true}
                isMobileOrTablet={isMobileOrTablet}
                is_new={data.is_new && data.is_new}
                popupGallery={popupGallery}
                setPopupGallery={setPopupGallery}
                activeImage={activeImage}
                setActiveImage={setActiveImage}
                activeImageIndex={activeImageIndex}
                setActiveImageIndex={setActiveImageIndex}
              />
            );
          })}
      </Slider>

      {/* product finishes slider */}
      <ProductFinishes
        {...data}
        finishes={finishes}
        activeFinish={activeFinish}
        setActiveFinish={setActiveFinish}
        slider3={slider3}
        sliderNav1={sliderNav1}
        isMobileOrTablet={isMobileOrTablet}
        imageGalleryLength={image_gallery && image_gallery.length + 1}
      />
      {!isMobileOrTablet && (
        <MDBModal
          className="popup-gallery-modal"
          isOpen={popupGallery}
          toggle={() => setPopupGallery(!popupGallery)}
          centered
        >
          <MDBModalHeader>
            <Icon
              icon="active_filter_dismiss"
              onClick={() => setPopupGallery(!setPopupGallery)}
            />
          </MDBModalHeader>
          <MDBModalBody>
            <ModalPopupGallery
              popupGallery={popupGallery}
              setPopupGallery={setPopupGallery}
              activeImage={activeImage}
              setActiveImage={setActiveImage}
              base_image={base_image}
              image_gallery={image_gallery}
              finishes={finishes}
              activeImageIndex={activeImageIndex}
              setActiveImageIndex={setActiveImageIndex}
            />
          </MDBModalBody>
        </MDBModal>
      )}
    </div>
  );
};

export default ProductMediaGallerySlider;
