import React from "react";
import ShopOnline from "./shopOnline";
import ReactLoader from "app/layout/loader/loader";
import { MDBBox } from "mdbreact";
import useSite from "app/state/hooks/siteHooks/useSite";

const ShopOnlineDataContainer = (props) => {
  const [, getSiteCode] = useSite();

  if (props.data !== undefined) {
    if (props.data?.getOnlineShops.length > 0) return <ShopOnline {...props} />;
    else return <MDBBox>No online stores</MDBBox>;
  }
  return (
    <ReactLoader
      color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
      width={50}
      height={50}
      type={`ThreeDots`}
      key={"loader-items"}
    />
  );
};

export default ShopOnlineDataContainer;
