import gql from "graphql-tag";
export const PRODUCTS = gql`
  query Products(
    $filters: ProductAttributeFilterInput!
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      filter: $filters
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
        position
      }
      total_count
      items {
        id
        name
        sku
        brand
        is_new
        url_key
        finishes_count
        categories {
          canonical_url
          url_key
          url_path
        }
        canonical_url
        image {
          label
          url
        }
        small_image {
          label
          url
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;

export const PRODUCTS_SEARCH = gql`
  query Products(
    $search: String
    $filters: ProductAttributeFilterInput
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      filter: $filters
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
      }
      total_count
      items {
        id
        name
        sku
        brand
        is_new
        url_key
        finishes_count
        categories {
          canonical_url
          url_key
          url_path
        }
        canonical_url
        image {
          label
          url
        }
        small_image {
          label
          url
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;
