import React from "react";

export const sliderSettings = (data, isMobileOrTablet) => {
  return {
    dots: isMobileOrTablet ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    dotsClass: data.dotsClass,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    focusOnSelect: true,
    lazyLoad: "progressive",
    fade: true,
    touchMove: isMobileOrTablet ? true : false,

    customPaging: () => <div className={"slider-custom-dot"} />,
    appendDots: (dots) => (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <ul className={"list-custom-dots"} style={{ margin: "1rem 0 0 1rem" }}>
          {dots}
        </ul>
      </div>
    ),
  };
};
