import React from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import SpecTable from "./specTable";

const SpecificationContainer = (props) => {
  function renderHTML(text) {
    return {
      __html: `${text}`,
    };
  }

  return (
    <MDBContainer className="spec-container">
      <MDBRow className="descriptions-wrapper">
        <MDBCol size="12" className="product-name">
          {props.name && props.name}
        </MDBCol>
        <MDBCol size="12">
          <h6>SKU {props.sku && props.sku}</h6>
        </MDBCol>
        <MDBCol
          size="12"
          className="description"
          dangerouslySetInnerHTML={renderHTML(
            props.description && props.description.html
          )}
        ></MDBCol>
      </MDBRow>
      <MDBRow className="dimensions-container">
        <h6>SPECIFICATIONS</h6>
        <SpecTable {...props} />
      </MDBRow>
    </MDBContainer>
  );
};

export default SpecificationContainer;
