// logic is inversed, true means that an error exists

export const validate = (industryselect, countryselect, selectstate) => {
  return {
    industryselect: industryselect.length === 0,
    countryselect: countryselect.length === 0,
    selectstate: selectstate.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};
