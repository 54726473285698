import React from "react";
import { MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";

const RepCard = (props) => {
  let locators = props.repLocators;

  return (
    <MDBCol className={`find-rep-card`}>
      {locators.industry && (
        <h6 className="industry-label">{locators.industry}</h6>
      )}
      {locators.firstname && locators.lastname !== null && (
        <h6 className="rep-name-label">
          {locators.firstname + " " + locators.lastname}
        </h6>
      )}
      <div className="card-section">
        <Icon icon={"rep_card_location"} />
        <div className="card-section-text">
          {locators.position !== null && <p>{locators.position}</p>}
          {locators.state !== null && <p> {locators.state}</p>}
        </div>
      </div>
      <div className="card-section">
        <Icon icon={"rep_card_phone"} />
        <div className="card-section-text">
          {locators.phone !== null && <p> {locators.phone}</p>}
          {locators.fax !== null && <p> {locators.fax}</p>}
        </div>
      </div>
      {locators.email !== null && (
        <div className="card-section">
          <Icon icon={"rep_card_email"} />
          <div className="card-section-text">
            <a href={"mailto:" + locators.email}> {locators.email}</a>
          </div>
        </div>
      )}
      {locators.web_url !== null && (
        <div className="card-section">
          <Icon icon={"rep_card_link"} />
          <div className="card-section-text">
            <a href={locators.web_url}> {locators.web_url}</a>
          </div>
        </div>
      )}
    </MDBCol>
  );
};
export default RepCard;
