/**
 * This file has only one function, to create a list of which Redux state belonging to the project needs to be persisted
 */
import { isEmpty } from "core/helpers/functions";
import siteInitialState from "./data/site/initialState";
import customerInitialState from "./data/customer/initialState";
// import testInitialState from "./data/test/initialState";
export const persistList = {
  // test: testInitialState,
  site: siteInitialState,
  customer: customerInitialState,
};

export const getStateByKey = (store, key, projectStates) => {
  let thisState = {};
  if (!isEmpty(store) && !isEmpty(store[key])) {
    thisState = store[key];
  } else {
    thisState = projectStates[key]();
  }
  return thisState;
};
