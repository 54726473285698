export const validate = (newPassword, confirmPassword) => {
  return {
    newPassword: newPassword.length === 0,
    confirmPassword: confirmPassword.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];
  return hasError ? shouldShow : false;
};
