import React from "react";
import { connect } from "react-redux";
import ListingRender from "./listing-render";
import { default as commonActions } from "core/state/redux/data/common/actions";

const ListingRenderReduxContainer = (props) => {
  let category = props && props.category;
  return (
    <>
      <ListingRender {...props} category={category} />
    </>
  );
};

const mapStateToProps = (state) => ({
  data:
    typeof state.filters !== "undefined" &&
    typeof state.filters.data !== "undefined" &&
    typeof state.filters.data.items !== "undefined"
      ? state.filters.data.items
      : [],
  state: state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: () => {
      dispatch(commonActions.lock());
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingRenderReduxContainer);
