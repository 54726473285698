import React, { memo, useState } from "react";
import { MDBContainer } from "mdbreact";
import SliderItem from "./sliderItem";
import Slider from "react-slick";
import sliderSettings from "./sliderSettings";

const BannerSlider = memo((props) => {
  const site = props.site.siteCode;
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = sliderSettings(props, activeSlide, setActiveSlide);

  return (
    <MDBContainer className={"banner-slider-container w-100 " + site}>
      <Slider {...settings} className="banner-slider">
        {props.sortedSlides &&
          props.sortedSlides.map((item, index) => {
            return (
              <SliderItem key={`slider-slide-item-${index}`} item={item} />
            );
          })}
      </Slider>
    </MDBContainer>
  );
});

export default BannerSlider;
