import React from "react";
import Catalogs from "./catalogs";

const CatalogDataContainer = (props) => {
  const allCatalogs =
    typeof props.data !== undefined &&
    props.data &&
    typeof props.data.getCatalog !== undefined
      ? props.data.getCatalog.catalogs
      : false;

  const norwellCatalogs =
    allCatalogs &&
    allCatalogs.filter((catalogs) => catalogs.category === "Norwell lighting");

  const ilexCatalogs =
    allCatalogs &&
    allCatalogs.filter(
      (catalogs) => catalogs.category === "ILEX Customer Metalcraft"
    );

  return (
    <Catalogs
      allCatalogs={allCatalogs}
      norwellCatalogs={norwellCatalogs}
      ilexCatalogs={ilexCatalogs}
    />
  );
};

export default CatalogDataContainer;
