export default (state, action) => {
  return Object.assign({}, state, {
    page_data: Object.assign({}, state.page_data, {
      ...state.page_data,
      pageInfo: Object.assign({}, state.page_data.pageInfo, {
        ...state.page_data.pageInfo,
        page_size: action.size,
      }),
    }),
  });
};
