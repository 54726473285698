import React, { useState } from "react";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import Button from "core/components/button";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalFooter,
  MDBModalBody,
  MDBBox,
} from "mdbreact";
import Icon from "app/assets/icon/icon";
import Image from "app/layout/image";
import WishlistPlpItem from "./wishlistPlpItem";
import useSite from "app/state/hooks/siteHooks/useSite";
import Input from "app/layout/input";
import { Link } from "react-router-dom";
import ResetPasswordModal from "app/pages/customer/resetPassword/";
import { useDispatch } from "react-redux";
import { messagesActions } from "core/state/redux/data/messages";
import LoginRegisterPopupModal from "app/pages/customer/loginRegisterPopupModal/login-registerPopupModal";
import { validate, shouldMarkError } from "./validation";
import ReactLoader from "../loader/loader";
const Wishlist = ({ toggleModal, setToggleModal, ...props }) => {
  const {
    fetchWishlistData,
    getWishlistItems,
    createWishlist,
    checkEmail,
  } = useWishlist();
  // let fetchData = fetchWishlistData();
  let fetchArr = getWishlistItems();
  const [modalWishlist, setModalWishlist] = useState(false);
  const [modalWishlistSecond, setModalWishlistSecond] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState(false);
  const [toggleResetPassword, setToggleResetPassword] = useState(false);
  ///validation
  let errors = validate(email, password);
  const [touched, setTouched] = useState(false);
  //

  const modalToggle = () => {
    setUsername(false);
    setEmail("");
    setModalWishlist(!modalWishlist);
  };
  const modalToggleSecond = () => {
    setModalWishlistSecond(!modalWishlistSecond);
  };
  const [toggleLoginRegisterModal, setToggleLoginRegisterModal] = useState(
    false
  );
  const [selectedOption, setSelectedOption] = useState("login");

  let loggedIn = false;
  if (typeof props.customer !== "undefined") loggedIn = false;
  if (typeof props.customer === "object") {
    if ("token" in props.customer) {
      if (props.customer.token && props.customer.token !== null) {
        loggedIn = true;
      }
    }
  }
  const [project, setProject] = useState("");

  // determine if product is added to any wishlist
  let isInWishlist = false;

  fetchArr.map((list) => {
    return list.items.map((item) => {
      if (item.product.sku === props.product.sku) {
        isInWishlist = true;
      }
    });
  });
  let dispatch = useDispatch();

  const handleLoginCheck = () => {
    if (username === false) {
      setLoading(true);
      checkEmail(email).then((response) => {
        if (response === false) {
          setLoading(false);
          dispatch(
            messagesActions.addMessage(
              "We are not able to locate your email",
              "danger"
            )
          );
        }
        setLoading(false);
        setUsername(response);
      });
    } else {
      // login logic
      setLoading(true);
      props.lock({
        always: () => {},
        success: () => {
          setLoading(false);
          setModalWishlist(false);
          setModalWishlistSecond(true);
        },
        fail: () => {
          dispatch(
            messagesActions.addMessage(
              "The account sign-in was incorrect or your account is disabled temporarily. Please contact customer service.",
              "danger"
            )
          );
          setLoading(false);
        },
      });
      props.login(email, password, props.customer.cartToken);
    }
  };
  const [, getSiteCode] = useSite();
  return (
    <>
      {React.cloneElement(props.children, {
        onClick: () => {
          if (!loggedIn) {
            modalToggle();
          } else {
            modalToggleSecond();
          }
        },
        icon:
          isInWishlist === true
            ? getSiteCode() === "norwell_default"
              ? "norwell_wishlist_full"
              : "ilex_wishlist_full"
            : "wishlist_empty_heart",
      })}
      <div className="wishlist-modals-wrapper">
        {toggleLoginRegisterModal && (
          <LoginRegisterPopupModal
            toggleModal={toggleLoginRegisterModal}
            setToggleModal={setToggleLoginRegisterModal}
            selectedOption={selectedOption}
          />
        )}
        {modalWishlist && (
          <MDBModal
            isOpen={modalWishlist}
            toggle={(e) => {
              modalToggle();
            }}
            className={"wishlist-modal wishlist-wrapper"}
            centered
          >
            <MDBModalHeader fluid className="modal-title-wrapper">
              <p>Add this item to your Favorites</p>
              <Icon icon="x_mark_white" onClick={() => modalToggle(false)} />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer className="email-info">
                {username === false ? (
                  <MDBContainer className="product-image-and-name-wrapper-modal-plp">
                    <Image source={props.product.image.url} />
                    <MDBBox tag="h6">{props.product.name}</MDBBox>
                  </MDBContainer>
                ) : (
                  <MDBContainer className="not-your-email-wrapper">
                    <MDBBox tag="p">{email}</MDBBox>
                    <MDBBox tag="p">
                      Not your email?{" "}
                      <Link
                        onClick={(e) => {
                          setEmail("");
                          setUsername(false);
                          setTouched(false);
                        }}
                        className="go-back-link"
                      >
                        Go back
                      </Link>
                    </MDBBox>
                  </MDBContainer>
                )}
              </MDBContainer>
              <MDBContainer className="before-you-do-that-wishlist-wrapper">
                <MDBBox tag="h4" className="before-title">
                  {username === false
                    ? "Before you do that..."
                    : `Welcome back, ${username}`}
                </MDBBox>
                {username === false ? (
                  <>
                    <MDBBox tag="p" className="text-center">
                      <Link
                        className={"link-login-register-select"}
                        onClick={() => {
                          setSelectedOption("login");
                          modalToggle();
                          setToggleLoginRegisterModal(
                            !toggleLoginRegisterModal
                          );
                        }}
                      >
                        Login
                      </Link>{" "}
                      or{" "}
                      <Link
                        className={"link-login-register-select"}
                        onClick={() => {
                          setSelectedOption("register");
                          modalToggle();
                          setToggleLoginRegisterModal(
                            !toggleLoginRegisterModal
                          );
                        }}
                      >
                        Register
                      </Link>{" "}
                      with your email address
                    </MDBBox>
                  </>
                ) : (
                  <MDBBox tag="p" className="text-center ">
                    Great to see you again. Enter your account password to
                    continue
                  </MDBBox>
                )}
                {username === false ? (
                  <Input
                    placeholder={`${
                      shouldMarkError("email", errors, touched)
                        ? "Account Email is required"
                        : "Account Email"
                    }`}
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleLoginCheck();
                      }
                    }}
                    required
                    className={
                      shouldMarkError("email", errors, touched)
                        ? "invalid"
                        : "" + email !== "" && "valid"
                    }
                    onBlur={(e) => {
                      setTouched({ ...touched, email: true });
                    }}
                    shouldMarkError={shouldMarkError("email", errors, touched)}
                  />
                ) : (
                  <Input
                    placeholder={`${
                      shouldMarkError("password", errors, touched)
                        ? "Password is required"
                        : "Password"
                    }`}
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleLoginCheck();
                      }
                    }}
                    required
                    className={
                      shouldMarkError("password", errors, touched)
                        ? "invalid"
                        : "" + password !== "" && "valid"
                    }
                    onBlur={(e) => {
                      setTouched({ ...touched, password: true });
                    }}
                    shouldMarkError={shouldMarkError(
                      "password",
                      errors,
                      touched
                    )}
                  />
                )}
                <Button
                  disabled={loading && loading}
                  onClick={(e) => {
                    handleLoginCheck();
                  }}
                >
                  {loading ? "Processing..." : "Continue"}
                </Button>
                <Link
                  onClick={() => {
                    setToggleResetPassword(!toggleResetPassword);
                    setModalWishlist(!modalWishlist);
                  }}
                  className="forgot-password-link-login-wishlist"
                >
                  Forgot password?
                </Link>
              </MDBContainer>
            </MDBModalBody>
          </MDBModal>
        )}
        {modalWishlistSecond && (
          <MDBModal
            isOpen={modalWishlistSecond}
            toggle={(e) => {
              modalToggleSecond();
            }}
            className={"wishlist-modal second-modal wishlist-wrapper"}
            centered
          >
            <MDBModalHeader fluid className="modal-title-wrapper">
              <p>Add this item to your Projects</p>
              <Icon
                icon="x_mark_white"
                onClick={() => modalToggleSecond(false)}
              />
            </MDBModalHeader>
            <MDBModalBody className="logged-in-modal-wishlist-plp">
              <div className="create-new-project-input-button-wrapper">
                <Input
                  className="input-create-new-projects"
                  placeholder={"Create New Projects"}
                  group
                  type="text"
                  value={project}
                  onChange={(e) => {
                    if (e.target.value.length <= 24) {
                      setProject(e.target.value);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleLoginCheck();
                    }
                  }}
                />

                <Button
                  onClick={(e) => {
                    setLoading(true);
                    props.lock({
                      always: () => {},
                      success: () => {
                        setLoading(false);
                      },
                      fail: () => {
                        setLoading(false);
                      },
                    });
                    createWishlist(project).then(
                      setTimeout(() => {
                        setProject("");
                        setLoading(false);
                      }, 500)
                    );
                  }}
                >
                  Add
                </Button>
              </div>

              {fetchArr.map((list, index) => {
                let checked = false;
                let foundItem = list.items.filter(
                  (fItem) => fItem.product.sku === props.product.sku
                );
                if (foundItem.length !== 0) {
                  checked = true;
                }
                return (
                  <WishlistPlpItem
                    key={`wishlist-list-id-${list.id}`}
                    loading={loading}
                    setLoading={setLoading}
                    list={list}
                    checked={checked}
                    sku={props.product.sku}
                    index={index}
                  />
                );
              })}
            </MDBModalBody>
            <MDBModalFooter>
              <span className="wishlist-modal-save-button">
                {loading ? (
                  <ReactLoader
                    color={"#ffffff"}
                    width={50}
                    height={50}
                    type={`ThreeDots`}
                  />
                ) : (
                  <></>
                )}
              </span>
            </MDBModalFooter>
          </MDBModal>
        )}
        <ResetPasswordModal
          toggleLoginModal={modalWishlist}
          setToggleLoginModal={setModalWishlist}
          toggleModal={toggleResetPassword}
          setToggleModal={setToggleResetPassword}
        />
      </div>
    </>
  );
};

export default Wishlist;
