import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React from "react";
import { withRouter } from "react-router-dom";
import Breadcrumbs from "app/layout/breadcrumbs";
import { useMediaQuery } from "react-responsive";
import Pagination from "app/pages/catalog/category/pagination";
import SeoHelmet from "app/layout/seoHelmet";

const ShopOnline = (props) => {
  let { data } = props;
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  return (
    <>
      <SeoHelmet title="Shop Online" url={`/shop-online`} />
      <MDBContainer className="shop-online-wrapper">
        {!isMobileOrTablet && (
          <MDBRow>
            <Breadcrumbs />
          </MDBRow>
        )}
        <MDBRow>
          <h1 className="wow slideInUp">Shop Online</h1>
        </MDBRow>
        <MDBRow className="shop-online-content">
          {data.getOnlineShops.map((shop) => {
            return (
              <MDBCol md="4" className="shop-online-item">
                {shop.image !== "" || shop.image !== null ? (
                  <img src={shop.image} alt="shop-online-item"></img>
                ) : (
                  <img
                    src={"https://via.placeholder.com/300"}
                    alt="shop-online-item"
                  />
                )}
                <div className="shop-name-and-link-wrapper">
                  <h5>{shop.name}</h5>
                  <a target={"_blank"} href={shop.url}>
                    {shop.name + ".com"}
                  </a>
                </div>
              </MDBCol>
            );
          })}
          <MDBContainer className="pagination-mobile">
            <Pagination />
          </MDBContainer>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default withRouter(ShopOnline);
