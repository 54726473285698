import React from "react";
import { getSearchParameters } from "app/layout/filters/functions";
import { isEmpty, isUndefined } from "core/helpers/functions";
import SwitchFilter from "app/layout/filters/switchFilter";
import SidebarItem from "./sidebarItem";

const addTypeToFilters = (filters) => {
  return filters.map((filter) => {
    if (!filter.hasOwnProperty("type") && isFilterSwitchFilter(filter)) {
      return {
        ...filter,
        type: "boolean",
      };
    } else {
      if (filter.attribute_code === "brand") {
        return {
          ...filter,
          type: "boolean",
        };
      }
      return {
        ...filter,
        type: "multi",
      };
    }
  });
};

const groupBy = (filters, key) => {
  return filters.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const isFilterSwitchFilter = (filter) => {
  let filterIsSwitch = false;

  let mapValues = filter?.options?.map((filterOption) => {
    if (
      filterOption.value.toString() === "1" ||
      filterOption.value.toString() === "0"
    ) {
      filterIsSwitch = true;
    }
  });

  return filterIsSwitch;
};

export const generateFilterRender = (filters, showBrand) => {
  if (
    filters === undefined ||
    filters.data === undefined ||
    filters.data.aggregations === undefined
  ) {
    return;
  }

  let copiedFilters = filters.data.aggregations;
  // Remove Magento default filters
  if (filters.data.aggregations[0]?.hasOwnProperty("options")) {
    copiedFilters = filters.data.aggregations.filter((item) => {
      // if (showBrand) {
      //   return ["price", "category_id"].indexOf(item.attribute_code) === -1;
      // } else {
      //   return (
      //     ["price", "category_id", "brand"].indexOf(item.attribute_code) === -1
      //   );
      // }

      return ["price", "category_id"].indexOf(item.attribute_code) === -1;
    });

    copiedFilters = addTypeToFilters(copiedFilters);
    copiedFilters = groupBy(copiedFilters, "type");
  }

  let isNewCount = 0;
  let filterItems = filters.data?.items.map((item) => {
    if (item.is_new === 1) {
      isNewCount++;
    }
  });

  // sorting multi filters by position
  copiedFilters = Object.keys(copiedFilters).reduce((accumulator, key) => {
    if (key === "multi") {
      return {
        ...accumulator,
        [key]: copiedFilters[key].sort(
          (firstElement, secondElement) =>
            firstElement.position - secondElement.position
        ),
      };
    }

    return { ...accumulator, [key]: copiedFilters[key] };
  }, {});

  return Object.entries(copiedFilters)
    .sort()
    .map((filterType) => {
      return (
        <div key={filterType} className={`filters-${filterType[0]}-wrapper`}>
          {filterType[1].map((filter, index) => {
            let isSwitch = isFilterSwitchFilter(filter);
            if (isSwitch) {
              return (
                <SwitchFilter
                  key={index}
                  filterOption={filter.options[1]}
                  filter={filter}
                  isSwitch={isSwitch}
                  isNewCount={isNewCount}
                />
              );
            } else {
              return (
                <div
                  className={"multi-filter " + filter.attribute_code}
                  key={index}
                >
                  <SidebarItem isSwitch={isSwitch} filter={filter} />
                </div>
              );
            }
          })}
        </div>
      );
    });
};

export function getActiveFilters(filters) {
  const searchParameters = getSearchParameters();

  let activeFilters = [];

  if (isEmpty(searchParameters) || isUndefined(searchParameters)) {
    return activeFilters;
  }

  const allFilterKeys = Object.keys(searchParameters);
  const allFilterValues = Object.values(searchParameters);
  const allActiveFilterValues = allFilterValues.join(",").split(",");
  let activeFilter = null;

  activeFilters = allActiveFilterValues.map((value, index) => {
    if (value === "1" && allFilterKeys[index] === "is_new") {
      const filterKey = allFilterKeys[index];
      const filterValue = allActiveFilterValues[index];
      activeFilter = createFilter(filterKey, filterValue);
    } else {
      const filterKeyIndex = allFilterValues.findIndex((subArray) => {
        return subArray.indexOf(allActiveFilterValues[index]) !== -1;
      });
      const filterKey = allFilterKeys[filterKeyIndex];
      const filterValue = allActiveFilterValues[index];
      activeFilter = createFilter(filterKey, filterValue);
    }
    return findFilter(filters, activeFilter);
  });

  activeFilters = activeFilters.filter((activeFilter) => {
    return activeFilter !== undefined;
  });

  return activeFilters;
}

const findFilter = (filters, filterToSearchFor) => {
  let filter = undefined;
  let filterOption = undefined;

  if (
    isEmpty(filters) ||
    isUndefined(filters) ||
    isUndefined(filterToSearchFor)
  ) {
    return filter;
  }

  filter = Object.values(filters).find((aggregationFilter) => {
    return aggregationFilter["attribute_code"] ===
      filterToSearchFor.attribute_code
      ? aggregationFilter
      : undefined;
  });

  if (!isUndefined(filter)) {
    if (isFilterSwitchFilter(filter)) {
      if (filter.attribute_code === "is_new") {
        return {
          label: "New",
          attribute_code: filter.options[0].value,
        };
      }
    } else {
      filterOption = filter.options.find((option) => {
        return option.value === filterToSearchFor.filterValue;
      });
    }
  }

  return filterOption;
};

const createFilter = (filterId, filterValue) => {
  return {
    attribute_code: filterId,
    filterValue: filterValue,
  };
};
