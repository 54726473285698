import React, { useState } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Input from "app/layout/input";
import Button from "core/components/button";
import CmsBlock from "app/layout/cmsBlock";
import { validate, shouldMarkError } from "./resetPasswordPageValidation";
import { useDispatch } from "react-redux";
import { messagesActions } from "core/state/redux/data/messages";
import LoginRegisterPopupModal from "../../loginRegisterPopupModal/login-registerPopupModal";

const ResetPasswordPage = ({
  props,
  loading,
  setLoading,
  touched,
  setTouched,
  status,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmNewPassword,
}) => {
  let errors = validate(newPassword, confirmPassword);

  let currentUrl = window.location.href;
  const [toggleLoginRegisterModal, setToggleLoginRegisterModal] = useState(
    false
  );
  const getToken = (str) => {
    return str.split("=")[1];
  };
  let token = getToken(currentUrl);
  let dispatch = useDispatch();
  const [loginButton, setLoginStatus] = useState(false);

  const sendData = () => {
    if (newPassword.length !== 0 && newPassword === confirmPassword) {
      setLoading(true);
      props.lock({
        always: () => {},
        success: () => {
          setLoading(false);
          props.history.push({
            pathname: "/",
          });
          setLoginStatus(true);
        },
        fail: () => {
          dispatch(
            messagesActions.addMessage(
              "Something went wrong. Please contact customer service.",
              "danger"
            )
          );
          setLoading(false);
        },
      });
      props.resetPassword(token, newPassword, confirmPassword);
    } else {
      if (newPassword.length === 0) {
        dispatch(
          messagesActions.addMessage(
            "Password and confirm password are required.",
            "danger"
          )
        );
      }
      if (newPassword !== confirmPassword) {
        dispatch(
          messagesActions.addMessage(
            "Password and confirm password must be the identical.",
            "danger"
          )
        );
      }
    }
  };
  return (
    <>
      <div className="privacy-policy-image-wrapper">
        <CmsBlock id="reset_password_image" dangHtml={true} />
      </div>
      <MDBContainer className="reset-password-page ">
        <MDBRow fluid className="breadcrumbs-container">
          <Breadcrumbs />
        </MDBRow>
        <h1>Forgot Password</h1>

        <p>Please enter your new password </p>

        <MDBContainer className="reset-password-page-form-wrapper">
          <Input
            wrapperClass={"light"}
            placeholder={`${
              shouldMarkError("newPassword", errors, touched)
                ? "New password is required"
                : "new password"
            }`}
            required={true}
            type={"password"}
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            className={`light ${
              shouldMarkError("newPassword", errors, touched)
                ? "invalid"
                : "" + newPassword !== "" && "valid"
            }
            `}
            onBlur={(e) => {
              setTouched({ ...touched, newPassword: true });
            }}
            shouldMarkError={shouldMarkError("newPassword", errors, touched)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                sendData();
              }
            }}
          />

          <Input
            wrapperClass={"light"}
            placeholder={`${
              shouldMarkError("confirmPassword", errors, touched)
                ? "confirm password is required"
                : "confirm password"
            }`}
            required={true}
            type={"password"}
            value={confirmPassword}
            onChange={(event) => setConfirmNewPassword(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                sendData();
              }
            }}
            className={
              shouldMarkError("confirmPassword", errors, touched)
                ? "invalid"
                : "" + confirmPassword !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, confirmPassword: true });
            }}
            shouldMarkError={shouldMarkError(
              "confirmPassword",
              errors,
              touched
            )}
          />
          <Button
            disabled={loading}
            className={"btn-with-icon"}
            direction={"mr-2"}
            leftIcon={true}
            icon={"arrow-right"}
            text={<span>{loading ? "processing..." : "reset password"}</span>}
            onClick={() => {
              sendData();
            }}
          />
        </MDBContainer>
        <LoginRegisterPopupModal
          toggleModal={toggleLoginRegisterModal}
          setToggleModal={setToggleLoginRegisterModal}
        />
      </MDBContainer>
    </>
  );
};

export default ResetPasswordPage;
