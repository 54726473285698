import React from "react";
import Slider from "react-slick";
import GalleryItem from "../galleryItem";
import sliderSettings from "./heritageOfQualitySliderSettings";
import { MDBCol } from "mdbreact";

const HeritageOfQualityMobile = ({ galleries }) => {
  const settings = sliderSettings();

  return (
    <MDBCol lg="8" className="heritage-of-slider-container">
      {galleries ? (
        <Slider {...settings} className="heritage-of-quality-slider">
          {galleries.map((gallery) => {
            return <GalleryItem gallery={gallery} />;
          })}
        </Slider>
      ) : (
        <></>
      )}
    </MDBCol>
  );
};
export default HeritageOfQualityMobile;
