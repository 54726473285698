import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import SeoHelmet from "app/layout/seoHelmet";
import { Redirect } from "react-router-dom";
import useSite from "app/state/hooks/siteHooks/useSite";
import WhereToBuyQueryContainer from "./whereToBuy-query-container";

const WhereToBuyReduxContainer = (props) => {
  const [, getSiteCode] = useSite();
  if (getSiteCode() === "norwell_default") {
    return (
      <>
        <SeoHelmet title="Where to buy" url={`/where-to-buy`} />
        <WhereToBuyQueryContainer {...props} />
      </>
    );
  } else {
    return <Redirect to={"/"} />;
  }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WhereToBuyReduxContainer);
