import React from "react";

const Pages = ({ customer, selectedPage, page, history, orderData }) => {
  let SelectedPage = selectedPage;

  return (
    <SelectedPage
      customer={customer}
      history={history}
      page={page}
      orderData={orderData}
    />
  );
};

export default Pages;
