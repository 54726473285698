import React from "react";
import RecentlyViewed from "./recentlyViewed";

const RecentlyViewedDataContainer = (props) => {
  let filteredArray =
    props.recently_viewed &&
    props.recently_viewed.items.length > 0 &&
    props.recently_viewed.items.filter((product) => product.sku !== props.sku);

  return <RecentlyViewed {...props} filteredArray={filteredArray} />;
};

export default RecentlyViewedDataContainer;
