export const contactFormSubmit = (
  e,
  selectedCompany,
  companyName,
  firstName,
  lastName,
  email,
  subject,
  message,
  setTouched,
  errors,
  lock,
  setLoading,
  selectedCompanyValidation,
  setSelectedCompanyValidation,
  messageValidation,
  setMessageValidation,
  sendEmail,
  site,
  setSelectedCompany,
  setCompanyName,
  setFirstName,
  setLastName,
  setEmail,
  setSubject,
  setMessage
) => {
  e.preventDefault();
  let isTrue = true;
  setTouched({
    companyName: true,
    firstName: true,
    lastName: true,
    email: true,
    subject: true,
    message: true,
  });
  Object.keys(errors).map((item) => {
    let error = errors[item];
    if (isTrue) {
      if (error === true) {
        isTrue = false;
      }
    }
  });

  if (selectedCompany && message) {
    setSelectedCompanyValidation(true);
    setMessageValidation(true);
    if (isTrue) {
      let input = {
        input: {
          attributes: [
            { code: "company_name", value: companyName },
            { code: "firstname", value: firstName },
            { code: "lastname", value: lastName },
            { code: "email", value: email },
            { code: "subject", value: subject },
            { code: "message", value: message },
          ],
          is_quote: false,
          return_form: false,
          template: site === "norwell_default" ? "11" : "12",
          fileBase64: "",
          fileName: "",
          fileType: "",
        },
      };
      setLoading(true);
      lock({
        always: (e) => {},
        success: (e) => {
          setLoading(false);
          setSelectedCompany("");
          setCompanyName("");
          setFirstName("");
          setLastName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setTouched({
            companyName: false,
            firstName: false,
            lastName: false,
            email: false,
            subject: false,
            message: false,
          });
        },
        fail: (e) => {
          setLoading(false);
        },
      });
      sendEmail(input);
    }
  } else {
    if (!selectedCompany) {
      setSelectedCompanyValidation(false);
    }
    if (!message) {
      setMessageValidation(false);
    }
  }
};
