import React from "react";
import gql from "graphql-tag";
import GraphqlWrapper from "app/layout/graphQlWrapper";
import WhatsNewSlider from "./whatsNewSlider";

const WHATS_NEW_SLIDER = gql`
  query($store_id: String!) {
    getWhatsNew(store_id: $store_id) {
      product_name
      product_image
      category_name
      product_url
    }
  }
`;

const WhatsNewSliderQueryContainer = (props) => {
  const site =
    typeof props.site !== "undefined" &&
    typeof props.site.siteCode !== "undefined" &&
    props.site.siteCode === "norwell_default"
      ? "2"
      : "1";

  return (
    <GraphqlWrapper
      query={WHATS_NEW_SLIDER}
      variables={{
        id: "whats_new_slider",
        store_id: site,
      }}
      saveQuery={true}
    >
      <WhatsNewSlider {...props} />
    </GraphqlWrapper>
  );
};

export default WhatsNewSliderQueryContainer;
