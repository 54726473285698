import React from "react";
import { gql } from "graphql.macro";
import WhereToBuyData from "./whereToBuy-data-container";
import { useQuery } from "react-apollo-hooks";
import ReactLoader from "app/layout/loader";
import useSite from "app/state/hooks/siteHooks/useSite";

const GET_STORE_LOCATOR_QUERY = gql`
  query {
    getStoreLocator {
      api_key
      stores {
        city
        latitude
        longitude
        name
        phone
        showroom
        state
        store_id
        store_type
        street
        website
      }
    }
  }
`;

const WhereToBuyQueryContainer = (props) => {
  const { data, loading, error } = useQuery(GET_STORE_LOCATOR_QUERY, {
    variables: { id: `store`, url: props.url },
    fetchPolicy: "cache-first",
  });
  const [, getSiteCode] = useSite();
  if (error) return <h1>something is not working</h1>;
  if (loading) {
    return (
      <div className="data-loader-container loading">
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
        />
      </div>
    );
  }

  return <WhereToBuyData {...props} data={data} />;
};

export default WhereToBuyQueryContainer;
