export const createPageSizeOptions = (totalCount) => {
  return [
    { label: "View 32", value: 32 },
    { label: "View 64", value: 64 },
    { label: "View 96", value: 96 },
    { label: "View 128", value: 128 },
    {
      label: "View All",
      value: totalCount,
    },
  ];
};
