import React from "react";
import OurCategories from "./ourCategories";

const OurCategoriesDataContainer = (props) => {
  let onHomeCategories = props?.data?.categoryList[0]?.children.filter(
    (item) => {
      return item.is_home === 1;
    }
  );

  return <OurCategories {...props} onHomeCategories={onHomeCategories} />;
};

export default OurCategoriesDataContainer;
