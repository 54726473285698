import React, { useState, useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import CatalogToolbar from "app/layout/customToolbar";
import CatalogItem from "app/pages/catalog/catalogPage/catalog-item";
import uuid from "react-uuid";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import CustomerRequestPopupModal from "app/pages/catalog/catalogPage/catalog-request-modal";
import Pagination from "app/layout/customPagination";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import SeoHelmet from "app/layout/seoHelmet";

const Catalogs = (props) => {
  const location = useLocation();
  const [showData, setShowData] = useState(props.allCatalogs);
  const [catalogRequest, setCatalogRequest] = useState(false);
  const toggle = () => {
    setCatalogRequest(!catalogRequest);
  };
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  //variables for pagination
  const totalPosts = showData.length;
  const [posts, setPosts] = useState(showData);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts && posts.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    if (location.state) {
      setCurrentPage(location.state.currentPage);
      setShowData(location.state.currentPosts);
    } else {
      setCurrentPage(1);
    }
    setPosts(showData);
  }, [showData]);

  return (
    <>
      <SeoHelmet title="Catalog" url={`/catalog`} />
      <MDBContainer className="catalog-page-wrapper">
        <MDBContainer className="catalog-content">
          {!isMobileOrTablet && (
            <MDBRow fluid className="breadcrumbs-container">
              <Breadcrumbs />
            </MDBRow>
          )}
          <MDBRow>
            <h3 className="wow slideInUp">Catalogs</h3>
          </MDBRow>
          {/* <CatalogToolbar
            showData={showData}
            setShowData={setShowData}
            norwellData={props.norwellCatalogs}
            ilexData={props.ilexCatalogs}
            location={"catalog"}
          /> */}
          {showData.length > 12 && (
            <Pagination
              position={"top"}
              postsPerPage={postsPerPage}
              totalPosts={totalPosts}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              indexOfLastPost={indexOfLastPost}
            />
          )}
          <MDBRow>
            <MDBCol className="catalogs-wrapper">
              {currentPosts &&
                currentPosts.map((catalog, index) => {
                  return (
                    <CatalogItem
                      catalog={catalog}
                      key={uuid()}
                      index={index}
                      catalogs={showData}
                    />
                  );
                })}
            </MDBCol>
          </MDBRow>
          {showData.length > 12 && (
            <Pagination
              position={"bottom"}
              postsPerPage={postsPerPage}
              totalPosts={totalPosts}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              indexOfLastPost={indexOfLastPost}
            />
          )}
          <MDBRow>
            <Button
              className="with-arrow"
              onClick={() => {
                setCatalogRequest(!catalogRequest);
              }}
            >
              <Icon icon="right_arrow_white" />
              Catalog Request
            </Button>
            <CustomerRequestPopupModal
              catalogRequest={catalogRequest}
              setCatalogRequest={setCatalogRequest}
              toggle={toggle}
            />
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    </>
  );
};

export default Catalogs;
