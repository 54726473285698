import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import useSite from "app/state/hooks/siteHooks/useSite";
import { useProductFunctions } from "./hooks";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import SpecificationContainer from "./specificationContainer";

const ProductSpecification = (props) => {
  let { activeFinish, specification } = props;
  const [, getSiteCode] = useSite();
  const [getWhereToBuy, getQuoteRequest, getRevitFiles] = useProductFunctions();
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const site = getSiteCode();
  const [selectedFile, setSelectedFile] = useState("");
  const [openSpec, setOpenSpec] = useState(isMobileOrTablet ? false : true);
  const has_quote =
    props.quote_request && props.quote_request === 1 ? true : false;

  return (
    <MDBContainer className="specifications-container" fluid>
      <MDBRow className="specification-bar">
        <MDBCol
          lg={site === "norwell_default" ? "2" : "2"}
          size={isMobileOrTablet ? "6" : "2"}
          className="file-download-container"
        >
          {props.revit_files &&
            props.revit_files.length > 0 &&
            getRevitFiles(props.revit_files, selectedFile, setSelectedFile)}
        </MDBCol>
        {isMobileOrTablet && (
          <MDBCol size={"6"} className="spec-sheet-container">
            <Button className={`spec-sheet-button custom-button `}>
              <a
                href={props.pdf_file}
                target="_blank"
                download
                rel="noopener noreferrer"
              >
                Spec Sheet
                <Icon icon="download_icon" />
              </a>
            </Button>
          </MDBCol>
        )}
        <MDBCol
          lg={site === "norwell_default" ? "8" : has_quote ? "8" : "8"}
          className="spec-quote-container"
        >
          {site === "norwell_default" ? (
            getWhereToBuy()
          ) : has_quote ? (
            getQuoteRequest(props, activeFinish)
          ) : (
            <></>
          )}
          <Button
            className="spec-button custom-button"
            onClick={() => {
              setOpenSpec(!openSpec);
            }}
          >
            Specification
            <Icon icon={openSpec ? "x_mark_white" : "specification_icon"} />
          </Button>
        </MDBCol>
        {!isMobileOrTablet && (
          <MDBCol
            lg={site === "norwell_default" ? "2" : has_quote ? "2" : "2"}
            className="spec-sheet-container"
          >
            {props.pdf_file && (
              <Button className="spec-sheet-button custom-button">
                <a
                  href={props.pdf_file}
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                >
                  Spec Sheet
                  <Icon icon="download_icon" />
                </a>
              </Button>
            )}
          </MDBCol>
        )}
      </MDBRow>
      <MDBRow className={"specifications-details-container " + openSpec}>
        <MDBCol size="12">
          <SpecificationContainer {...props} specification={specification} />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProductSpecification;
