export default (state, action) => {
  console.log("reducers changeTotalPages", state, action);

  return Object.assign({}, state, {
    page_data: Object.assign({}, state.page_data, {
      ...state.page_data,
      pageInfo: Object.assign({}, state.page_data.pageInfo, {
        ...state.page_data.pageInfo,
        total_pages: action.totalPages,
      }),
    }),
  });
};
