import gql from "graphql-tag";
export const CATALOGS = gql`
  query getCatalog {
    getCatalog{
    catalogs {
      catalogs_id
      category
      fliphtml5
      image
      pdf
      title
      url
    }
   }
  }
`;