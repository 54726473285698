import React, { useState, useEffect } from "react";
import Login from "./loginForm";

const LoginStateContainer = (props) => {
  let { getCartInformation, token } = props;
  useEffect(() => {
    getCartInformation();
  }, [getCartInformation, token]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const [status, setStatus] = useState(false);
  const [toggleResetPasswordModal, setToggleResetPasswordModal] = useState(
    false
  );

  return (
    <Login
      {...props}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      status={status}
      setStatus={setStatus}
      toggleResetPasswordModal={toggleResetPasswordModal}
      setToggleResetPasswordModal={setToggleResetPasswordModal}
    />
  );
};

export default LoginStateContainer;
