import React, { useState } from "react";
import MobileHeader from "./mobile";

const MobileHeaderStateContainer = ({
  displaySearch,
  setDisplaySearch,
  searchVal,
  setSearchVal,
  menuItems,
  mobile,
}) => {
  const [open, setOpen] = useState(false);
  const [toggleLogin, setToggleLogin] = useState(false);
  const [toggleResetPassword, setToggleResetPassword] = useState(false);
  const [toggleRegister, setToggleRegister] = useState(false);

  let adjustedMenuItems = Object.assign({}, menuItems, {
    children: menuItems?.children?.map((child) => {
      if (child.children.length === 0) {
        return child;
      }
      if (
        !child?.children[0]?.name.includes(
          `All ${child.name.toLowerCase().trim()}`
        ) &&
        !child?.children[0]?.name.includes(
          `all ${child.name.toLowerCase().trim()}`
        ) &&
        !child?.children[0]?.name.includes(
          `ALL ${child.name.toLowerCase().trim()}`
        ) &&
        !child?.children[0]?.name.includes(
          `ALL ${child.name.toUpperCase().trim()}`
        ) &&
        !child?.children[0]?.name.includes(
          `All ${child.name.charAt(0).toUpperCase().trim()}`
        ) &&
        !child?.children[0]?.name.includes(
          `All ${child.name.charAt(0).toUpperCase().trim()}`
        )
      ) {
        return {
          ...child,
          children: [
            {
              name: `All ${child.name}`,
              url_path: `${child.name
                .toLowerCase()
                .trim()}/all-${child.name.toLowerCase().trim()}`,
            },
          ].concat(child.children),
        };
      }
      return child;
    }),
  });

  return (
    <MobileHeader
      open={open}
      setOpen={setOpen}
      menuItems={adjustedMenuItems}
      mobile={mobile}
      toggleLogin={toggleLogin}
      setToggleLogin={setToggleLogin}
      toggleRegister={toggleRegister}
      setToggleRegister={setToggleRegister}
      toggleResetPassword={toggleResetPassword}
      setToggleResetPassword={setToggleResetPassword}
      displaySearch={displaySearch}
      setDisplaySearch={setDisplaySearch}
      searchVal={searchVal}
      setSearchVal={setSearchVal}
    />
  );
};

export default MobileHeaderStateContainer;
