import React, { useState, useEffect } from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import RegisterForm from "../register";
import LoginForm from "../login";
import ResetPasswordModal from "../resetPassword";

const LoginRegisterPopupModal = ({
  toggleModal,
  setToggleModal,
  selectedOption,
}) => {
  const [activeTab, setActiveTab] = useState(
    selectedOption === undefined || selectedOption === "login" ? 1 : 2
  );
  const [toggleResetPassword, setToggleResetPassword] = useState(false);

  useEffect(() => {
    if (toggleResetPassword) {
      setToggleModal(false);
    }
  }, [toggleResetPassword]);

  useEffect(() => {
    setActiveTab(
      selectedOption === undefined || selectedOption === "login" ? 1 : 2
    );
  }, [toggleModal]);

  return (
    <>
      <MDBModal
        isOpen={toggleModal}
        toggle={() => setToggleModal(!toggleModal)}
        className="login-register-popup-modal"
        centered
      >
        <MDBModalBody>
          <MDBNav className="nav-tabs ">
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={activeTab === 1}
                onClick={() => setActiveTab(1)}
                role="tab"
              >
                Login
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="#"
                active={activeTab === 2}
                onClick={() => setActiveTab(2)}
                role="tab"
              >
                Register
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent activeItem={activeTab}>
            <MDBTabPane tabId={1} role="tabpanel">
              <LoginForm
                toggleModal={toggleModal}
                setToggleModal={setToggleModal}
                toggleResetPassword={toggleResetPassword}
                setToggleResetPassword={setToggleResetPassword}
              />
            </MDBTabPane>
            <MDBTabPane tabId={2} role="tabpanel">
              <RegisterForm
                toggleModal={toggleModal}
                setToggleModal={setToggleModal}
              />
            </MDBTabPane>
          </MDBTabContent>
        </MDBModalBody>
      </MDBModal>

      <ResetPasswordModal
        toggleLoginModal={toggleModal}
        setToggleLoginModal={setToggleModal}
        toggleModal={toggleResetPassword}
        setToggleModal={setToggleResetPassword}
      />
    </>
  );
};
export default LoginRegisterPopupModal;
