import types from "./types";
import getSiteCode from "./reducers/getSiteCode";
import getSiteUrl from "./reducers/getSiteUrl";
import setSiteCode from "./reducers/setSiteCode";
import setSiteUrl from "./reducers/setSiteUrl";
import setSite from "./reducers/setSite";

const siteReducer = (state, action) => {
  if (state === undefined) {
    return {};
  }

  if (action.type === types.GET_SITE_CODE) {
    return getSiteCode(state, action);
  }

  if (action.type === types.GET_SITE_URL) {
    return getSiteUrl(state, action);
  }

  if (action.type === types.SET_SITE_CODE) {
    return setSiteCode(state, action);
  }

  if (action.type === types.SET_SITE_URL) {
    return setSiteUrl(state, action);
  }

  if (action.type === types.SET_SITE) {
    return setSite(state, action);
  }

  return state;
};

export default siteReducer;
