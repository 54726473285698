import React, { useState } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { ReactComponent as NorwellIcon } from "app/assets/icon/graphic_element.svg";
import { ReactComponent as IlexIcon } from "app/assets/icon/ILEX_graphic_element.svg";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import themeConfiguration from "config/themeConfiguration";

const CategoryItem = ({ category, site }) => {
  const [showText, setShowText] = useState(false);

  return (
    <MDBCol
      lg="2"
      className="category-item-container"
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <Link to={category.url_key ? category.url_key : "#"}>
        <MDBRow className="category-image-container">
          <MDBCol className="category-image">
            <div className="gallery-image">
              <LazyLoad height="100%" offset={0}>
                <img src={category.image} />
              </LazyLoad>
            </div>
            {category.thumbnail && (
              <div className="widget-image">
                <LazyLoad height="100%" offset={0}>
                  <img
                    src={themeConfiguration.magento_url + category.thumbnail}
                  />
                  {site === "norwell_default" ? <NorwellIcon /> : <IlexIcon />}
                </LazyLoad>
              </div>
            )}
          </MDBCol>
        </MDBRow>
      </Link>
      <MDBRow className="category-name-container">
        <h5 className="wow fadeIn">
          {showText && "View all "}
          {category.name}
        </h5>
      </MDBRow>
    </MDBCol>
  );
};

export default CategoryItem;
