import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import { messagesActions } from "core/state/redux/data/messages";
import QuoteRequestSummary from "./quoteRequestSummary-state-container";

const QuoteRequestSummaryReduxContainer = (props) => {
  return <QuoteRequestSummary {...props} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (data) => {
      dispatch(commonActions.sendEmail(data));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestSummaryReduxContainer);
