import React from "react";
import { Link } from "react-router-dom";

const mobileLinks = ({ setOpen, site }) => {
  return (
    <div className="mobile-links-container">
      <Link to="/about-us" onClick={() => setOpen(false)}>
        About Us
      </Link>
      {/* <Link to="/shop_online" onClick={() => setOpen(false)}>
        Shop Online
      </Link> */}
      <Link to="/contact-us" onClick={() => setOpen(false)}>
        Contact Us
      </Link>
      <Link to="/catalog" onClick={() => setOpen(false)}>
        Catalog
      </Link>
      <Link to="/gallery" onClick={() => setOpen(false)}>
        Gallery
      </Link>
      {site.siteCode.includes("norwell") ? (
        ""
      ) : (
        <Link to={"/press"} onClick={() => setOpen(false)}>
          Press
        </Link>
      )}
      {site.siteCode.includes("norwell") ? (
        ""
      ) : (
        <Link to={"/custom"} onClick={() => setOpen(false)}>
          Custom
        </Link>
      )}
    </div>
  );
};

export default mobileLinks;
