export const returnPolicySubmit = (
  e,
  companyName,
  firstName,
  phone,
  email,
  subject,
  message,
  setTouched,
  errors,
  lock,
  setLoading,
  messageValidation,
  setMessageValidation,
  sendEmail,
  site,
  setCompanyName,
  setFirstName,
  setPhone,
  setEmail,
  setSubject,
  setMessage
) => {
  e.preventDefault();
  let isTrue = true;

  setTouched({
    companyName: true,
    firstName: true,
    phone: true,
    email: true,
    subject: true,
    message: true,
  });
  Object.keys(errors).map((item) => {
    let error = errors[item];
    if (isTrue) {
      if (error === true) {
        isTrue = false;
      }
    }
  });
  if (message) {
    setMessageValidation(true);
    if (isTrue) {
      let input = {
        input: {
          attributes: [
            { code: "company_name", value: companyName },
            { code: "firstname", value: firstName },
            { code: "telephone", value: phone },
            { code: "email", value: email },
            { code: "subject", value: subject },
            { code: "message", value: message },
          ],
          is_quote: false,
          return_form: true,
          template: site === "norwell_default" ? "20" : "19",
          fileBase64: "",
          fileName: "",
          fileType: "",
        },
      };
      setLoading(true);
      lock({
        always: (e) => {},
        success: (e) => {
          setLoading(false);
          setCompanyName("");
          setFirstName("");
          setPhone("");
          setEmail("");
          setSubject("");
          setMessage("");
          setTouched({
            companyName: false,
            firstName: false,
            phone: false,
            email: false,
            subject: false,
            message: false,
          });
        },
        fail: (e) => {
          setLoading(false);
        },
      });
      sendEmail(input);
    }
  } else {
    if (!message) {
      setMessageValidation(false);
    }
  }
};
