import React from "react";
import ReactLoader from "app/layout/loader";
import { isUndefined, isEmpty } from "core/helpers/functions";
import ProductWidget from "app/layout/productWidget";
import useSite from "app/state/hooks/siteHooks/useSite";

const Listing = (props) => {
  let { data, category, showBrand } = props;
  const [, getSiteCode] = useSite();
  let formattedItems;
  let settings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 5,
    variableWidth: false,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  if (isUndefined(data)) return "";

  let items = [];
  let _items = {};
  if (!isUndefined(props.data)) {
    _items = props.data;
  }

  if (isUndefined(props.data) || props.dataLoading) {
    items.push(
      <ReactLoader
        color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
        width={50}
        height={50}
        type={`ThreeDots`}
        key={"loader-items"}
      />
    );
  } else {
    if (!isUndefined(_items)) {
      formattedItems = _items;
      if (formattedItems.length > 0) {
        return (
          <>
            <ProductWidget
              view={"grid"}
              parentSize="2"
              msrp={"MSRP"}
              items={formattedItems}
              settings={settings}
              uneven={true}
              category={category}
              showBrand={showBrand}
            />
          </>
        );
      } else {
        return (
          <p key={"no-products-key"} className={`listing-no-products`}>
            {/* There are no available products with these filters. */}
          </p>
        );
      }
    } else {
      items.push(
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
          key={"loader-items"}
        />
      );
    }
  }

  if (
    !isUndefined(_items.products) &&
    isEmpty(_items.products.items) &&
    !isUndefined(_items.products.items)
  ) {
    items.push(
      <p key={"no-products-key"} className={`listing-no-products`}>
        There are no available products with these filters.
      </p>
    );
  }

  return (
    <>
      <div className="listing-content">
        {typeof formattedItems !== "undefined" ? (
          <ProductWidget
            view={"grid"}
            parentSize="2"
            msrp={"MSRP"}
            items={formattedItems}
            settings={settings}
            uneven={true}
            category={category}
            showBrand={showBrand}
          />
        ) : (
          <ReactLoader
            color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
            width={50}
            height={50}
            type={`ThreeDots`}
            key={"loader-items"}
          />
        )}
      </div>
    </>
  );
};

export default Listing;
