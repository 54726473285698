import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const ModalPopupGallery = (props) => {
  let { base_image, image_gallery, finishes, activeImageIndex } = props;
  let slider1 = null;
  let slider2 = null;
  const [sliderNav1, setSliderNav1] = useState(null);
  const [sliderNav2, setSliderNav2] = useState(null);
  useEffect(() => {
    setSliderNav2(slider2);
    setSliderNav1(slider1);
  }, []);

  let dotsLength = finishes.length + image_gallery.length + 1;

  return (
    <div className="popup-modal">
      <Slider
        className="popup-slider"
        asNavFor={sliderNav2}
        ref={(slider) => (slider1 = slider)}
        {...{
          dots: false,
          infinite: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: activeImageIndex,
          fade: true,
        }}
      >
        <img src={base_image.url} alt="" />
        {image_gallery &&
          image_gallery.map((item) => {
            return <img src={item.url} alt="" />;
          })}
        {finishes &&
          finishes.map((finish) => {
            return <img src={finish.image} alt="" />;
          })}
      </Slider>

      {dotsLength > 2 && (
        <div className="popup-nav-slider-wrapper">
          <div className="popup-nav-slider-container">
            <Slider
              asNavFor={sliderNav1}
              className={
                "nav-popup-slider slick-vertical " +
                (dotsLength > 4 && "show-arrows")
              }
              ref={(slider) => (slider2 = slider)}
              {...{
                dots: false,
                infinite: dotsLength > 4 ? true : false,
                arrows: true,
                slidesToShow: dotsLength > 4 ? 4 : dotsLength,
                slidesToScroll: 1,
                vertical: true,
                verticalSwiping: true,
                initialSlide: activeImageIndex,
              }}
            >
              {image_gallery.length > 0 && (
                <img
                  alt=""
                  onClick={() => {
                    sliderNav1.slickGoTo(0);
                  }}
                  src={base_image.url}
                />
              )}
              {image_gallery &&
                image_gallery.map((item, index) => {
                  return (
                    <img
                      alt=""
                      onClick={() => {
                        sliderNav1.slickGoTo(index + 1);
                      }}
                      src={item.url}
                    />
                  );
                })}
              {finishes &&
                finishes.map((finish, index) => {
                  return (
                    <img
                      alt=""
                      onClick={() => {
                        sliderNav1.slickGoTo(index + image_gallery.length + 1);
                      }}
                      src={finish.image}
                    />
                  );
                })}
            </Slider>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalPopupGallery;
