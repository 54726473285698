import React from "react";
import HotspotCardProductUrl from "./hotspotCardProductUrl";
import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle } from "mdbreact";

const HotspotCard = (props) => {
  let { dot } = props;

  return (
    <HotspotCardProductUrl productUrl={dot && dot.url && dot.url}>
      <div>
        <label className={dot.top > 50 ? "card-tick top" : "card-tick bottom"}>
          <label className="inner-tick"></label>
        </label>
        <MDBCard
          className={
            dot.top > 50 ? "cardContainer top" : "cardContainer bottom"
          }
          style={{
            left:
              dot && dot.left && dot.left > 80
                ? "8rem"
                : dot.left < 20
                ? "-7rem"
                : dot.left > 60
                ? "7rem"
                : "0",
          }}
        >
          {dot.display_type === "product" && (
            <MDBCardImage
              className="product-image"
              src={dot.image}
              alt="hot spot picture"
            />
          )}
          <MDBCardBody>
            {dot.display_type === "product" && (
              <>
                <MDBCardTitle className="product-name">{dot.name}</MDBCardTitle>
              </>
            )}
            {dot.display_type === "text" && <p>{dot.addInfo}</p>}
          </MDBCardBody>
          <div className="dots"></div>
        </MDBCard>
      </div>
    </HotspotCardProductUrl>
  );
};

export default HotspotCard;
