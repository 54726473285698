import React from "react";
import EffectComponent from "./effect";
import ListingRender from "./render";
import ListingToolbarComponent from "./listing-toolbar-component";

const ListingComponentContainer = (props) => {
  if (props.dataLoading) {
    return (
      <>
        <EffectComponent
          {...props}
          dataLoading={props.dataLoading}
          setDataLoading={props.setDataLoading}
        />
      </>
    );
  }
  return (
    <>
      <ListingToolbarComponent
        {...props}
        position={"top"}
        dataLoading={props.dataLoading}
      />
      <EffectComponent
        {...props}
        dataLoading={props.dataLoading}
        setDataLoading={props.setDataLoading}
      />
      <ListingRender
        dataLoading={props.dataLoading}
        showBrand={
          typeof props.showBrand !== undefined ? props.showBrand : false
        }
        category={props && props.category && props.category}
      />
      <ListingToolbarComponent
        {...props}
        position={"bottom"}
        dataLoading={props.dataLoading}
      />
    </>
  );
};

export default ListingComponentContainer;
