import React from "react";
import { connect } from "react-redux";
import { default as commonActions } from "core/state/redux/data/common/actions";
import customerActions from "app/state/redux/data/customer/actions";
import { withApollo } from "react-apollo";
import SubscribeStateContainer from "./subscribe-state-container";

const SubscribeReduxContainer = (props) => {
  return <SubscribeStateContainer {...props} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    subscribe: (email, first_name, last_name, what_describes_you) => {
      dispatch(
        customerActions.newsletterCustomerSubscribe(
          email,
          first_name,
          last_name,
          what_describes_you
        )
      ).then(() => {
        dispatch(commonActions.unlock("success"));
      });
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    unLock: () => {
      dispatch(commonActions.unlock());
    },
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(SubscribeReduxContainer)
);
