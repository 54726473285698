import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import SocialMedia from "./socialMedia";

const SOCIAL_MEDIA = gql`
  query getSocialMedia {
    getSocialMedia {
      facebook
      pinterest
    }
  }
`;

const SocialMediaQueryContainer = (props) => {
  return (
    <GraphQlWrapper query={SOCIAL_MEDIA} variables={{ id: "social_media" }}>
      <SocialMedia />
    </GraphQlWrapper>
  );
};

export default SocialMediaQueryContainer;
