import { isUndefined } from "core/helpers/functions";

export function createRequestFromFilters(filters, category_id) {
  if (isUndefined(filters)) {
    let initialFilter = {
      filter: { category_id: { eq: category_id } },
    };
    return initialFilter;
  }

  let request = { filter: { category_id: { eq: category_id } } };

  Object.keys(filters).forEach((filter) => {
    let _filter = remapFilterId(filter);
    if (!isUndefined(filters[filter].filter_value)) {
      if (filters[filter].filter_type === "multi") {
        request["filter"][_filter] = { in: filters[filter].filter_value };
      } else {
        if (_filter === "shop_by") {
          request["filter"][_filter] = {
            like: `%${filters[filter].filter_value}%`,
          };
        } else {
          request["filter"][_filter] = { eq: filters[filter].filter_value };
        }
      }
    } else {
      let keys = Object.keys(filters[_filter]);

      // check to see if it's from/to filter
      if ("from" === keys[0] && "to" === keys[1]) {
        request["filter"][_filter] = {
          [keys[0]]: filters[_filter].from.filter_value,
          [keys[1]]: filters[_filter].to.filter_value,
        };
      }
    }
    return true;
  });

  return request;
}

export const createSortVariable = (sort) => {
  if (isUndefined(sort)) {
    // return { is_new: "DESC" };
    return { position: "ASC" };
  }
  return sort;
};

const remapFilterId = (filter) => {
  let _filter = filter;
  if (filter === "category") {
    _filter = "category_id";
  }
  return _filter;
};
