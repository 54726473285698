import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import GalleryItem from "./galleryItem";
import { useMediaQuery } from "react-responsive";
import HeritageOfQualityMobile from "./heritageOfQualityMobile";
import uuid from "react-uuid";

const HeritageOfQuality = (props) => {
  const galleries = props.galleries;
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <MDBRow lg="12" className="heritage-of-quality-container">
      <MDBCol lg="4" className="heritage-of-quality-widget">
        <CmsBlock id="home_a_heritage_of_quality" dangHtml={true} />
      </MDBCol>
      {isTabletOrMobile ? (
        <HeritageOfQualityMobile galleries={galleries} />
      ) : (
        <MDBCol lg="8" className="heritage-of-quality-gallery-container">
          {galleries &&
            galleries.map((gallery, index) => {
              if (index % 2 === 0) {
                return (
                  <MDBRow key={uuid()} className="gallery-items-row">
                    <GalleryItem gallery={gallery} />
                    <GalleryItem gallery={galleries[index + 1]} />
                  </MDBRow>
                );
              }
            })}
        </MDBCol>
      )}
    </MDBRow>
  );
};
export default HeritageOfQuality;
