// @ts-check

import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import themeConfiguration from "config/themeConfiguration";
import { cache } from "core/graphql/apolloCache";
import queryLoader from "core/graphql/queryLoader";
import queryActions from "../../queries/actions";
import commonActions from "../actions";

const apolloClient = new ApolloClient({
  link: createHttpLink({
    uri: themeConfiguration.magento_url + themeConfiguration.graphql_url,
  }),
  cache,
});

/**
 *
 * @param {import('redux').Store} store
 * @param {import('redux').Action & { version: string, flag: string }} action
 */
const getCacheVersion = async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("generateCache"),
      variables: { input: "fetch" },
    });
    if (!data.generateCache) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }

    if (
      (action.version !== data.generateCache && action.flag !== "wait") ||
      store.getState().cache.cache === 0
    ) {
      store.dispatch(commonActions.setFlag("wait"));
      store.dispatch(commonActions.setCacheVersion(data.generateCache));
      store.dispatch(queryActions.removeAllQueries());

      // figure out if we need to clear cacheStorage?
      // if (window.caches) {
      //   caches.keys().then(function(names) {
      //     for (let name of names) {
      //       console.log(name);
      //        caches.delete(name);
      //     }
      //   });
      //   console.log("Cleared service worker cache.");
      // }

      store.dispatch(commonActions.setFlag("ready"));
    }
  } catch (error) {
    store.dispatch(queryActions.removeQuery());
    return;
  }
};

export default getCacheVersion;
