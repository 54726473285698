import React from "react";
import { useSelector } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import Misc from "./misc";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import Modal from "core/components/modal";
import { default as DynamicRouter } from "core/components/router";
import { ModalProvider } from "core/components/modal/ModalContext";
import ModalWrapper from "app/layout/modal/ModalWrapper";
import ReactGa from "react-ga";

// Global application style
import "@fortawesome/fontawesome-free/css/all.min.css";

// Import css files
import "../main.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withApollo } from "react-apollo";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import WOW from "wow.js";
import { useEffect } from "react";
import SessionMessages from "app/layout/sessionMessages";
library.add(fab, fas);

// --------------------------------------------------------------------------------------------------------------------------------------------------------
// This is the layout definition for every page on the website, it's content controls how any page will be rendered.
// It is used to bootstrap the header, footer, modals, and misc components, as well as provide a point of insertion for the content of any page based on
// the current URL.
// This file should not be edited unless you are making a change that should effect the layout of all pages
// Keep in mind that this is loaded on every page of the website, so try to keep it and it's children light
// --------------------------------------------------------------------------------------------------------------------------------------------------------

const Layout = (props) => {
  const site = useSelector((state) => state.site);
  useEffect(() => {
    new WOW().init();
  }, []);
  //temp solution for google analytics
  const GET_KEY = gql`
    {
      storeConfig {
        google_analytics_account
      }
    }
  `;
  const { data } = useQuery(GET_KEY);

  let googleAnalyticsKey =
    typeof data === "undefined"
      ? ""
      : data.storeConfig?.google_analytics_account? data.storeConfig.google_analytics_account : "";

  useEffect(() => {
    ReactGa.initialize(googleAnalyticsKey);
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className={`${site.siteCode}`}>
      <ModalProvider {...props}>
        <Header />
        <div className={`session-container`}>
          <SessionMessages target="global" /> {/* SESSION MESSAGES */}
        </div>
        <DynamicRouter /> {/* CONTENT */}
        <Modal ModalWrapper={ModalWrapper} />
        <Misc />
        <Footer />
      </ModalProvider>
    </div>
  );
};

export default withApollo(Layout);
