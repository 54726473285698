import React, { useState } from "react";
import { MDBContainer, MDBCol } from "mdbreact";
import Button from "core/components/button";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import Input from "core/components/input";

const CreateWishlistCore = (props) => {
  const { createWishlist } = useWishlist();
  let { project, setProject, hideModal } = props;
  const [loading, setLoading] = useState(false);
  return (
    <MDBContainer className="wishlist-create-wrapper">
      <MDBCol md="12" className="modal-title-wrapper">
        <p>Create Project</p>
      </MDBCol>

      <MDBCol md="12" className="input-button-container">
        <Input
          className={"custom-input"}
          disabled={loading}
          placeholder={"Enter project name"}
          group
          type="text"
          value={project}
          maxLength="20"
          onChange={(e) => {
            if (e.target.value.length <= 24) {
              setProject(e.target.value);
            }
          }}
        />

        <Button
          color="primary"
          disabled={project.length < 3 || loading}
          onClick={(e) => {
            setLoading(true);
            let target =
              typeof props.target === "undefined" ? "global" : props.target;
            createWishlist(project, target).then(() => {
              setLoading(false);
              setProject("");
              if (typeof hideModal === "function") {
                hideModal();
              }
            });
          }}
        >
          {(loading && <span>{"Creating"}</span>) || <span>{"Create"}</span>}
        </Button>
      </MDBCol>
    </MDBContainer>
  );
};
export default CreateWishlistCore;
