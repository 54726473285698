import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import uuid from "react-uuid";

const Pagination = (props) => {
  let { pageNumber, totalPages, leftArrow, rightArrow, position } = props;

  let pagesArray = [];
  for (let i = 0; i < totalPages; i++) {
    pagesArray.push(i + 1);
  }

  const selectedPage = (page) => {
    props.changePageNumber(page);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const previousPage = () => {
    if (pageNumber > 1) {
      props.changePageNumber(pageNumber - 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };
  const nextPage = () => {
    if (pageNumber < totalPages) {
      props.changePageNumber(pageNumber + 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  return (
    <MDBCol className="pagination-component-container">
      <MDBRow className="pagination-component">
        <MDBCol
          size="2"
          className={
            leftArrow ? "left-arrow-container has-icon" : "left-arrow-container"
          }
          onClick={() => {
            previousPage();
          }}
        >
          <span>{leftArrow && <Icon icon={leftArrow} />}</span>
        </MDBCol>
        <MDBCol className="page-number-container">
          <p className="current-page">
            {pageNumber} OF {totalPages !== 0 ? totalPages : 1}
          </p>
          <ul className={"page-number-list " + position}>
            {pagesArray.map((page) => {
              return (
                <li key={uuid()} onClick={() => selectedPage(page)}>
                  {page}
                </li>
              );
            })}
          </ul>
        </MDBCol>
        <MDBCol
          size="2"
          className={
            rightArrow
              ? "right-arrow-container has-icon"
              : "right-arrow-container"
          }
          onClick={() => {
            nextPage();
          }}
        >
          <span>{rightArrow && <Icon icon={rightArrow} />}</span>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default Pagination;
