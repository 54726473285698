export const sliderSettings = (selectedGallery, setHotspot) => {
  return {
    dots: false,
    arrows: false,
    draggable: false,
    infinite: true,
    slidesToShow: 3,
    variableWidth: false,
    slidesToScroll: 1,
    // prevArrow: (
    //   <div className="holder">
    //     <Icon icon="black_arrow_down" />
    //   </div>
    // ),
    // nextArrow: (
    //   <div className="holder">
    //     <Icon icon="black_arrow_down" />
    //   </div>
    // ),
    afterChange: (newIndex) => {
      setHotspot((prevState) => ({
        ...prevState,
        image: selectedGallery.gallery_items[newIndex].image,
        name: selectedGallery.gallery_items[newIndex].name,
      }));
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
};
