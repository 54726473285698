import React from "react";
import { isUndefined } from "core/helpers/functions";
import { generateFilterRender } from "./functions";
import { MDBRow, MDBCol, MDBModalBody, MDBModal } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import Toolbar from "../toolbar";
const Sidebar = (props) => {
  let {
    showFilters,
    setShowFilters,
    category,
    filters,
    dataLoading,
    toggleFilters,
    setToggleFilters,
    showBrand,
  } = props;

  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  if (isUndefined(filters) || dataLoading) {
    return <></>;
  }

  return (
    <>
      {isMobileOrTablet ? (
        <MDBModal
          isOpen={toggleFilters}
          toggle={() => setToggleFilters(!toggleFilters)}
          className="filters-container-modal"
        >
          <MDBModalBody>
            <Toolbar
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              category={category}
              dataLoading={dataLoading}
              toggleFilters={toggleFilters}
              setToggleFilters={setToggleFilters}
            />

            <MDBRow id="sidebar" className={"sidebar " + toggleFilters}>
              <MDBCol md="12" className="sidebar_main_container">
                <div className="all-filters">
                  {!isMobileOrTablet && <h3>Filter by</h3>}
                  {!isUndefined(filters) &&
                    !isUndefined(filters.data) &&
                    generateFilterRender(filters, showBrand)}
                </div>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
        </MDBModal>
      ) : (
        <MDBRow id="sidebar" className={"sidebar " + toggleFilters}>
          <MDBCol md="12" className="sidebar_main_container">
            <div className="all-filters">
              <h3>Filter by</h3>
              {!isUndefined(filters) &&
                !isUndefined(filters.data) &&
                generateFilterRender(filters, showBrand)}
            </div>
          </MDBCol>
        </MDBRow>
      )}
    </>
  );
};

export default Sidebar;
