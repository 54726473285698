import { emailValidation } from "app/config/customer/validationRules";

export const validate = (
  firstName,
  lastName,
  companyName,
  email,
  addressOne,
  title,
  country,
  city,
  stateName,
  zipCode,
  phone
) => {
  return {
    firstName: firstName.length === 0,
    lastName: lastName.length === 0,
    companyName: companyName.length === 0,
    email: email.length === 0 || ValidateEmail(email),
    addressOne: addressOne.length === 0,
    title: title.length === 0,
    country: country.length === 0,
    city: city.length === 0,
    stateName: stateName.length === 0,
    zipCode: zipCode.length === 0,
    phone: phone.length === 0,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];
  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
