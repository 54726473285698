import React, { useState } from "react";
import CustomForm from "./customForm";

const CustomFormStateContainer = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [typeOfProject, setTypeOfProject] = useState("");
  return (
    <CustomForm
      {...props}
      firstName={firstName}
      lastName={lastName}
      email={email}
      phone={phone}
      typeOfProject={typeOfProject}
      setFirstName={setFirstName}
      setLastName={setLastName}
      setEmail={setEmail}
      setPhone={setPhone}
      setTypeOfProject={setTypeOfProject}
    />
  );
};

export default CustomFormStateContainer;
