import React from "react";
import GraphQlWrapper from "core/components/graphQlWrapper";
import { useSelector } from "react-redux";
import gql from "graphql-tag";
import SiteMap from "./siteMap";

const MENU_QUERY = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      children {
        name
      }
    }
  }
`;

const SiteMapQueryContainer = ({ ...props }) => {
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );

  return (
    <GraphQlWrapper
      query={MENU_QUERY}
      variables={site.siteCode.includes("norwell") ? { id: "3" } : { id: "2" }}
      saveQuery={true}
    >
      <SiteMap {...props} site={site} />
    </GraphQlWrapper>
  );
};

export default SiteMapQueryContainer;
