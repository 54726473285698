import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import { useMediaQuery } from "react-responsive";
import FindARepComponentContainer from "./find-a-rep-component-container";
import WebsiteContactInfo from "./websiteContactInfo";

const FindRep = (props) => {
  props.repLocators.sort((a, b) => {
    return a.position - b.position;
  });
  const isMobile = useMediaQuery({ maxWidth: 920 });
  return (
    <MDBContainer fluid className={"find-rep-container"}>
      <MDBRow>
        <MDBCol className={"find-rep-page customer-service"}>
          {!isMobile && (
            <MDBRow>
              <MDBCol className="wow slideInDown">
                <Breadcrumbs />
              </MDBCol>
            </MDBRow>
          )}
          <MDBRow>
            <MDBCol className="wow slideInUp" size="12">
              <h3>Find a Rep</h3>
            </MDBCol>
          </MDBRow>
          <MDBRow className="find-a-rep-main">
            <FindARepComponentContainer {...props} />
            <WebsiteContactInfo />
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default FindRep;
