import React, { memo, useEffect } from "react";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import Icon from "app/assets/icon/icon";
import Input from "core/components/input";
import _ from "lodash";

const SearchInput = memo(
  ({ search, changeSearch, tmpSearch, setTmpSearch }) => {
    function closeSearch() {
      document.getElementsByTagName("BODY")[0].click();
    }
    useEffect(() => {
      document.getElementById("search-input").focus();
    }, []);

    return (
      <MDBContainer className="search-input">
        <div className="close-search" onClick={closeSearch}>
          <div className="x-one">
            <div className="x-two"></div>
          </div>
        </div>
        <MDBRow className="search-dropdown-text">
          <MDBCol size="12">
            <h3>Search</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow className="input-filed-container">
          <MDBCol className="input-filed-column">
            <Input
              id={`search-input`}
              label={`${search === "" ? "Search" : ""}`}
              value={tmpSearch}
              placeholder={"Enter keyword or SKU"}
              onChange={(e) => {
                setTmpSearch(e.target.value);
              }}
            />
            <div
              className={`search-icon`}
              onClick={(e) => {
                if (search.length > 0) {
                  changeSearch("");
                  setTmpSearch("");
                } else {
                  changeSearch(tmpSearch);
                }
              }}
            >
              <Icon
                id={`${
                  typeof search === "undefined"
                    ? "searchGray"
                    : search.length === 0
                    ? "searchGray"
                    : "removeFilterButtonGray"
                }`}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchInput;
