import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MDBRow, MDBCol, MDBContainer } from "mdbreact";
import Overlay from "app/layout/overlay";
import { default as Pages } from "app/config/routing/pages/customerPages";
import { normalizeRoutes } from "../functions";
import Icon from "app/assets/icon/icon";

const CustomerSidebar = (props) => {
  let { selectedLink, setSelectedLink, screenwidth, logoutCustomer } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [mobileSelectedItem, setMobileSelectedItem] = useState(
    "account dashboard"
  );
  let PagesObjectMapper = normalizeRoutes(Pages);

  if (screenwidth < 992) {
    return (
      <>
        <MDBContainer className="mobile-sidebar">
          <MDBRow>
            <MDBCol md="12" className={`mobile-menu-wrapper`}>
              <h6
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                className={"dropdown-selected-option " + isOpen}
              >
                {/* {selectedLink} */}
                {mobileSelectedItem}
                <Icon
                  icon={isOpen ? "arrow_up_orange_full" : "arrow_down_full"}
                />
              </h6>

              <Overlay
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                className={`mobile-menu-overlay`}
              >
                <MDBRow className="mobile-account-menu">
                  {Object.keys(PagesObjectMapper).map((value, index) => {
                    let page = PagesObjectMapper[value];
                    if (page.inSidebar) {
                      return (
                        <Link
                          className={`mobile-account-menu-item ${
                            page.disabled ? "disabled" : ""
                          } border-bottom align-items-center justify-content-start ${
                            page.url === selectedLink ? "active" : ""
                          }`}
                          key={page.url}
                          to={"/" + page.url}
                          onClick={(e) => {
                            if (typeof page.function !== "undefined") {
                              e.preventDefault();
                              if (page.function === "logoutCustomer") {
                                logoutCustomer();
                              }
                            } else {
                              setSelectedLink(page.url);
                              setMobileSelectedItem(page.menuText);
                            }
                            setIsOpen(false);
                          }}
                        >
                          <MDBCol
                            className={`account-link text-decoration-none `}
                          >
                            {page.menuText}
                          </MDBCol>
                        </Link>
                      );
                    }
                  })}
                </MDBRow>
              </Overlay>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    );
  }

  return (
    <div className="customer-sidebar">
      <ul className="list-group main-account-group account-group">
        {Object.keys(PagesObjectMapper).map((value, index) => {
          let page = PagesObjectMapper[value];
          if (!page.separated && page.inSidebar) {
            return (
              <Link
                className={`list-group-item  ${
                  page.disabled ? "disabled" : ""
                } account-link ${page.url === selectedLink ? "active" : ""}`}
                key={page.url}
                to={"/" + page.url}
                onClick={(e) => {
                  if (typeof page.function !== "undefined") {
                    e.preventDefault();
                    if (page.function === "logoutCustomer") {
                      logoutCustomer();
                    }
                  } else {
                    setSelectedLink(page.url);
                  }
                }}
              >
                {page.menuText}
              </Link>
            );
          }
        })}
      </ul>
      <ul className="list-group secondary-account-group account-group">
        {Object.keys(PagesObjectMapper).map((value, index) => {
          let page = PagesObjectMapper[value];
          if (page.separated && page.inSidebar) {
            return (
              <Link
                className={`list-group-item ${
                  page.disabled ? "disabled" : ""
                } account-link  ${page.url === selectedLink ? "active" : ""}`}
                key={page.url}
                to={"/" + page.url}
                onClick={(e) => {
                  if (typeof page.function !== "undefined") {
                    e.preventDefault();
                    if (page.function === "logoutCustomer") {
                      logoutCustomer();
                    }
                  } else {
                    setSelectedLink(page.url);
                  }
                }}
              >
                {page.menuText}
              </Link>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default CustomerSidebar;
