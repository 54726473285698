import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactLoader from "app/layout/loader";
import Inventory from "./inventory";
import gql from "graphql-tag";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import useSite from "app/state/hooks/siteHooks/useSite";

const query = gql`
  query inventoryProducts(
    $pageSize: Int
    $currentPage: Int
    $sort: ProductAttributeSortInput
  ) {
    inventoryProducts(
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      total_count
      page_info {
        total_pages
      }
      items {
        id
        name
        sku
        url_key
        __typename
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              value {
                sku
                price
                image
                quantity
                discontinued
                next_rec_date
              }
            }
          }
        }
      }
    }
  }
`;

const InventoryQueryContainer = (props) => {
  const customer = useSelector((state) => state.customer);
  const [, getSiteCode] = useSite();

  let filter = {
    pageSize: 1000, // Approved to hardcode
    // currentPage: currentPage,
    sort: { sku: "ASC" },
  };

  const { loading, error, data, refetch } = useQuery(query, {
    variables: filter,
    fetchPolicy: "cache-and-network",
  });

  function filterItem(item) {
    let returnValue = true;
    if (item.options) {
      item.options.forEach((option) => {
        if (option.value) {
          option.value.forEach((val) => {
            if (val.sku === null) {
              returnValue = false;
            }
          });
        }
      });
    }
    return returnValue;
  }

  if (data && data.products && data.products.items) {
    data.products.items = data.products.items.filter(filterItem);
  }

  if (customer.data.is_inventory_visible === null) return <Redirect to={"/"} />;
  if (error) return `Error! ${error}`;
  if (loading && !data)
    return (
      <div className="inventory-loader-container">
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
          key={"loader-items"}
        />
      </div>
    );

  let inventoryItems = data.inventoryProducts.items.filter(
    (item) => item.options !== null
  );
  return (
    <>
      <Inventory {...props} refetch={refetch} inventoryData={inventoryItems} />
    </>
  );
};

export default InventoryQueryContainer;
