import React, { useState, useEffect } from "react";
import { MDBContainer, MDBModal, MDBModalBody } from "mdbreact";
import CreateWishlistCore from "./createWishlistCore";

const CreateWishlistCoreStateContainer = (props) => {
  let { hideModal } = props;
  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState("wishlist");
  const [toggleModal, setToggleModal] = useState(true);
  /*Mobile start*/
  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);
  /*Mobile end*/
  const toggle = () => {
    setToggleModal(!toggleModal);
  };
  if (screenWidth > 767) {
    return (
      <MDBContainer id={`create-project-modal`}>
        <MDBModal
          className="modal-wrapper"
          isOpen={toggleModal}
          centered
          toggle={(e) => {
            toggle();
          }}
        >
          <MDBModalBody>
            <MDBContainer fluid>
              <CreateWishlistCore
                activeWishlistName={props.activeWishlistName}
                project={project}
                setProject={setProject}
                loading={loading}
                setLoading={setLoading}
                projectName={projectName}
                setProjectName={setProjectName}
                hideModal={hideModal}
              />
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  } else {
    return (
      <CreateWishlistCore
        activeWishlistName={props.activeWishlistName}
        project={project}
        setProject={setProject}
        loading={loading}
        setLoading={setLoading}
        projectName={projectName}
        setProjectName={setProjectName}
        hideModal={hideModal}
      />
    );
  }
};
export default CreateWishlistCoreStateContainer;
