import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Toolbar from "./toolbar";
import Sidebar from "./sidebar";
import Listing from "./listing";

const CategoryComponentContainer = (props) => {
  let { allCategories, categoryFilter, category, filters, search } = props;
  const [showFilters, setShowFilters] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);

  let host = window.location.href;
  useEffect(() => {
    if (host.includes("search-results")) {
      setShowBrand(true);
    }
  }, []);

  return (
    <MDBCol className="listing-with-toolbar">
      <Toolbar
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        category={category}
        dataLoading={dataLoading}
        toggleFilters={toggleFilters}
        setToggleFilters={setToggleFilters}
        showBrand={showBrand}
      />
      <Sidebar
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        category={category}
        dataLoading={dataLoading}
        toggleFilters={toggleFilters}
        setToggleFilters={setToggleFilters}
        showBrand={showBrand}
      />
      <MDBRow className="main-listing">
        <MDBCol className="listing-wrapper">
          <Listing
            key={"listing"}
            category_id={categoryFilter}
            allCategories={allCategories}
            category={category}
            navFilters={filters}
            search={search}
            dataLoading={dataLoading}
            setDataLoading={setDataLoading}
            showBrand={showBrand}
          />
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default CategoryComponentContainer;
