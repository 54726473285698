import React, { useEffect } from "react";
import Component from "./graphQlWrapper";
import { isUndefined, isEmpty } from "core/helpers/functions";
import ReactLoader from "app/layout/loader";
import useSite from "app/state/hooks/siteHooks/useSite";

const GraphQlQueryWrapper = (props) => {
  let {
    data,
    variables,
    query,
    type,
    addQuery,
    saveQuery,
    customer,
    thisTemp,
    loading,
    finalChildren,
  } = props;
  const [, getSiteCode] = useSite();
  useEffect(() => {
    if (isEmpty(data) && saveQuery && !isEmpty(thisTemp)) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    } else if (isEmpty(data) && isEmpty(thisTemp) && loading === true) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    } else if (data === thisTemp && saveQuery === true) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    } else if (!saveQuery) {
      if (
        !isUndefined(variables) &&
        !isUndefined(variables.id) &&
        !isUndefined(variables.url)
      ) {
        addQuery(variables.id, variables.url, query, variables, type);
      } else {
        if (!isUndefined(variables.identifier)) {
          addQuery(variables.identifier, "query", query, variables, type);
        } else {
          addQuery(null, "query", query, variables, type);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, loading]);

  //added loader
  if (loading) {
    return (
      <div className={"data-loader-container loading"}>
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#C1263B"}
          width={50}
          height={50}
          type={`Circles`}
        />
      </div>
    );
  }
  if (isEmpty(data) && !isEmpty(thisTemp)) {
    data = thisTemp;
  }

  let error;

  if (loading) {
    return props.children;
  }

  if (error) return <div>Error: {error.message}</div>;

  const children = React.Children.map(finalChildren, (child) => {
    if (!loading && data) {
      return React.cloneElement(child, { data: data });
    }
  });

  return <Component {...props}>{children}</Component>;
};

export default GraphQlQueryWrapper;
