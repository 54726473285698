import React from "react";
import { MDBCol, MDBView, MDBMask } from "mdbreact";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";
import themeConfiguration from "config/themeConfiguration";

const GalleryItem = ({ gallery }) => {
  const path = `/gallery-detail/${
    gallery.name
      .split(" ")
      .join("-")
      .toLowerCase()
      .replace(/,/, "")
      .replace(/-$/, "") +
    "-" +
    "id_" +
    gallery.gallery_id
  }`;

  return (
    <MDBCol className="gallery-item">
      <Link
        to={{
          pathname: path,
        }}
      >
        <MDBView waves>
          <LazyLoad height={"100%"} offset={1000}>
            <img
              src={themeConfiguration.magento_url + gallery.homepage_image}
              className="img-fluid"
              alt={"heritage-of-quality-gallery-item"}
            />
          </LazyLoad>
          <MDBMask className="flex" overlay="teal-slight">
            <p className="white-text wow fadeInUp">{gallery.name}</p>
          </MDBMask>
        </MDBView>
      </Link>
    </MDBCol>
  );
};
export default GalleryItem;
