import React from "react";
import { gql } from "graphql.macro";
import { useQuery } from "react-apollo-hooks";
import ReactLoader from "app/layout/loader";
import useSite from "app/state/hooks/siteHooks/useSite";
import ShopOnlineData from "./shopOnline-data-container";

const GET_ONLINE_SHOPS = gql`
  query getOnlineShops {
    getOnlineShops {
      image
      name
      url
    }
  }
`;

const ShopOnlineQueryContainer = (props) => {
  const { data, loading, error } = useQuery(GET_ONLINE_SHOPS, {
    variables: { id: `onlineShops` },
    fetchPolicy: "cache-first",
  });
  const [, getSiteCode] = useSite();
  if (error) return <h1>something is not working</h1>;
  if (loading) {
    return (
      <div className="data-loader-container loading">
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
        />
      </div>
    );
  }

  return <ShopOnlineData {...props} data={data} />;
};

export default ShopOnlineQueryContainer;
