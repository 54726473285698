import React, { useState } from "react";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { MDBRow, MDBCol } from "mdbreact";
import Checkbox from "app/layout/checkbox";

const WishlistPlpItem = (props) => {
  const { addToWishlist, removeFromWishlist } = useWishlist();
  let { list, setLoading, checked, index } = props;
  const [checkedItem, setCheckedItem] = useState(checked);
  const setCheckedFn = () => {
    setLoading(true);
    setTimeout(() => {
      if (checkedItem) {
        let removeWishlistPlp = {
          wishlist: list.name,
          skus: [props.sku],
        };
        removeFromWishlist(removeWishlistPlp).then(
          setLoading(false),
          setCheckedItem(!checked)
        );
      } else {
        let addWishlistPlp = {
          products: [{ sku: props.sku }],
          wishlists: [{ name: list.name }],
        };
        addToWishlist(addWishlistPlp).then(
          setLoading(false),
          setCheckedItem(!checkedItem)
        );
      }
    }, 500);
  };

  return (
    <MDBCol className="wishlist-plp-item-wrapper">
      <MDBCol className="project-name-and-number-of-items">
        <MDBRow className="wishlist-name-row">
          <MDBCol className="wishlist-name">
            <h6>{list.name}</h6>
          </MDBCol>
        </MDBRow>
        <MDBRow className="wishlist-items-row">
          <MDBCol className="wishlist-items">
            <span className="number-of-items">{list.items_count} Items</span>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol className="checkbox-container">
        <div className="checkbox-wrapper">
          <Checkbox
            checkbox_id={`check-box-wishlist-${index}`}
            checked={checkedItem}
            setChecked={setCheckedFn}
            theme={"light"}
            type={"checkbox"}
          />
        </div>
      </MDBCol>
    </MDBCol>
  );
};

export default WishlistPlpItem;
