import React from "react";
import { connect } from "react-redux";
import { cacheActions } from "app/state/redux/data/cache";
import JolaCache from "./jolaCache";

const CacheReduxContainer = (props) => {
  props.getCacheVersion(props.cacheVersion, props.flag);

  return <JolaCache {...props} />;
};

const mapStateToProps = (state) => ({
  cacheVersion: state.cache.cache,
  flag: state.cache.flag,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCacheVersion: (version, flag) => {
      dispatch(cacheActions.getCacheVersion(version, flag));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CacheReduxContainer);
