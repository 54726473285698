import React from "react";
import { MDBContainer } from "mdbreact";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SeoHelmet from "app/layout/seoHelmet";
import BannerSlider from "./bannerSlider";
import WhatsNewSlider from "./whatsNewSlider";
import OurCategories from "./ourCategories";
import HeritageOfQuality from "./heritageOfQuality";

const Home = () => {
  return (
    <>
      <SeoHelmet title={""} url={""} />
      <MDBContainer className={"home-container"} fluid>
        <BannerSlider />
        <WhatsNewSlider
          title={" NEW"}
          subtitle={"FRESH STUFF"}
          theme={"light"}
          dots={false}
        />
        <OurCategories />
        <HeritageOfQuality />
      </MDBContainer>
    </>
  );
};

export default Home;
