import React from "react";
import { MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";

const ProductStandardsForFiltering = (props) => {
  let { standardsArray } = props;

  return (
    <MDBCol className="product-standards-for-filtering">
      {standardsArray &&
        standardsArray.map((item, index) => {
          //temporary solution for removing FLUORESCENT from standards array
          if (item !== "FLUORESCENT") {
            if (item === "HALOGEN LAMPS") {
              return (
                <Icon
                  key={`standards-for-filtering-${index}`}
                  icon={"HALOGEN_LAMPS"}
                />
              );
            }
            return (
              <Icon key={`standards-for-filtering-${index}`} icon={item} />
            );
          } else return <></>;
        })}
    </MDBCol>
  );
};

export default ProductStandardsForFiltering;
