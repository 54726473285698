import React, { useRef, useEffect } from "react";
import { MDBRow, MDBContainer, MDBCol } from "mdbreact";
import Input from "app/layout/input";
import Button from "core/components/button";
import { validate, shouldMarkError } from "./catalogValidation";
import { useSelector } from "react-redux";
import CatalogCheckbox from "./catalog-checkbox";
import { useState } from "react";
import Icon from "app/assets/icon/icon";
import MaskedInput from "react-text-mask";
import SessionMessages from "core/components/sessionMessages";

const CatalogRequestForm = (props) => {
  let {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    companyName,
    setCompanyName,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    setLoading,
    touched,
    setTouched,
    addressOne,
    addressTwo,
    setAddressOne,
    setAddressTwo,
    country,
    setCountry,
    zipCode,
    setZipCode,
    stateName,
    setStateName,
    title,
    setTitle,
    city,
    setCity,
  } = props;
  let errors = validate(
    firstName,
    lastName,
    companyName,
    email,
    addressOne,
    title,
    country,
    city,
    stateName,
    zipCode,
    phone
  );

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => {
    scrollToRef(myRef);
  };
  useEffect(() => {
    executeScroll();
  }, [props.catalogRequest]);

  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  let currentSiteTemplate = site.siteCode?.includes("norwell") ? `18` : `17`;
  const [checkedCatalogs, setCheckedCatalogs] = useState([]);

  const catalogRequest = (e) => {
    let variables = [
      { code: "firstname", value: firstName },
      { code: "lastname", value: lastName },
      { code: "email", value: email },
      { code: "addressOne", value: addressOne },
      { code: "title", value: title },
      { code: "country", value: country },
      { code: "city", value: city },
      { code: "stateName", value: stateName },
      { code: "zipCode", value: zipCode },
      { code: "phone", value: phone },
      { code: "addressTwo", value: addressTwo },
      { code: "companyName", value: companyName },
    ];
    if (checkedCatalogs.length > 0) {
      let ids = "";
      checkedCatalogs.map((catalog) => (ids = ids + catalog.value + ","));
      ids = ids.slice(0, -1);
      let value = { code: "catalogs", value: ids };
      variables.push(value);
    }

    let isTrue = true;
    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      addressOne: true,
      title: true,
      country: true,
      city: true,
      stateName: true,
      zipCode: true,
      companyName: true,
      phone: true,
    });
    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });

    e.preventDefault();
    if (isTrue === true) {
      setLoading(true);
      props.lock({
        always: (e) => {},
        success: (e) => {
          setLoading(false);
          setFirstName("");
          setLastName("");
          setCompanyName("");
          setAddressOne("");
          setAddressTwo("");
          setCountry("");
          setTitle("");
          setPhone("");
          setEmail("");
          setCity("");
          setStateName("");
          setZipCode("");
          setTouched({
            firstName: false,
            lastName: false,
            email: false,
            addressOne: false,
            title: false,
            country: false,
            city: false,
            stateName: false,
            zipCode: false,
            companyName: false,
            phone: false,
          });
        },
        fail: (e) => {
          setLoading(false);
        },
      });
      props.sendEmail({
        input: {
          attributes: variables,
          template: currentSiteTemplate,
          fileBase64: "",
          fileName: "",
          fileType: "",
          return_form: false,
          is_quote: false,
        },
      });
    }
  };

  return (
    <MDBContainer className="catalog-request-modal-form-wrapper">
      <h1 ref={myRef}>Catalog Request</h1>
      <MDBRow>
        <MDBCol lg="6" className="form-side form-left-side">
          <Input
            placeholder={`${
              shouldMarkError("firstName", errors, touched)
                ? "First Name is required"
                : "First Name"
            }`}
            required={true}
            type={"text"}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            className={
              shouldMarkError("firstName", errors, touched)
                ? "invalid"
                : "" + firstName !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, firstName: true });
            }}
            shouldMarkError={shouldMarkError("firstName", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("lastName", errors, touched)
                ? "Last Name is required"
                : "Last Name"
            }`}
            required={true}
            type={"text"}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            className={
              shouldMarkError("lastName", errors, touched)
                ? "invalid"
                : "" + lastName !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, lastName: true });
            }}
            shouldMarkError={shouldMarkError("lastName", errors, touched)}
          />

          <Input
            placeholder={`${
              shouldMarkError("companyName", errors, touched)
                ? "Company Name is required"
                : "Company"
            }`}
            required={true}
            type={"text"}
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value)}
            className={
              shouldMarkError("companyName", errors, touched)
                ? "invalid"
                : "" + companyName !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, companyName: true });
            }}
            shouldMarkError={shouldMarkError("companyName", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("addressOne", errors, touched)
                ? "Address 1 is required"
                : "Address 1"
            }`}
            required={true}
            type={"text"}
            value={addressOne}
            onChange={(event) => setAddressOne(event.target.value)}
            className={
              shouldMarkError("addressOne", errors, touched)
                ? "invalid"
                : "" + addressOne !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, addressOne: true });
            }}
            shouldMarkError={shouldMarkError("addressOne", errors, touched)}
          />
          <Input
            placeholder={"Address 2"}
            required={false}
            type={"text"}
            value={addressTwo}
            onChange={(event) => setAddressTwo(event.target.value)}
            onBlur={(e) => {
              setTouched({ ...touched, addressTwo: true });
            }}
          />
          <Input
            placeholder={`${
              shouldMarkError("country", errors, touched)
                ? "Country is required"
                : "Country"
            }`}
            required={true}
            type={"text"}
            value={country}
            onChange={(event) => setCountry(event.target.value)}
            className={
              shouldMarkError("country", errors, touched)
                ? "invalid"
                : "" + country !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, country: true });
            }}
            shouldMarkError={shouldMarkError("country", errors, touched)}
          />
        </MDBCol>
        <MDBCol lg="6" className="form-side form-right-side">
          <Input
            placeholder={`${
              shouldMarkError("title", errors, touched)
                ? "Position/Title is required"
                : "Position/Title"
            }`}
            required={true}
            type={"text"}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            className={
              shouldMarkError("title", errors, touched)
                ? "invalid"
                : "" + title !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, title: true });
            }}
            shouldMarkError={shouldMarkError("title", errors, touched)}
          />
          <div className="masked-input">
            <MaskedInput
              type={"text"}
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={phone !== undefined ? phone : ""}
              guide={false}
              onChange={(event) => setPhone(event.target.value)}
              onBlur={(e) => {
                setTouched({ ...touched, phone: true });
              }}
              className={
                shouldMarkError("phone", errors, touched)
                  ? "invalid"
                  : "" + phone !== "" && "valid"
              }
              placeholder={`${
                shouldMarkError("phone", errors, touched)
                  ? "Phone Number is required"
                  : "Phone Number"
              }`}
            />
            <label className="required-label">
              {typeof shouldMarkError("phone", errors, touched) ===
              "undefined" ? (
                "Required"
              ) : shouldMarkError("phone", errors, touched) ? (
                <Icon icon="error_icon" />
              ) : (
                <Icon icon="check_mark_icon_black" />
              )}
            </label>
          </div>
          <Input
            placeholder={`${
              shouldMarkError("email", errors, touched)
                ? "Email is required"
                : "Email"
            }`}
            required={true}
            type={"text"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={
              shouldMarkError("email", errors, touched)
                ? "invalid"
                : "" + email !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, email: true });
            }}
            shouldMarkError={shouldMarkError("email", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("city", errors, touched)
                ? "City is required"
                : "City"
            }`}
            required={true}
            type={"text"}
            value={city}
            onChange={(event) => setCity(event.target.value)}
            className={
              shouldMarkError("city", errors, touched)
                ? "invalid"
                : "" + city !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, city: true });
            }}
            shouldMarkError={shouldMarkError("city", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("stateName", errors, touched)
                ? "State/Province is required"
                : "State/Province"
            }`}
            required={true}
            type={"text"}
            value={stateName}
            onChange={(event) => setStateName(event.target.value)}
            className={
              shouldMarkError("stateName", errors, touched)
                ? "invalid"
                : "" + stateName !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, stateName: true });
            }}
            shouldMarkError={shouldMarkError("stateName", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("zipCode", errors, touched)
                ? "Zip/Postal Code is required"
                : "Zip/Postal Code"
            }`}
            required={true}
            type={"text"}
            value={zipCode}
            onChange={(event) => setZipCode(event.target.value)}
            className={
              shouldMarkError("zipCode", errors, touched)
                ? "invalid"
                : "" + zipCode !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, zipCode: true });
            }}
            shouldMarkError={shouldMarkError("zipCode", errors, touched)}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="checkbox-section">
        {props.data.getCatalog.catalogs.map((catalog, index) => {
          return (
            <CatalogCheckbox
              catalog={catalog}
              index={index}
              checkedCatalogs={checkedCatalogs}
              setCheckedCatalogs={setCheckedCatalogs}
            />
          );
        })}
      </MDBRow>
      <MDBRow fluid className="request-catalog-form-btn-wrapper">
        <Button
          disabled={loading && true}
          onClick={(e) => {
            catalogRequest(e);
          }}
        >
          {loading ? "Sending..." : "Send Request"}
        </Button>
        <p className="session-message">
          <SessionMessages target="sendEmail" />
        </p>
      </MDBRow>
    </MDBContainer>
  );
};

export default CatalogRequestForm;
