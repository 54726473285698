import React, { useState } from "react";
import Toolbar from "./toolbar";

const ToolbarStateContainer = (props) => {
  let { data, changeSortFilter } = props;
  // const [sort, setSort] = useState(data?.sort_fields?.default);
  const [sort, setSort] = useState({ label: "Position", value: "position" });
  const [sortOrder, setSortOrder] = useState("ASC");

  const changeSortFilterHandler = (data, sortOrderValue) => {
    // if (data.value !== "is_new") {
    //   sortOrderValue = "ASC";
    // }
    setSort(data);
    changeSortFilter({ [data.value]: sortOrderValue });
  };

  return (
    <Toolbar
      {...props}
      sort={sort}
      setSort={setSort}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      changeSortFilterHandler={changeSortFilterHandler}
      dataLoading={props.dataLoading}
    />
  );
};

export default ToolbarStateContainer;
