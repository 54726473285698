import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  MDBBox,
  MDBRow,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBContainer,
  MDBModalFooter,
  MDBCollapse,
} from "mdbreact";
import Input from "app/layout/input";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import Pdf from "../print/Pdf";
import Checkbox from "app/layout/checkbox/checkbox";
import MaskedInput from "react-text-mask";
import { validate, shouldMarkError } from "./pdfModalEmailValidation";

const PdfModal = ({
  showPdfModal,
  togglePdfModal,
  selectedWishlist,
  attributeOptions,
}) => {
  const pdfComponent = useRef();
  const pdfQtyOfItemsPerPage = [
    {
      size: 1,
      selectedImage: <Icon icon="oneSelected" />,
      disabledImage: <Icon icon="oneDisabled" />,
    },
    {
      size: 2,
      selectedImage: <Icon icon="twoSelected" />,
      disabledImage: <Icon icon="twoDisabled" />,
    },
    {
      size: 4,
      selectedImage: <Icon icon="fourSelected" />,
      disabledImage: <Icon icon="fourDisabled" />,
    },
  ];

  const [showPdfPersonal, setShowPdfPersonal] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactCompany, setContactCompany] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [pdfMessage, setPdfMessage] = useState("");
  const [pdfFormat, setPdfFormat] = useState(pdfQtyOfItemsPerPage[0].size);
  const [touched, setTouched] = useState({});

  let errors = validate(contactEmail);

  let pdfOptions = {
    showPdfPersonal,
    contactName,
    contactEmail,
    contactCompany,
    contactPhone,
    pdfMessage,
    pdfFormat,
  };
  const handlePrint = useReactToPrint({
    content: () => pdfComponent.current,
  });
  const generateDownloadLinkAndOpen = () => {
    if (!showPdfPersonal) {
      handlePrint();
    } else {
      if (showPdfPersonal && !errors.contactEmail) {
        handlePrint();
      } else {
        if (showPdfPersonal && contactEmail.length === 0) {
          handlePrint();
        } else {
          setTouched({ ...touched, contactEmail: true });
        }
      }
    }
  };

  const togglePdfPersonal = () => {
    setShowPdfPersonal(!showPdfPersonal);
  };

  return (
    <>
      <MDBContainer fluid id={`download-pdf-modal`}>
        <MDBModal
          isOpen={showPdfModal}
          toggle={togglePdfModal}
          className={"wishlist-pdf-modal"}
          centered
        >
          <MDBModalHeader classNam="modal-header">
            <h5>Download Project</h5>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                <div className="checkbox-wrapper">
                  <Checkbox
                    checkbox_id={`check-box-show-personal-info`}
                    checked={showPdfPersonal}
                    setChecked={togglePdfPersonal}
                    theme={"light"}
                    type={"checkbox"}
                  />
                  <p>Add contact information</p>
                </div>
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBCollapse isOpen={showPdfPersonal}>
              <MDBRow className="contact-information-wrapper">
                <MDBCol md="6" className="input-wrapper">
                  <Input
                    wrapperClass={"light"}
                    placeholder={"Name"}
                    group
                    type="text"
                    value={contactName}
                    onChange={(e) => {
                      setContactName(e.target.value);
                    }}
                  />
                  <Input
                    wrapperClass={"light"}
                    placeholder={`${
                      shouldMarkError("contactEmail", errors, touched)
                        ? "Enter valid email "
                        : "Email"
                    }`}
                    required={true}
                    type={"text"}
                    value={contactEmail}
                    onChange={(event) => setContactEmail(event.target.value)}
                    className={
                      shouldMarkError("contactEmail", errors, touched)
                        ? "invalid"
                        : "" + contactEmail !== "" && "valid"
                    }
                    onBlur={(e) => {
                      setTouched({ ...touched, contactEmail: true });
                    }}
                    shouldMarkError={shouldMarkError(
                      "contactEmail",
                      errors,
                      touched
                    )}
                  />
                </MDBCol>
                <MDBCol md="6" className="input-wrapper">
                  <Input
                    wrapperClass={"light"}
                    placeholder={"Company name"}
                    group
                    type="text"
                    value={contactCompany}
                    onChange={(e) => {
                      setContactCompany(e.target.value);
                    }}
                  />
                  <div className="masked-input light">
                    <MaskedInput
                      type={"text"}
                      mask={[
                        "(",
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        "-",
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/,
                      ]}
                      value={contactPhone !== undefined ? contactPhone : ""}
                      guide={false}
                      onChange={(event) => setContactPhone(event.target.value)}
                      placeholder={"Phone Number"}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
            <MDBRow className="sizes-pdf">
              {pdfQtyOfItemsPerPage.map((amount, index) => {
                return (
                  <MDBCol
                    md="3"
                    key={`pdf-qty-index-${index}`}
                    onClick={() => {
                      setPdfFormat(amount.size);
                    }}
                  >
                    {pdfFormat !== amount.size
                      ? amount.disabledImage
                      : amount.selectedImage}
                  </MDBCol>
                );
              })}
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div className="textarea-container">
                  <Input
                    wrapperClass={"light"}
                    type="textarea"
                    placeholder="Message"
                    className="textarea"
                    maxLength="250"
                    onChange={(e) => {
                      setPdfMessage(e.target.value);
                    }}
                  />
                  <label className="textarea-length">
                    {pdfMessage.length}/250
                  </label>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow fluid className="w-100">
              <MDBCol size="12">
                <Button
                  onClick={() => {
                    setTimeout(() => {
                      generateDownloadLinkAndOpen();
                    }, 500);
                  }}
                >
                  Generate PDF
                </Button>
              </MDBCol>
              <MDBCol size="12">
                <MDBBox
                  className="cancel-btn-pdf-modal"
                  tag="p"
                  onClick={() => {
                    togglePdfModal();
                  }}
                >
                  Cancel
                </MDBBox>
              </MDBCol>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>
        <MDBBox className="d-none">
          <Pdf
            ref={pdfComponent}
            documentTitle={selectedWishlist[0].name}
            products={selectedWishlist[0].items}
            attributeOptions={attributeOptions}
            {...pdfOptions}
          />
        </MDBBox>
      </MDBContainer>
    </>
  );
};

export default PdfModal;
