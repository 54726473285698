import React, { memo, useState, useCallback, useRef, useEffect } from "react";
import { MDBCol } from "mdbreact";
import uuid from "react-uuid";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";

const MobileToolbar = memo(
  (props) => {
    let {
      options,
      activeData,
      showData,
      setShowData,
      norwellData,
      ilexData,
      location,
    } = props;

    const [openMenu, setOpenMenu] = useState(false);
    const [activeOption, setActiveOption] = useState(options[0].label);
    const ref = useRef(null);
    useEffect(() => {
      options.filter((option) => {
        if (option.index === activeData) setActiveOption(option.label);
      });
    }, []);
    const onClickOutside = useCallback((e) => {
      if (!ref.current.contains(e.target)) setOpenMenu(false);
    }, []);

    useEffect(() => {
      document.addEventListener("click", onClickOutside);
      return () => {
        document.removeEventListener("click", onClickOutside);
      };
    }, []);
    useEffect(() => {
      showData &&
      showData[0] &&
      showData[0].category &&
      showData[0].category[0] &&
      showData[0].category[0].name &&
      showData[0].category[0].name === "Norwell Lighting"
        ? setActiveOption(options[0].label)
        : setActiveOption(options[1].label);
    }, [showData]);

    return (
      <MDBCol className={"mobile-custom-toolbar-component " + props.position}>
        <div onClick={() => setOpenMenu(!openMenu)} ref={ref}>
          <p>{activeOption}</p>
          <span className={"arrow " + openMenu}></span>
        </div>
        <ul className={"custom-toolbar-list " + openMenu} reversed>
          {options?.map((item) => {
            return (
              <Link
                to={{
                  pathname: "/" + location,
                  state: {
                    currentPage: 1,
                    currentPosts:
                      item.index === "norwell-data" ? norwellData : ilexData,
                  },
                }}
              >
                <li
                  key={uuid()}
                  onClick={() => {
                    if (item.index === "norwell-data") {
                      setShowData(norwellData);
                    } else {
                      setShowData(ilexData);
                    }
                    setActiveOption(item.label);
                  }}
                >
                  {item.label}
                </li>
              </Link>
            );
          })}
        </ul>
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default MobileToolbar;
