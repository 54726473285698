import apolloClient from "core/graphql/apolloClient";
import commonActions from "../actions";
import queryActions from "core/state/redux/data/queries/actions";
import queryLoader from "app/graphql/queryLoader";
import { messagesActions } from "core/state/redux/data/messages";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: queryLoader("sendEmail"),
      variables: action.data,
    });

    if (!data.sendEmail) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }
    store.dispatch(commonActions.unlock("success"));
    store.dispatch(
      messagesActions.addMessage(data.sendEmail, "success", "sendEmail")
    );
  } catch (error) {
    store.dispatch(commonActions.unlock("fail"));
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
