import React from "react";
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";
import Input from "app/layout/input";
import { validate, shouldMarkError } from "./validation";

const ResetPassword = ({
  props,
  email,
  setEmail,
  loading,
  setLoading,
  touched,
  setTouched,
  toggleModal,
  setToggleModal,
  setToggleLoginModal,
}) => {
  const errors = validate(email);

  return (
    <MDBRow className="reset-password-form-container">
      <MDBCol className="reset-password-form">
        <h5>Reset Your Password</h5>
        <p>
          If you are already an existing user, please reset your password. Since
          we are using a new interface, this is a required step.
        </p>
        <Input
          placeholder={
            shouldMarkError("email", errors, touched)
              ? "Account email is required"
              : "Account email"
          }
          required={true}
          type={"text"}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className={
            shouldMarkError("email", errors, touched)
              ? "invalid"
              : "" + email !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, email: true });
          }}
          shouldMarkError={shouldMarkError("email", errors, touched)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              setLoading(true);
              props.lock({
                always: () => {
                  setLoading(true);
                },
                success: () => {
                  setLoading(false);
                },
                fail: () => {
                  setLoading(false);
                },
              });
              props.onForgotPassword(email);
            }
          }}
        />
        <MDBBtn
          disabled={loading && loading}
          onClick={(e) => {
            setLoading(true);
            props.lock({
              always: () => {
                setLoading(true);
              },
              success: () => {
                setLoading(false);
              },
              fail: () => {
                setLoading(false);
              },
            });
            props.onForgotPassword(email);
          }}
        >
          {loading ? "Sending..." : "Reset Password"}
        </MDBBtn>
        <p
          className="back-to-login"
          onClick={() => {
            setToggleModal(!toggleModal);
            setToggleLoginModal(true);
          }}
        >
          Back to login
        </p>
      </MDBCol>
    </MDBRow>
  );
};
export default ResetPassword;
