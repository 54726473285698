import filterActions from "../actions";

export default (next) => (store, action) => {
  const pageInfo = store.getState().filters?.page_data?.pageInfo;
  const totalCount = store.getState().filters?.data?.total_count;

  console.log("changePageSize middleware action", action);

  console.log("store.getState().filters", store.getState().filters);

  console.log("pageInfo middleware - pageInfo", pageInfo);

  let newPageTotalCount;
  if (totalCount % action.size === 0) {
    newPageTotalCount = Math.trunc(totalCount / action.size);
  } else {
    newPageTotalCount = Math.trunc(totalCount / action.size) + 1;
  }

  store.dispatch(filterActions.changeTotalPages(newPageTotalCount));

  store.dispatch(filterActions.changePageNumber(1));

  console.log("filter middleware - changePageSize - action", action);

  next(action);
};
