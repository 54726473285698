import React from "react";
import { connect } from "react-redux";
import Gallery from "./gallery-data-container";
import SeoHelmet from "app/layout/seoHelmet";

const GalleryReduxContainer = (props) => {
  return (
    <>
      <SeoHelmet title="Gallery" url={`/gallery`} />
      <Gallery {...props} />
    </>
  );
};

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, null)(GalleryReduxContainer);
