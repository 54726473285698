import Homepage from "app/pages/home";
import Dashboard from "app/pages/customer/account/pages/dashboard";
import Information from "app/pages/customer/account/pages/information";
import Newsletter from "app/pages/customer/account/pages/newsletter";
import Favorites from "app/pages/customer/account/pages/wishlistCore";
import FavoritesShareWishlist from "app/pages/customer/account/pages/wishlistCore/shareWishlist";

export default [
  {
    url: "customer",
    component: Dashboard,
    exact: true,
    inSidebar: true,
    dropdownText: "dashboard",
    menuText: "account dashboard",
    inDropdown: true,
  },
  {
    url: "customer/information",
    component: Information,
    exact: true,
    inSidebar: true,
    dropdownText: "information",
    menuText: "account information",
    inDropdown: false,
  },
  {
    url: "customer/newsletter",
    component: Newsletter,
    exact: true,
    inSidebar: true,
    menuText: "newsletter subscription",
    inDropdown: false,
  },
  {
    url: "favorites/share",
    component: FavoritesShareWishlist,
    separated: true,
    exact: false,
    inSidebar: false,
    dropdownText: "share wishlist",
    menuText: "share wishlist",
    inDropdown: false,
    disabled: false,
  },
  {
    url: "favorites",
    component: Favorites,
    separated: true,
    exact: true,
    inSidebar: true,
    dropdownText: "my favorites",
    menuText: "my favorites",
    inDropdown: true,
    disabled: false,
  },
  {
    url: "favorites/edit",
    component: Favorites,
    separated: true,
    exact: true,
    inSidebar: true,
    dropdownText: "my projects",
    menuText: "my projects",
    inDropdown: false,
    disabled: false,
    showSidebar: false,
  },
];

export const pagesConfig = {
  sidebarTitle: "my account",
  mainTitle: "dashboard",
};

export { default as ErrorPage } from "app/pages/error";

export const DefaultPage = Homepage;
