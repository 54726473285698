import React, { useState } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import { Link } from "react-router-dom";
import Iframe from "react-iframe";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import CustomerRequestPopupModal from "app/pages/catalog/catalogPage/catalog-request-modal";

const CatalogDetail = (props) => {
  const [catalogRequest, setCatalogRequest] = useState(false);
  const toggle = () => {
    setCatalogRequest(!catalogRequest);
  };

  let { selectedCatalog } = props;

  return (
    <>
      <MDBContainer className="catalog-details-wrapper">
        <MDBRow className="breadcrumbs">
          <Link to={"/"}>
            <p>Home</p>
          </Link>
          <p className="dash">-</p>
          <Link to={"/catalog"}>
            <p>Catalog</p>
          </Link>
          <p className="dash">-</p>
          <Link>
            <p>{selectedCatalog?.title}</p>
          </Link>
        </MDBRow>
        <MDBContainer>
          <h1>{selectedCatalog?.title}</h1>
          <div className="iframe-wrapper">
            <Iframe
              url={selectedCatalog?.fliphtml5}
              width="1000px"
              height="600px"
              frameborder="0"
              allowfullscreen
              allowtransparency
            />
          </div>
          <Button
            className="with-arrow"
            onClick={() => {
              setCatalogRequest(!catalogRequest);
            }}
          >
            <Icon icon="right_arrow_white" />
            Catalog Request
          </Button>
          <CustomerRequestPopupModal
            catalogRequest={catalogRequest}
            setCatalogRequest={setCatalogRequest}
            toggle={toggle}
          />
        </MDBContainer>
      </MDBContainer>
    </>
  );
};

export default CatalogDetail;
