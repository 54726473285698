import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Slider from "react-slick";
import { finishesSliderSettings } from "./finishesSliderSettings";
import Icon from "app/assets/icon/icon";

const ProductFinishes = (data) => {
  let {
    finishes,
    activeFinish,
    setActiveFinish,
    slider3,
    sliderNav1,
    isMobileOrTablet,
    imageGalleryLength,
  } = data;

  return (
    <MDBRow className="product-finishes-slider-container">
      {finishes && finishes.length > 4 && (
        <MDBCol
          size={isMobileOrTablet ? "1" : "4"}
          className="slider-arrow prev-arrow"
          onClick={() => slider3.slickPrev()}
        >
          <Icon icon="black_arrow_up" />
        </MDBCol>
      )}

      <MDBCol
        size={isMobileOrTablet ? "10" : "12"}
        className="product-finishes-slider-column"
      >
        <Slider
          asNavFor={sliderNav1}
          ref={(slider) => (slider3 = slider)}
          {...finishesSliderSettings(data, isMobileOrTablet, setActiveFinish)}
          className="product-finishes-slider"
        >
          {finishes &&
            finishes.map((item, index) => {
              return (
                <MDBRow className="finish-item">
                  {!isMobileOrTablet && (
                    <MDBCol
                      size="8"
                      className={
                        activeFinish === index
                          ? "finish-title active"
                          : "finish-title"
                      }
                    >
                      <p>
                        {item.title && item.title !== "false"
                          ? item.title.length > 30
                            ? item.title.slice(0, 30) + "..."
                            : item.title
                          : ""}
                      </p>
                    </MDBCol>
                  )}
                  <MDBCol
                    size={isMobileOrTablet ? "12" : "4"}
                    className={
                      activeFinish === index
                        ? "finish-image active"
                        : "finish-image"
                    }
                    onClick={() => {
                      sliderNav1.slickGoTo(imageGalleryLength + index);
                      setActiveFinish(index);
                    }}
                  >
                    <img
                      key={`product-media-gallery-item-${index}`}
                      className={
                        item.image &&
                        item.image.includes("placeholder") &&
                        "placeholder"
                      }
                      src={
                        item.image && item.image.includes("placeholder")
                          ? "/placeholder.png"
                          : item.image
                      }
                      alt={item.title && item.title}
                    />
                  </MDBCol>
                </MDBRow>
              );
            })}
        </Slider>
      </MDBCol>
      {finishes && finishes.length > 4 && (
        <MDBCol
          size={isMobileOrTablet ? "1" : "4"}
          className="slider-arrow next-arrow"
          onClick={() => {
            slider3.slickNext();
          }}
        >
          <Icon icon="black_arrow_down" />
        </MDBCol>
      )}
    </MDBRow>
  );
};

export default ProductFinishes;
