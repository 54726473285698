import { useSelector } from "react-redux";

const useSite = () => {
  const site = useSelector((state) => state.site);

  const getSite = () => {
    return { ...site };
  };

  const getSiteCode = () => {
    return site.siteCode;
  };

  return [getSite, getSiteCode];
};

export default useSite;
