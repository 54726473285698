import React from "react";
import { Link } from "react-router-dom";
import { MDBView, MDBMask } from "mdbreact";
import themeConfiguration from "config/themeConfiguration";

const GalleryItem = (props) => {
  let { gallery, galleries, currentPage } = props;
  let path = "";
  path = `/gallery-detail/${
    gallery.name
      .split(" ")
      .join("-")
      .toLowerCase()
      .replace(/,/g, "")
      .replace(/-$/g, "")
      .replace(/°/g, "")
      .replace(/:/g, "") +
    "-" +
    "id_" +
    gallery.gallery_id
  }`;

  if (gallery.image.indexOf("pub/") !== -1) {
    gallery.image = gallery.image.substring(4);
  }

  return (
    <>
      {gallery && (
        <Link
          to={{
            pathname: path,
            state: {
              currentPage: currentPage,
              currentPosts: galleries,
            },
          }}
        >
          <div className="gallery-item">
            <MDBView waves>
              <img
                src={themeConfiguration.magento_url + "pub/" + gallery.image}
                alt={"gallery-image " + gallery.name}
              />
              <MDBMask className="flex gallery-item-mask" overlay="teal-slight">
                <p className="white-text">{gallery.name}</p>
              </MDBMask>
            </MDBView>
          </div>
        </Link>
      )}
    </>
  );
};

export default GalleryItem;
