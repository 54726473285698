import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Listing from "./listing-effect-component";
import { isEmpty, isUndefined } from "core/helpers/functions";
import { createRequestFromFilters, createSortVariable } from "./functions";
import { PRODUCTS, PRODUCTS_SEARCH } from "./queries";
import ReactLoader from "app/layout/loader";
import { MDBContainer } from "mdbreact";
import useSite from "app/state/hooks/siteHooks/useSite";

const ListingQueryContainer = (props) => {
  const {
    category_id,
    category,
    filters,
    showFilters,
    addFilteredData,
    pageData,
  } = props;
  const currentPage = pageData?.pageInfo?.current_page;
  let pageSize = props.pageSize;
  const [, getSiteCode] = useSite();

  if (typeof pageSize === "undefined") {
    pageSize = 32;
  }
  const pageNumber = pageData?.pageInfo?.total_pages;
  let params = window.location.search;
  const urlParams = new URLSearchParams(params);
  let search = urlParams.get("search");

  let finalQuery = null;

  let variables;
  let request = createRequestFromFilters(filters.items, category_id);
  let sort = createSortVariable(filters.sort);
  if (!isEmpty(request)) {
    if (
      typeof request.filter !== "undefined" &&
      typeof request.filter.category_id !== "undefined" &&
      request.filter.category_id.eq === 0
    ) {
      variables = {
        search: search,
        filters: request.filter,
        pageSize: pageSize,
        currentPage:
          typeof currentPage !== "undefined" && typeof currentPage === "number"
            ? currentPage
            : 1,
      };
      variables["filters"]["category_id"]["eq"] = category_id;
      variables["sort"] = sort;
    } else {
      variables = {
        search: search,
        filters: request.filter,
        sort: sort,
        pageSize: pageSize,
        currentPage:
          typeof currentPage !== "undefined" && typeof currentPage === "number"
            ? currentPage
            : 1,
      };
    }
  }
  let newNavFilters = props.navFilters;
  if (typeof props.navFilters !== "undefined") {
    Object.keys(props.navFilters).map((item) => {
      if (item === "search") {
        delete newNavFilters["search"];
      }

      if (item === "sort") {
        delete newNavFilters["sort"];
      }
    });
  }

  variables.filters = { ...variables.filters, ...newNavFilters };

  finalQuery = search === null ? PRODUCTS : PRODUCTS_SEARCH;
  const { loading, error, data, refetch } = useQuery(finalQuery, {
    variables: variables,
    fetchPolicy: "cache-first",
  });

  props.setDataLoading(loading);

  if (isUndefined(data) && !loading) {
    return (
      <Listing
        {...props}
        loading={loading}
        refetch={refetch}
        data={props.category}
        pageNumber={pageNumber !== undefined ? currentPage : 1}
      />
    );
  }
  if (loading) {
    return (
      <MDBContainer
        className={`d-flex flex-column align-items-center justify-content-center center`}
      >
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
        />
      </MDBContainer>
    );
  }

  if (error) return `Error! ${error}`;

  return (
    <Listing
      {...props}
      loading={loading}
      variables={variables}
      refetch={refetch}
      data={data}
      showFilters={showFilters}
      addFilteredData={addFilteredData}
      category={category}
      pageNumber={pageNumber !== undefined ? pageNumber : 1}
      currentPage={currentPage !== undefined ? currentPage : 1}
      pageSize={pageSize !== undefined ? pageSize : 32}
    />
  );
};

export default ListingQueryContainer;
