import React from "react";
import Layout from "./layout";
import apolloClient from "app/graphql/apolloClient";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import Cache from "app/layout/cache";
import store from "core/state/redux/store";
import history from "core/state/redux/history";
import ScrollToTop from "react-router-scroll-top";

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ApolloHooksProvider client={apolloClient}>
        <ReduxProvider store={store}>
          <Cache>
            <Router history={history}>
              <ScrollToTop>
                <Layout />
              </ScrollToTop>
            </Router>
          </Cache>
        </ReduxProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default App;
