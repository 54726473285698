import React, { memo, useEffect, useState } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBox } from "mdbreact";
import { Redirect } from "react-router-dom";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { useWishlistAddToCart } from "app/state/hooks/wishlistHooks/wishlistHookAddToCart";
// import useLocking from "core/state/hooks/commonHooks/useLocking";
import { useWishlist } from "app/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { isEqual } from "lodash";
import ItemsWishlist from "./itemsWishlist";
import WishlistPageListing from "./wishlistPageListing";
import { wishlistTabs } from "./data";
import ShareWishlist from "./shareWishlist";
import useCustomer from "core/state/hooks/customerHooks/useCustomer";
import SeoHelmet from "app/layout/seoHelmet/seoHelmet";
import ReactLoader from "app/layout/loader/loader";
import useSite from "app/state/hooks/siteHooks/useSite";
import Icon from "app/assets/icon/icon";
import { useMediaQuery } from "react-responsive";
import RemoveAllFromWishlist from "./itemsWishlist/item/removeAllFromWishlist";

/**
 * Wishlist with the index 0 is always the favorites, it shows products, while the rest show more projects or downloads
 */
const WishlistCore = memo(
  ({
    sendEmailAction,
    wishlistNumberOfItems,
    activeWishlistName,
    setActiveWishlistName,
    project,
    setProject,
    currentSelectedProject,
    setCurrentSelectedProject,
    selectedTab,
    selectedWishlistId,
    setSelectedWishlist,
    pending,
    setPending,
    history,
    page,
  }) => {
    const {
      fetchWishlistData,
      getWishlistItems,
      createWishlist,
    } = useWishlist();
    const { deleteItemsFromWishlist } = useWishlistAddToCart();
    const [getCustomer] = useCustomer();
    const [showPdfModal, setShowPdfModal] = useState(0);
    const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

    const togglePdfModal = () => {
      setShowPdfModal(!showPdfModal);
    };
    const [, getSiteCode] = useSite();

    let myWishlistsArr = [...getWishlistItems()];

    let customer = getCustomer();
    const [, setNewUrl] = useState("");
    const [removeAll, setRemoveAll] = useState(false);
    useEffect(() => {
      fetchWishlistData();
    }, [customer, myWishlistsArr]);

    if (
      typeof myWishlistsArr != "undefined" &&
      myWishlistsArr != null &&
      myWishlistsArr.length != null &&
      !myWishlistsArr.length > 0
    ) {
      createWishlist("My Favorites", "wishlist-modal").then((response) => {
        myWishlistsArr = [...getWishlistItems()];
      });

      return (
        <>
          {!myWishlistsArr.length > 0 && (
            <ReactLoader
              color={
                getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"
              }
              width={50}
              height={50}
              type={`ThreeDots`}
              key={"loader-items"}
            />
          )}
        </>
      );
    } else {
      myWishlistsArr = [...getWishlistItems()];
      let selectedWishlist = myWishlistsArr.filter(function (wishlist) {
        return parseInt(wishlist.id) === selectedWishlistId;
      });
      myWishlistsArr = myWishlistsArr.sort((a, b) => {
        if (
          a.name.toLowerCase() === "my favorites" ||
          b.name.toLowerCase() === "my favorites"
        ) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        } else {
          return 1;
        }
      });
      if (myWishlistsArr.length === 0) return <Redirect to={"/"} />;
      const isDisabled =
        typeof selectedWishlist[0] !== "undefined" &&
        selectedWishlist[0].items_count > 0
          ? false
          : typeof myWishlistsArr[0] !== "undefined" &&
            selectedWishlistId === 0 &&
            myWishlistsArr[0].items_count
          ? false
          : true;
      let currentUrl = page.pathname;
      let numberOfItems = 0;
      if (selectedWishlist[0] === undefined) {
        numberOfItems = myWishlistsArr[0].items_count;
      }
      const goToTab = (url) => {
        setNewUrl(url);
      };
      myWishlistsArr.sort(function (a, b) {
        return a.id - b.id;
      });
      return (
        <>
          <SeoHelmet title="Favorites" url={`/favorites`} />
          <MDBContainer id={`favorites-tabs`}>
            <MDBCol>
              <MDBRow className="favorites-tabs-border">
                {wishlistTabs.map((tab) => {
                  return (
                    <MDBCol
                      md="2"
                      key={`wishlist-tab-id-${tab.id}`}
                      className={`wishlist-tab  ${
                        currentUrl === "/" + tab.url ? "active" : "inactive"
                      }`}
                      onClick={(e) => {
                        history.push({
                          pathname: `/${tab.url}`,
                        });
                        goToTab(tab.url);
                      }}
                    >
                      <p>{tab.name}</p>
                      <p
                        className={`number-of-items ${
                          currentUrl === "/" + tab.url
                            ? "active-num-tab"
                            : "inactive-num-tab"
                        }`}
                      >
                        {tab.name === "Favorited items"
                          ? wishlistNumberOfItems[0].items_count
                          : wishlistNumberOfItems.length - 1}
                      </p>
                    </MDBCol>
                  );
                })}
              </MDBRow>
            </MDBCol>
          </MDBContainer>
          <MDBContainer id={`favorites-page`}>
            <MDBRow className={`favorite-page-list`}>
              {currentUrl.includes("favorites/edit") &&
              selectedWishlistId === 0 ? (
                <ItemsWishlist
                  setActiveWishlistName={setActiveWishlistName}
                  activeWishlistName={activeWishlistName}
                  project={project}
                  setProject={setProject}
                  currentSelectedProject={currentSelectedProject}
                  setCurrentSelectedProject={setCurrentSelectedProject}
                  selectedWishlistId={selectedWishlistId}
                  setSelectedWishlist={setSelectedWishlist}
                  myWishlistsArr={myWishlistsArr}
                  page={page}
                  history={history}
                />
              ) : (
                <WishlistPageListing
                  selectedWishlistId={selectedWishlistId}
                  myWishlistsArr={myWishlistsArr}
                  setSelectedWishlist={setSelectedWishlist}
                  history={history}
                  showPdfModal={showPdfModal}
                  setShowPdfModal={setShowPdfModal}
                  togglePdfModal={togglePdfModal}
                />
              )}
            </MDBRow>
            {!(
              history.location.pathname.includes("favorites/edit") &&
              history.location.search.length === 0
            ) ? (
              <MDBCol className={`favorite-page-action wow fadeInDown`}>
                <MDBRow>
                  <MDBCol className={`top-action`} size="8">
                    <MDBRow>
                      {(selectedWishlist[0]?.items_count > 0 ||
                        numberOfItems > 0) && (
                        <MDBCol size="5" className={`email-share-wrapper`}>
                          <MDBRow>
                            <ModalConsumer value={{ useWrapper: true }}>
                              {({ showModal }) => (
                                <MDBBox
                                  tag="p"
                                  className="share-wishlist-btn"
                                  onClick={(e) => {
                                    showModal(() => {
                                      return (
                                        <ShareWishlist
                                          sendEmailAction={sendEmailAction}
                                          wishlistId={selectedWishlistId}
                                          wishlists={myWishlistsArr}
                                        />
                                      );
                                    });
                                  }}
                                >
                                  Share {!isMobileOrTablet && " to email"}
                                  <Icon icon={"share_wishlist_to_email"} />
                                </MDBBox>
                              )}
                            </ModalConsumer>
                          </MDBRow>
                        </MDBCol>
                      )}
                      {(selectedWishlist[0]?.items_count > 0 ||
                        numberOfItems > 0) && (
                        <MDBCol size="7">
                          <MDBRow>
                            <MDBBox
                              className="download-pdf-wishlist-btn"
                              tag="p"
                              onClick={() => {
                                togglePdfModal();
                              }}
                            >
                              <Icon icon="download_icon" />
                              Download PDF
                            </MDBBox>
                          </MDBRow>
                        </MDBCol>
                      )}
                    </MDBRow>
                  </MDBCol>
                  {(selectedWishlist[0]?.items_count > 0 ||
                    numberOfItems > 0) && (
                    <MDBCol size="4">
                      <MDBRow className="remove-all-wrapper-wishlist">
                        <ModalConsumer value={{ useWrapper: true }}>
                          {({ showModal }) => (
                            <>
                              <MDBCol className={` remove-all-wrapper`}>
                                <MDBBox
                                  className="remove-all-wishlist-btn"
                                  tag="p"
                                  disabled={
                                    isDisabled || pending === "removeall"
                                  }
                                  onClick={(e) => {
                                    showModal(() => {
                                      return (
                                        <RemoveAllFromWishlist
                                          removeAll={removeAll}
                                          setRemoveAll={setRemoveAll}
                                          myWishlistsArr={myWishlistsArr}
                                          setPending={setPending}
                                          selectedWishlistId={
                                            selectedWishlistId
                                          }
                                          myWishlistsArr={myWishlistsArr}
                                          deleteItemsFromWishlist={
                                            deleteItemsFromWishlist
                                          }
                                        />
                                      );
                                    });
                                  }}
                                >
                                  <span>
                                    {pending === "removeall"
                                      ? "Removing..."
                                      : "Remove All"}
                                  </span>

                                  <Icon icon={"remove_all_wishlist"} />
                                </MDBBox>
                              </MDBCol>
                            </>
                          )}
                        </ModalConsumer>
                      </MDBRow>
                    </MDBCol>
                  )}
                </MDBRow>
              </MDBCol>
            ) : (
              <></>
            )}
          </MDBContainer>
        </>
      );
    }
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);
export default WishlistCore;
