import React from "react";
import { MDBBox, MDBRow, MDBCol, MDBContainer } from "mdbreact";
import { ModalConsumer } from "core/components/modal/ModalContext";
import { isEmpty } from "core/helpers/functions";
import EditWishlist from "../editWishlist";
import Icon from "app/assets/icon/icon";
import PdfModal from "../print/pdfModal";
import ProductWidget from "app/layout/productWidget";
import { Link } from "react-router-dom";

const WishlistPageListing = ({
  myWishlistsArr,
  selectedWishlistId,
  history,
  togglePdfModal,
  showPdfModal,
  attributeOptions,
}) => {
  let selectedWishlist = myWishlistsArr.filter((wishlist) => {
    return parseInt(wishlist.id) === selectedWishlistId;
  });
  if (typeof selectedWishlist[0] === "undefined") {
    selectedWishlist.push(myWishlistsArr[0]);

    history.push({
      pathname: history.location.pathname,
    });
  }
  return (
    <>
      <MDBContainer className="wishlist-page-listing-wrapper">
        <MDBRow>
          <MDBCol md="12">
            {selectedWishlist[0].name.toLowerCase() !== "my favorites" && (
              <MDBRow className="back-link-container">
                <MDBCol>
                  <Link to={"favorites/edit"}>
                    <h6 className="wow fadeInLeft">
                      <Icon icon={"small_black_arrow_left"} /> Back
                    </h6>
                  </Link>
                </MDBCol>
              </MDBRow>
            )}
            <MDBBox tag="h3" className={`wishlist-title wow slideInUp`}>
              {typeof selectedWishlist !== "undefined" &&
                typeof selectedWishlist[0] !== "undefined" && (
                  <>
                    <MDBRow
                      className={`d-flex justify-content-sm-start align-items-center justify-content-center`}
                    >
                      <MDBCol>
                        <MDBRow>
                          <MDBCol>
                            {selectedWishlist[0].name.toLowerCase() ===
                            "my favorites"
                              ? "Favorites"
                              : selectedWishlist[0].name}
                          </MDBCol>
                          {selectedWishlist[0].name.toLowerCase() !==
                            "my favorites" && (
                            <MDBCol className="edit-text">
                              <ModalConsumer value={{ useWrapper: true }}>
                                {({ showModal }) => (
                                  <p
                                    className="wishlist-btn edit-btn"
                                    onClick={(e) => {
                                      showModal(() => {
                                        return (
                                          <EditWishlist
                                            wishlist={selectedWishlist[0]}
                                          />
                                        );
                                      });
                                    }}
                                  >
                                    Edit
                                  </p>
                                )}
                              </ModalConsumer>
                            </MDBCol>
                          )}
                        </MDBRow>
                      </MDBCol>
                      <MDBCol className="items-number">
                        <p>
                          {selectedWishlist[0].items.length}
                          {selectedWishlist[0].items.length > 1
                            ? " items"
                            : " item"}
                        </p>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}
            </MDBBox>
          </MDBCol>
        </MDBRow>
        {(!isEmpty(selectedWishlist[0].items) && (
          <ProductWidget
            view={"grid"}
            parentSize="2"
            items={selectedWishlist[0].items}
            uneven={true}
            showBrand={true}
            wishlistGrid={true}
            showWishlist={false}
            activeWishlistName={selectedWishlist[0].name}
          />
        )) || (
          <MDBRow>
            <MDBCol size="12">
              <MDBBox tag="h5">
                There are no products currently in this project
              </MDBBox>
            </MDBCol>
          </MDBRow>
        )}
        <PdfModal
          selectedWishlist={selectedWishlist}
          showPdfModal={showPdfModal}
          togglePdfModal={togglePdfModal}
          attributeOptions={attributeOptions}
        />
      </MDBContainer>
    </>
  );
};
export default WishlistPageListing;
