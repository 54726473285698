import sitesInfo from "config/sites";

let host = window.location.href;
if (host.includes("localhost") || host.includes("192.168")) {
  host = "https://norwellpwa.joladev2.com";
}
// if (host.includes("localhost") || host.includes("192.168")) {
//   host = "https://ilexpwa.joladev2.com";
// }

let landingSite = Object.values(sitesInfo).find((site) => {
  if (host.trim().includes(site.siteUrl.trim())) {
    return site;
  } else return undefined;
});

if (!landingSite) {
  landingSite = sitesInfo.norwell;
}

export default {
  siteUrl: landingSite.siteUrl,
  siteCode: landingSite.siteCode,
};
