import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import Related from "./relatedProducts";
import SeoHelmet from "app/layout/seoHelmet";
import ProductInfo from "./productInfo";
import Breadcrumbs from "app/layout/breadcrumbs";
import RecentlyViewed from "./recentlyViewed";

const Product = (props) => {
  let { data, loading, currentlyViewedProduct } = props;
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  useEffect(() => {
    if (!props.loading && typeof props.data.sku !== undefined) {
      props.addRecentlyViewedProduct(currentlyViewedProduct);
    }
  }, [props.data.sku]);

  return (
    <>
      <SeoHelmet
        url={props.variables.url}
        title={data.meta_title}
        description={data.meta_description}
        image={
          typeof data.image !== "undefined" &&
          typeof data.image.url !== "undefined" &&
          data.image.url !== null
            ? data.image.url
            : ""
        }
      />
      <MDBContainer className="product-page-container">
        <MDBRow>
          <MDBCol size="12">
            {!isMobileOrTablet && (
              <MDBRow className="wow fadeInDown breadcrumbs-container">
                <Breadcrumbs />
              </MDBRow>
            )}
            <MDBRow>
              <ProductInfo {...data} loading={loading} />
            </MDBRow>
            {data.related_products && data.related_products.length > 0 && (
              <MDBRow>
                <Related {...data} />
              </MDBRow>
            )}
            <MDBRow>
              <RecentlyViewed {...data} />
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Product;
