import React, { useState, useEffect, useCallback } from "react";
import QuoteRequest from "./quoteRequest";
import { useCart } from "app/state/hooks/cartHooks/useCart";

const QuoteRequestStateContainer = (props) => {
  const [state, setState] = useState(false);
  const [, , , getCartInformation] = useCart();

  const callbackGetCartInformation = useCallback(() => {
    getCartInformation();
  }, []);

  useEffect(() => {
    callbackGetCartInformation();
  }, [callbackGetCartInformation]);

  useEffect(() => {
    setState({
      items:
        typeof props.customer.data.cart !== "undefined"
          ? props.customer.data.cart.items.map((item) => ({
              id: item.id,
              quantity: item.quantity,
              product: item.product,
              customizable_option: item.customizable_option,
            }))
          : [],
    });
  }, [props]);

  const setItems = (id) => (e) => {
    e.preventDefault();
    if (Number.parseInt(e.target.value) && e.target.value > 0) {
      setState({
        items: state.items.map((item) => ({
          id: item.id,
          quantity:
            item.id === id ? Number.parseInt(e.target.value) : item.quantity,
          product: item.product,
          customizable_option: item.customizable_option,
        })),
      });
    }
  };

  const increaseItems = (id) => {
    setState({
      items: state.items.map((item) => ({
        id: item.id,
        quantity: item.id === id ? item.quantity + 1 : item.quantity,
        product: item.product,
        customizable_option: item.customizable_option,
      })),
    });
  };

  const decreaseItems = (id) => {
    setState({
      items: state.items.map((item) => ({
        id: item.id,
        quantity:
          item.id === id && item.quantity > 0
            ? item.quantity - 1
            : item.quantity,
        product: item.product,
        customizable_option: item.customizable_option,
      })),
    });
  };
  let stateProps = {
    increaseItems,
    decreaseItems,
    setItems,
  };

  return (
    <QuoteRequest
      {...props}
      stateProps={stateProps}
      quoteRequestItems={state.items}
    />
  );
};

export default QuoteRequestStateContainer;
