import React from "react";
import GraphQlWrapper from "app/layout/graphQlWrapper";
import { useSelector } from "react-redux";
import gql from "graphql-tag";
import Header from "./header";

const CATEGORY_LIST = gql`
  query Category($id: String!) {
    categoryList(filters: { ids: { eq: $id } }) {
      id
      name
      include_in_menu
      url_path
      position
      description
      is_home
      products {
        total_count
      }
      children {
        id
        name
        image
        url_key
        url_path
        include_in_menu
        position
        description
        is_home
        thumbnail
        products {
          total_count
        }
        children {
          id
          name
          image
          url_key
          url_path
          include_in_menu
          position
          description
          is_home
          products {
            total_count
          }
        }
      }
    }
  }
`;

const HeaderQueryContainer = (props) => {
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  return (
    <GraphQlWrapper
      query={CATEGORY_LIST}
      variables={site.siteCode.includes("norwell") ? { id: "3" } : { id: "2" }}
      saveQuery={true}
    >
      <Header {...props} />
    </GraphQlWrapper>
  );
};

export default HeaderQueryContainer;
