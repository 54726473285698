// import { useDispatch } from "react-redux";
import sitesInfo from "config/sites";
import apolloClient from "core/graphql/apolloClient";

export const useWebsite = () => {
  // const dispatch = useDispatch();
  const useWebsite = async (siteCode) => {
    const siteToSwitchTo = Object.values(sitesInfo).find((site) => {
      if (site.siteCode === siteCode) return site;
      else return undefined;
    });

    if (siteToSwitchTo === undefined) {
      throw new Error("store code is not valid");
    }

    // dispatch(queriesActions.removeAllQueries(true));
    // await clearCache();
    window.location = siteToSwitchTo.siteUrl;
  };

  // const clearCache = async () => {
  //   try {
  //     apolloClient.cache.reset();
  //   } finally {
  //   }
  // };

  return {
    useWebsite,
  };
};
