import React from "react";
import CustomerSidebar from "./sidebar";
import CustomerPages from "./pages";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import { default as Pages } from "core/config/routing/pages/customerPages";
import { normalizeRoutes } from "./functions";
import Breadcrumbs from "app/layout/breadcrumbs";
import { useMediaQuery } from "react-responsive";

const Customer = (props) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  let PagesObjectMapper = normalizeRoutes(Pages);
  let hideSidebar = false;
  let isFavorites = false;

  Object.keys(PagesObjectMapper).map((value, index) => {
    let page = PagesObjectMapper[value];
    if (
      props.location.pathname === `/${page.url}` &&
      typeof page.showSidebar !== "undefined" &&
      page.showSidebar === false
    ) {
      hideSidebar = true;
      isFavorites = true;
    }
    return true;
  });

  let nameOfPage = props.currentLocation.split("/").pop();
  if (isFavorites) {
    return (
      <MDBBox className={`customer-account page-main-content favorites`}>
        <MDBBox lg={`12`}>
          <CustomerPages {...props} />
        </MDBBox>
      </MDBBox>
    );
  } else {
    return (
      <MDBContainer
        className={`customer-account page-main-content ${nameOfPage}`}
      >
        {!isMobileOrTablet && <Breadcrumbs />}
        <h3 className="page-title wow slideInDown">Account Dashboard</h3>
        <MDBRow className="customer-account-row">
          {hideSidebar === false ? (
            <MDBCol lg="3" className="sidebar-container">
              <CustomerSidebar {...props} />
            </MDBCol>
          ) : (
            <></>
          )}
          <MDBCol
            className="dashboard-container wow fadeIn"
            lg={`${hideSidebar ? "12" : "9"}`}
          >
            <CustomerPages {...props} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
};

export default Customer;
