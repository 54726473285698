import React from "react";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import { MDBRow, MDBCol } from "mdbreact";
import Input from "app/layout/input";
import Select from "app/layout/select";
import Button from "core/components/button";
import { validate, shouldMarkError } from "./contactFormValidation";
import { contactFormSubmit } from "./contactFormSubmit";
import { handleKeyDown } from "./handleKeyDown";
import { useEffect } from "react";
import useSite from "app/state/hooks/siteHooks/useSite";
import SessionMessages from "core/components/sessionMessages";

const ContactForm = (props) => {
  let {
    selectedCompany,
    setSelectedCompany,
    companyName,
    setCompanyName,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    subject,
    setSubject,
    message,
    setMessage,
    loading,
    setLoading,
    touched,
    setTouched,
    options,
    selectedCompanyValidation,
    setSelectedCompanyValidation,
    messageValidation,
    setMessageValidation,
    sendEmail,
  } = props;

  const [, getSiteCode] = useSite();
  const site = getSiteCode();
  const [lock] = useLocking();
  let errors = validate(
    companyName,
    firstName,
    lastName,
    email,
    subject,
    message
  );
  useEffect(() => {
    if (selectedCompany) {
      setSelectedCompanyValidation(true);
    }
    if (message) {
      setMessageValidation(true);
    }
  }, [selectedCompany, message]);

  return (
    <MDBRow className="contact-us-form">
      <MDBCol size="12" className="contact-us-form-title wow fadeInDown">
        <h5>Send an email</h5>
        <p>Complete the form below</p>
      </MDBCol>
      <MDBCol lg="6" className="contact-us-form-column left">
        <Select
          placeholder="Select the company you are contacting"
          options={options}
          value={selectedCompany}
          onChange={(e) => setSelectedCompany(e)}
        />
        <Input
          placeholder={`${
            shouldMarkError("companyName", errors, touched)
              ? "Company Name is required"
              : "Company Name"
          }`}
          required={true}
          type={"text"}
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
          className={
            shouldMarkError("companyName", errors, touched)
              ? "invalid"
              : "" + companyName !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, companyName: true });
          }}
          shouldMarkError={shouldMarkError("companyName", errors, touched)}
        />
        <Input
          placeholder={`${
            shouldMarkError("firstName", errors, touched)
              ? "First Name is required"
              : "First Name"
          }`}
          required={true}
          type={"text"}
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          className={
            shouldMarkError("firstName", errors, touched)
              ? "invalid"
              : "" + firstName !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, firstName: true });
          }}
          shouldMarkError={shouldMarkError("firstName", errors, touched)}
        />
        <Input
          placeholder={`${
            shouldMarkError("lastName", errors, touched)
              ? "Last Name is required"
              : "Last Name"
          }`}
          required={true}
          type={"text"}
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          className={
            shouldMarkError("lastName", errors, touched)
              ? "invalid"
              : "" + lastName !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, lastName: true });
          }}
          shouldMarkError={shouldMarkError("lastName", errors, touched)}
        />

        <Input
          placeholder={`${
            shouldMarkError("email", errors, touched)
              ? "Email is required"
              : "Email"
          }`}
          required={true}
          type={"text"}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          className={
            shouldMarkError("email", errors, touched)
              ? "invalid"
              : "" + email !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, email: true });
          }}
          shouldMarkError={shouldMarkError("email", errors, touched)}
        />
      </MDBCol>
      <MDBCol lg="6" className="contact-us-form-column right">
        <Input
          placeholder={`${
            shouldMarkError("subject", errors, touched)
              ? "Subject is required"
              : "Subject"
          }`}
          required={true}
          type={"text"}
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          className={
            shouldMarkError("subject", errors, touched)
              ? "invalid"
              : "" + subject !== "" && "valid"
          }
          onBlur={(e) => {
            setTouched({ ...touched, subject: true });
          }}
          shouldMarkError={shouldMarkError("subject", errors, touched)}
        />
        <div className="textarea-input-wrapper textarea-container">
          <Input
            type="textarea"
            placeholder={`${
              shouldMarkError("message", errors, touched)
                ? "Message is required"
                : "Message"
            }`}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            className={"textarea"}
            maxLength={250}
            onBlur={(e) => {
              setTouched({ ...touched, message: true });
            }}
            shouldMarkError={shouldMarkError("message", errors, touched)}
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                selectedCompany.value,
                companyName,
                firstName,
                lastName,
                email,
                subject,
                message,
                setTouched,
                errors,
                lock,
                setLoading,
                selectedCompanyValidation,
                setSelectedCompanyValidation,
                messageValidation,
                setMessageValidation,
                sendEmail,
                site,
                setSelectedCompany,
                setCompanyName,
                setFirstName,
                setLastName,
                setEmail,
                setSubject,
                setMessage
              )
            }
          />
          <label className="textarea-length">{message.length + "/250"}</label>
        </div>
      </MDBCol>
      <MDBCol size="12" className="wow pulse contact-us-form-button">
        <Button
          disabled={loading && true}
          onClick={(e) => {
            contactFormSubmit(
              e,
              selectedCompany.value,
              companyName,
              firstName,
              lastName,
              email,
              subject,
              message,
              setTouched,
              errors,
              lock,
              setLoading,
              selectedCompanyValidation,
              setSelectedCompanyValidation,
              messageValidation,
              setMessageValidation,
              sendEmail,
              site,
              setSelectedCompany,
              setCompanyName,
              setFirstName,
              setLastName,
              setEmail,
              setSubject,
              setMessage
            );
          }}
        >
          {loading ? "Sending..." : "Send"}
        </Button>
        <p className={"error-message " + selectedCompanyValidation}>
          Please select the Company you are contacting
        </p>
        <p className={"error-message " + messageValidation}>
          Please enter the message
        </p>
        <p className="session-message">
          <SessionMessages target="sendEmail" />
        </p>
      </MDBCol>
    </MDBRow>
  );
};

export default ContactForm;
