import React from "react";
import WhereToBuy from "./whereToBuy";
import SeoHelmet from "app/layout/seoHelmet";

const WhereToBuyDataContainer = (props) => {
  let stores = [];
  let etailers = [];
  if (props.data !== undefined) {
    props.data.getStoreLocator.stores
      .filter((store) => {
        return store.store_type === "Store";
      })
      .map((store) =>
        stores.push({
          store_id: store.store_id,
          name: store.name,
          state: store.state,
          city: store.city,
          street: store.street,
          lat: parseFloat(store.latitude),
          lng: parseFloat(store.longitude),
          store_type: store.store_type,
          website: store.website,
          showroom: store.showroom,
          phone: store.phone,
        })
      );
    props.data.getStoreLocator.stores
      .filter((store) => {
        return store.store_type !== "Store";
      })
      .map((store) =>
        etailers.push({
          store_id: store.store_id,
          name: store.name,
          state: store.state,
          city: store.city,
          street: store.street,
          lat: parseFloat(store.latitude),
          lng: parseFloat(store.longitude),
          store_type: store.store_type,
          website: store.website,
          showroom: store.showroom,
          phone: store.phone,
        })
      );
  }
  return (
    <>
      <SeoHelmet title="Where to buy" url={`/where-to-buy`} />
      <WhereToBuy {...props} stores={stores} etailers={etailers} />
    </>
  );
};

export default WhereToBuyDataContainer;
