const SET_SITE_URL = "SET_SITE_URL";
const GET_SITE_URL = "GET_SITE_URL";
const SET_SITE_CODE = "SET_SITE_CODE";
const GET_SITE_CODE = "GET_SITE_CODE";
const SET_SITE = "SET_SITE";

export default {
  SET_SITE_URL,
  GET_SITE_URL,
  SET_SITE_CODE,
  GET_SITE_CODE,
  SET_SITE,
};
