import React from "react";
import { Route, Switch } from "react-router-dom";
import globalPages, {
  DefaultPage,
  ErrorPage,
  TemporaryPage,
} from "core/config/routing/pages/globalPages";
import customerPages from "core/config/routing/pages/customerPages";
import guestPages from "core/config/routing/pages/guestPages";
import DynamicRouter from "./dynamic-content-router";
import DynamicGraphQlWrapperQueryContainer from "core/components/dynamicGraphQlWrapper";
import queryLoader from "app/config/routing/loader";
import { isUndefined } from "core/helpers/functions";
import { loadState } from "core/state/redux/localStorage";

const Router = (props) => {
  let globalProps = props;

  let state = loadState();

  if (
    !isUndefined(state) &&
    !isUndefined(state.customer) &&
    !isUndefined(state.customer.data) &&
    !isUndefined(state.customer.data.token) &&
    state.customer.data.token !== false
  ) {
    return (
      <Switch>
        <Route key={"index"} path="/" exact component={DefaultPage} />
        {Object.keys(customerPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              component={customerPages[key].component}
              exact={customerPages[key].exact}
            />
          );
        })}
        {Object.keys(globalPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              component={globalPages[key].component}
              exact={globalPages[key].exact}
            />
          );
        })}
        <Route
          path="/:url"
          render={(props) => {
            return (
              <DynamicGraphQlWrapperQueryContainer
                query={queryLoader("urlResolver")}
                queryIdentifier={"urlResolver"}
                variables={{ url: props.location.pathname.substr(1) }}
                loader={false}
                data={globalProps.data}
                loadingComponent={() => {
                  return (
                    <>
                      <TemporaryPage />
                    </>
                  );
                }}
              >
                <DynamicRouter
                  {...globalProps}
                  variables={{ url: props.location.pathname.substr(1) }}
                />
              </DynamicGraphQlWrapperQueryContainer>
            );
          }}
        />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route key={"index"} path="/" exact component={DefaultPage} />
        {Object.keys(guestPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              component={guestPages[key].component}
              exact={guestPages[key].exact}
            />
          );
        })}
        {Object.keys(globalPages).map((key) => {
          return (
            <Route
              key={key}
              path={"/" + key}
              component={globalPages[key].component}
              exact={globalPages[key].exact}
            />
          );
        })}
        <Route
          path="/:url"
          render={(props) => {
            return (
              <DynamicGraphQlWrapperQueryContainer
                query={queryLoader("urlResolver")}
                queryIdentifier={"urlResolver"}
                variables={{ url: props.location.pathname.substr(1) }}
                loader={false}
                data={globalProps.data}
                loadingComponent={() => {
                  return (
                    <>
                      <TemporaryPage />
                    </>
                  );
                }}
              >
                <DynamicRouter
                  {...globalProps}
                  variables={{ url: props.location.pathname.substr(1) }}
                />
              </DynamicGraphQlWrapperQueryContainer>
            );
          }}
        />
        {/* If you want to redirect all guests to specific url if page is not available or found */}
        {/* <Redirect key={"redirection"} to="/" component={DefaultPage} /> */}

        <Route key={"not-found"} component={ErrorPage} />
      </Switch>
    );
  }
};

export default Router;
