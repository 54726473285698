import React from "react";
import { Link } from "react-router-dom";
import { substringToUppercase } from "./function";

const Breadcrumbs = (props) => {
  let links = props.links;
  if (typeof props.links === "undefined") {
    links = props.backupLinks;
  }
  return (
    <div className={`breadcrumbs`}>
      <ul>
        <li>
          {typeof links !== "undefined" &&
            links.map((link, index) => {
              let title =
                link.title[0].toUpperCase() +
                link.title.slice(1, link.title.length) +
                " " +
                (index < links.length - 1 ? "-" + " " : "");
              title = title.replace(/\w\S*/g, function (txt) {
                return (
                  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                );
              });
              title = substringToUppercase(title, "Ada");
              title = substringToUppercase(title, "Led");

              return (
                <Link key={link.url} to={link.url}>
                  <span>{title}</span>
                </Link>
              );
            })}
        </li>
      </ul>
    </div>
  );
};

export default Breadcrumbs;
