import React from "react";
import { MDBContainer } from "mdbreact";
import ProductWidget from "app/layout/productWidget";
import ReactLoader from "app/layout/loader";
import settings from "./settings";
import useSite from "app/state/hooks/siteHooks/useSite";

const Related = (props) => {
  const [, getSiteCode] = useSite();
  return typeof props.related_products !== "undefined" ? (
    <MDBContainer className="related-products-container">
      <ProductWidget
        view={"slider"}
        settings={settings(props)}
        items={props.related_products}
        title={"Related Products"}
        subtitle={"SEE ALSO"}
        showBrand={false}
      />
    </MDBContainer>
  ) : (
    <ReactLoader
      color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
      width={50}
      height={50}
      type={`ThreeDots`}
      key={"loader-items"}
    />
  );
};

export default Related;
