import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import InvQuo from "./invQuo";

const InvQuoReduxContainer = (props) => {
  return <InvQuo {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart:
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cartToken !== "undefined"
      ? state.customer.data
      : undefined,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvQuoReduxContainer);
