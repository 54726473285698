import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import ReactLoader from "app/layout/loader";
import ContentDataContainer from "./content-data-container";
import useSite from "app/state/hooks/siteHooks/useSite";

const REP_LOCATORS_WITH_FILTERS = gql`
  query getRepLocators($filters: RepLocatorFilterInput) {
    getRepLocators(filter: $filters) {
      country
      state
      industry
      fax
      firstname
      lastname
      phone
      email
      country
      state
      image
      industry
      position
      web_url
      show_in_grid
    }
  }
`;

const ContentQueryContainer = (props) => {
  let variables = "";
  const [, getSiteCode] = useSite();

  if (props.industrySelect.value === "All")
    variables = {
      state: props.selectState.value,
      country: props.countrySelect.value,
    };
  else
    variables = {
      industry: props.industrySelect.value,
      state: props.selectState.value,
      country: props.countrySelect.value,
    };
  const { data, loading } = useQuery(REP_LOCATORS_WITH_FILTERS, {
    variables: {
      filters: variables,
    },
    fetchPolicy: "cache-first",
  });

  if (loading)
    return (
      <>
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
          key={"loader-items"}
        />
      </>
    );
  return <ContentDataContainer data={data} />;
};

export default ContentQueryContainer;
