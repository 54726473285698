import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import PressDataContainer from "./press-data-container";

const GALLERY = gql`
  query {
    getAllGalleryData {
      gallery_id
      name
      on_home
      image
      description
      homepage_image
      gallery_items {
        name
        image
      }
      category {
        name
      }
    }
  }
`;

const PressQueryContainer = () => {
  return (
    <GraphQlWrapper query={GALLERY} variables={{ id: "gallery" }}>
      <PressDataContainer />
    </GraphQlWrapper>
  );
};

export default PressQueryContainer;
