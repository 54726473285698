import React from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import Slider from "react-slick";
import SliderItem from "./whatsNewSliderItem";
import sliderSettings from "./whatsNewSliderSettings";
import useSite from "app/state/hooks/siteHooks/useSite";
import LazyLoad from "react-lazyload";

const WhatsNewSlider = (props) => {
  const [, getSiteCode] = useSite();
  const site = getSiteCode();
  const settings = sliderSettings(props.dots, props.data.getWhatsNew);

  return (
    <MDBContainer className="whats-new-slider-container">
      {props && props.data && props.data.getWhatsNew.length > 0 && (
        <MDBRow className="slider-title">
          <p className="wow fadeInDown">
            <span className="subtitle-line"></span>
            {props.subtitle}
          </p>
          <h1 className="wow fadeInUp">
            {site === "ilex_default" ? <span> What's </span> : "What's "}{" "}
            {props.title}
          </h1>
        </MDBRow>
      )}

      <Slider {...settings} className="whats-new-slider">
        {props &&
          props.data &&
          props.data.getWhatsNew.map((product, index) => {
            return (
              <LazyLoad height="100%" offset={0}>
                <SliderItem
                  key={`slider-slide-item-${index}`}
                  product={product}
                  theme={props.theme}
                />
              </LazyLoad>
            );
          })}
      </Slider>
    </MDBContainer>
  );
};

export default WhatsNewSlider;
