import React from "react";
import { connect } from "react-redux";
import customerActions from "core/state/redux/data/customer/actions";
import SidebarItem from "./sidebarItem";
import { isUndefined } from "core/helpers/functions";

const SidebarItemReduxContainer = (props) => {
  if (isUndefined(props.data)) return <></>;
  return <SidebarItem {...props} />;
};

const mapStateToProps = (state) => ({
  data: state.filters.data,
  category: state.filters.category,
  filters: state.filters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(customerActions.unlock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarItemReduxContainer);
