import React, { memo, useState } from "react";
import { MDBCol, MDBRow, MDBBtn } from "mdbreact";
import CmsBlock from "app/layout/cmsBlock";
import SiteSwitcher from "app/layout/siteSwitcher";
import SocialMedia from "../footerBottom/socialMedia";
import Icon from "app/assets/icon/icon";
import Subscribe from "app/pages/customer/subscribe";

const FooterLeftColumn = memo((props) => {
  const site = props.site.siteCode;
  const [toggleSubscribeModal, setToggleSubscribeModal] = useState(false);
  return (
    <MDBCol
      lg="6"
      className={typeof site !== "undefined" && site + " footer_left_column"}
    >
      <SiteSwitcher switcherLocation="footer" />

      <CmsBlock id="footer_block_1" dangHtml={true} />
      <MDBRow className="buttons-wrapper">
        <MDBBtn
          className="with-arrow"
          onClick={() => setToggleSubscribeModal(!toggleSubscribeModal)}
        >
          <Icon icon="right_arrow_white" />
          Subscribe
        </MDBBtn>

        <SocialMedia />
      </MDBRow>

      <Subscribe
        toggleModal={toggleSubscribeModal}
        setToggleModal={setToggleSubscribeModal}
      />
    </MDBCol>
  );
});

export default FooterLeftColumn;
