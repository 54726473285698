import React from "react";
import { connect } from "react-redux";
import BannerSliderQueryContainer from "./bannerSlider-query-container";

const BannerSliderReduxContainer = (props) => {
  return <BannerSliderQueryContainer {...props} />;
};

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, null)(BannerSliderReduxContainer);
