import React from "react";
import { MDBRow, MDBTable, MDBTableBody } from "mdbreact";

const InventoryMobile = ({ currentPosts }) => {
  function calculatedDate(item, type) {
    let returnValue = "/";

    if (item.options) {
      returnValue = '';
      item.options.forEach(option => {
        option.value.forEach(val => {
          if (!val[type]) {
            val[type] = '/';
          }
          else {
            val[type] = new Date(val[type]);
            val[type] = val[type].toLocaleString("default", {month: "long"}) + " " + val[type].getDate() + ", " + val[type].getFullYear()
          }
          returnValue = returnValue + val[type] + "\n";
        })
      })
    }

    return returnValue;
  }

  return (
    <MDBRow className="inventory-mobile-page-wrapper">
      {currentPosts.length === 0 ? (
        <p> There are currently no results for this search.</p>
      ) : (
        <MDBTable>
          <MDBTableBody>
            {currentPosts.map((item) => {
              return (
                <>
                  <div className="mobile-inventory-wrapper-item">
                    <tr key={`inventory-data-${item.sku}-${item.id}`}>
                      <th className="model-no">
                        <p>Model No.</p>
                        <p>{item.sku}</p>
                      </th>
                    </tr>
                    <tr>
                      <th className="item-inventory-mobile-description">
                        <p>Description</p>
                        <p dangerouslySetInnerHTML={{__html: item.description.html}} />
                      </th>
                    </tr>
                    {/*<tr>*/}
                    {/*  <th className="item-inventory-mobile">*/}
                    {/*    <p>Next rec. date</p>*/}
                    {/*    <p>*/}
                    {/*      {calculatedDate(item, 'next_rec_date')}*/}
                    {/*    </p>*/}
                    {/*  </th>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*  <th className="item-inventory-mobile">*/}
                    {/*    <p>Discontinued</p>*/}
                    {/*    <p>*/}
                    {/*      {calculatedDate(item, 'discontinued')}*/}
                    {/*    </p>*/}
                    {/*  </th>*/}
                    {/*</tr>*/}
                  </div>
                </>
              );
            })}
          </MDBTableBody>
        </MDBTable>
      )}
    </MDBRow>
  );
};
export default InventoryMobile;
