import React from "react";
import { connect } from "react-redux";
import commonActions from "app/state/redux/data/common/actions";
import GraphQlQueryWrapper from "./graphQlWrapper-state-contanier";
import { pendingTask, begin } from "react-redux-spinner";
import { default as queryActionTypes } from "app/state/redux/data/queries/types";
import { default as graphQlConfig } from "config/graphQlConfig";
import store from "core/state/redux/store";
import { isUndefined, isEmpty } from "core/helpers/functions";
import { customerIsLoggedIn } from "core/helpers/customer";
import { isQueryDataCached, isQueryInTemp } from "core/helpers/queries";
const ADD_QUERY = queryActionTypes.ADD_QUERY;
const REMOVE_QUERY = queryActionTypes.REMOVE_QUERY;

const GraphQlReduxContainer = (props) => {
  let token = false;
  let data = undefined;
  let saveQuery = true;
  let temp = undefined;
  let loading = true;
  // let identifier = null;

  if (isUndefined(props.saveQuery)) saveQuery = true;

  token = customerIsLoggedIn(props.customer);

  if (isUndefined(props.variables)) {
    loading = !(!isUndefined(props.data) && !props.data[props.variables.id]);
  }

  data = isQueryDataCached(props);

  if (data) loading = false;

  if (isEmpty(data)) {
    temp = isQueryInTemp(props);
  }

  if (isEmpty(data)) {
    if (isEmpty(temp)) {
      loading = true;
    }
  }

  return (
    <GraphQlQueryWrapper
      {...props}
      token={token}
      store={store}
      loading={loading}
      data={data}
      finalChildren={props.children}
      saveQuery={saveQuery}
      thisTemp={temp}
    />
  );
};

const mapStateToProps = (state) => ({
  thisState: state.customer,
  customer: state.customer.data,
  modal: state.customer.modal,
  timestamp: state.common.timestamp,
  data: state.queries.data,
  temp: state.queries.temp,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addQuery: (
      id,
      url,
      query,
      variables = [],
      type = graphQlConfig.types["query"]
    ) => {
      dispatch({
        type: ADD_QUERY,
        [pendingTask]: begin,
        id: id,
        query: query,
        variables: variables,
        queryType: type,
      }).then(function (response) {});

      return true;
    },
    removeQuery: (id) => {
      dispatch({
        type: REMOVE_QUERY,
        [pendingTask]: begin,
        id: id,
      });
    },
    getCacheVersion: () => {
      dispatch(commonActions.getCacheVersion());
    },
    closeAllModals: () => {
      dispatch(commonActions.closeAllModals());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GraphQlReduxContainer);
