import React from "react";
import { Link } from "react-router-dom";
import Icon from "app/assets/icon/icon";
import uuid from "react-uuid";

const MenuMobile = (props) => {
  let {
    menuItems,
    hoveredDropdown,
    setHoveredDropdown,
    showDropdown,
    setShowDropdown,
    setOpen,
  } = props;

  return (
    <div className="header-menu">
      {Object.keys(menuItems).map((element, index) => {
        let currentMenuItem = menuItems[index];

        if (menuItems[index]?.include_in_menu === 0) {
          return <></>;
        }

        return (
          <div key={uuid()} className="mobile-menu-item">
            <div className="mobile-menu-item-main">
              {showDropdown && hoveredDropdown === index ? (
                <Icon icon="mobile_white_arrow" />
              ) : (
                <></>
              )}
              {currentMenuItem.children !== undefined &&
              currentMenuItem.children.length !== 0 ? (
                <div
                  onClick={() => {
                    if (hoveredDropdown === index) {
                      setShowDropdown(!showDropdown);
                    } else {
                      setHoveredDropdown(index);
                      setShowDropdown(true);
                    }
                  }}
                >
                  {currentMenuItem.name.toUpperCase()}
                </div>
              ) : (
                <Link
                  to={`/${currentMenuItem.url_path}`}
                  onClick={() => setOpen(false)}
                >
                  {currentMenuItem.name.toUpperCase()}
                </Link>
              )}
            </div>
            {showDropdown && hoveredDropdown === index && (
              <div className="mobile-menu-item-submenu">
                {currentMenuItem.children.map((child) => {
                  let newUrl = child.url_path;
                  if (typeof child.id === "undefined")
                    newUrl = newUrl.substr(0, newUrl.indexOf("/"));
                  return (
                    <Link to={`/${newUrl}`} onClick={() => setOpen(false)}>
                      {child.name}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MenuMobile;
