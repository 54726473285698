import React, { memo } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import _ from "lodash";
import Icon from "app/assets/icon/icon";
import ProductWidget from "app/layout/productWidget";
import { sliderSettings } from "./settings";
import ReactLoader from "app/layout/loader";
import Button from "core/components/button";
import useSite from "app/state/hooks/siteHooks/useSite";

const SearchResults = memo(
  ({ data, loading, setDisplaySearch, tmpSearch }) => {
    let searchResults = data.products;
    let { items } = searchResults;
    const [, getSiteCode] = useSite();

    if (loading) {
      return (
        <MDBContainer
          fluid
          id={`search-results-container`}
          className={`no-results text-center align-items-center justify-content-center`}
        >
          <MDBRow className={`d-flex w-100 flex-column`}>
            <MDBCol>
              <Icon icon={`ducducLogoWoLetters`} />
            </MDBCol>
            <MDBCol className={`align-items-center justify-content-center`}>
              <ReactLoader
                color={
                  getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"
                }
                width={50}
                height={50}
                type={`ThreeDots`}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    }

    if (items.length === 0 && !loading)
      return (
        <MDBContainer
          fluid
          id={`search-results-container`}
          className={`no-results text-center`}
        >
          <MDBRow className={`w-100`}>
            <MDBCol className="no-results-found-search">
              <p>No Results Found</p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );

    return (
      <MDBContainer fluid id={`search-results-container`}>
        <MDBRow className={`w-100 search-widget-wrapper`}>
          <MDBCol
            className={`search-product-widget ${
              items.length === 1
                ? "one"
                : items.length === 2
                ? "two"
                : items.length === 3
                ? "three"
                : ""
            }`}
          >
            <ProductWidget
              view={"grid"}
              parentSize="2"
              items={items}
              onClick={(e) => {
                setDisplaySearch(false);
              }}
              labels={false}
              showBrand={true}
              showWishlist={false}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className={"w-100"}>
          <MDBCol className={`text-center`}>
            <Button
              to={`/search-results?search=` + encodeURI(tmpSearch)}
              onClick={(e) => {
                setDisplaySearch(false);
              }}
              className={"view-all with-arrow"}
            >
              See all results
              <Icon icon={"right_arrow_white"} />
            </Button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default SearchResults;
