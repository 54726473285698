import { isEmpty } from "core/helpers/functions";
import history from "core/state/redux/history";

function transformToAssocArray(prmstr) {
  let params = {};
  let prmarr = prmstr.split("&");
  for (let i = 0; i < prmarr.length; i++) {
    let tmparr = prmarr[i].split("=");
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export const getSearchParameters = () => {
  let prmstr = window.location.search.substr(1);
  return prmstr !== null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
};

export const revertFromAssocArray = (prmarray) => {
  let blkstr = [];

  typeof prmarray === "object"
    ? Object.keys(prmarray).map((item, index) => {
        let value = prmarray[item];
        if (value !== "") {
          let str = `${item}=${value}`;
          blkstr.push(str);
        }
      })
    : prmarray.map((value, index) => {
        let str = `${index}=${value}`;
        blkstr.push(str);
      });
  return blkstr;
};

export const removeValueFromArray = (array, value) => {
  let filteredArray = Object.keys(array).reduce((accumulator, currentKey) => {
    let currentValue = array[currentKey];
    if (currentValue === null || currentValue === undefined) return accumulator;
    let newCurrentValue = currentValue
      .split(",")
      .filter((item) => {
        return item.toString() !== value.toString();
      })
      .join(",");
    if (newCurrentValue && newCurrentValue.length > 0) {
      return { ...accumulator, [currentKey]: newCurrentValue };
    }
    return accumulator;
  }, {});
  return filteredArray;
};

export const sortObject = (object) =>
  Object.keys(object)
    .sort()
    .reduce(
      (previousValue, currentValue) => (
        (previousValue[currentValue] = object[currentValue]), previousValue
      ),
      {}
    );

export const pushSortValueToURL = (searchParameters, sortLabel) => {
  let currentSearchUpdatedValues = [];
  let searchTerm = undefined;
  let searchValue = [];

  const sortAttributeFound =
    typeof searchParameters["sort"] !== "undefined" ? true : false;

  if (sortAttributeFound) {
    searchParameters["sort"] = sortLabel;
  } else {
    searchValue.push(sortLabel);
    currentSearchUpdatedValues["sort"] = searchValue;
  }

  Object.keys(searchParameters).map((item) => {
    if (item !== "") {
      const split =
        typeof searchParameters[item] !== "undefined"
          ? searchParameters[item].split(/,/g)
          : false;
      if (searchParameters[item] !== false && searchParameters[item] !== "") {
        currentSearchUpdatedValues[item] = split;
      }
    }
  });

  if (currentSearchUpdatedValues) {
    const newSort = sortObject(currentSearchUpdatedValues);
    searchTerm = revertFromAssocArray(newSort).join("&");
  }

  history.push({
    pathname: `${history.location.pathname}`,
    search: `${!isEmpty(searchTerm) ? searchTerm : ""}`,
  });
};

export const pushFilterValueToURL = (
  searchParameters,
  filter,
  filterOption,
  isSwitch,
  optionValue
) => {
  let value;

  const { attribute_code } = filter;

  if (isSwitch) {
    if (
      typeof optionValue !== "undefined" &&
      filter.attribute_code === "brand"
    ) {
      value = optionValue;
    } else {
      value = 1;
    }
  } else {
    value = filterOption.value;
  }

  let currentSearchUpdatedValues = [];
  let searchTerm = undefined;

  const attributeIsSearched =
    typeof searchParameters[attribute_code] !== "undefined" ? true : false;

  const search = attributeIsSearched ? searchParameters[attribute_code] : false;

  let searchValues = search ? search.split(/, /g) : false;
  const valueAlreadySearched = searchValues
    ? searchValues.includes(value)
    : [false];

  if (attributeIsSearched) {
    if (search) {
      if (valueAlreadySearched) {
        let foundIndex = searchValues.indexOf(value);
        searchValues.splice(foundIndex, 1);
      } else {
        searchValues.push(filterOption.value);
      }

      if (!isEmpty(searchValues)) {
        currentSearchUpdatedValues[attribute_code] = searchValues;
      }
    }
  } else {
    if (searchValues === false) {
      searchValues = [];
    }

    searchValues.push(value);

    currentSearchUpdatedValues[attribute_code] = searchValues;
  }

  Object.keys(searchParameters).map((item) => {
    if (item !== attribute_code) {
      const split =
        typeof searchParameters[item] !== "undefined"
          ? searchParameters[item].split(/,/g)
          : false;
      if (searchParameters[item] !== false && searchParameters[item] !== "") {
        currentSearchUpdatedValues[item] = split;
      }
    }
  });

  if (currentSearchUpdatedValues) {
    const newSort = sortObject(currentSearchUpdatedValues);
    searchTerm = revertFromAssocArray(newSort).join("&");
  }

  history.push({
    pathname: `${history.location.pathname}`,
    search: `${
      !isEmpty(searchTerm) && searchTerm !== attribute_code + "="
        ? searchTerm
        : ""
    }`,
  });
};

export const clearAllFilters = () => {
  history.push({
    pathname: `${history.location.pathname}`,
    search: ``,
  });
};

export const removeFilterValueFromURL = (
  searchParameters,
  filter,
  filterOption,
  isSwitch,
  optionValue
) => {
  let value;
  let newSearchParameters;
  let attribute_code = filter?.hasOwnProperty("attribute_code")
    ? filter?.attribute_code
    : filter.value;

  if (isSwitch) {
    if (
      filter.attribute_code === "brand" &&
      typeof optionValue !== "undefined"
    ) {
      value = optionValue;
    } else {
      value = 1;
    }
  } else {
    value = filterOption.hasOwnProperty("value")
      ? filterOption.value
      : filterOption;
  }

  newSearchParameters = removeValueFromArray(
    searchParameters,
    value,
    attribute_code
  );

  let finalSearchParams = "";

  if (newSearchParameters) {
    finalSearchParams = Object.keys(newSearchParameters)
      .map((key) => `${key}=${newSearchParameters[key]}`)
      .join("&");
  }

  history.push({
    pathname: `${history.location.pathname}`,
    search: `${finalSearchParams}`,
  });
};
