import React, { useState } from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import QuoteRequestStateContainer from "./quoteRequest-state-container";
import SeoHelmet from "app/layout/seoHelmet";
import { Redirect } from "react-router-dom";
import { messagesActions } from "core/state/redux/data/messages";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import useSite from "app/state/hooks/siteHooks/useSite";

const QuoteRequestReduxContainer = (props) => {
  const [getLoggedIn] = useCustomer();
  const [, getSiteCode] = useSite();
  const [quoteStatus, setQuoteStatus] = useState(false);
  if (getLoggedIn() && getSiteCode() === "ilex_default") {
    if (quoteStatus) {
      return <Redirect to="/quote-success" />;
    } else {
      return (
        <>
          <SeoHelmet title="Quote Request" url={`/quote-request`} />
          <QuoteRequestStateContainer
            {...props}
            quoteStatus={quoteStatus}
            setQuoteStatus={setQuoteStatus}
          />
        </>
      );
    }
  } else {
    return <Redirect to={"/"} />;
  }
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  cart:
    typeof state.customer.data !== "undefined" &&
    typeof state.customer.data.cartToken !== "undefined"
      ? state.customer.data
      : undefined,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
    addMessage: (message, type) => {
      dispatch(messagesActions.addMessage(message, type));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestReduxContainer);
