import React, { memo, useEffect, useState } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import { Link } from "react-router-dom";
import Icon from "app/assets/icon/icon";
import _ from "lodash";
import Wishlist from "app/layout/wishlist";
import Input from "app/layout/input";
import Button from "core/components/button";
import useSite from "app/state/hooks/siteHooks/useSite";
import ReactLoader from "app/layout/loader/loader";

const QuoteRequestItem = memo(
  (props) => {
    let {
      item,
      stateProps,
      quantity,
      setQuantity,
      removeItem,
      updateCart,
      productsAndComments,
      index,
      loading,
      setLoading,
    } = props;
    const [, getSiteCode] = useSite();
    useEffect(() => {
      if (item.quantity !== quantity) {
        setQuantity(item.quantity);
      }
    }, [item, quantity, setQuantity]);
    const [comment, setComment] = useState("");

    useEffect(() => {
      productsAndComments[index] = {
        ...productsAndComments[index],
        value: comment,
      };
    }, [comment]);
    return (
      <MDBRow size="12" className="quote-request-items">
        <MDBCol lg="7" className="product-container">
          <MDBRow className="product-container-row">
            <MDBCol lg="6" className="image-container">
              <div className="wishlist-container">
                <Wishlist product={{ ...item.product }}>
                  <Icon icon="wishlist_empty_heart" />
                </Wishlist>
              </div>
              <Link to={item && item.product && item.product.url_key}>
                <img
                  src={
                    item &&
                    item.product &&
                    item.product.small_image &&
                    item.product.small_image.url
                      ? item.product.small_image.url
                      : "https://www.lankwaifong.com/uploads/images/Article/SummerJourney/200x200.png"
                  }
                  alt="item-image"
                />
              </Link>
            </MDBCol>
            <MDBCol lg="6" className="product-info">
              <div>
                <h5>
                  {item &&
                    item.product &&
                    item.product.name &&
                    item.product.name}
                </h5>
                <p>
                  SKU:{" "}
                  {item && item.product && item.product.sku && item.product.sku}
                </p>
              </div>
              <div className="textarea-container">
                <Input
                  type={"textarea"}
                  className="textarea"
                  placeholder="Comments"
                  value={comment}
                  onChange={(event) => setComment(event.target.value)}
                  maxLength={250}
                />
                <label className="textarea-length">
                  {comment.length + "/250"}
                </label>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol lg="5" className="quote-request-item-functions">
          <MDBRow className="functions-wrapper">
            <MDBCol size="8" className="quantity-container">
              <MDBRow className="quantity-buttons">
                <MDBCol size="3">
                  <Button
                    onClick={() => {
                      stateProps.decreaseItems(item.id);
                      setLoading(true);
                      props.lock({
                        always: () => {},
                        success: () => {
                          setLoading(false);
                        },
                        fail: () => {
                          setLoading(false);
                        },
                      });
                      updateCart([
                        {
                          cart_item_id: item.id,
                          customizable_options: [],
                          quantity: item.quantity - 1,
                        },
                      ]);
                    }}
                  >
                    -
                  </Button>
                </MDBCol>
                {loading ? (
                  <MDBCol size="6" className="quantity-value">
                    <ReactLoader
                      color={
                        getSiteCode() === "norwell_default"
                          ? "#B9571B"
                          : "#c1263b"
                      }
                      width={20}
                      height={20}
                      type={`ThreeDots`}
                    />
                  </MDBCol>
                ) : (
                  <MDBCol size="6" className="quantity-value">
                    {item && item.quantity && item.quantity < 10
                      ? "0" + item.quantity
                      : item.quantity}
                  </MDBCol>
                )}

                <MDBCol size="3">
                  <Button
                    onClick={() => {
                      stateProps.increaseItems(item.id);
                      setLoading(true);
                      props.lock({
                        always: () => {},
                        success: () => {
                          setLoading(false);
                        },
                        fail: () => {
                          setLoading(false);
                        },
                      });
                      updateCart([
                        {
                          cart_item_id: item.id,
                          customizable_options: [],
                          quantity: item.quantity + 1,
                        },
                      ]);
                    }}
                  >
                    +
                  </Button>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol size="4" className="remove-item-container">
              <Icon
                onClick={() => {
                  removeItem(item.id);
                }}
                icon={"remove_item_icon"}
              />
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    );
  },
  (prevProps, nextProps) => {
    if (_.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default QuoteRequestItem;
