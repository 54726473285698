import React from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBCol, MDBRow } from "mdbreact";
import Icon from "app/assets/icon/icon";
import Button from "core/components/button";

const WebsiteContactInfo = () => {
  return (
    <MDBCol lg="3" className="website-contact-info">
      <MDBRow>
        <MDBCol size="12">
          <Button to="/find-a-rep/shop-online" className="with-arrow">
            Shop online
            <Icon icon="right_arrow_white" />
          </Button>
        </MDBCol>
        <MDBCol className="website-info" size="12">
          <Icon icon={"dark_logo_norwell"} />
          <div className="phone-wrapper">
            <Icon icon={"phone_icon_ilex"} />
            <div className="phone-info">
              <h6>Phone</h6>
              <CmsBlock id="norwell_contact_information" dangHtml={true} />
            </div>
          </div>
        </MDBCol>
        <MDBCol className="website-info ilex_default" size="12">
          <Icon icon={"dark_logo_ilex"} />
          <div className="phone-wrapper">
            <Icon icon={"phone_icon_ilex"} />
            <div className="phone-info">
              <h6>Phone</h6>
              <CmsBlock id="norwell_contact_information" dangHtml={true} />
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBCol>
  );
};

export default WebsiteContactInfo;
