import React, { useState } from "react";
import WishlistCore from "./wishlistCore";
import { isNaN } from "lodash";

const WishlistCoreStateContainer = ({ history, page, ...props }) => {
  const [activeWishlistName, setActiveWishlistName] = useState("wishlist");
  const [project, setProject] = useState("");
  const [currentSelectedProject, setCurrentSelectedProject] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [, setSelectedWishlist] = useState(0);
  const [pending, setPending] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  let project_id = parseInt(urlParams.get("project_id"));
  if (typeof page === "undefined") {
    page = window.location;
  }
  let wishlistNumberOfItems = props.wishlistNumberOfItems;
  let sendEmailAction = props.sendEmail;

  return (
    <WishlistCore
      sendEmailAction={sendEmailAction}
      wishlistNumberOfItems={wishlistNumberOfItems}
      activeWishlistName={activeWishlistName}
      setActiveWishlistName={setActiveWishlistName}
      project={project}
      setProject={setProject}
      currentSelectedProject={currentSelectedProject}
      setCurrentSelectedProject={setCurrentSelectedProject}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      selectedWishlistId={isNaN(project_id) ? 0 : project_id}
      setSelectedWishlist={setSelectedWishlist}
      pending={pending}
      setPending={setPending}
      history={history}
      page={page}
    />
  );
};

export default WishlistCoreStateContainer;
