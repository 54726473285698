import gql from "graphql-tag";
export const PRODUCT = gql`
  query Product($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      total_count
      items {
        id
        name
        sku
        arriving_date
        url_key
        is_new
        pdf_file
        revit_files
        quote_request
        standards_for_filtering
        finishes_count
        meta_title
        product_options_info
        specification {
          finishes
          finish_premium
          finish_optional
          height
          width
          length
          tto
          projection
          ada
          canopy_size
          backplate_height
          backplate_width
          backplate_diameter
          backplate_depth
          mounting_standard
          mounting_options
          overall_height
          body_height
          overall_diameter
          bowl_height
          diffuser
          diffuser_optional
          glass
          glass_size
          shade
          shade_size
          acrylic
          acrylic_options
          lamping_standard
          lamping_integrated_led
          lamping_fluorescent
          lamping_halogen
          lamping_options
          material
          add_prod_info
          uletl_listed
          ul_etl_listed_damp_location_ra
          ul_etl_listed_wet_location_rat
        }
        description {
          html
        }
        short_description {
          html
        }
        media_gallery {
          position
          label
          url
          disabled
        }
        image {
          label
          url
          disabled
        }
        thumbnail {
          label
          url
        }
        categories {
          name
          id
        }
        related_products {
          name
          sku
          id
          finishes_count
          url_key
          is_new
          image {
            url
            label
          }
          categories {
            canonical_url
            name
          }
        }
        ... on CustomizableProductInterface {
          options {
            title
            option_id
            ... on CustomizableDropDownOption {
              title
              value {
                title
                image
                option_type_id
              }
            }
          }
        }
      }
    }
  }
`;
