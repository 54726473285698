import React, { useState } from "react";
import Button from "core/components/button";
import { validate, shouldMarkError } from "./validation";
import { MDBContainer } from "mdbreact";
import Input from "app/layout/input";
import MaskedInput from "react-text-mask";
import Icon from "app/assets/icon/icon";
import SessionMessages from "core/components/sessionMessages";

const CustomForm = (props) => {
  let {
    firstName,
    lastName,
    email,
    phone,
    typeOfProject,
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setTypeOfProject,
  } = props;

  let errors = validate(firstName, lastName, email, phone, typeOfProject);
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    description: false,
    typeOfProject: false,
  });

  const [loading, setLoading] = useState(false);

  const _handleSendEmail = (e) => {
    let isTrue = true;
    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
      typeOfProject: true,
    });

    Object.keys(errors).map((item) => {
      let error = errors[item];
      if (isTrue) {
        if (error === true) {
          isTrue = false;
        }
      }
    });
    if (isTrue) {
      setLoading(true);
      props.lock({
        always: (e) => {},
        success: (e) => {
          setLoading(false);
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setTypeOfProject("");
          setTouched({
            firstName: false,
            lastName: false,
            email: false,
            phone: false,
            typeOfProject: false,
          });
        },
        fail: (e) => {
          setLoading(false);
        },
      });
      props.sendEmail({
        input: {
          attributes: [
            { code: "email", value: email },
            { code: "firstName", value: firstName },
            { code: "lastName", value: lastName },
            { code: "phone", value: phone },
            { code: "typeOfProject", value: typeOfProject },
          ],
          template: "14",
          is_quote: false,
          return_form: false,
        },
      });
    } else {
      props.addMessage("Fail to send email", "danger");
    }
  };

  return (
    <MDBContainer className="custom-page-form-wrapper">
      <MDBContainer className="custom-page-form-title-wrapper">
        <h4>Request a Quote</h4>
        <p>Tell us about your project</p>
      </MDBContainer>
      <Input
        placeholder={`${
          shouldMarkError("firstName", errors, touched)
            ? "First Name is required"
            : "First Name"
        }`}
        required={true}
        type={"text"}
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        className={
          shouldMarkError("firstName", errors, touched)
            ? "invalid"
            : "" + firstName !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, firstName: true });
        }}
        shouldMarkError={shouldMarkError("firstName", errors, touched)}
      />

      <Input
        placeholder={`${
          shouldMarkError("lastName", errors, touched)
            ? "Last Name is required"
            : "Last Name"
        }`}
        required={true}
        type={"text"}
        value={lastName}
        onChange={(event) => setLastName(event.target.value)}
        className={
          shouldMarkError("lastName", errors, touched)
            ? "invalid"
            : "" + lastName !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, lastName: true });
        }}
        shouldMarkError={shouldMarkError("lastName", errors, touched)}
      />

      <Input
        placeholder={`${
          shouldMarkError("email", errors, touched)
            ? "Email is required"
            : "Email"
        }`}
        required={true}
        type={"text"}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        className={
          shouldMarkError("email", errors, touched)
            ? "invalid"
            : "" + email !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, email: true });
        }}
        shouldMarkError={shouldMarkError("email", errors, touched)}
      />
      <div className="masked-input">
        <MaskedInput
          type={"text"}
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={phone !== undefined ? phone : ""}
          guide={false}
          onChange={(event) => setPhone(event.target.value)}
          onBlur={(e) => {
            setTouched({ ...touched, phone: true });
          }}
          className={
            shouldMarkError("phone", errors, touched)
              ? "invalid"
              : "" + phone !== "" && "valid"
          }
          placeholder={`${
            shouldMarkError("phone", errors, touched)
              ? "Phone Number is required"
              : "Phone Number"
          }`}
        />
        <label className="required-label">
          {typeof shouldMarkError("phone", errors, touched) === "undefined" ? (
            "Required"
          ) : shouldMarkError("phone", errors, touched) ? (
            <Icon icon="error_icon" />
          ) : phone === "" ? (
            "Required"
          ) : (
            <Icon icon="check_mark_icon_black" />
          )}
        </label>
      </div>
      <Input
        placeholder={`${
          shouldMarkError("typeOfProject", errors, touched)
            ? "Type of project is required"
            : "Type of project"
        }`}
        required={true}
        type={"text"}
        value={typeOfProject}
        onChange={(event) => setTypeOfProject(event.target.value)}
        className={
          shouldMarkError("typeOfProject", errors, touched)
            ? "invalid"
            : "" + typeOfProject !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, typeOfProject: true });
        }}
        shouldMarkError={shouldMarkError("typeOfProject", errors, touched)}
      />

      <Button
        disabled={loading && true}
        className={"custom-page-submit-btn"}
        onClick={() => _handleSendEmail()}
      >
        {loading ? "Processing..." : "Submit"}
      </Button>
      <p className="session-message">
        <SessionMessages target="sendEmail" />
      </p>
    </MDBContainer>
  );
};

export default CustomForm;
