import React from "react";
import { connect } from "react-redux";
import { filterActions } from "core/state/redux/data/filters";
import Sidebar from "./sidebar";

const SidebarReduxContainer = (props) => {
  return (
    <Sidebar
      toggleFilter={props.toggleFilter}
      setToggleFilter={props.setToggleFilter}
      addFilter={props.addFilter}
      allCategories={props.allCategories}
      clearFilters={props.clearFilters}
      hideFilters={props.hideFilters}
      removeFilter={props.removeFilter}
      selected_category={props.category_id}
      variables={props.variables}
      resetPageNumber={props.resetPageNumber}
      filters={props.filters}
      dataLoading={props.dataLoading}
      toggleFilters={props.toggleFilters}
      setToggleFilters={props.setToggleFilters}
      showBrand={props.showBrand}
    />
  );
};

const mapStateToProps = (state) => ({
  filters: state.filters,
  filtered_category: state.filters.data,
  locked: state.common.locked,
});

const mapDispatchToProps = (dispatch) => {
  return {
    addFilter: (filter) => {
      dispatch(filterActions.addFilter(filter));
    },
    removeFilter: (filter) => {
      dispatch(filterActions.removeFilter(filter));
    },
    clearFilters: () => {
      dispatch(filterActions.clearFilters());
    },
    resetPageNumber: () => {
      dispatch(filterActions.changePageNumber(1));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarReduxContainer);
