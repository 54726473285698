import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

export default () => {
  return {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    lazyLoad: "progressive",
    customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
    appendDots: (dots) => (
      <MDBRow
        id="slider-indicators-container"
        style={{
          padding: "15px",
          textAlign: "center",
        }}
      >
        <MDBCol className="indicators-container">
          <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
            {dots}
          </ul>
        </MDBCol>
      </MDBRow>
    ),
  };
};
