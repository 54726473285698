import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isUndefined } from 'core/helpers/functions';
import React from 'react';
import Select, { components } from 'react-select';

const DropdownIndicator = (props) => {
  let openIcon = isUndefined(props.selectProps.openIndicator)
    ? 'caret-up'
    : props.selectProps.openIndicator;
  let closedIcon = isUndefined(props.selectProps.closedIndicator)
    ? 'caret-down'
    : props.selectProps.closedIndicator;
  return (
    <components.DropdownIndicator {...props}>
      {(props.selectProps.menuIsOpen && (
        <FontAwesomeIcon icon={openIcon} />
      )) || <FontAwesomeIcon icon={closedIcon} />}
    </components.DropdownIndicator>
  );
};
const indicatorSeparatorStyle = {
  width: 0,
};
const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};
const Select2 = (props) => {
  return (
    <div className={`select2 ` + props.wrapperClass}>
      <Select
        styles={props.styles}
        disabled={props.disabled}
        name={props.name}
        type={props.type}
        icon={props.icon}
        value={props.value}
        defaultInputValue={props.defaultInputValue}
        onChange={(event) => props.onChange(event)}
        onBlur={(event) => {
          if (typeof props.onBlur !== 'undefined') {
            props.onBlur(event);
          }
        }}
        onClick={(event) => {
          if (typeof props.onClick !== 'undefined') {
            props.onClick(event);
          }
        }}
        required={props.required}
        wrapperClass={props.wrapperClass}
        placeholder={props.placeholder}
        options={props.options}
        useCustom={props.useCustom}
        className={`react-select-container ${props.className}`}
        classNamePrefix={`react-select`}
        menuIsOpen={props.menuIsOpen}
        maxMenuHeight={230}
        openIndicator={props.openIndicator}
        closedIndicator={props.closedIndicator}
        isSearchable={props.isSearchable}
        components={{ DropdownIndicator, IndicatorSeparator }}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

export default Select2;
