import React from "react";
import { useDispatch } from "react-redux";
import {
  getSearchParameters,
  pushFilterValueToURL,
  removeFilterValueFromURL,
} from "app/layout/filters/functions";
import filterActions from "app/state/redux/data/filters/actions";
import Checkbox from "app/layout/checkbox";

const CheckboxFilter = ({ filter, filterOption, isSwitch }) => {
  const dispatch = useDispatch();
  let searchParameters = getSearchParameters();
  const handleCheckboxFiltering = () => {
    searchParameters = getSearchParameters();
    if (
      !Object.values(searchParameters)
        .toString()
        .split(",")
        .includes(filterOption.value)
    ) {
      dispatch(filterActions.changePageNumber(1));
      pushFilterValueToURL(searchParameters, filter, filterOption, isSwitch);
    } else {
      removeFilterValueFromURL(
        searchParameters,
        filter,
        filterOption,
        isSwitch
      );
      dispatch(filterActions.changePageNumber(1));
    }
  };

  return (
    <div className="custom-control custom-checkbox checkbox-filter-item">
      <Checkbox
        checked={Object.values(searchParameters)
          .toString()
          .split(",")
          .includes(filterOption.value)}
        checkbox_id={filterOption.label}
        theme="light"
        setChecked={() => handleCheckboxFiltering()}
      />
      <p className="checkbox-text">{filterOption.label}</p>
    </div>
  );
};

export default CheckboxFilter;
