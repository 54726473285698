import React from "react";
import { MDBModal, MDBModalBody } from "mdbreact";
import ResetPasswordForm from "./resetPasswordForm";

const ResetPasswordModal = ({
  toggleLoginModal,
  setToggleLoginModal,
  toggleModal,
  setToggleModal,
}) => {
  return (
    <MDBModal
      isOpen={toggleModal}
      toggle={() => setToggleModal(!toggleModal)}
      centered
      className="reset-password-modal"
    >
      <MDBModalBody>
        <ResetPasswordForm
          toggleLoginModal={toggleModal}
          setToggleLoginModal={setToggleLoginModal}
          toggleModal={toggleModal}
          setToggleModal={setToggleModal}
        />
      </MDBModalBody>
    </MDBModal>
  );
};

export default ResetPasswordModal;
