import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import QuoteRequestItemStateContainer from "./quoteRequestItem-state-container";
import customerActions from "core/state/redux/data/customer/actions";

const QuoteRequestItemReduxContainer = (props) => {
  return <QuoteRequestItemStateContainer {...props} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
    updateCart: (items) => {
      dispatch(customerActions.updateCart(items));
    },
    removeItem: (id) => {
      dispatch(customerActions.removeProductFromCart(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuoteRequestItemReduxContainer);
