import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import FindRepDataContainer from "./find-a-rep-data-container";
import ReactLoader from "app/layout/loader";
import useSite from "app/state/hooks/siteHooks/useSite";

const REP_LOCATORS = gql`
  query getAllRepLocators {
    getAllRepLocators {
      fax
      firstname
      lastname
      email
      country
      state
      industry
      phone
      image
      position
      web_url
      show_in_grid
    }
  }
`;

const FindRepQueryContainer = (props) => {
  const { data, loading, error } = useQuery(REP_LOCATORS, {
    variables: {},
    fetchPolicy: "cache-first",
  });
  const [, getSiteCode] = useSite();
  if (error) return <h1>something is not working</h1>;
  if (loading) {
    return (
      <div className="data-loader-container loading">
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
        />
      </div>
    );
  }

  return <FindRepDataContainer data={data} />;
};

export default FindRepQueryContainer;
