import React, { useState } from "react";
import Subscribe from "./subscribe";

const SubscribeStateContainer = (props) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [whatDescribesYou, setWhatDescribesYou] = useState("");
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({});
  const [whatDescribesYouValidation, setWhatDescribesYouValidation] = useState(
    true
  );

  return (
    <Subscribe
      {...props}
      email={email}
      setEmail={setEmail}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      whatDescribesYou={whatDescribesYou}
      setWhatDescribesYou={setWhatDescribesYou}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      whatDescribesYouValidation={whatDescribesYouValidation}
      setWhatDescribesYouValidation={setWhatDescribesYouValidation}
    />
  );
};

export default SubscribeStateContainer;
