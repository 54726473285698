import React, { useState } from "react";
import { MDBCol } from "mdbreact";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import useCustomer from "app/state/hooks/customerHooks/useCustomer";
import Login from "../../../../customer/loginRegisterPopupModal/login-registerPopupModal";
import Select from "app/layout/select";
import ReactLoader from "app/layout/loader/loader";

export const useProductFunctions = () => {
  const [getLoggedIn] = useCustomer();
  const [quantity, setQuantity] = useState(1);
  const [toggleLogin, setToggleLogin] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingNumber, setLoadingNumber] = useState(false);

  const increaseQty = () => {
    setLoadingNumber(true);
    setQuantity(quantity + 1);
    setTimeout(() => {
      setLoadingNumber(false);
    }, 500);
  };
  const reduceQty = () => {
    setLoadingNumber(true);
    if (quantity > 1) setQuantity(quantity - 1);
    setTimeout(() => {
      setLoadingNumber(false);
    }, 500);
  };
  const handleQuoteBtnClick = (props) => {
    const optionId =
      props.options && props.options[0] && props.options[0].option_id;
    let finishId =
      props.options &&
      props.options[0] &&
      props.options[0].value &&
      props.options[0].value[props.activeFinish] &&
      props.options[0].value[props.activeFinish].option_type_id;
    finishId = finishId.toString();
    if (getLoggedIn()) {
      if (props.sku) {
        setLoading(true);
        props.addItemToCart(quantity, props.sku, optionId, finishId);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    }
  };
  function getWhereToBuy() {
    return (
      <Button to="/where-to-buy" className="where-to-buy-button custom-button">
        Where to buy
        <Icon icon="globe_icon" />
      </Button>
    );
  }
  function getQuoteRequest(props) {
    return (
      <div
        className={`quote-buttons-container ${getLoggedIn() ? true : false}`}
      >
        {getLoggedIn() && (
          <Button className="quantity-button custom-button">
            <span
              className={quantity > 1 ? "minus-icon" : "minus-icon disabled"}
              onClick={() => reduceQty()}
            >
              -
            </span>
            {loadingNumber ? (
              <ReactLoader
                color={"#ffffff"}
                width={30}
                height={30}
                type={`ThreeDots`}
              />
            ) : quantity < 10 ? (
              "0" + quantity
            ) : (
              quantity
            )}
            <span className="plus-icon" onClick={() => increaseQty()}>
              +
            </span>
          </Button>
        )}
        <Button
          className="quote-req-button custom-button"
          onClick={() => {
            if (getLoggedIn()) handleQuoteBtnClick(props);
            else setToggleLogin(!toggleLogin);
          }}
        >
          {getLoggedIn()
            ? loading
              ? "Adding to Quote Request"
              : "Add to Quote Request"
            : "Login to Get Quote Request"}
          {getLoggedIn() && <Icon icon="quote_icon" />}
        </Button>
        <Login toggleModal={toggleLogin} setToggleModal={setToggleLogin} />
      </div>
    );
  }
  function getRevitFiles(revit_files, selectedFile, setSelectedFile) {
    let files = [];
    revit_files.map((item) => {
      let name = item.substring(item.lastIndexOf("/") + 1);
      name = name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      name = name.length > 15 ? name.slice(0, 15) : name;
      return files.push({ value: item, label: name });
    });

    return (
      <MDBCol className="files-download-container">
        <Button className="files-download-button custom-button">
          <Select
            options={files}
            value={selectedFile}
            placeholder={"Select file"}
            onChange={(event) => setSelectedFile(event)}
          />

          <a
            className={selectedFile === "" && "disabled"}
            href={selectedFile.value}
            download={selectedFile.label}
          >
            <Icon icon="download_icon" />
          </a>
        </Button>
      </MDBCol>
    );
  }
  return [getWhereToBuy, getQuoteRequest, getRevitFiles];
};
