import React from "react";
import { useQuery } from "@apollo/react-hooks";
import ReactLoader from "app/layout/loader";
import PrivacyPolicy from "./privacyPolicy";
import gql from "graphql-tag";
import useSite from "app/state/hooks/siteHooks/useSite";

const query = gql`
  query {
    getPrivacyPolicy {
      content
      id
      title
    }
  }
`;

const PrivacyPolicyQueryContainer = (props) => {
  const { loading, error, data, refetch } = useQuery(query, {});
  const [, getSiteCode] = useSite();

  if (loading)
    return (
      <div className="data-loader-container loading">
        <ReactLoader
          color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
          width={50}
          height={50}
          type={`ThreeDots`}
          key={"loader-items"}
        />
      </div>
    );
  if (error) return `Error! ${error}`;

  return (
    <>
      <PrivacyPolicy {...props} refetch={refetch} data={data} />
    </>
  );
};

export default PrivacyPolicyQueryContainer;
