/*global google*/
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { compose, withProps, withHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import Button from "core/components/button";
import { MDBContainer, MDBRow, MDBCol, MDBBox } from "mdbreact";
import Select2 from "core/components/select/select";
import Input from "app/layout/input";
import Icon from "app/assets/icon/icon";
import Breadcrumbs from "app/layout/breadcrumbs";
import lodash from "lodash";
import { isEmpty } from "core/helpers/functions";
import { googleMapsStyle } from "./googleMapsStyle";
import { useScript } from "app/state/hooks/useScript/useScript";
import ReactLoader from "app/layout/loader";
import { useMediaQuery } from "react-responsive";
const {
  MarkerClusterer,
} = require("react-google-maps/lib/components/addons/MarkerClusterer");

const calculateRoute = (lat1, lng1, lat2, lng2) => {
  let directionUrl =
    "https://www.google.com/maps/dir/?api=1&origin=" +
    lat1 +
    "," +
    lng1 +
    "&" +
    "destination=" +
    lat2 +
    "," +
    lng2;
  return directionUrl;
};
const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCIKajdxnw25suNPzUQIVQzbBmxN9n4XrE&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers();
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  return (
    <GoogleMap
      defaultOptions={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: googleMapsStyle,
      }}
      defaultZoom={props.newPosition.radius.zoom}
      zoom={props.newPosition.radius.zoom}
      defaultCenter={{ lat: props.newPosition.lat, lng: props.newPosition.lng }}
      center={{ lat: props.newPosition.lat, lng: props.newPosition.lng }}
    >
      {/* store markers */}
      {props.isMarkerShown && (
        <>
          <MarkerClusterer
            onClick={props.onMarkerClustererClick}
            averageCenter
            enableRetinaIcons
            gridSize={60}
          >
            {props.stores.map((store) => {
              return (
                <Marker
                  key={`store-locator-marker-store-id-${store.store_id}`}
                  onClick={() => {
                    props.setShownStores({
                      ...props.shownStore,
                      [store.store_id]: !props.shownStores[store.store_id],
                    });
                    props.setNewPosition({ lat: store.lat, lng: store.lng });
                  }}
                  position={{ lat: store.lat, lng: store.lng }}
                >
                  {props.shownStores[store.store_id] && (
                    <InfoWindow
                      onCloseClick={() => {
                        props.setShownStores({
                          ...props.shownStores,
                          [store.store_id]: !props.shownStores[store.store_id],
                        });
                      }}
                    >
                      <>
                        <MDBBox tag="h5">{store.name}</MDBBox>
                        <MDBBox className="shinfo">
                          <MDBBox tag="p">{store.street}</MDBBox>
                          <p>{store.city}</p>
                          <p>{store.state}</p>
                          <p>T {store.phone}</p>
                        </MDBBox>
                        <MDBBox className={"website-content-wrapper"}>
                          {store.showroom !== null ? (
                            <MDBBox className={"showroom-content"}>
                              <a
                                href={store.showroom}
                                target={"_blank"}
                                className={"link-elaine"}
                                rel="noopener noreferrer"
                              >
                                Visit Our Showroom
                              </a>
                            </MDBBox>
                          ) : (
                            <></>
                          )}
                          <MDBBox className={"directions-content"}>
                            <a
                              href={calculateRoute(
                                props.coordinates.lat,
                                props.coordinates.lng,
                                store.lat,
                                store.lng
                              )}
                              target={"_blank"}
                              rel="noopener noreferrer"
                              className={"link-elaine"}
                            >
                              Get direction
                            </a>
                          </MDBBox>
                          {store.website !== null ? (
                            <MDBBox className={"website-content"}>
                              <a
                                href={
                                  store.website.includes("http")
                                    ? store.website
                                    : "http://" + store.website
                                }
                                target={"_blank"}
                                rel="noopener noreferrer"
                                className={"link-elaine"}
                              >
                                Visit Our Website
                              </a>
                            </MDBBox>
                          ) : (
                            <></>
                          )}
                        </MDBBox>
                      </>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
          </MarkerClusterer>

          {/* <MarkerClusterer
          onClick={props.onMarkerClustererClick}
          averageCenter
          enableRetinaIcons
          gridSize={60}
        > */}
          {/* selected location */}
          {props.newPosition.lat !== null && props.showMarker && (
            <Marker
              position={{
                lat: props.newPosition.lat,
                lng: props.newPosition.lng,
              }}
            />
          )}
          {/* </MarkerClusterer> */}
        </>
      )}
    </GoogleMap>
  );
});

const WhereToBuy = (props) => {
  let { location } = props;
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  // check if the request came from form outside of this page
  let isPredefined =
    typeof location.state !== "undefined" &&
    location.state !== null &&
    typeof location.state.input !== "undefined" &&
    location.state.input !== ""
      ? true
      : false;
  let predefinedSearch = isPredefined ? location.state.input : "";
  let predefinedRadius = isPredefined
    ? { label: "100 mi", value: "100", zoom: 8 }
    : "";
  let predefinedCoordinates = isPredefined
    ? location.state.coordinates
    : {
        lat: 26.7903264,
        lng: -80.0984423,
      };

  const [showMarker, setShowMarker] = useState(false);
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  let defaultShownStores = {};
  if (props.stores) {
    props.stores.map((store) => {
      defaultShownStores[store.store_id] = false;
    });
  }
  const [shownStores, setShownStores] = useState(defaultShownStores);

  const [address, setAddress] = useState(predefinedSearch);
  const [radius, setRadius] = useState(predefinedRadius);
  const [newradius, setNewradius] = useState("");
  const [newPosition, setNewPosition] = useState({
    lat: 39.6231307,
    lng: -105.5188457,
    radius: radius,
  });
  const [storesNearby, setStoresNearby] = useState([]);
  const [coordinates, setCoordinates] = useState(predefinedCoordinates);
  const [defaultLocation, setDefaultLocation] = useState({ ...location });
  const [, setZipCode] = useState("");
  const [, setCountryShortName] = useState("");
  const [, setCountryLongName] = useState("");
  let Map = (
    <MyMapComponent
      coordinates={coordinates}
      newPosition={
        newPosition.radius === ""
          ? {
              lat: newPosition.lat,
              lng: newPosition.lng,
              radius: { value: "10", zoom: 5 },
            }
          : { lat: newPosition.lat, lng: newPosition.lng, radius: radius }
      }
      setNewPosition={setNewPosition}
      showMarker={showMarker}
      isMarkerShown={isMarkerShown}
      stores={storesNearby}
      shownStores={shownStores}
      setShownStores={setShownStores}
      etailers={props.etailers}
      radius={radius}
    />
  );

  function handleChange(e) {
    if (radius === "") {
      setRadius(e);
      setNewradius(e);
    } else {
      setNewradius(e);
    }

    // if (!isEmpty(address) && status)
    //   findRetail(coordinates.lat, coordinates.lng, e);
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    var addressComponent = results[0].address_components;
    setAddress(value);
    setZipCode("");
    setCountryLongName("");
    setCountryShortName("");
    for (var x = 0; x < addressComponent.length; x++) {
      var chk = addressComponent[x];
      if (chk.types[0] === "postal_code") {
        setZipCode(chk.long_name);
      }
      if (chk.types[0] === "country") {
        setCountryShortName(chk.short_name);
        setCountryLongName(chk.long_name);
      }
    }
    const latLng = await getLatLng(results[0]);
    setCoordinates(latLng);
    const newrad = newradius;
    setRadius(newrad);
    return { latLng, newrad };
  };

  var getDistance = function (lat1, lng1, lat2, lng2) {
    let distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(lat1, lng1),
      new google.maps.LatLng(lat2, lng2)
    );
    return distance / 1000 / 1.609;
  };

  const findRetail = (lat, lng, radius) => {
    if (status) {
      setNewPosition({
        lat: 39.6231307,
        lng: -105.5188457,
        radius: {
          label: "10 km",
          value: 10,
          zoom: 12,
        },
      });
      setShowMarker(true);
    } else {
      setStoresNearby([
        ...props.stores
          .filter((store) => {
            let distance = getDistance(lat, lng, store.lat, store.lng);
            return distance <= radius.value;
          })
          .sort((a, b) => {
            const storeADistance = getDistance(lat, lng, a.lat, a.lng);
            const storeBDistance = getDistance(lat, lng, b.lat, b.lng);
            console.log(storeADistance, storeBDistance);
            return storeADistance - storeBDistance;
          })
          .map((store) => store),
      ]);
      setNewPosition({
        lat,
        lng,
        radius,
      });
      setRadius(radius);
      //setShowMarker(true);
    }
  };
  const [status, setStatus] = useState(false);

  const [viewAll, setViewAll] = useState(false);
  const toggle = () => {
    setViewAll(!viewAll);
  };

  useEffect(() => {
    if (isPredefined) {
      if (!lodash.isEqual(location, defaultLocation)) {
        handleSelect(location.state.input);
        setRadius({ ...{ label: "100 mi", value: "100", zoom: 8.5 } });
        setTimeout(() => {
          document.getElementById("find-retailer-submit").click();
        }, 500);
      } else {
        setTimeout(() => {
          document.getElementById("find-retailer-submit").click();
        }, 500);
      }
    }
  }, [location]);
  let googleMapsAPIKey =
    typeof props.data === "undefined"
      ? " "
      : props.data.getStoreLocator.api_key;

  const [loaded, error] = useScript(
    googleMapsAPIKey.length > 1
      ? `https://maps.googleapis.com/maps/api/js?key=${googleMapsAPIKey}&libraries=places`
      : ""
  );

  if (!loaded || error) {
    return <ReactLoader />;
  }

  return (
    <MDBContainer className="where-to-buy-page-container">
      <div style={{ display: "hidden" }} id="map"></div>
      <MDBContainer className={"find-a-retailer-wrapper"}>
        {!isMobileOrTablet && <Breadcrumbs />}
        <h3>Find a Showroom</h3>
        <MDBRow className="map-and-map-input-wrapper">
          <MDBContainer className={"map"}>{Map}</MDBContainer>
          <MDBContainer className={"map-inputs"}>
            <MDBBox>
              {/* <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
                searchOptions={{
                  types: ["address"],
                  componentRestrictions: { country: "us" },
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => ( */}
              <MDBBox className="input-wrapper">
                <div className="country-input-wrapper">
                  <Input
                    type="text"
                    //{...getInputProps()}
                    placeholder="Enter Location or ZIP code"
                    value={address}
                    onChange={(event) => {
                      setAddress(event.target.value);
                    }}
                  />
                  <Icon icon={"find_rep_country_arrow"} />
                </div>
                {/* <MDBBox className={"locations-wrapper"}>
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#ced4d2" : "#fff",
                      marginTop: "1rem",
                      borderRadius: "1rem",
                      padding: "1rem",
                    };

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { style })}
                        key={suggestion.description}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </MDBBox> */}
              </MDBBox>
              )}
              {/* </PlacesAutocomplete> */}
              <Select2
                className="select-radius"
                onChange={(e) => handleChange(e)}
                value={newradius}
                options={[
                  { label: "10 mi", value: "10", zoom: 12 },
                  { label: "25 mi", value: "25", zoom: 11 },
                  { label: "50 mi", value: "50", zoom: 9 },
                  { label: "100 mi", value: "100", zoom: 8 },
                  { label: "250 mi", value: "250", zoom: 7 },
                ]}
                placeholder={"Search Radius"}
                openIndicator="caret-up"
                closedIndicator="caret-down"
                useCustom={true}
                //isSearchable={false}
              />
              <Button
                id={`find-retailer-submit`}
                direction={"mr-3"}
                // leftIcon={true}
                // icon={"arrow-right"}
                // text={<span>Search</span>}
                disabled={isEmpty(address) || radius === ""}
                onClick={() => {
                  handleSelect(address).then(({ latLng, newrad }) => {
                    setIsMarkerShown(true);
                    if (radius.zoom === 15) {
                      setRadius({
                        ...{ label: "250 mi", value: "250", zoom: 7 },
                      });
                    }

                    findRetail(
                      latLng.lat,
                      latLng.lng,
                      radius.zoom === 15
                        ? { label: "250 mi", value: "250", zoom: 7 }
                        : newrad
                    );

                    setShowMarker(true);
                  });
                }}
              >
                Search
              </Button>
              {showMarker && (
                <MDBBox className={"search-container"}>
                  {/* <MDBBox className="search-res" tag="span">
                  search results:
                </MDBBox> */}
                  {!lodash.isUndefined(storesNearby) &&
                  !lodash.isEmpty(storesNearby) ? (
                    <>
                      <ol className={`${viewAll ? "clicked" : "not-clicked"}`}>
                        {storesNearby.map((store) => {
                          return (
                            <li
                              key={`store-locator-list-of-location-id-${store.store_id}`}
                              className={`store-content ${
                                shownStores[store.store_id] ? "active" : ""
                              }`}
                              onClick={(e) => {
                                setShownStores({
                                  //...shownStores,
                                  [store.store_id]: store.store_id,
                                });
                                setRadius({
                                  ...{ label: "10 mi", value: "10", zoom: 15 },
                                });
                                setNewPosition({
                                  lat: store.lat,
                                  lng: store.lng,
                                });
                              }}
                            >
                              <MDBRow>
                                <MDBCol
                                  size="12"
                                  className="pin-and-name-wrapper"
                                >
                                  <Icon icon={"icon_pin_orange"} />
                                  <h5>{store.name}</h5>
                                </MDBCol>
                                <MDBCol md="12">
                                  <p>{store.street}</p>
                                  <p>{store.city}</p>
                                  <p>
                                    {store.state}{" "}
                                    <span className="float-right">
                                      T {store.phone}
                                    </span>
                                  </p>

                                  <MDBBox className={"website-content-wrapper"}>
                                    {store.showroom !== null ? (
                                      <MDBBox className={"showroom-content"}>
                                        <a
                                          href={store.showroom}
                                          target={"_blank"}
                                          rel="noopener noreferrer"
                                          className={"link-to-website"}
                                        >
                                          Visit Our Showroom
                                        </a>
                                      </MDBBox>
                                    ) : (
                                      <></>
                                    )}

                                    {store.website !== null ? (
                                      <MDBBox className={"website-content"}>
                                        <a
                                          href={
                                            store.website.includes("http")
                                              ? store.website
                                              : "http://" + store.website
                                          }
                                          target={"_blank"}
                                          rel="noopener noreferrer"
                                          className={"link-to-website"}
                                        >
                                          {store.website}
                                        </a>
                                      </MDBBox>
                                    ) : (
                                      <></>
                                    )}
                                  </MDBBox>
                                </MDBCol>
                              </MDBRow>
                            </li>
                          );
                        })}
                        <hr />
                      </ol>
                      {storesNearby.length < 3 ? (
                        <></>
                      ) : (
                        <>
                          {!viewAll ? (
                            <MDBBox
                              tag="h4"
                              style={{ cursor: "pointer" }}
                              className={`text-center view-all`}
                              onClick={(e) => {
                                toggle();
                              }}
                            >
                              See all results
                            </MDBBox>
                          ) : (
                            <MDBBox
                              tag="h4"
                              style={{ cursor: "pointer" }}
                              className={`text-center view-all`}
                              onClick={(e) => {
                                toggle();
                              }}
                            >
                              Less Results
                            </MDBBox>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <MDBBox className={"search-container"}>
                      <MDBBox tag="h5">
                        We’re sorry, we couldn’t find any results
                      </MDBBox>
                      <MDBBox tag="p">
                        Check out some of our other e-taile locations
                      </MDBBox>
                      <ol className={viewAll ? "clicked" : "not-clicked"}>
                        {props.etailers.map((etailer) => {
                          return (
                            <li className={"store-content"}>
                              <MDBBox tag="h5"> {etailer.name}</MDBBox>

                              {etailer.website && (
                                <MDBBox className={"website-content"}>
                                  <a
                                    href={
                                      etailer.website.includes("http")
                                        ? etailer.website
                                        : "http://" + etailer.website
                                    }
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                  >
                                    visit our site
                                  </a>
                                </MDBBox>
                              )}
                            </li>
                          );
                        })}
                      </ol>
                    </MDBBox>
                  )}
                </MDBBox>
              )}
            </MDBBox>
          </MDBContainer>
        </MDBRow>
        <p className="find-a-showroom-bottom-text">
          Find products in various online shops. Click the button to see the
          list of online shops our products are listed in.
        </p>
        <div className="shop-online-btn-store-locator">
          <Button className="with-arrow " to="/where-to-buy/shop-online">
            Shop online <Icon icon="right_arrow_white" />
          </Button>
        </div>
      </MDBContainer>
    </MDBContainer>
  );
};

export default withRouter(WhereToBuy);
