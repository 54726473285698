export const handleLogin = (
  e,
  setTouched,
  errors,
  setLoading,
  props,
  component,
  login,
  email,
  password,
  customer,
  setStatus,
  cartToken
) => {
  e.preventDefault();
  let isTrue = true;
  setTouched({ email: true, password: true });

  Object.keys(errors).map((item) => {
    let error = errors[item];
    if (isTrue) {
      if (error === true) {
        isTrue = false;
      }
    }
  });
  if (isTrue) {
    setLoading(true);
    props.lock({
      always: (e) => {},
      success: (e) => {
        setLoading(false);
        setStatus(true);
      },
      fail: (e) => {
        setLoading(false);
        setStatus(false);
      },
    });
    login(email, password, cartToken);
  }
};
