import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import Wishlist from "./wishlist";

const WishlistReduxContainer = (props) => {
  return <Wishlist {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    lock: (callbackFunction) => {
      dispatch(commonActions.lock(callbackFunction));
    },
    login: (username, password, cartToken, callbackFunction) => {
      dispatch(customerActions.loginCustomer(username, password, cartToken));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishlistReduxContainer);
