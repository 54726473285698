import React from "react";
import { connect } from "react-redux";
import { default as commonActions } from "core/state/redux/data/common/actions";
import customerActions from "core/state/redux/data/customer/actions";
import { withApollo } from "react-apollo";
import ResetPasswordStateContainer from "./resetPasswordForm-state-container";

const ResetPasswordReduxContainer = (props) => {
  return <ResetPasswordStateContainer {...props} />;
};

const mapStateToProps = (state) => ({
  customer: state.customer,
  loading: state.customer.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onForgotPassword: (email) => {
      dispatch(customerActions.forgotCustomerPassword(email));
    },
    resetPassword: (token, password, confirmPassword) => {
      dispatch(commonActions.lock());
      dispatch(
        customerActions.resetCustomerPassword(token, password, confirmPassword)
      );
    },
    resetPasswordSuccess: (value) => {
      dispatch(customerActions.resetCustomerPasswordSuccess(value));
    },
    unlock: () => {
      dispatch(commonActions.unlock());
    },
    lock: (callback) => {
      dispatch(commonActions.lock(callback));
    },
  };
};

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordReduxContainer)
);
