import { emailValidation } from "app/config/customer/validationRules";

export const validate = (
  changeEmail,
  changePassword,
  firstname,
  lastname,
  email,
  password,
  newpassword,
  newpasswordconf
) => {
  return {
    changeEmail: changeEmail.length === 0,
    changePassword: changePassword.length === 0,
    firstname: firstname.length === 0,
    lastname: lastname.length === 0,
    email: email.length === 0 || ValidateEmail(email),
    password: password.length < 6,
    newpassword: newpassword.length < 6,
    newpasswordconf: newpassword !== newpasswordconf,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];

  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
