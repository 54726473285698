import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";

const AccountMobileModal = ({ open, setOpen }) => {
  return (
    <MDBContainer className={"account-mobile-wrapper " + open}>
      <MDBRow className="acc-mobile-row">
        <MDBCol>
          <Link
            onClick={() => {
              open && setOpen(!open);
            }}
            to="/customer"
            className="uppercase"
          >
            Account dashboard
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="acc-mobile-row">
        <MDBCol>
          <Link
            onClick={() => {
              open && setOpen(!open);
            }}
            to="/customer/information"
            className="uppercase"
          >
            Account information
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="acc-mobile-row">
        <MDBCol>
          <Link
            onClick={() => {
              open && setOpen(!open);
            }}
            to="/customer/newsletter"
            className="uppercase"
          >
            Newsletter subscription
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="acc-mobile-row">
        <MDBCol>
          <Link
            onClick={() => {
              open && setOpen(!open);
            }}
            to="favorites"
          >
            My Favorites
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="acc-mobile-row">
        <MDBCol>
          <Link
            onClick={() => {
              open && setOpen(!open);
            }}
            to="/favorites/edit"
          >
            My Projects
          </Link>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};
export default AccountMobileModal;
