import React from "react";
import useSite from "app/state/hooks/siteHooks/useSite";
import { Link } from "react-router-dom";

const HotspotCard = (props) => {
  let { productUrl } = props;
  // const [, getSiteCode] = useSite();
  return (
    <>
      {/* {productUrl ? (
        getSiteCode() === "ilex_default" ? (
          <a href={"//norwellpwa.joladev2.com/" + productUrl}>
            {React.cloneElement(props.children)}
          </a>
        ) : (
          <Link to={"/" + productUrl}>
            {React.cloneElement(props.children)}
          </Link>
        )
      ) : (
        <>{props.children}</>
      )} */}
      {productUrl ? (
        <Link to={"/" + productUrl}>{React.cloneElement(props.children)}</Link>
      ) : (
        <>{props.children}</>
      )}
    </>
  );
};

export default HotspotCard;
