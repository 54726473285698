import React, { memo } from "react";
import { MDBFooter, MDBRow } from "mdbreact";
import FooterLeftColumn from "./footerLeftColumn/footerLeftColumn";
import FooterRightColumn from "./footerRightColumn/footerRightColumn";
import FooterBottom from "./footerBottom/footerBottom";
import SiteSwitcher from "app/layout/siteSwitcher";
import { isEqual } from "lodash";

const Footer = memo(
  (props) => {
    return (
      <div id={"footer"}>
        <MDBFooter className="footer-container">
          <MDBRow className="mobile-footer-logo">
            <SiteSwitcher switcherLocation="footer" />
          </MDBRow>
          <MDBRow lg="12" className="footer-container-top">
            <FooterLeftColumn {...props} />
            <FooterRightColumn {...props} />
          </MDBRow>
          <FooterBottom {...props} />
        </MDBFooter>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Footer;
