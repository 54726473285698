export const substringToUppercase = (string, substring) => {
  if (string.includes(substring)) {
    string =
      string.slice(0, string.search(substring)) +
      string
        .slice(
          string.search(substring),
          string.search(substring) + substring.length
        )
        .toUpperCase() +
      string.slice(string.search(substring) + substring.length, string.length);
  }
  return string;
};
