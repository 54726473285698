import React from "react";
import { MDBContainer } from "mdbreact";
import ProductWidget from "app/layout/productWidget";
import { settings } from "./settings";

const RecentlyViewed = (props) => {
  return (
    <MDBContainer className="recently-viewed-products-container">
      <ProductWidget
        view={"slider"}
        settings={settings}
        items={props.filteredArray}
        title={"Recently Viewed"}
        subtitle={"PEOPLE ALSO LIKED"}
        showBrand={false}
      />
    </MDBContainer>
  );
};

export default RecentlyViewed;
