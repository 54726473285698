import commonActions from "../actions";
import queryActions from "core/state/redux/data/queries/actions";
import queryLoader from "core/graphql/queryLoader";
import { apolloClientMutation } from "app/graphql/apolloClient";
export default async (store, action) => {
  try {
    const { data } = await apolloClientMutation.mutate({
      mutation: queryLoader("generateCache"),
      variables: { input: "fetch" },
    });
    if (!data.generateCache) {
      console.log("GraphQL response", data);
      throw new Error("No cache information received");
    }

    if (action.version !== data.generateCache) {
      if (action.flag !== "wait") {
        store.dispatch(commonActions.setFlag("wait")).then(() => {
          store
            .dispatch(commonActions.setCacheVersion(data.generateCache))
            .then(() => {
              store.dispatch(queryActions.removeAllQueries()).then(() => {
                store.dispatch(commonActions.setFlag("ready")).then(() => {});
              });
            });
        });
      } else if (action.version === 0) {
        store.dispatch(commonActions.setFlag("wait")).then(() => {
          store
            .dispatch(commonActions.setCacheVersion(data.generateCache))
            .then(() => {
              store.dispatch(commonActions.setFlag("ready"));
            });
        });
      } else if (action.flag === "wait") {
        store.dispatch(commonActions.setFlag("wait")).then(() => {
          store
            .dispatch(commonActions.setCacheVersion(data.generateCache))
            .then(() => {
              console.log(store.getState().cache.cache, "store");
              store.dispatch(queryActions.removeAllQueries()).then(() => {
                store.dispatch(commonActions.setFlag("ready")).then(() => {});
              });
            });
        });
      }
    }
  } catch (error) {
    store.dispatch(queryActions.removeQuery());
    return;
  }
};
