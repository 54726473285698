import React from "react";
import { useLocation } from "react-router-dom";
import GalleryDetail from "./galleryDetail";

const GalleryDetailDataContainer = (props) => {
  const location = useLocation();
  if (
    typeof props.data === "undefined" ||
    typeof props.data.getAllGalleryData === "undefined"
  )
    return <></>;

  const path = location.pathname;
  let previousGallery = false;
  let nextGallery = false;

  const galleryId = path
    .slice(path.indexOf("id_"), path.length)
    .replace(/id_/, "");
  const allGalleries =
    typeof props.data !== undefined &&
    props.data &&
    typeof props.data.getAllGalleryData !== undefined
      ? props.data.getAllGalleryData
      : false;

  const allGalleriesWithoutPress =
    allGalleries &&
    allGalleries.filter(
      (gallery) =>
        gallery && gallery.category[0] && gallery.category[0].name !== "Press"
    );

  const norwellGalleries =
    allGalleriesWithoutPress &&
    allGalleriesWithoutPress.filter(
      (gallery) =>
        gallery &&
        gallery.category[0] &&
        gallery.category[0].name === "Norwell Lighting"
    );

  const ilexGalleries =
    allGalleriesWithoutPress &&
    allGalleriesWithoutPress.filter(
      (gallery) =>
        gallery &&
        gallery.category[0] &&
        gallery.category[0].name === "ILEX Custom Metalcraft"
    );

  let selectedGallery = allGalleriesWithoutPress.find((gallery, index) => {
    if (gallery.gallery_id === galleryId) {
      previousGallery = allGalleriesWithoutPress[index - 1];
      nextGallery = allGalleriesWithoutPress[index + 1];
      return gallery;
    }
  });

  const hotspots = selectedGallery?.hotspot;

  return (
    <GalleryDetail
      selectedGallery={selectedGallery}
      previousGallery={previousGallery}
      nextGallery={nextGallery}
      hotspots={hotspots}
      norwellGalleries={norwellGalleries}
      ilexGalleries={ilexGalleries}
    />
  );
};

export default GalleryDetailDataContainer;
