import React from "react";
import gql from "graphql-tag";
import GraphqlWrapper from "app/layout/graphQlWrapper";
import HeritageOfQuality from "./heritageOfQuality-data-container";

const GALLERIES = gql`
  query {
    getAllGalleryData {
      gallery_id
      name
      on_home
      image
      description
      homepage_image
      gallery_items {
        name
        image
      }
      category {
        name
        category_id
      }
      hotspot {
        content
        image
        name
      }
    }
  }
`;

const HeritageOfQualityQueryContainer = (props) => {
  return (
    <GraphqlWrapper
      query={GALLERIES}
      variables={{
        id: "galleries",
      }}
      saveQuery={true}
    >
      <HeritageOfQuality {...props} />
    </GraphqlWrapper>
  );
};

export default HeritageOfQualityQueryContainer;
