import React from "react";
import { MDBRow, MDBCol } from "mdbreact";

export const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true,
  initialSlide: 0,
  focusOnSelect: true,
  lazyLoad: "progressive",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        dots: true,
      },
    },
    {
      breakpoint: 485,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
  customPaging: (i) => <div className={"slider-custom-dot"}>{i}</div>,
  appendDots: (dots) => (
    <MDBRow
      id="slider-indicators-container"
      style={{
        padding: "15px",
        textAlign: "center",
      }}
    >
      <MDBCol className="indicators-container">
        <ul className={"list-custom-dots"} style={{ margin: "0px" }}>
          {dots}
        </ul>
      </MDBCol>
    </MDBRow>
  ),
};
