import React, { memo } from "react";
import ImageContainer from "./imageContainer";
import themeConfiguration from "config/themeConfiguration";
import lodash from "lodash";
import ImageLoader from "react-load-image";
import ReactLoader from "app/layout/loader";

function Preloader() {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactLoader color={"#363B45"} width={50} height={50} type={`TailSpin`} />
    </div>
  );
}

const Image = memo(
  (props) => {
    let source = "";
    if (typeof props.source === "undefined") return "";
    if (!lodash.isUndefined(props.backend) && props.backend === true) {
      if (props.backend_public) {
        source =
          themeConfiguration.magento_url +
          themeConfiguration.media_url +
          props.source;
      } else {
        source = themeConfiguration.magento_url + props.source;
      }
    } else {
      source = props.source.includes("http") ? props.source : props.source;
    }
    return (
      <ImageContainer {...props}>
        <ImageLoader src={source}>
          <img className={props.className} alt={props.alt} />
          <div>Error!</div>
          <Preloader props={props} />
        </ImageLoader>
      </ImageContainer>
    );
  },
  (prevProps, nextProps) => {
    if (lodash.isEqual(prevProps, nextProps)) {
      return true;
    }

    return false;
  }
);

export default Image;
