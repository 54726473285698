import React from "react";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Icon from "app/assets/icon/icon";
import useSite from "app/state/hooks/siteHooks/useSite";
import { Link } from "react-router-dom";
import Wishlist from "app/layout/wishlist";
import LazyLoad from "react-lazyload";
import ReactLoader from "app/layout/loader";

const ProductItemSliderView = (props) => {
  let { item } = props;
  const [, getSiteCode] = useSite();
  return (
    <MDBContainer className="product-slider-view-container">
      <MDBRow className="product-item-header-row">
        <MDBCol className={`product-new-label`}>
          {item.is_new ? (
            getSiteCode() === "norwell_default" ? (
              <Icon icon={"norwell_new_label"} />
            ) : (
              <Icon icon={"ilex_new_label"} />
            )
          ) : (
            <></>
          )}
        </MDBCol>
        <MDBCol className={`wishlist-product-widget`}>
          <Wishlist product={{ ...item }}>
            <Icon icon="wishlist_empty_heart" />
          </Wishlist>
        </MDBCol>
      </MDBRow>
      <MDBRow className="product-item-image-row">
        <MDBCol lg="12" className="product-item-image-column">
          <Link onClick={props.onClick} to={"/" + item.url_key}>
            <LazyLoad
              height={"100%"}
              offset={100}
              placeholder={
                <ReactLoader
                  color={"#363B45"}
                  width={50}
                  height={50}
                  type={`Oval`}
                  key={"loader-items"}
                />
              }
            >
              <img
                alt="product-slider-item"
                className={
                  item.image &&
                  item.image.url &&
                  item.image.url.includes("placeholder") &&
                  "placeholder"
                }
                src={
                  item.image &&
                  item.image.url &&
                  item.image.url.includes("placeholder")
                    ? "/placeholder.png"
                    : item.image && item.image.url
                }
              />
            </LazyLoad>
          </Link>
        </MDBCol>
      </MDBRow>
      <MDBRow className="product-item-info-row">
        <label className="product-item-info-triangle"></label>
        <MDBCol lg="12">
          <h5>{item.name}</h5>
        </MDBCol>
        <MDBRow lg="12" className="finishes-container">
          <MDBCol className="finishes-column">
            <p>SKU {item.sku}</p>
            <p className="finishes">
              {item.finishes_count && item.finishes_count > 1
                ? item.finishes_count + " Finishes Available"
                : item.finishes_count === 1
                ? item.finishes_count + " Finish Available"
                : ""}
            </p>
          </MDBCol>
        </MDBRow>
      </MDBRow>
    </MDBContainer>
  );
};

export default ProductItemSliderView;
