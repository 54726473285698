import gql from "graphql-tag";
export const PRODUCTS = gql`
  query Products(
    $search: String
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
      }
      total_count
      items {
        is_new
        id
        name
        sku
        brand
        url_key
        finishes_count
        upsell_products {
          id
          name
          sku
          url_key
          image {
            label
            url
            disabled
          }
          small_image {
            label
            url
            disabled
          }
          thumbnail {
            url
            label
            disabled
          }
          media_gallery {
            disabled
            label
            url
          }
          url_rewrites {
            parameters {
              name
              value
            }
            url
          }
        }

        image {
          label
          url
        }
        small_image {
          label
          url
        }
        url_rewrites {
          parameters {
            name
            value
          }
          url
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;

export const PRODUCTS_CUSTOMER = gql`
  query ProductsCustomer(
    $search: String
    $sort: ProductAttributeSortInput
    $pageSize: Int
    $currentPage: Int
  ) {
    products(
      search: $search
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      aggregations {
        attribute_code
        count
        label
        options {
          value
          label
        }
      }
      total_count
      items {
        id
        name
        sku
        brand
        url_key
        upsell_products {
          id
          name
          sku
          url_key
          image {
            label
            url
            disabled
          }
          small_image {
            label
            url
            disabled
          }
          thumbnail {
            url
            label
            disabled
          }
          media_gallery {
            disabled
            label
            url
          }
          price_range {
            minimum_price {
              final_price {
                value
              }
            }
          }
          url_rewrites {
            parameters {
              name
              value
            }
            url
          }
        }

        image {
          label
          url
        }
        small_image {
          label
          url
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            fixed_product_taxes {
              amount {
                currency
                value
              }
              label
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
        url_rewrites {
          parameters {
            name
            value
          }
          url
        }
      }
      page_info {
        page_size
        total_pages
        current_page
      }
      sort_fields {
        options {
          label
          value
        }
        default
      }
    }
  }
`;
