export const registerSubmit = (
  e,
  firstName,
  lastName,
  companyName,
  email,
  phone,
  password,
  subscribe,
  termsAndConditions,
  setTermsValidationMessage,
  setTouched,
  errors,
  lock,
  setLoading,
  setStatus,
  registerCustomerNew
) => {
  e.preventDefault();

  let isTrue = true;
  if (!termsAndConditions) {
    setTermsValidationMessage(true);
  }
  setTouched({
    firstname: true,
    lastname: true,
    company: true,
    email: true,
    phone: true,
    confirm_email: true,
    password: true,
    confirm_password: true,
    termsAndConditions: true,
  });
  Object.keys(errors).map((item) => {
    let error = errors[item];
    if (isTrue) {
      if (error === true) {
        isTrue = false;
      }
    }
  });
  if (termsAndConditions) {
    if (isTrue) {
      let customer = {
        firstname: firstName,
        lastname: lastName,
        company_name: companyName,
        email: email,
        telephone: phone,
        password: password,
        is_subscribed: subscribe,
      };
      setLoading(true);
      lock({
        always: (e) => {},
        success: (e) => {
          setLoading(false);
          setStatus(true);
        },
        fail: (e) => {
          setLoading(false);
          setStatus(false);
        },
      });
      registerCustomerNew(customer);
    }
  }
};
