import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";
import useSite from "app/state/hooks/siteHooks/useSite";
import themeConfiguration from "config/themeConfiguration";

const Logo = (props) => {
  const storeConfigData = props.data && props.data.storeConfig;
  const [, getSiteCode] = useSite();
  return (
    <>
      {storeConfigData && (
        <MDBRow className="logo-desktop d-lg-block">
          <MDBCol>
            <Link to="/">
              <img
                src={
                  themeConfiguration.magento_url +
                  "/pub/media/logo/" +
                  storeConfigData.header_logo_src
                }
                className={"logo-image " + getSiteCode()}
                alt={storeConfigData.logo_alt}
              />
            </Link>
          </MDBCol>
        </MDBRow>
      )}
    </>
  );
};

export default Logo;
