import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import RecentlyViewed from "./recentlyViewed-data-container";

const RecentlyViewedReduxContainer = (props) => {
  return <RecentlyViewed {...props} />;
};

const mapStateToProps = (state) => ({
  site: state.site,
  recently_viewed: state.customer.recently_viewed,
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentlyViewedReduxContainer);
