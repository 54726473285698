import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBCol,
} from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import Input from "app/layout/input";
import InventoryMobile from "./inventoryMobile";
import Button from "core/components/button";
import { useMediaQuery } from "react-responsive";
import Pagination from "app/layout/customPagination";
import SeoHelmet from "app/layout/seoHelmet";
import { Link } from "react-router-dom";

const Inventory = (props) => {
  const { inventoryData } = props;
  const [search, setSearch] = useState("");
  const [inventoryItems, setInventoryItems] = useState(inventoryData);
  const handleSearch = () => {
    let newInventoryItems = inventoryData.filter((item) => {
      if (item.sku.includes(search) || item.description?.html.includes(search))
        return item;
    });
    setInventoryItems(newInventoryItems);
  };
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });

  //pagination variables
  const totalPosts = inventoryData.length;

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const currentPosts =
    inventoryItems && inventoryItems.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    setCurrentPage(1);
  }, [search]);

  function calculatedQty(item) {
    let returnValue = "";

    if (item.options) {
      item.options.forEach((option) => {
        if (option.value) {
          option.value.forEach((val) => {
            returnValue = returnValue + val.quantity + "\n";
          });
        }
      });
    }

    return returnValue;
  }

  function calculatedSku(item) {
    let returnValue = "";

    if (item.options) {
      item.options.forEach((option) => {
        if (option.value) {
          option.value.forEach((val) => {
            returnValue = returnValue + val.sku + "\n";
          });
        }
      });
    }

    return returnValue;
  }

  function calculatedDate(item, type) {
    let returnValue = "/";

    if (item.options) {
      returnValue = "";
      item.options.forEach((option) => {
        if (option.value) {
          option.value.forEach((val) => {
            if (!val[type]) {
              val[type] = "/";
            } else if (val[type] !== "/") {
              val[type] = new Date(val[type]);
              val[type] =
                val[type].toLocaleString("default", { month: "long" }) +
                " " +
                val[type].getDate() +
                ", " +
                val[type].getFullYear();
            }
            returnValue = returnValue + val[type] + "\n";
          });
        }
      });
    }

    return returnValue;
  }

  function calculatedBoolean(item, type) {
    let returnValue = "";
    if (item.options) {
      item.options.forEach((option) => {
        if (option.value) {
          option.value.forEach((val) => {
            const calculatedVal = val[type]?.toString() === "1" ? "Yes" : "No";
            returnValue = returnValue + calculatedVal + "\n";
          });
        }
      });
    }
    return returnValue;
  }

  const isDiscontinued = (item) => {
    let result = "";
    item.options.forEach((option) => {
      option.value.every((val) => {
        result = parseInt(val.discontinued) === 1;
      });
    });

    return result;
  };

  return (
    <>
      <SeoHelmet title="Inventory" url={`/inventory`} />
      <MDBContainer className="inventory-page-wrapper">
        {!isMobileOrTablet && (
          <MDBRow>
            <Breadcrumbs />
          </MDBRow>
        )}
        <MDBRow className="inventory-page-title">
          <MDBCol>
            <h3 className="wow slideInUp">Inventory</h3>
          </MDBCol>
        </MDBRow>
        <MDBRow className="pagination-search-container wow fadeInDown">
          <MDBCol size="8" className="inventory-search-container">
            <MDBRow className="inventory-search-input-wrapper ">
              <Input
                wrapperClass={"light"}
                placeholder={"By SKU "}
                group
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <Button onClick={() => handleSearch()}>Search</Button>
            </MDBRow>
          </MDBCol>
          {inventoryItems?.length > 12 && (
            <MDBCol size="4">
              <Pagination
                position={"top"}
                postsPerPage={postsPerPage}
                totalPosts={totalPosts}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                indexOfLastPost={indexOfLastPost}
              />
            </MDBCol>
          )}
        </MDBRow>
        {!isMobileOrTablet ? (
          <MDBRow className="table-inventory-wrapper">
            {currentPosts?.length === 0 ? (
              <p> There are currently no results for this search.</p>
            ) : (
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>Model No.</th>
                    <th>Simple SKU</th>
                    <th>Inventory</th>
                    {/*<th>Description</th>*/}
                    <th>Next rec. date</th>
                    <th>Discontinued</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>
                  {currentPosts?.map((item) => {
                    return (
                      <tr key={`inventory-data-${item.sku}-${item.id}`}>
                        <td>
                          <Link
                            className={`${
                              isDiscontinued(item) ? "inactive" : "active"
                            }`}
                            to={item.url_key && item.url_key}
                            target="_blank"
                          >
                            {item.sku}
                          </Link>
                        </td>
                        <td className={"calculated-simple"}>
                          {calculatedSku(item)}
                        </td>
                        <td className={"calculated-simple"}>
                          {calculatedQty(item)}
                        </td>
                        {/*<td dangerouslySetInnerHTML={{__html: item.description.html}} />*/}
                        <td className={"calculated-simple"}>
                          {calculatedDate(item, "next_rec_date")}
                        </td>
                        <td className={"calculated-simple"}>
                          {calculatedBoolean(item, "discontinued")}
                        </td>
                      </tr>
                    );
                  })}
                </MDBTableBody>
              </MDBTable>
            )}
          </MDBRow>
        ) : (
          <InventoryMobile {...props} currentPosts={currentPosts} />
        )}
        {inventoryItems?.length > 12 && (
          <Pagination
            position={"bottom"}
            postsPerPage={postsPerPage}
            totalPosts={totalPosts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            indexOfLastPost={indexOfLastPost}
          />
        )}
      </MDBContainer>
    </>
  );
};
export default Inventory;
