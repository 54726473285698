import React from "react";
import ActiveFilterItem from "app/pages/catalog/category/toolbar/activeFilters/activeFilterItem";
import { isUndefined } from "core/helpers/functions";
import {
  clearAllFilters,
  getSearchParameters,
} from "app/layout/filters/functions";
import uuid from "react-uuid";
const ActiveFilters = ({ activeFilters }) => {
  return (
    <div className="active-filters-wrapper">
      <div className="active-filters-items">
        {!isUndefined(activeFilters) &&
          activeFilters.map((filter) => {
            if (isUndefined(filter)) {
              return <></>;
            } else {
              return <ActiveFilterItem key={uuid()} filter={filter} />;
            }
          })}
      </div>
      <div className={"active-filters-info "}>
        {!isUndefined(activeFilters) && activeFilters.length > 0 ? (
          <p onClick={() => clearAllFilters()}>
            Clear All({activeFilters.length})
          </p>
        ) : getSearchParameters() === undefined ||
          Object.keys(getSearchParameters()).length === 0 ? (
          <p className="no-filters">No filters added(0)</p>
        ) : (
          <p onClick={() => clearAllFilters()}>
            <p className="no-filters">No filters added(0)</p>
          </p>
        )}
      </div>
    </div>
  );
};

export default ActiveFilters;
