export const handleSubmit = (
  e,
  setTouched,
  errors,
  setLoading,
  subscribe,
  email,
  firstName,
  lastName,
  whatDescribesYou,
  whatDescribesYouValidation,
  setWhatDescribesYouValidation,
  setFirstName,
  setLastName,
  setEmail,
  setWhatDescribesYou,
  lock
) => {
  e.preventDefault();
  let isTrue = true;

  setTouched({ email: true, firstName: true, lastName: true });
  Object.keys(errors).map((item) => {
    let error = errors[item];
    if (isTrue) {
      if (error === true) {
        isTrue = false;
      }
    }
  });
  if (typeof whatDescribesYou === "undefined") {
    setWhatDescribesYouValidation(false);
    setLoading(false);
  } else {
    setWhatDescribesYouValidation(true);
    setLoading(false);
  }
  if (isTrue && whatDescribesYouValidation) {
    setLoading(true);
    lock({
      always: (e) => {},
      success: (e) => {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setWhatDescribesYou("");
        setTouched({ email: false, firstName: false, lastName: false });
      },
      fail: (e) => {
        setLoading(false);
      },
    });
    subscribe(email, firstName, lastName, whatDescribesYou);
  }
};
