import React from "react";

export function Handle({
  handle: { id, value, percent },
  getHandleProps,
  useDecimal = false,
}) {
  return (
    <div
      style={{
        left: `${percent}%`,
      }}
      className={`handle`}
      {...getHandleProps(id)}
    >
      {(useDecimal && <div className={`text`}>{value.toFixed(1)}</div>) || (
        <div className={`text`}>{value}</div>
      )}
    </div>
  );
}

export function Track({ source, target, getTrackProps }) {
  return (
    <div
      className={`track`}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickable (optional) */
      }
    />
  );
}

export function Tick({ tick, count }) {
  return (
    <div className={`ticks`}>
      <div
        className={`tick-first`}
        style={{
          left: `${tick.percent}%`,
        }}
      />
      <div
        className={`tick-second`}
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value}
      </div>
    </div>
  );
}
