import React from "react";
import Slider from "react-slick";
import CategoryItem from "../categoryItem";
import sliderSettings from "./sliderSettings";

const OurCategoriesMobile = (props) => {
  const settings = sliderSettings();

  return (
    <Slider {...settings} className="our-categories-slider">
      {props &&
        props.onHomeCategories &&
        props.onHomeCategories.map((category, index) => {
          return (
            <CategoryItem
              key={`slider-slide-item-${index}`}
              category={category}
              site={props.site}
            />
          );
        })}
    </Slider>
  );
};
export default OurCategoriesMobile;
