import React, { useState } from "react";
import Menu from "./menu";

const MenuStateContainer = ({ menuItems, width, setOpen }) => {
  menuItems.children = menuItems.children?.map((menuItem, index) => {
    return {
      ...menuItem,
      position: index + 1,
    };
  });

  const [hoveredDropdown, setHoveredDropdown] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  menuItems =
    typeof menuItems !== "undefined" &&
    typeof menuItems.children !== "undefined"
      ? menuItems.children
      : {};

  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [categoryIndex, setCategoryIndex] = useState(undefined);

  const openMobileDropdown = (index) => {
    if (categoryIndex === index) {
      if (showMobileDropdown === true) {
        setShowMobileDropdown(false);
      } else {
        setShowMobileDropdown(true);
      }
    } else {
      setCategoryIndex(index);
      setShowMobileDropdown(true);
    }
  };

  return (
    <Menu
      menuItems={menuItems}
      hoveredDropdown={hoveredDropdown}
      setHoveredDropdown={setHoveredDropdown}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      openMobileDropdown={openMobileDropdown}
      showMobileDropdown={showMobileDropdown}
      setShowMobileDropdown={setShowMobileDropdown}
      categoryIndex={categoryIndex}
      setCategoryIndex={setCategoryIndex}
      width={width}
      setOpen={setOpen}
    />
  );
};

export default MenuStateContainer;
