import React, { useState } from "react";
import QuoteRequestSummary from "./quoteRequestSummary";

const QuoteRequestSummaryStateContainer = (props) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [comments, setComments] = useState("");
  const [touched, setTouched] = useState({});
  const [fileForUpload, setFileForUpload] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <QuoteRequestSummary
      {...props}
      email={email}
      setEmail={setEmail}
      phone={phone}
      setPhone={setPhone}
      companyName={companyName}
      setCompanyName={setCompanyName}
      comments={comments}
      setComments={setComments}
      touched={touched}
      setTouched={setTouched}
      fileForUpload={fileForUpload}
      setFileForUpload={setFileForUpload}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default QuoteRequestSummaryStateContainer;
