import React, { memo, useState, useEffect } from "react";
import { MDBCol, MDBRow } from "mdbreact";
import Breadcrumbs from "app/layout/breadcrumbs";
import GalleryToolbar from "app/layout/customToolbar";
import GalleryItem from "./galleryItem";
import uuid from "react-uuid";
import Pagination from "app/layout/customPagination";
import { useMediaQuery } from "react-responsive";
import { isEqual } from "lodash";
import { useLocation } from "react-router-dom";

const Gallery = memo(
  (props) => {
    const location = useLocation();
    const currentSelectedPage = window.location.hostname.indexOf('ilex') === -1;
    let { norwellGalleries, ilexGalleries } = props;
    const [showGalleries, setShowGalleries] = useState(currentSelectedPage ? norwellGalleries : ilexGalleries);
    const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
    //pagination variables
    const totalPosts = showGalleries.length;
    const [posts, setPosts] = useState(showGalleries);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(12);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts =
      posts && posts.slice(indexOfFirstPost, indexOfLastPost);

    useEffect(() => {
      if (location.state) {
        setCurrentPage(location.state.currentPage);
        setShowGalleries(location.state.currentPosts);
      } else {
        setCurrentPage(1);
      }
      setPosts(showGalleries);
    }, [showGalleries]);

    return (
      <MDBCol className="gallery-container">
        {!isMobileOrTablet && (
          <MDBRow className="breadcrumbs-container ">
            <Breadcrumbs />
          </MDBRow>
        )}
        <MDBRow className="title-container" fluid>
          <p className="subtitle wow slideInLeft">
            <label className="subtitle-element"></label>BROWSE THROUGH
            EXPERIENCE
          </p>
          <h1 className="title wow slideInUp">Gallery</h1>
        </MDBRow>
        <GalleryToolbar
          showData={showGalleries}
          setShowData={setShowGalleries}
          norwellData={norwellGalleries}
          ilexData={ilexGalleries}
          location={"gallery"}
        />
        {showGalleries.length > 12 && (
          <Pagination
            position={"top"}
            postsPerPage={postsPerPage}
            totalPosts={totalPosts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            indexOfLastPost={indexOfLastPost}
          />
        )}
        <MDBRow>
          <MDBCol className={"gallery-items-container"}>
            {currentPosts &&
              currentPosts.map((gallery, index) => {
                return (
                  <GalleryItem
                    gallery={gallery}
                    key={uuid()}
                    index={index}
                    galleries={showGalleries}
                    currentPage={currentPage}
                  />
                );
              })}
          </MDBCol>
        </MDBRow>
        {showGalleries.length > 12 && (
          <Pagination
            position={"bottom"}
            postsPerPage={postsPerPage}
            totalPosts={totalPosts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            indexOfLastPost={indexOfLastPost}
          />
        )}
      </MDBCol>
    );
  },
  (prevProps, nextProps) => {
    if (isEqual(prevProps, nextProps)) {
      return true;
    }
    return false;
  }
);

export default Gallery;
