import React from "react";
import { connect } from "react-redux";
import commonActions from "core/state/redux/data/common/actions";
import customerActions from "app/state/redux/data/customer/actions";
import ProductSpecifications from "./productSpecification-data-container";

const ProductSpecificationsReduxContainer = (props) => {
  return <ProductSpecifications {...props} />;
};

const mapStateToProps = (state) => ({
  customer:
    typeof state.customer.data !== "undefined"
      ? state.customer.data.token
      : false,
  cart_items:
    state.customer.data !== undefined
      ? state.customer.data.cart !== undefined
        ? state.customer.data.cart
          ? state.customer.data.cart.items
          : []
        : []
      : [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    unLock: () => {
      dispatch(commonActions.unlock());
    },
    lock: () => {
      dispatch(commonActions.lock());
    },
    addItemToCart: (
      amount,
      productSku,
      customizableOptionId,
      customizableOptionValue
    ) => {
      dispatch(
        customerActions.addProductsToCart([
          {
            data: {
              sku: productSku,
              quantity: amount,
            },
            customizable_options: [
              {
                id: customizableOptionId,
                value_string: customizableOptionValue,
              },
            ],
          },
        ])
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSpecificationsReduxContainer);
