import { loader } from "graphql.macro";

export default (query, additionalMapping = []) => {
  if (query === "urlResolver") {
    return loader("app/graphql/queries/urlResolver.graphql");
  }

  throw new Error(
    "Unknown query, you probably forgot to define it in config/routing/loader.js"
  );
};
