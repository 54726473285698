import React from "react";
import Breadcrumbs from "./breadcrumbs";
import { useLocation } from "react-router-dom";

const BreadcrumbsStateContainer = (props) => {
  const location = useLocation();
  const sacntify = (string) => {
    string = string.replace(/-/g, " ");
    return string;
  };
  const getParents = (items, current) => {
    let currentIndex = items.indexOf(current);
    let urlPath = items.slice(0, currentIndex + 1).join("/");
    return {
      url: urlPath,
      title: sacntify(current),
    };
  };

  const getLinks = () => {
    let links = [];
    let pathname = location.pathname;
    if (pathname.charAt(pathname.length - 1) === "/") {
      pathname = pathname.substr(0, pathname.length - 1);
    }
    let items = location.pathname.split("/");
    items.map((item) => {
      if (item === "") {
        links.push({ url: "/", title: "Home" });
      } else {
        links.push(getParents(items, item));
      }
    });
    return links;
  };

  return <Breadcrumbs {...props} backupLinks={getLinks()} />;
};

export default BreadcrumbsStateContainer;
