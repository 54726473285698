import React, { useState } from "react";
import RegisterForm from "./registerForm";

const RegisterStateContainer = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [subscribe, setSubscribe] = useState(true);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({});
  const [termsValidationMessage, setTermsValidationMessage] = useState(false);
  return (
    <RegisterForm
      {...props}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      companyName={companyName}
      setCompanyName={setCompanyName}
      email={email}
      setEmail={setEmail}
      confirmEmail={confirmEmail}
      setConfirmEmail={setConfirmEmail}
      phone={phone}
      setPhone={setPhone}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      termsAndConditions={termsAndConditions}
      setTermsAndConditions={setTermsAndConditions}
      subscribe={subscribe}
      setSubscribe={setSubscribe}
      status={status}
      setStatus={setStatus}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      termsValidationMessage={termsValidationMessage}
      setTermsValidationMessage={setTermsValidationMessage}
    />
  );
};

export default RegisterStateContainer;
