import apolloClient from "core/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import messagesActions from "core/state/redux/data/messages/actions.js";
import commonActions from "core/state/redux/data/common/actions";

const NEWSLETTER_CUSTOMER_SUBSCRIBE = "newsletterCustomerSubscribe";
const IS_CUSTOMER_SUBSCRIBED = "isCustomerSubscribed";

export default async (store, action) => {
  try {
    const { data } = await apolloClient.query({
      query: queryLoader(IS_CUSTOMER_SUBSCRIBED),
      variables: {
        email: action.email,
      },
    });

    const is_subscribed = data.isEmailSubscribed.is_subscribed;

    if (!is_subscribed) {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader(NEWSLETTER_CUSTOMER_SUBSCRIBE),
        variables: {
          email: action.email,
          first_name: action.first_name,
          last_name: action.last_name,
          what_describes_you: action.what_describes_you,
        },
      });
      store.dispatch(
        messagesActions.addMessage(
          "You have successfully subscribed.",
          "success",
          "subscribe"
        )
      );
    } else {
      store.dispatch(
        messagesActions.addMessage(
          "This email is already subscribed",
          "danger",
          "subscribe"
        )
      );
    }
  } catch (error) {
    store.dispatch(
      messagesActions.addMessage(error.toString(), "danger", "subscribe")
    );
    store.dispatch(commonActions.unlock());
    return;
  }
};
