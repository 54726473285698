import commonActions from "../../common/actions";

export default async (store, action) => {
  try {
    let recentlyViewedList = store.getState().customer.recently_viewed.items;
    let exists = false;

    //stores maximum 20 items in recently viewed array
    if (recentlyViewedList.length > 20) {
      recentlyViewedList.pop();
    }
    recentlyViewedList.map((item) => {
      if (item.sku === action.product.sku) {
        exists = true;
        item.timestamp = Date.now();
      }
      return item;
    });
    //if item already exists, update timestamp
    if (!exists) {
      if (typeof action.productSky !== undefined)
        recentlyViewedList.push({
          ...action.product,
          timestamp: Date.now(),
        });
    }
    recentlyViewedList.sort(
      (item1, item2) => item2.timestamp - item1.timestamp
    );
    store.dispatch(commonActions.unlock("success"));
  } catch (error) {
    store.dispatch(commonActions.unlock("fail"));
  }
};
