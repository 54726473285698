import React, { useState, useEffect } from "react";
import Product from "./product";
import { isUndefined } from "core/helpers/functions";

const ProductDataContainer = (props) => {
  let { data } = props;
  let product =
    !isUndefined(data) &&
    !isUndefined(data.products) &&
    !isUndefined(data.products.items[0])
      ? data.products.items[0]
      : {
          name: "Loading...",
          description: { html: "<p>Retrieving information...</p>" },
          image: { url: "" },
        };

  //object for adding to recently viewed products
  let currentlyViewedProduct = {
    sku: product.sku && product.sku,
    name: product.name && product.name,
    url_key: product.url_key && product.url_key,
    image: {
      label: product.image && product.image.label && product.image.label,
      url: product.image && product.image.url && product.image.url,
    },
    finishes_count: product.finishes_count && product.finishes_count,
    is_new: product.is_new && product.is_new,
  };

  return (
    <Product
      {...props}
      data={product}
      currentlyViewedProduct={currentlyViewedProduct}
    />
  );
};

export default ProductDataContainer;
