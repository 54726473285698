import React from "react";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import { MDBCol } from "mdbreact";
import Input from "app/layout/input";
import Button from "core/components/button";
import { validate, shouldMarkError } from "./returnPolicyFormValidation";
import MaskedInput from "react-text-mask";
import { returnPolicySubmit } from "./returnPolicyFormSubmit";
import Icon from "app/assets/icon/icon";
import useSite from "app/state/hooks/siteHooks/useSite";
import SessionMessages from "core/components/sessionMessages";

const ReturnPolicyForm = (props) => {
  let {
    companyName,
    setCompanyName,
    firstName,
    setFirstName,
    phone,
    setPhone,
    email,
    setEmail,
    subject,
    setSubject,
    message,
    setMessage,
    loading,
    setLoading,
    touched,
    setTouched,
    messageValidation,
    setMessageValidation,
    sendEmail,
  } = props;

  const [lock] = useLocking();
  let errors = validate(companyName, firstName, phone, email, subject, message);
  const [, getSiteCode] = useSite();
  const site = getSiteCode();
  return (
    <MDBCol lg="4" className="return-policy-form">
      <h5>Please fill in the form</h5>
      <Input
        placeholder={`${
          shouldMarkError("companyName", errors, touched)
            ? "Company Name is required"
            : "Company Name"
        }`}
        required={true}
        type={"text"}
        value={companyName}
        onChange={(event) => setCompanyName(event.target.value)}
        className={
          shouldMarkError("companyName", errors, touched)
            ? "invalid"
            : "" + companyName !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, companyName: true });
        }}
        shouldMarkError={shouldMarkError("companyName", errors, touched)}
      />
      <Input
        placeholder={`${
          shouldMarkError("firstName", errors, touched)
            ? "First Name is required"
            : "First Name"
        }`}
        required={true}
        type={"text"}
        value={firstName}
        onChange={(event) => setFirstName(event.target.value)}
        className={
          shouldMarkError("firstName", errors, touched)
            ? "invalid"
            : "" + firstName !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, firstName: true });
        }}
        shouldMarkError={shouldMarkError("firstName", errors, touched)}
      />
      <div className="masked-input">
        <MaskedInput
          type={"text"}
          mask={[
            "(",
            /[1-9]/,
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          value={phone}
          guide={false}
          onChange={(event) => setPhone(event.target.value)}
          onBlur={(e) => {
            setTouched({ ...touched, phone: true });
          }}
          className={
            shouldMarkError("phone", errors, touched)
              ? "invalid"
              : "" + phone !== "" && "valid"
          }
          placeholder={`${
            shouldMarkError("phone", errors, touched)
              ? "Phone Number is required"
              : "Phone Number"
          }`}
        />
        <label className="required-label">
          {" "}
          {typeof shouldMarkError("phone", errors, touched) === "undefined" ? (
            "Required"
          ) : shouldMarkError("phone", errors, touched) ? (
            <Icon icon="error_icon" />
          ) : (
            <Icon icon="check_mark_icon_black" />
          )}
        </label>
      </div>
      <Input
        placeholder={`${
          shouldMarkError("email", errors, touched)
            ? "Email is required"
            : "Email"
        }`}
        required={true}
        type={"text"}
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        className={
          shouldMarkError("email", errors, touched)
            ? "invalid"
            : "" + email !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, email: true });
        }}
        shouldMarkError={shouldMarkError("email", errors, touched)}
      />
      <Input
        placeholder={`${
          shouldMarkError("subject", errors, touched)
            ? "Subject is required"
            : "Subject"
        }`}
        required={true}
        type={"text"}
        value={subject}
        onChange={(event) => setSubject(event.target.value)}
        className={
          shouldMarkError("subject", errors, touched)
            ? "invalid"
            : "" + subject !== "" && "valid"
        }
        onBlur={(e) => {
          setTouched({ ...touched, subject: true });
        }}
        shouldMarkError={shouldMarkError("subject", errors, touched)}
      />
      <div className="textarea-container">
        <Input
          type="textarea"
          placeholder={`${
            shouldMarkError("message", errors, touched)
              ? "Message is required"
              : "Message"
          }`}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          className={"textarea"}
          onBlur={(e) => {
            setTouched({ ...touched, message: true });
          }}
          shouldMarkError={shouldMarkError("message", errors, touched)}
          maxLength="250"
        />
        <label className="textarea-length">{message.length + "/250"}</label>
      </div>
      <MDBCol className="button-container">
        <Button
          disabled={loading && true}
          onClick={(e) => {
            returnPolicySubmit(
              e,
              companyName,
              firstName,
              phone,
              email,
              subject,
              message,
              setTouched,
              errors,
              lock,
              setLoading,
              messageValidation,
              setMessageValidation,
              sendEmail,
              site,
              setCompanyName,
              setFirstName,
              setPhone,
              setEmail,
              setSubject,
              setMessage
            );
          }}
        >
          {loading ? "Sending..." : "Send"}
        </Button>
      </MDBCol>
      <p className={"error-message " + messageValidation}>
        Please enter the message
      </p>
      <p className="session-message">
        <SessionMessages target="sendEmail" />
      </p>
    </MDBCol>
  );
};

export default ReturnPolicyForm;
