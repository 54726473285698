import React from "react";
import { connect } from "react-redux";
import WhatsNewSlider from "./whatsNewSlider-query-container";

const WhatsNewSliderReduxContainer = (props) => {
  return <WhatsNewSlider {...props} />;
};

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, null)(WhatsNewSliderReduxContainer);
