import React from "react";
import gql from "graphql-tag";
import FooterReduxContainer from "./footer-redux-container";
import GraphqlWrapper from "app/layout/graphQlWrapper";

const STORE_CONFIG = gql`
  query storeConfig {
    storeConfig {
      website_id
      header_logo_src
      logo_alt
      copyright
    }
  }
`;

const FooterQueryContainer = (props) => {
  return (
    <GraphqlWrapper
      query={STORE_CONFIG}
      variables={{
        id: "store_config",
      }}
      saveQuery={true}
    >
      <FooterReduxContainer {...props} />
    </GraphqlWrapper>
  );
};

export default FooterQueryContainer;
