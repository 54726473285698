import React from "react";
import BannerSlider from "./bannerSlider";

const BannerSliderDataContainer = (props) => {
  let sortedSlides =
    props.data &&
    props.data.slider &&
    props.data.slider.slides &&
    props.data.slider.slides;

  sortedSlides =
    sortedSlides &&
    sortedSlides.sort((slide1, slide2) => {
      return slide1.order_number - slide2.order_number;
    });

  return <BannerSlider {...props} sortedSlides={sortedSlides} />;
};

export default BannerSliderDataContainer;
