import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Icon from "app/assets/icon/icon";
import { Link } from "react-router-dom";
import LoginRegisterPopupModal from "app/pages/customer/loginRegisterPopupModal/login-registerPopupModal";
import AccountMobileModal from "./accountMobile";

const Dropdown = ({
  mobile,
  toggleLoginRegisterModal,
  setToggleLoginRegisterModal,
  toggleAccountMobile,
  setToggleAccountMobile,
  clickable = true,
}) => {
  const dropdown = useRef(null);
  const dispatch = useDispatch();
  const customer = useSelector((state) =>
    state !== undefined && state.customer !== undefined
      ? state.customer.data
      : undefined
  );
  const [toggled, setToggled] = useState(false);
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setToggled(false);
      }
    };
    document.addEventListener("scroll", handleClickOutside, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.addEventListener("scroll", handleClickOutside, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return mobile === false ? (
    <>
      <div
        className={"header-top-float-right-account_dropdown-wrapper"}
        onClick={() => {
          if (!customer.token && clickable) {
            setToggleLoginRegisterModal(!toggleLoginRegisterModal);
          }
        }}
      >
        <div
          ref={dropdown}
          className="header-dropdown-wrapper"
          id="header_dropdown_id"
        >
          {customer !== undefined &&
          customer.firstname !== false &&
          customer.firstname?.length > 0 ? (
            <button
              onClick={() => {
                if (clickable) {
                  setToggled(!toggled);
                }
              }}
            >
              {customer.firstname !== false &&
              customer.firstname !== undefined ? (
                <>
                  <label>Welcome</label>

                  <label className="header-dropdown-wrapper-customer-name">
                    {", " + customer.firstname}
                  </label>
                  <Icon
                    icon={
                      site.siteCode.includes("norwell")
                        ? "account_icon"
                        : "account_icon"
                    }
                  />
                </>
              ) : (
                <></>
              )}
            </button>
          ) : (
            <>
              <Icon
                icon={
                  site.siteCode.includes("norwell")
                    ? "account_icon"
                    : "account_icon"
                }
              />
            </>
          )}
        </div>
        <div className={toggled ? "dropdown_active" : "dropdown_not_active"}>
          <Link to="/customer">My Account</Link>
          <p
            onClick={() => {
              dispatch({
                type: "jola/customer/LOGOUT_CUSTOMER",
              });
            }}
          >
            Logout
          </p>
        </div>
      </div>
      <LoginRegisterPopupModal
        toggleModal={toggleLoginRegisterModal}
        setToggleModal={setToggleLoginRegisterModal}
      />
    </>
  ) : (
    <AccountMobileModal
      toggleModal={toggleAccountMobile}
      setToggleModal={setToggleAccountMobile}
    />
  );
};

export default Dropdown;
