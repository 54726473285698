import React, { useState } from "react";
import ProductSlider from "./productMediaGallerySlider";

const ProductMediaGallerySliderDataContainer = (data) => {
  const [sliderNav1, setSliderNav1] = useState(null);
  const [sliderNav2, setSliderNav2] = useState(null);
  const [sliderNav3, setSliderNav3] = useState(null);
  let slider1 = null;
  let slider2 = null;
  let slider3 = null;

  const base_image = typeof data !== undefined && data.image && data.image;
  const [activeImage, setActiveImage] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [popupGallery, setPopupGallery] = useState(false);

  let image_gallery =
    typeof data !== undefined &&
    data.media_gallery &&
    typeof data.media_gallery !== undefined
      ? data.media_gallery
      : false;
  image_gallery =
    image_gallery &&
    image_gallery.filter((image) => {
      if (image.disabled) {
        return false;
      } else {
        return image.url !== base_image.url;
      }
    });

  const finishes =
    typeof data !== undefined &&
    data.options &&
    typeof data.options !== undefined &&
    typeof data.options[0] !== undefined &&
    typeof data.options[0].value !== undefined
      ? data.options[0].value
      : false;

  return (
    <ProductSlider
      {...data}
      sliderNav1={sliderNav1}
      setSliderNav1={setSliderNav1}
      sliderNav2={sliderNav2}
      setSliderNav2={setSliderNav2}
      sliderNav3={sliderNav3}
      setSliderNav3={setSliderNav3}
      slider1={slider1}
      slider2={slider2}
      slider3={slider3}
      base_image={base_image}
      image_gallery={image_gallery}
      finishes={finishes}
      activeImage={activeImage}
      setActiveImage={setActiveImage}
      popupGallery={popupGallery}
      setPopupGallery={setPopupGallery}
      activeImageIndex={activeImageIndex}
      setActiveImageIndex={setActiveImageIndex}
    />
  );
};

export default ProductMediaGallerySliderDataContainer;
