import { handleLogin } from "./handleLogin";
export const handleKeyDown = (
  e,
  setTouched,
  errors,
  setLoading,
  props,
  component,
  login,
  email,
  password,
  customer,
  setStatus,
  cartToken
) => {
  if (e.key === "Enter") {
    handleLogin(
      e,
      setTouched,
      errors,
      setLoading,
      props,
      component,
      login,
      email,
      password,
      customer,
      setStatus,
      cartToken
    );
  }
};
