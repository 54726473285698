import apolloClient from "app/graphql/apolloClient";
import queryLoader from "app/graphql/queryLoader";
import { messagesActions } from "core/state/redux/data/messages";
import { isArray, isObject } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "app/state/redux/data/common";
import { customerActions } from "app/state/redux/data/customer";

function useCart() {
  const cart = useSelector((state) => state.customer.data.cart);

  const getCart = () => {
    return cart;
  };
  const addProductToCart = async (request) => {
    if (request.type === "SimpleProduct") {
      addSimpleProductToCart(request);
    }
    if (request.type === "BundleProduct") {
      addBundledProductToCart(request);
    }
    if (request.type === "ConfigurableProduct") {
      dispatch(commonActions.unlock("fail"));
      dispatch(
        messagesActions.addMessage(
          "configurable product support is disabled",
          "danger"
        )
      );
    }
  };

  const addSimpleProductToCart = async (request) => {
    // creating proper buyRequest
    let buyRequest = [];
    if (typeof request.data === "object") {
      buyRequest = [
        {
          data: { ...request.data },
          customizable_options: { ...request.customizable_options },
        },
      ];
    } else if (isArray(request.data)) {
      buyRequest = [...request.data];
    }

    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addProduct"),
        variables: {
          items: buyRequest,
          cart_id: cart.id,
        },
      });
      if (data.addSimpleProductsToCart !== null) {
        dispatch(
          customerActions.setCartInformation(data.addSimpleProductsToCart.cart)
        );
        dispatch(
          messagesActions.addMessage(
            "Item successfully added to cart!",
            "success"
          )
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      dispatch(
        messagesActions.addMessage(
          "unable to add products to cart, please try again later",
          "danger"
        )
      );
    }
  };

  const addBundledProductToCart = async (request) => {
    // creating proper buyRequest
    let buyRequest = [...generateBundleBuyRequest(request)];

    try {
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("addBundleProductsToCart"),
        variables: {
          items: buyRequest,
          cart_id: cart.id,
        },
      });
      if (data.addBundleProductsToCart !== null) {
        dispatch(
          customerActions.setCartInformation(data.addBundleProductsToCart.cart)
        );
        dispatch(
          messagesActions.addMessage(
            "Item successfully added to cart!",
            "success"
          )
        );
        dispatch(commonActions.unlock("success"));
      }
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      dispatch(
        messagesActions.addMessage(
          "unable to add products to cart, please try again later",
          "danger"
        )
      );
      console.log(err);
    }
  };

  const saveAdditionalCheckInformation = async (request) => {
    try {
      let finalRequest = { ...request, cart_id: cart.id };
      const { data } = await apolloClient.mutate({
        mutation: queryLoader("setCheckAdditional"),
        variables: { input: finalRequest },
        fetchPolicy: "no-cache",
      });

      dispatch(
        messagesActions.addMessage("Check information updated", "success")
      );
      dispatch(customerActions.setCheckAdditional(data.setCheckAdditional));
      dispatch(commonActions.unlock("success"));
    } catch (err) {
      dispatch(commonActions.unlock("fail"));
      console.log(err.message);
      return;
    }
  };

  const getCartInformation = () => {
    dispatch(customerActions.getCartInformation());
  };

  let dispatch = useDispatch();

  return [
    getCart,
    saveAdditionalCheckInformation,
    addProductToCart,
    getCartInformation,
  ];
}

// helper functions
const generateBundleBuyRequest = (request) => {
  let buyRequest = [];
  let bundle_options = [];

  Object.keys(request.data.bundle_options).map((item) => {
    let bundle = request.data.bundle_options[item];
    if (isArray(bundle)) {
      // write logic if multiple bundle items are sent through array
    }
    if (isObject(bundle)) {
      Object.keys(bundle).map((bundle_key) => {
        let bundle_item = { ...bundle[bundle_key] };
        if (typeof bundle_item.id === "undefined") return "";
        if (typeof bundle_item.value === "undefined") {
          bundle_item["value"] = bundle_item.id;
        }

        let sanitized_bundle_item = {
          id: bundle_item.option_id,
          value: [bundle_item.value + ""],
          quantity: parseFloat(bundle_item.quantity),
        };
        return bundle_options.push(sanitized_bundle_item);
      });
    }
    return "";
  });
  if (typeof request.data === "object") {
    if (typeof request.customizable_options === "undefined") {
      request["customizable_options"] = [];
    }
    let item_request = {
      sku: request.data.sku,
      quantity: parseFloat(request.data.quantity),
    };
    buyRequest = [
      {
        data: item_request,
        customizable_options: [...request.customizable_options],
        bundle_options: bundle_options,
      },
    ];
  } else if (isArray(request.data)) {
    buyRequest = [...request.data];
  }

  return buyRequest;
};
export { useCart };
