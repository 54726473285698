import React, { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import filterActions from "app/state/redux/data/filters/actions";
import { MDBCol } from "mdbreact";
import { createPageSizeOptions } from "./data";
import uuid from "react-uuid";

const PageView = (props) => {
  const dispatch = useDispatch();

  const { pageInformation, totalCount } = props;

  const pageSizeOptions = createPageSizeOptions(totalCount);

  const [openMenu, setOpenMenu] = useState(false);

  const ref = useRef(null);

  const onClickOutside = useCallback((e) => {
    if (!ref.current.contains(e.target)) setOpenMenu(false);
  }, []);
  let viewLabel = pageSizeOptions.filter((item) => {
    if (pageInformation?.page_size === item.value) return item;
  });
  let viewLabelShow = pageSizeOptions[0].label;
  if (typeof viewLabel !== "undefined" && viewLabel !== null) {
    if (viewLabel.length === 0) viewLabelShow = pageSizeOptions[0].label;
    else viewLabelShow = viewLabel[0].label;
  }

  useEffect(() => {
    document.addEventListener("click", onClickOutside);
    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, []);

  return (
    <MDBCol className={"page-view-component " + props.position}>
      <div onClick={() => setOpenMenu(!openMenu)} ref={ref}>
        <p>{viewLabelShow}</p>
        <span className={"arrow " + openMenu}></span>
      </div>
      <ul className={"page-view-list " + openMenu} reversed>
        {pageSizeOptions?.map((item) => {
          return (
            <li
              key={uuid()}
              onClick={() => {
                dispatch(filterActions.changePageSize(item.value));
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            >
              {item.label}
            </li>
          );
        })}
      </ul>
    </MDBCol>
  );
};

export default PageView;
