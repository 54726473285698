export const quoteRequestSummary = (
  e,
  email,
  phone,
  companyName,
  comments,
  sendEmail,
  fileBase64,
  fileType,
  fileName,
  touched,
  setTouched,
  errors,
  lock,
  setLoading,
  quoteStatus,
  setQuoteStatus,
  productsAndComments,
  setPhone,
  setEmail,
  setCompanyName,
  setComments,
  setFileForUpload,
  getCartInformation
) => {
  e.preventDefault();
  let isTrue = true;

  setTouched({
    email: true,
    phone: true,
    companyName: true,
  });
  Object.keys(errors).map((item) => {
    let error = errors[item];
    if (isTrue) {
      if (error === true) {
        isTrue = false;
      }
    }
  });
  if (isTrue) {
    let input = {
      input: {
        attributes: [
          { code: "email", value: email },
          { code: "phone", value: phone },
          { code: "companyName", value: companyName },
          { code: "comments", value: comments },
          ...productsAndComments,
        ],
        fileBase64: fileBase64,
        fileType: fileType,
        fileName: fileName,
        template: "13",
        is_quote: true,
        return_form: false,
      },
    };

    setLoading(true);
    lock({
      always: (e) => {},
      success: (e) => {
        setLoading(false);
        setQuoteStatus(true);
        setPhone("");
        setEmail("");
        setCompanyName("");
        setComments("");
        setFileForUpload("");
        setTouched({
          email: false,
          phone: false,
          companyName: false,
        });
        getCartInformation();
      },
      fail: (e) => {
        setLoading(false);
        setQuoteStatus(false);
      },
    });
    sendEmail(input);
  }
};
