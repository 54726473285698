import React, { useState } from "react";
import ResetPassword from "./resetPasswordForm";

const ResetPasswordStateContainer = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const [status, setStatus] = useState(false);

  return (
    <ResetPassword
      props={props}
      email={email}
      setEmail={setEmail}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      status={status}
      setStatus={setStatus}
      toggleModal={props.toggleModal}
      setToggleModal={props.setToggleModal}
      toggleLoginModal={props.toggleLoginModal}
      setToggleLoginModal={props.setToggleLoginModal}
    />
  );
};

export default ResetPasswordStateContainer;
