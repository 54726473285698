import React from "react";
import { sliderSettings } from "./setting";
import Slider from "react-slick";
import CheckboxFilter from "app/layout/filters/checkboxFilter";
import { useEffect } from "react";

const SidebarItemSlider = (props) => {
  let {
    isSwitch,
    filter,
    activeFilters,
    activeFiltersNumber,
    setActiveFiltersNumber,
  } = props;

  useEffect(() => {
    let total = activeFiltersNumber;
    if (activeFilters.length > 0) {
      filter.options.map((item) => {
        activeFilters.map((activeFilter) => {
          if (activeFilter.value === item.value) {
            total = total + 1;
          }
        });
      });
      setActiveFiltersNumber(total);
    }
  }, []);

  return (
    <Slider {...sliderSettings}>
      {filter.options.map((filterItem, index) => {
        return (
          <CheckboxFilter
            key={index}
            filterOption={filterItem}
            filter={filter}
            isSwitch={isSwitch}
          />
        );
      })}
    </Slider>
  );
};

export default SidebarItemSlider;
