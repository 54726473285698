import React from "react";
import { MDBView } from "mdbreact";
import themeConfiguration from "config/themeConfiguration";
import HotspotMask from "./hotspotMask";

const Hotspot = (props) => {
  let image = props.image?.includes("pub") ? props.image : `pub/${props.image}`;
  return (
    <div className="hotspotContentContainer">
      <MDBView>
        <img
          src={themeConfiguration.magento_url + image}
          className="hotspot-background-image"
          alt=""
        />
        {props?.dots &&
          props?.dots.map((dot, index) => {
            return <HotspotMask key={`card-component-${index}`} dot={dot} />;
          })}
      </MDBView>
    </div>
  );
};

export default Hotspot;
