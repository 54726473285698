import React from "react";
import Loader from "react-loader-spinner";
import themeConfiguration from "config/themeConfiguration";

const ReactLoader = (props) => {
  return (
    <div className={"loading-container"}>
      <Loader
        visible={typeof props.visible !== "undefined" ? props.visible : true}
        type={
          typeof props.type === "undefined"
            ? themeConfiguration.loader.type
            : props.type
        }
        color={(props.color && props.color) || themeConfiguration.loader.color}
        height={
          typeof props.height === "undefined"
            ? themeConfiguration.loader.height
            : props.height
        }
        width={
          typeof props.width === "undefined"
            ? themeConfiguration.loader.width
            : props.width
        }
      />
    </div>
  );
};

export default ReactLoader;
