import React, { useState } from "react";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
import Button from "core/components/button";
import Checkbox from "app/layout/checkbox";
import { isUndefined } from "core/helpers/functions";
import TitleDescription from "app/layout/titleDescription/titleDescription";
import Icon from "app/assets/icon/icon";

const Newsletter = (props) => {
  let [newsletter, setNewsletter] = useState(props.customer.is_subscribed);

  return (
    <MDBContainer className="account-information-container ">
      <MDBRow>
        <TitleDescription title="Newsletter Subscription" />
      </MDBRow>
      <MDBRow>
        <MDBCol lg="12">
          <h3 className="gray medium dashboard-subtite">Subscription Option</h3>
        </MDBCol>
      </MDBRow>
      <MDBRow className="account-information border-bottom">
        <MDBCol lg="6" md="12">
          <div className="checkbox-wrapper">
            <Checkbox
              checked={newsletter}
              setChecked={setNewsletter}
              checkbox_id={"newsletter)checkbox"}
              theme={"light"}
            />
            <p>General subscription</p>
          </div>
          <Button
            color="primary"
            className={"btn-with-icon big with-arrow"}
            direction={"mr-2"}
            leftIcon={true}
            disabled={props.locked}
            text={<span>{props.locked ? "Saving..." : "Save"} </span>}
            onClick={(e) => {
              e.preventDefault();
              if (isUndefined(props.locked) || props.locked === false) {
                props.onSubmit({
                  is_subscribed: newsletter,
                });
              }
            }}
          >
            Save
            <Icon icon="right_arrow_white" />
          </Button>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Newsletter;
