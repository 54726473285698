import React, { useEffect } from "react";
import Icon from "app/assets/icon/icon";
import Select from "app/layout/select";
import ActiveFilters from "app/pages/catalog/category/toolbar/activeFilters";
import { isUndefined, isObject } from "core/helpers/functions";
import { MDBRow, MDBCol } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import { getActiveFilters } from "app/pages/catalog/category/sidebar/functions";
import Button from "core/components/button";
import { useState } from "react";

const Toolbar = (props) => {
  const isTabOrMob = useMediaQuery({ maxWidth: 992 });

  let {
    data,
    sort,
    sortOrder,
    setSortOrder,
    changeSortFilterHandler,
    paginationOnly,
    dataLoading,
    toggleFilters,
    setToggleFilters,
    showBrand,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.pageNumber]);

  if (isUndefined(data) || dataLoading) {
    return <></>;
  }

  let activeFilters = getActiveFilters(props.filters?.data?.aggregations);

  let totalCount = props.pageInfo?.totalCount;
  let sortByOptions = data.sort_fields?.options;

  // Remove position from sort options
  // sortByOptions = sortByOptions?.filter((item) => {
  //   return item.value !== "position";
  // });

  //remove is new from sort options
  sortByOptions = sortByOptions?.filter((item) => {
    return item.value !== "is_new";
  });

  // Rename Alphabetical to Name for sort options
  if (sortByOptions) {
    sortByOptions.find((item, index) => {
      if (item.value === "name") {
        sortByOptions[index].label = "Name";
      }
    });
  }
  // Add Newest option to sort options
  // if (sortByOptions?.indexOf("Newest") === -1) {
  //   sortByOptions.push({ label: "Newest", value: "is_new" });
  // }

  return (
    <MDBRow className="toolbar-main-wrapper">
      <MDBCol md="12" id="toolbar">
        {(isUndefined(paginationOnly) && (
          <>
            <MDBRow
              id={showBrand && "search-page-toolbar"}
              className={"toolbar-container container " + toggleFilters}
            >
              {!showBrand && (
                <MDBCol className="plp-main-title">
                  <h3 className="wow fadeInDown">{props.category?.name}</h3>
                </MDBCol>
              )}

              {!isTabOrMob && (
                <MDBCol className={"plp-active-filters " + showBrand}>
                  <ActiveFilters activeFilters={activeFilters} />
                </MDBCol>
              )}
              <MDBCol className="plp-filters-sort-wrapper">
                <div className={"sort-control " + toggleFilters}>
                  {totalCount !== 0 && (
                    <>
                      <div className="sort-options ">
                        <div className="sort-options-content">
                          {totalCount > 1 && (
                            <Select
                              name={"sort"}
                              type={"text"}
                              value={sort}
                              onChange={(event) => {
                                changeSortFilterHandler(event, sortOrder);
                              }}
                              required={true}
                              wrapperClass="small main-select"
                              placeholder={"Sort by"}
                              options={sortByOptions}
                              useCustom={true}
                              openIndicator="caret-up"
                              closedIndicator="caret-down"
                              isSearchable={false}
                            />
                          )}
                        </div>
                        {totalCount > 1 && (
                          <div
                            className={`black_arrow_down ${
                              sortOrder === "ASC" && "black_arrow_up"
                            } arrow-asc-desc-wrapper`}
                            onClick={(e) => {
                              let sortValue;
                              let _sortOrder =
                                sortOrder === "ASC" ? "DESC" : "ASC";
                              if (isObject(sort)) {
                                sortValue = sort;
                              } else {
                                sortValue = {
                                  label: "Alphabetical",
                                  value: sort,
                                  __typename: "SortField",
                                };
                              }
                              changeSortFilterHandler(sortValue, _sortOrder);
                              setSortOrder(_sortOrder);
                            }}
                          >
                            {sortOrder === "DESC" ? (
                              <Icon icon={"black_arrow_down"} />
                            ) : (
                              <Icon icon={"black_arrow_up"} />
                            )}
                          </div>
                        )}
                        <div className="filters-button-container">
                          <Button
                            onClick={() => setToggleFilters(!toggleFilters)}
                            className={"filters-button " + toggleFilters}
                          >
                            {!isTabOrMob && "FILTERS"}
                            <Icon
                              icon={
                                toggleFilters
                                  ? "hamburger_close"
                                  : "filters_icon"
                              }
                            />
                          </Button>
                        </div>
                      </div>
                      {isTabOrMob && (
                        <MDBRow>
                          <MDBCol className="plp-active-filters">
                            <ActiveFilters activeFilters={activeFilters} />
                          </MDBCol>
                        </MDBRow>
                      )}
                    </>
                  )}
                </div>
              </MDBCol>
            </MDBRow>
          </>
        )) || <></>}
      </MDBCol>
    </MDBRow>
  );
};

export default Toolbar;
