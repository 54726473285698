import React from "react";
import Gallery from "./gallery";

const GalleryDataContainer = (props) => {
  const allGalleries =
    typeof props.data !== undefined &&
    props.data &&
    typeof props.data.getAllGalleryData !== undefined
      ? props.data.getAllGalleryData
      : false;

  const allGalleriesWithoutPress =
    allGalleries &&
    allGalleries.filter(
      (gallery) =>
        gallery && gallery.category[0] && gallery.category[0].name !== "Press"
    );
  const norwellGalleries =
    allGalleriesWithoutPress &&
    allGalleriesWithoutPress.filter(
      (gallery) =>
        gallery &&
        gallery.category[0] &&
        gallery.category[0].name === "Norwell Lighting"
    );

  const ilexGalleries =
    allGalleriesWithoutPress &&
    allGalleriesWithoutPress.filter(
      (gallery) =>
        gallery &&
        gallery.category[0] &&
        gallery.category[0].name === "ILEX Custom Metalcraft"
    );

  return (
    <Gallery
      norwellGalleries={norwellGalleries}
      ilexGalleries={ilexGalleries}
    />
  );
};

export default GalleryDataContainer;
