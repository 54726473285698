import React from "react";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
} from "mdbreact";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import themeConfiguration from "config/themeConfiguration";

const PressItem = (props) => {
  let {
    selectedGallery,
    setSelectedGallery,
    openModal,
    setOpenModal,
    pressGalleries,
  } = props;

  return (
    <>
      {selectedGallery && (
        <MDBModal
          isOpen={openModal}
          toggle={() => setOpenModal(!openModal)}
          className="press-item-modal"
        >
          <MDBModalHeader
            isOpen={openModal}
            toggle={() => setOpenModal(!openModal)}
          >
            <p>
              {selectedGallery.name}

              <Icon
                icon="hamburger_close"
                onClick={() => setOpenModal(!openModal)}
              />
            </p>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow className="press-item-modal-container">
              <MDBCol className={"press-item-modal-controls left"}>
                <Button
                  onClick={() => {
                    pressGalleries.find((item, index) => {
                      if (
                        item.gallery_id === selectedGallery.gallery_id &&
                        index > 0
                      ) {
                        setSelectedGallery(pressGalleries[index - 1]);
                      }
                    });
                  }}
                >
                  <Icon icon="black_arrow_down" />
                </Button>
              </MDBCol>
              <MDBCol className="press-item-image-container">
                <img
                  src={themeConfiguration.magento_url + selectedGallery.image}
                  alt={"press-page-modal-image " + selectedGallery.name}
                />
              </MDBCol>
              <MDBCol className={"press-item-modal-controls right"}>
                <Button
                  onClick={() => {
                    pressGalleries.find((item, index) => {
                      if (
                        item.gallery_id === selectedGallery.gallery_id &&
                        index < pressGalleries.length
                      ) {
                        setSelectedGallery(pressGalleries[index + 1]);
                      }
                    });
                  }}
                >
                  <Icon icon="black_arrow_down" />
                </Button>
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <p>{selectedGallery.description}</p>
          </MDBModalFooter>
        </MDBModal>
      )}
    </>
  );
};

export default PressItem;
