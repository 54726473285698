import Image from "core/components/image/image";
import Price from "core/components/price";
import { useWishlist } from "core/state/hooks/wishlistHooks/wishlistHookAddRemove";
import { useWishlistAddToCart } from "core/state/hooks/wishlistHooks/wishlistHookAddToCart";
import _ from "lodash";
import {
  MDBBox,
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { useState } from "react";
import WishlistItemsInner from "./wishlistItemsInner/wishlistItemsInner";

const Item = (props) => {
  const [
    addToWishlist,
    removeFromWishlist,
    fetchWishlistData,
    getWishlistItems,
    moveItemToWishlist,
    editWishlist,
    wishlist,
    myWishlists,
    createWishlist,
    deleteWishlist,
  ] = useWishlist();
  const [addToCartWishlist] = useWishlistAddToCart();
  const [wishlistList, setWishlistList] = useState(false);
  const [addToCart, setAddToCart] = useState(false);
  const [moveWishlist, setMoveWishlist] = useState(false);

  const toggle = () => {
    setWishlistList(!wishlistList);
  };
  let { item, activeWishlistName, myWishlistsArr } = props;

  let wishlistRemoveItems = {
    skus: [props.item.product.sku],
    wishlist: activeWishlistName,
  };
  let wishlistAddedItem = {
    name: activeWishlistName,
    itemId: props.item.product.id.toString(),
  };
  return (
    <>
      <MDBContainer className="m-4">
        <MDBRow>
          <MDBCol>
            <Image source={item.product.image.url} />
          </MDBCol>
          <MDBCol>
            <MDBBox>{item.product.name}</MDBBox>
            {!_.isUndefined(item.product.price_range) && (
              <Price
                price={item.product.price_range.maximum_price.final_price.value}
                type={"fixed"}
              />
            )}
          </MDBCol>
          <MDBCol>
            <MDBBtn
              className="wishlist-btn"
              onClick={(e) => {
                setAddToCart(true);
                addToCartWishlist(wishlistAddedItem).then(
                  setTimeout(() => {
                    setAddToCart(false);
                  }, 800)
                );
              }}
            >
              {addToCart ? "ADDING..." : "ADD TO CART"}
            </MDBBtn>
            <MDBBtn
              className={"wishlist-btn"}
              onClick={(e) => {
                removeFromWishlist(wishlistRemoveItems);
              }}
            >
              Remove
            </MDBBtn>
          </MDBCol>
          <MDBCol>
            <MDBBtn
              className="wishlist-btn"
              onClick={(e) => {
                toggle();
              }}
            >
              {moveWishlist ? "MOVING WISHLIST" : "MOVE WISHLIST"}
            </MDBBtn>
            {wishlistList && (
              <MDBModal
                isOpen={wishlistList}
                toggle={toggle}
                className={"modal-wrapper wishlist-wrapper move-wishlist-modal"}
                centered
                fromWishlist={activeWishlistName}
                allWishlists={myWishlistsArr}
                item={item}
              >
                <MDBModalHeader onClick={toggle}>
                  <div className="close-btn">
                    <button type="button">
                      <i className="close-icon"></i>
                    </button>
                  </div>
                </MDBModalHeader>
                <MDBModalBody>
                  {props.allWishlists.map((wishlistInner, index) => {
                    let foundItem = wishlistInner.items.filter(
                      (fItem) => fItem.product.sku === props.item.product.sku
                    );
                    if (foundItem.length === 0) {
                      return (
                        <WishlistItemsInner
                          wishlistInner={wishlistInner}
                          item={item}
                          activeWishlistName={activeWishlistName}
                        />
                      );
                    }
                  })}
                </MDBModalBody>
              </MDBModal>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};
export default Item;
