import React from "react";

const Checkbox = ({ checked, setChecked, checkbox_id, theme }) => {
  return (
    <div className={"checkbox " + theme}>
      <input
        type='checkbox'
        id={checkbox_id}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <label htmlFor={checkbox_id}></label>
    </div>
  );
};

export default Checkbox;
