import React from "react";
import { MDBModal, MDBModalBody, MDBRow, MDBCol } from "mdbreact";
import Input from "app/layout/input";
import Select from "app/layout/select";
import { validate, shouldMarkError } from "./validation";
import { handleSubmit } from "./handleSubmit";
import { handleKeyDown } from "./handleKeyDown";
import SessionMessages from "core/components/sessionMessages";
import Button from "core/components/button";

const Subscribe = (props) => {
  let {
    toggleModal,
    setToggleModal,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    whatDescribesYou,
    setWhatDescribesYou,
    loading,
    setLoading,
    touched,
    setTouched,
    subscribe,
    whatDescribesYouValidation,
    setWhatDescribesYouValidation,
    lock,
  } = props;

  let errors = validate(email, firstName, lastName);
  const options = [
    { value: 1, label: "Designer" },
    { value: 2, label: "Retail" },
  ];

  return (
    <MDBModal
      isOpen={toggleModal}
      toggle={() => setToggleModal(!toggleModal)}
      centered
      className="subscribe-modal"
    >
      <MDBModalBody>
        <MDBRow className="subscribe-modal-container">
          <MDBCol className="subscribe-modal-column">
            <h5>Stay up to date</h5>
            <p>Subscribe to our site</p>
            <Input
              placeholder={`${
                shouldMarkError("firstName", errors, touched)
                  ? "First Name is required"
                  : "First Name"
              }`}
              value={firstName}
              required={true}
              onChange={(event) => setFirstName(event.target.value)}
              className={
                shouldMarkError("firstName", errors, touched)
                  ? "invalid"
                  : "" + firstName !== "" && "valid"
              }
              onBlur={(e) => {
                setTouched({ ...touched, firstName: true });
              }}
              shouldMarkError={shouldMarkError("firstName", errors, touched)}
            />
            <Input
              placeholder={`${
                shouldMarkError("lastName", errors, touched)
                  ? "Last Name is required"
                  : "Last Name"
              }`}
              value={lastName}
              required={true}
              onChange={(event) => setLastName(event.target.value)}
              className={
                shouldMarkError("lastName", errors, touched)
                  ? "invalid"
                  : "" + lastName !== "" && "valid"
              }
              onBlur={(e) => {
                setTouched({ ...touched, lastName: true });
              }}
              shouldMarkError={shouldMarkError("lastName", errors, touched)}
            />
            <Input
              placeholder={`${
                shouldMarkError("email", errors, touched)
                  ? "Email is required"
                  : "Email"
              }`}
              value={email}
              required={true}
              onChange={(event) => setEmail(event.target.value)}
              className={
                shouldMarkError("email", errors, touched)
                  ? "invalid"
                  : "" + email !== "" && "valid"
              }
              onBlur={(e) => {
                setTouched({ ...touched, email: true });
              }}
              shouldMarkError={shouldMarkError("email", errors, touched)}
            />
            <Select
              placeholder={"What best describes you"}
              options={options}
              value={whatDescribesYou}
              onChange={(event) => setWhatDescribesYou(event)}
              isSearchable={false}
              onKeyDown={(e) =>
                handleKeyDown(
                  e,
                  setTouched,
                  errors,
                  setLoading,
                  subscribe,
                  email,
                  firstName,
                  lastName,
                  whatDescribesYou.label,
                  whatDescribesYouValidation,
                  setWhatDescribesYouValidation,
                  setFirstName,
                  setLastName,
                  setEmail,
                  setWhatDescribesYou,
                  lock
                )
              }
            />
            <Button
              disabled={loading && loading}
              onClick={(e) =>
                handleSubmit(
                  e,
                  setTouched,
                  errors,
                  setLoading,
                  subscribe,
                  email,
                  firstName,
                  lastName,
                  whatDescribesYou.label,
                  whatDescribesYouValidation,
                  setWhatDescribesYouValidation,
                  setFirstName,
                  setLastName,
                  setEmail,
                  setWhatDescribesYou,
                  lock
                )
              }
            >
              {loading ? "Processing..." : "Subscribe"}
            </Button>
            <p className="error-messages">
              {whatDescribesYouValidation ? (
                <SessionMessages target="subscribe" />
              ) : (
                "Please select the option that best describes you."
              )}
            </p>
          </MDBCol>
          <p className="error-messages">
            <SessionMessages target="register" />
          </p>
        </MDBRow>
      </MDBModalBody>
    </MDBModal>
  );
};
export default Subscribe;
