import React, { useState } from "react";
import QuoteRequestItem from "./quoteRequestItem";

const QuoteRequestItemStateContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(props.item.quantity);
  return (
    <QuoteRequestItem
      {...props}
      loading={loading}
      setLoading={setLoading}
      quantity={quantity}
      setQuantity={setQuantity}
    />
  );
};

export default QuoteRequestItemStateContainer;
