import React from "react";
import { connect } from "react-redux";
import CategoryListQueryContainer from "./category-categoryList-query-container";
import lodash from "lodash";

const CategoryReduxContainer = (props) => {
  let { data, site } = props;
  let allCategories = [];
  let category_id = 2;

  if (site.siteCode === "norwell_default") {
    category_id = 3;
  }

  if (!lodash.isEmpty(props.allCategories)) {
    if (typeof props.allCategories[category_id] !== "undefined") {
      allCategories = props.allCategories[category_id].data.categoryList;
    }
  }

  if (
    lodash.isEmpty(props.allCategories) &&
    !lodash.isEmpty(props.allCategoriesTmp)
  ) {
    allCategories =
      typeof props.allCategoriesTmp[category_id] !== "undefined" &&
      typeof props.allCategoriesTmp[category_id].data !== "undefined"
        ? props.allCategoriesTmp[category_id].data.categoryList
        : [];
  }

  return (
    <CategoryListQueryContainer
      variables={props.variables}
      query={props.query}
      allCategories={allCategories}
      data={data}
      category_id={props.id}
    />
  );
};

const mapStateToProps = (state) => ({
  flag: state.cache.flag,
  site: state.site,
  allCategories: state.queries.data,
  allCategoriesTmp: state.queries.temp,
});

export default connect(mapStateToProps, null)(CategoryReduxContainer);
