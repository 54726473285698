import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import RepCard from "./repCard";

const Content = (props) => {
  let { repLocators } = props;
  if (typeof repLocators === "undefined") {
    return (
      <MDBRow className={`find-rep-content no-items justify-content-center`}>
        <MDBCol size="12" className={`text-container`}>
          <p>Please make a selection above to search for your Rep</p>
        </MDBCol>
      </MDBRow>
    );
  } else if (repLocators.length === 0) {
    return (
      <MDBRow className={`find-rep-content no-results justify-content-center`}>
        <MDBCol size="12" className={`text-container`}>
          <p>
            Sorry, there doesn’t seem to be a Rep in your area or there’s an
            error with your search Complete a search again or contact Ilex
            Customer Service at <a href="tel:800.9777.4470">800.9777.4470</a>
          </p>
        </MDBCol>
      </MDBRow>
    );
  } else {
    return (
      <>
        <MDBRow className={`find-rep-content find-rep-results`}>
          {repLocators.map((repLocators) => {
            return <RepCard repLocators={repLocators} />;
          })}
        </MDBRow>
      </>
    );
  }
};

export default Content;
