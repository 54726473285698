import React, { memo } from "react";
import Icon from "app/assets/icon/icon";

const SocialMedia = memo((props) => {
  return (
    <div className="social-media-wrapper">
      <a
        href={props.data && props.data.getSocialMedia.facebook}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon="facebook" />
      </a>
      <a
        href={props.data && props.data.getSocialMedia.pinterest}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon="pinterest" />
      </a>
    </div>
  );
});

export default SocialMedia;
