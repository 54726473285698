import React from "react";
import { connect } from "react-redux";
import OurCategories from "./ourCategories-data-container";

const OurCategoriesReduxContainer = (props) => {
  return <OurCategories {...props} />;
};

const mapStateToProps = (state) => ({
  site: state.site,
});

export default connect(mapStateToProps, null)(OurCategoriesReduxContainer);
