import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Button from "core/components/button";
import Select2 from "core/components/select";
import { findMaskByCountry } from "./functions";
import { validate, shouldMarkError } from "./validation";

const Toolbar = (props) => {
  let {
    countrySelect,
    industrySelect,
    selectState,
    countries,
    industries,
    states,
    setSelectStateFn,
    setIndustrySelectFn,
    setSelectCountryFn,
    setCanSearch,
    countriesAndStates,
  } = props;
  const generateSanitizedMaskArray = (object) => {
    return Object.keys(object).map((key) => {
      let regexChar = object[key];
      if (object[key] === "+") regexChar = "+";
      if (object[key] === "#") regexChar = /\d/;
      return regexChar;
    });
  };
  const [maskSanitizedArray] = useState(
    generateSanitizedMaskArray(findMaskByCountry("US").mask.split(""))
  );

  if (industrySelect === "") {
    setIndustrySelectFn(industries[0]);
  }

  let errors = validate(
    industrySelect,
    countrySelect,
    selectState,
    maskSanitizedArray
  );
  const [touched, setTouched] = useState({
    industrySelect: false,
    countrySelect: false,
    selectState: false,
  });
  const showResults = () => {
    setTouched({
      industryselect: true,
      countryselect: true,
      selectstate: true,
    });
    setCanSearch(true);
  };

  //filtered states for selected country
  const [filteredStatesArray, setFilteredStatesArray] = useState(states);
  let filteredStates = [];
  useEffect(() => {
    countriesAndStates.map((country) => {
      if (country.value === countrySelect.value) {
        country.states.map((state) => {
          filteredStates.push(state);
        });
      }
    });
    setFilteredStatesArray(filteredStates);
    setSelectStateFn("");
  }, [countrySelect]);

  return (
    <>
      <MDBRow className={`align-items-center find-rep-toolbar `}>
        <MDBCol lg="3" className="select-container">
          <Select2
            name={"country"}
            type={"text"}
            value={countrySelect}
            onChange={(event) => {
              setSelectCountryFn(event);
            }}
            required={true}
            placeholder={"Select Country"}
            options={countries}
            useCustom={true}
            isSearchable={true}
            onBlur={(e) => {
              setTouched({ ...touched, countryselect: true });
            }}
            className={
              shouldMarkError("countryselect", errors, touched)
                ? "validation-error"
                : ""
            }
            wrapperClass={"country-select"}
          />
        </MDBCol>
        <MDBCol lg="3" className="select-container">
          <Select2
            name={"industry"}
            type={"text"}
            value={industrySelect}
            onChange={(event) => setIndustrySelectFn(event)}
            required={true}
            placeholder={"Select Industry"}
            options={industries}
            useCustom={true}
            isSearchable={true}
            onBlur={(e) => {
              setTouched({ ...touched, industryselect: true });
            }}
            className={
              shouldMarkError("industryselect", errors, touched)
                ? "validation-error"
                : ""
            }
          />
        </MDBCol>
        <MDBCol lg="3" className="select-container">
          <Select2
            wrapperClass={!countrySelect && "disabled"}
            name={"state"}
            type={"text"}
            required={true}
            value={selectState}
            onChange={(event) => setSelectStateFn(event)}
            placeholder={"Select State"}
            options={countrySelect ? filteredStatesArray : states}
            isSearchable={true}
            onBlur={(e) => {
              setTouched({ ...touched, selectstate: true });
            }}
            className={
              shouldMarkError("selectstate", errors, touched)
                ? "validation-error"
                : ""
            }
          />
        </MDBCol>
        <MDBCol lg="3" className="button-container">
          <Button
            disabled={selectState === "" && true}
            className="search-button"
            onClick={() => showResults()}
          >
            Search
          </Button>
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default Toolbar;
