import React from "react";
import Breadcrumbs from "app/layout/breadcrumbs";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { isEmpty } from "lodash";
import CategoryComponentContainer from "./category-component-container";
import SeoHelmet from "app/layout/seoHelmet";
import { findCategoryByAttribute } from "core/helpers/category";
import { GenerateRequest } from "./hooks";
import { useMediaQuery } from "react-responsive";

const Category = (props) => {
  const { allCategories, categoryFilter } = props;
  let category = undefined;
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  if (typeof allCategories[0]?.children !== "undefined") {
    category = findCategoryByAttribute(
      props.category_id,
      allCategories[0].children,
      "id"
    );
  }
  return (
    <>
      <SeoHelmet
        url={category?.url_path}
        title={category?.name}
        description={category?.meta_description}
        image={typeof category?.image !== "undefined" ? category.image : ""}
      />
      <div id={`category-${categoryFilter}`} className={`category-container`}>
        <MDBContainer className="plp-main-container">
          {!isMobileOrTablet && (
            <MDBCol lg="12" className="wow slideInDown breadcrumbs-container">
              <Breadcrumbs />
            </MDBCol>
          )}
          {(typeof category !== "undefined" &&
            typeof category.children === "undefined") ||
          (typeof category !== "undefined" && isEmpty(category.children)) ? (
            <MDBRow className="main-plp-container">
              <CategoryComponentContainer
                categoryFilter={category.id}
                category={category}
                filters={GenerateRequest()}
              />
            </MDBRow>
          ) : (
            <>
              {typeof category !== "undefined" &&
              typeof category.children === "undefined" ? (
                <MDBRow className="main-plp-container">
                  <CategoryComponentContainer
                    categoryFilter={category.id}
                    category={category}
                  />
                </MDBRow>
              ) : (
                <CategoryComponentContainer
                  categoryFilter={category?.id}
                  category={category}
                  filters={GenerateRequest()}
                />
              )}
            </>
          )}
        </MDBContainer>
      </div>
    </>
  );
};

export default Category;
