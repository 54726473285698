import React from "react";
import gql from "graphql-tag";
import GraphQlWrapper from "core/components/graphQlWrapper";
import Logo from "./logo";

const STORE_CONFIG = gql`
  query storeConfig {
    storeConfig {
      website_id
      header_logo_src
      logo_alt
      copyright
    }
  }
`;

const LogoQueryContainer = (props) => {
  return (
    <GraphQlWrapper
      query={STORE_CONFIG}
      variables={{ id: "store_config" }}
      saveQuery={true}
    >
      <Logo />
    </GraphQlWrapper>
  );
};

export default LogoQueryContainer;
