import axios from "axios";
import themeConfiguration from "config/themeConfiguration";

export const uploadFileTrigger = () => {
  document.getElementById("upload-file-id").click();
};

export const prepareFileForUpload = async (file, setEncodedFile) => {
  let reader = new FileReader();
  let base64 = "";
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    base64 = reader.result.split(",")[1];
    setEncodedFile({
      base64_encoded_data: base64,
      name: file.name,
      type: file.type,
    });
  };
};

export const fileUpload = async (e, setFileForUpload) => {
  const url =
    themeConfiguration.app_url + "rest/V1/jola-customerregister/uploadDocument";
  let file = e.target.files[0];
  if (typeof file !== "undefined" && file.type !== undefined) {
    if (
      (file.type === "application/pdf" ||
        file.type === "image/jpeg" ||
        file.type === "image/tiff") &&
      file.size / 1000000 <= 2
    ) {
      setFileForUpload(file);

      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      let res = await axios
        .post(url, formData, config)
        .then((data) => {
          if (data.status === 200) {
            console.log("success");
          }
        })
        .catch((err) => {
          console.log("error", err);
          setFileForUpload("");
        });
    } else {
      setFileForUpload("");
    }
  }
};
