import React from "react";
import { MDBContainer } from "mdbreact";
import ReactLoader from "app/layout/loader";
import useSite from "app/state/hooks/siteHooks/useSite";

const Temporary = (props) => {
  const [, getSiteCode] = useSite();
  return (
    <MDBContainer
      id={`temporary`}
      className={`d-flex flex-column align-items-center justify-content-center center`}
    >
      <ReactLoader
        color={getSiteCode() === "norwell_default" ? "#B9571B" : "#c1263b"}
        width={50}
        height={50}
        type={`ThreeDots`}
      />
      {props.message}
    </MDBContainer>
  );
};

export default Temporary;
