/**
 * If you want your redux state to be persistant, you will need to add a initialState function and map to the key accordingly
 * The key should match what is listed in the persistorConfigurator and also the Redux reducer
 */
// import { getTestState } from "./initialStates";
import { persistList, getStateByKey } from "./persistorConfigurator";
import { getSiteState, getCustomerState } from "./initialStates";

export const projectStates = {
  // test: getTestState,
  site: getSiteState,
  customer: getCustomerState,
};

export const generateAppPersistedState = (store, list = persistList) => {
  let persistedList = {};
  Object.keys(list).map((key, value) => {
    let currentListValue = list[key];
    if (currentListValue) {
      let data = getStateByKey(store, key, projectStates);
      return (persistedList[key] = data);
    }
    return false;
  });
  return persistedList;
};

export default projectStates;
