import { useSelector } from "react-redux";
// code for getting parameters

function getSearchParameters() {
  let prmstr = window.location.search.substr(1);
  return prmstr !== null && prmstr !== "" ? transformToAssocArray(prmstr) : {};
}

function transformToAssocArray(prmstr) {
  let params = {};
  let prmarr = prmstr.split("&");
  for (let i = 0; i < prmarr.length; i++) {
    let tmparr = prmarr[i].split("=");
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export function GenerateRequest() {
  let filterState = {};
  let params = getSearchParameters();
  Object.keys(params).map((code) => {
    if (code !== "pageNumber") {
      let value = typeof params[code] !== "undefined" ? params[code] : "";

      let filter = { [code]: { in: [value] } };
      if (code !== "") {
        if (code === "price") {
          let splitValues = value.split("-");
          filter = { from: splitValues[0], to: splitValues[1] };
          filterState[code] = { ...filter };
        } else {
          if (value !== "") {
            filterState[code] = { in: [...value.split(/,/g)] };
          }
        }
      }
    }
  });
  return filterState;
}

export function useFilters() {
  const filters = useSelector((state) => state.filters);
  function getState() {
    return filters;
  }
  return [getState];
}
