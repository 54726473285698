import { useSelector } from "react-redux";

const useCustomer = () => {
  const customer = useSelector((state) => state.customer.data);

  const getLoggedIn = () => {
    return customer.token;
  };

  return [getLoggedIn];
};

export default useCustomer;
