import React, { useState } from "react";
import CmsBlock from "app/layout/cmsBlock";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Icon from "app/assets/icon/icon";
import { useSelector } from "react-redux";

const IlexBlocks = () => {
  const site = useSelector((state) =>
    state !== undefined && state.site !== undefined ? state.site : undefined
  );
  const [readMore, setReadMore] = useState(false);
  return (
    <>
      <div className="ilex-blocks-wrapper">
        <MDBRow className="second-block-about-us">
          <MDBCol lg="7" className="second-block-about-us-column">
            <MDBRow>
              <MDBCol className={"ilex-text " + readMore}>
                <CmsBlock id="about_us_second_block_text" dangHtml={true} />
                <p className="read-more" onClick={() => setReadMore(!readMore)}>
                  Read {readMore ? "less" : "more"}
                </p>
              </MDBCol>
            </MDBRow>
            {site.siteCode.includes("ilex") ? (
              <></>
            ) : (
              <>
                <div className="about-us-visit-website">
                  <Icon icon="right_arrow_white" />
                  <CmsBlock dangHtml={true} id="about_us_visit_ilex_website" />
                </div>
              </>
            )}
          </MDBCol>
          <MDBCol lg="5" className="second-block-images-wrapper">
            <MDBContainer className="second-block-first-image">
              <CmsBlock
                id="about_us_second_block_first_image"
                dangHtml={true}
              />
            </MDBContainer>
            <MDBContainer className="second-block-second-image">
              <CmsBlock
                id="about_us_second_block_second_image"
                dangHtml={true}
              />
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </div>
    </>
  );
};

export default IlexBlocks;
