import React, { useRef, useEffect, useCallback } from "react";
import useSite from "app/state/hooks/siteHooks/useSite";
import MenuDesktop from "./menuDesktop";
import MenuMobile from "./menuMobile";

const Menu = ({
  menuItems,
  hoveredDropdown,
  setHoveredDropdown,
  showDropdown,
  setShowDropdown,
  width,
  setOpen,
}) => {
  const navbarRef = useRef();
  const [, getSiteCode] = useSite();
  const site = getSiteCode();
  const handleMouseMoveOutsideNavbar = useCallback((e) => {
    if (navbarRef.current && !navbarRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  });

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMoveOutsideNavbar);
    return () => {
      document.removeEventListener("mousemove", handleMouseMoveOutsideNavbar);
    };
  }, [handleMouseMoveOutsideNavbar]);

  return width > 1024 ? (
    <MenuDesktop navbarRef={navbarRef} menuItems={menuItems} site={site} />
  ) : (
    <MenuMobile
      menuItems={menuItems}
      hoveredDropdown={hoveredDropdown}
      setHoveredDropdown={setHoveredDropdown}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
      setOpen={setOpen}
    />
  );
};

export default Menu;
