export default (text) => {
  let array = [];
  let subArray = [];
  for (let i = 0; i < text.length; i++) {
    if (i === text.length - 1) {
      subArray.push(text[i]);
      array.push(subArray);
      subArray = [];
    } else {
      if (text[i] === " ") {
        array.push(subArray);
        subArray = [];
      } else {
        subArray.push(text[i]);
      }
    }
  }
  return array;
};
