import React, { useEffect } from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdbreact";
import { useMediaQuery } from "react-responsive";
import QuoteRequestItem from "./quoteRequestItem";
import QuoteRequestSummary from "./quoteRequestSummary";

const QuoteRequest = ({
  props,
  stateProps,
  quoteRequestItems,
  quoteStatus,
  setQuoteStatus,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  let productsAndComments = [];

  useEffect(() => {
    quoteRequestItems &&
      quoteRequestItems.map((item, index) => {
        productsAndComments.push({
          code: item.product.sku,
          value: "",
        });
      });
  }, [quoteRequestItems]);

  return (
    <MDBContainer className="quote-request-page">
      <MDBRow className="quote-request-page-title">
        <h3 className="wow slideInDown">Quote Request</h3>
      </MDBRow>
      <MDBRow size="12" className="quote-request-header ">
        {!isMobile && (
          <MDBCol lg="9">
            <MDBRow>
              <MDBCol size="7">
                <h6 className="wow slideInUp">Items</h6>
              </MDBCol>
              <MDBCol size="5">
                <h6 className="wow slideInUp">Quantity</h6>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        )}
        <MDBCol lg="3" className="quote-summary-title">
          <h6>Quote Summary</h6>
        </MDBCol>
      </MDBRow>
      <MDBRow className="quote-request-content">
        {quoteRequestItems && quoteRequestItems.length === 0 ? (
          <MDBCol lg="9" className="empty-quote">
            <p>Your Quote request is empty.</p>
          </MDBCol>
        ) : (
          <MDBCol lg="9" className="quote-request-items-container">
            {quoteRequestItems &&
              quoteRequestItems.map((item, index) => {
                return (
                  <QuoteRequestItem
                    key={`quote-request-item-${index}`}
                    item={item}
                    stateProps={stateProps}
                    productsAndComments={productsAndComments}
                    index={index}
                  />
                );
              })}
          </MDBCol>
        )}
        <QuoteRequestSummary
          quoteRequestItems={quoteRequestItems}
          quoteStatus={quoteStatus}
          setQuoteStatus={setQuoteStatus}
          productsAndComments={productsAndComments}
        />
      </MDBRow>
    </MDBContainer>
  );
};

export default QuoteRequest;
