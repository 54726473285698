import React, { useState, useEffect } from "react";
import { MDBBox, MDBRow, MDBCol, MDBContainer } from "mdbreact";
import Image from "app/layout/image";
import CreateWishlistCore from "../createWishlistCore";
import { ModalConsumer } from "core/components/modal/ModalContext";
import Button from "core/components/button";
import Icon from "app/assets/icon/icon";
import EditWishlist from "../editWishlist";

const ItemsWishlist = (props) => {
  // const { fetchWishlistData } = useWishlist();
  let { myWishlistsArr, history } = props;
  const [currentIndex] = useState(0);

  /*Mobile start*/
  const [screenWidth, setScreenWidth] = useState(
    document.documentElement.clientWidth
  );
  const onResizeSetWidth = () => {
    setScreenWidth(document.documentElement.clientWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onResizeSetWidth);
    return () => {
      window.removeEventListener("resize", onResizeSetWidth);
    };
  }, []);
  /*Mobile end*/

  myWishlistsArr = myWishlistsArr.filter((wishlist) => {
    if (wishlist.name.toLowerCase() !== "wish list") {
      return true;
    }
  });

  // fetchWishlistData();
  if (typeof myWishlistsArr[currentIndex] !== "undefined")
    if (myWishlistsArr.length > 1) {
      // props.setActiveWishlistName(myWishlistsArr[currentIndex].name);
      // myWishlistsArr.splice(0, 1);
    }

  return (
    <>
      <MDBContainer className="items-wishlist-wrapper">
        <MDBRow tag="h2" className={`project-title mb-5`}>
          Projects
        </MDBRow>
        <MDBCol md="12" className="wishlist-listings">
          {myWishlistsArr.length > 0 &&
            myWishlistsArr.map((item, index) => {
              if (item.name.toLowerCase() !== "my favorites") {
                return (
                  <MDBRow
                    key={`wishlist-objects-${item.id}`}
                    className={"wishlist-list-item list-item "}
                    style={{ cursor: "pointer" }}
                  >
                    <MDBCol
                      md="12"
                      className={`project-product-img`}
                      onClick={(e) => {
                        history.push({
                          search: `?project_id=${item.id}`,
                        });
                      }}
                    >
                      <MDBRow className="d-flex flex-wrap product-img-wrapper">
                        {item.items_count !== "0" ? (
                          item.items.slice(0, 4).map((fItem, index) => {
                            return (
                              index <= 3 && (
                                <MDBCol
                                  key={`another-map-${fItem.id}`}
                                  size="6"
                                  className={"list-names"}
                                >
                                  <Image source={fItem.product.image.url} />
                                </MDBCol>
                              )
                            );
                          })
                        ) : (
                          <MDBCol
                            style={{ cursor: "pointer" }}
                            className={`list-names d-flex justify-content-center text-center no-products`}
                          >
                            <MDBRow>
                              <MDBCol md="12">
                                <Icon icon="emptyProject" />
                              </MDBCol>
                              <MDBCol md="12">
                                <MDBBox tag="p" className={`no-products`}>
                                  There are no products currently in this
                                  project
                                </MDBBox>
                              </MDBCol>
                            </MDBRow>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </MDBCol>
                    <MDBCol
                      md="12"
                      className={`project-info`}
                      onClick={(e) => {
                        history.push({
                          search: `?project_id=${item.id}`,
                        });
                      }}
                    >
                      <MDBRow className="project-info-border">
                        <MDBCol size="8" tag="h4">
                          {item.name}
                        </MDBCol>
                        <MDBCol size="4" className="edit-text">
                          <ModalConsumer value={{ useWrapper: true }}>
                            {({ showModal }) => (
                              <p
                                className="wishlist-btn edit-btn"
                                onClick={(e) => {
                                  showModal(() => {
                                    return <EditWishlist wishlist={item} />;
                                  });
                                }}
                              >
                                Edit
                              </p>
                            )}
                          </ModalConsumer>
                        </MDBCol>
                      </MDBRow>
                      <MDBBox tag="p">
                        {item.items_count > 1
                          ? `${item.items_count} Items`
                          : `${item.items_count} Item`}
                      </MDBBox>
                    </MDBCol>
                  </MDBRow>
                );
              }
            })}
        </MDBCol>
        <MDBRow>
          <MDBCol md="12" className="create-project-and-button-wrapper">
            <MDBRow className={` create-project-wrapper`}>
              <MDBCol className={`create-new-project`}>
                {(screenWidth > 767 && (
                  <ModalConsumer value={{ useWrapper: true }}>
                    {({ showModal, hideModal }) => (
                      <Button
                        className={"create-new-btn"}
                        children={"Create new project"}
                        onClick={(e) => {
                          showModal(() => {
                            return (
                              <CreateWishlistCore
                                {...props}
                                hideModal={hideModal}
                              />
                            );
                          });
                        }}
                      />
                    )}
                  </ModalConsumer>
                )) || (
                  <MDBBox className={"wishlist-list-item list-item"}>
                    <CreateWishlistCore {...props} />
                  </MDBBox>
                )}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default ItemsWishlist;
