import React, { useState } from "react";
import CatalogRequestForm from "./catalog-request-form";

const CatalogRequestFormStateContainer = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({});
  const [addressOne, setAddressOne] = useState("");
  const [addressTwo, setAddressTwo] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [stateName, setStateName] = useState("");
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");

  return (
    <CatalogRequestForm
      {...props}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      companyName={companyName}
      setCompanyName={setCompanyName}
      email={email}
      setEmail={setEmail}
      phone={phone}
      setPhone={setPhone}
      status={status}
      setStatus={setStatus}
      loading={loading}
      setLoading={setLoading}
      touched={touched}
      setTouched={setTouched}
      addressOne={addressOne}
      addressTwo={addressTwo}
      setAddressOne={setAddressOne}
      setAddressTwo={setAddressTwo}
      country={country}
      setCountry={setCountry}
      zipCode={zipCode}
      setZipCode={setZipCode}
      stateName={stateName}
      setStateName={setStateName}
      title={title}
      setTitle={setTitle}
      city={city}
      setCity={setCity}
      data={props.data}
    />
  );
};

export default CatalogRequestFormStateContainer;
