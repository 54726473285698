import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const WhatsNewSliderItem = ({ product, theme }) => {
  return (
    <Link
      to={{ pathname: "/" + product.product_url, state: { sku: product.sku } }}
    >
      <MDBContainer
        className={"d-flex justify-content-center product-slider-item"}
      >
        <MDBRow className="image-row">
          <MDBCol className="image-container">
            <LazyLoad offset={0}>
              <img
                className={
                  product &&
                  product.product_image &&
                  product.product_image.includes("placeholder") &&
                  "placeholder"
                }
                src={
                  product.product_image &&
                  product.product_image.includes("placeholder")
                    ? "/placeholder.png"
                    : product.product_image
                }
                alt={`product-${product.product_name}`}
              />
            </LazyLoad>
          </MDBCol>
        </MDBRow>
        <MDBRow className="product-info-container">
          {theme === "light" ? (
            <MDBCol className="light-theme-product-info">
              <h5>{product.product_name}</h5>
              <p>{product.category_name}</p>
            </MDBCol>
          ) : (
            <MDBCol className="dark-theme-product-info">dark</MDBCol>
          )}
        </MDBRow>
      </MDBContainer>
    </Link>
  );
};
export default WhatsNewSliderItem;
