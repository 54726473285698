import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import textSplitter from "./textSplitter";
import Icon from "app/assets/icon/icon";
import uuid from "react-uuid";
import Button from "core/components/button";
import { useMediaQuery } from "react-responsive";
import themeConfiguration from "config/themeConfiguration";
import LazyLoad from "react-lazyload";
const SliderItem = ({ item, key }) => {
  const isMobileOrTablet = useMediaQuery({ maxWidth: 992 });
  const [mainTitle, setMainTitle] = useState("");
  useEffect(() => {
    setMainTitle(
      textSplitter(item.overlay_text).map((word, index) => {
        return (
          <span key={uuid()} className="span-word">
            {word.map((letter) => {
              return (
                <span key={uuid()} className="span-letter">
                  {letter}
                </span>
              );
            })}
          </span>
        );
      })
    );
  }, []);

  return (
    <MDBRow key={key} className="slide-row">
      <MDBCol lg="6" className="slide-left-side">
        <MDBRow className="slide-text">
          <MDBCol>
            <p>
              <label className="rhomboid"></label>
              <label>{item.overlay_cta_label}</label>
            </p>
            <h1>{mainTitle}</h1>
          </MDBCol>
        </MDBRow>
        <MDBRow className="slide-button">
          <MDBCol>
            {isMobileOrTablet ? (
              <a
                className="mobile-slider-button"
                href={item.button_url && item.button_url}
              >
                {item.overlay_cta_link}
                <Icon icon="right_arrow_white" />
              </a>
            ) : (
              <Button
                to={item.button_url && item.button_url}
                className="with-arrow"
              >
                {item.overlay_cta_link}
                <Icon icon="right_arrow_white" />
              </Button>
            )}
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol lg="6" className="slide-right-side">
        <div className="slide-image">
          <LazyLoad height="100%" offset={0}>
            <img
              src={themeConfiguration.magento_url + item.media}
              alt={`slider-image-${key}`}
            />
          </LazyLoad>
        </div>
      </MDBCol>
    </MDBRow>
  );
};

export default SliderItem;
