import React from "react";
import { MDBRow, MDBCol } from "mdbreact";
import Input from "app/layout/input";
import Checkbox from "app/layout/checkbox";
import useLocking from "core/state/hooks/commonHooks/useLocking";
import { validate, shouldMarkError } from "./registerValidation";
import { registerSubmit } from "./registerSubmit";
import { handleKeyDown } from "./handleKeyDown";
import SessionMessages from "core/components/sessionMessages";
import MaskedInput from "react-text-mask";
import Icon from "app/assets/icon/icon";
import { useEffect } from "react";
import Button from "core/components/button";

const RegisterForm = (props) => {
  let {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    companyName,
    setCompanyName,
    email,
    setEmail,
    confirmEmail,
    setConfirmEmail,
    phone,
    setPhone,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    termsAndConditions,
    setTermsAndConditions,
    subscribe,
    setSubscribe,
    termsValidationMessage,
    setTermsValidationMessage,
    registerCustomerNew,
    status,
    setStatus,
    loading,
    setLoading,
    touched,
    setTouched,
    site,
    setToggleModal,
  } = props;

  const [lock] = useLocking();
  let errors = validate(
    firstName,
    lastName,
    //companyName,
    email,
    //confirmEmail,
    //phone,
    password,
    //confirmPassword,
    termsAndConditions
  );
  useEffect(() => {
    if (termsAndConditions) {
      setTermsValidationMessage(false);
    }
  }, [termsAndConditions]);

  if (status) {
    setTimeout(() => {
      setToggleModal(false);
    }, 4000);
  }

  return (
    <MDBRow className="register-form">
      {status ? (
        <MDBCol className="register-success">
          <h5>You have successfully registered!</h5>
        </MDBCol>
      ) : (
        <MDBCol className="register-form-container">
          <h5>New Customer</h5>
          <p>
            Welcome to{" "}
            {site.siteCode === "norwell_default" ? "Norwell Lighting" : "ILEX"}.
          </p>
          <Input
            placeholder={`${
              shouldMarkError("firstname", errors, touched)
                ? "First Name is required"
                : "First Name"
            }`}
            required={true}
            type={"text"}
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            className={
              shouldMarkError("firstname", errors, touched)
                ? "invalid"
                : "" + firstName !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, firstname: true });
            }}
            shouldMarkError={shouldMarkError("firstname", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("lastname", errors, touched)
                ? "Last Name is required"
                : "Last Name"
            }`}
            required={true}
            type={"text"}
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            className={
              shouldMarkError("lastname", errors, touched)
                ? "invalid"
                : "" + lastName !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, lastname: true });
            }}
            shouldMarkError={shouldMarkError("lastname", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("company", errors, touched)
                ? "Company Name is required"
                : "Company Name"
            }`}
            required={false}
            type={"text"}
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value)}
            // className={
            //   shouldMarkError("company", errors, touched)
            //     ? "invalid"
            //     : "" + companyName !== "" && "valid"
            // }
            // onBlur={(e) => {
            //   setTouched({ ...touched, company: true });
            // }}
            //shouldMarkError={shouldMarkError("company", errors, touched)}
          />
          <Input
            placeholder={`${
              shouldMarkError("company", errors, touched)
                ? "Email is required"
                : "Email"
            }`}
            required={true}
            type={"text"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            className={
              shouldMarkError("email", errors, touched)
                ? "invalid"
                : "" + email !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, email: true });
            }}
            shouldMarkError={shouldMarkError("email", errors, touched)}
          />
          {/* <Input
            placeholder={`${
              shouldMarkError("confirm_email", errors, touched)
                ? "Confirm Email is required"
                : "Confirm Email"
            }`}
            required={true}
            type={"text"}
            value={confirmEmail}
            onChange={(event) => setConfirmEmail(event.target.value)}
            className={
              shouldMarkError("confirm_email", errors, touched)
                ? "invalid"
                : "" + confirmEmail !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, confirm_email: true });
            }}
            shouldMarkError={shouldMarkError("confirm_email", errors, touched)}
          /> */}
          {/* <div className="masked-input">
            <MaskedInput
              type={"text"}
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={phone !== undefined ? phone : ""}
              guide={false}
              onChange={(event) => setPhone(event.target.value)}
              onBlur={(e) => {
                setTouched({ ...touched, phone: true });
              }}
              className={
                shouldMarkError("phone", errors, touched)
                  ? "invalid"
                  : "" + phone !== "" && "valid"
              }
              placeholder={`${
                shouldMarkError("phone", errors, touched)
                  ? "Phone Number is required"
                  : "Phone Number"
              }`}
            />
            <label className="required-label">
              {typeof shouldMarkError("phone", errors, touched) ===
              "undefined" ? (
                "Required"
              ) : shouldMarkError("phone", errors, touched) ? (
                <Icon icon="error_icon" />
              ) : (
                <Icon icon="check_mark_icon_black" />
              )}
            </label>
          </div> */}
          <Input
            placeholder={`${
              shouldMarkError("password", errors, touched)
                ? "Password is required"
                : "Password"
            }`}
            required={true}
            type={"password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            className={
              shouldMarkError("password", errors, touched)
                ? "invalid"
                : "" + password !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, password: true });
            }}
            shouldMarkError={shouldMarkError("password", errors, touched)}
          />
          {/* <Input
            placeholder={`${
              shouldMarkError("password", errors, touched)
                ? "Confirm Password is required"
                : "Confirm Password"
            }`}
            required={true}
            type={"password"}
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            className={
              shouldMarkError("confirm_password", errors, touched)
                ? "invalid"
                : "" + confirmPassword !== "" && "valid"
            }
            onBlur={(e) => {
              setTouched({ ...touched, confirm_password: true });
            }}
            shouldMarkError={shouldMarkError(
              "confirm_password",
              errors,
              touched
            )}
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                firstName,
                lastName,
                companyName,
                email,
                phone,
                password,
                subscribe,
                termsAndConditions,
                setTermsValidationMessage,
                setTouched,
                errors,
                lock,
                setLoading,
                setStatus,
                registerCustomerNew
              )
            }
          /> */}
          <div className="checkbox-wrapper">
            <Checkbox
              checked={termsAndConditions}
              setChecked={setTermsAndConditions}
              checkbox_id={"terms_and_conditions"}
              theme={"dark"}
            />
            <p>
              Agree to
              <a
                className="terms-and-conditions-link"
                href="/terms-and-conditions"
              >
                Terms & Conditions
              </a>
            </p>
          </div>
          <div className="checkbox-wrapper">
            <Checkbox
              checked={subscribe}
              setChecked={setSubscribe}
              checkbox_id={"subscribe"}
              theme={"dark"}
            />
            <p>Stay up to date with news and promotions</p>
          </div>
          <p
            className={`${
              termsValidationMessage ? "terms-message active" : "terms-message"
            }`}
          >
            Please indicate that you have read and agree to the Terms &
            Conditions
          </p>
          <Button
            disabled={loading && true}
            onClick={(e) => {
              registerSubmit(
                e,
                firstName,
                lastName,
                companyName,
                email,
                phone,
                password,
                subscribe,
                termsAndConditions,
                setTermsValidationMessage,
                setTouched,
                errors,
                lock,
                setLoading,
                setStatus,
                registerCustomerNew
              );
            }}
          >
            {loading ? "Processing..." : "Submit"}
          </Button>
          <p className="error-messages">
            <SessionMessages target="register" />
          </p>
        </MDBCol>
      )}
    </MDBRow>
  );
};

export default RegisterForm;
