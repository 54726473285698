import { emailValidation } from "app/config/customer/validationRules";

export const validate = (
  firstname,
  lastname,
  //company,
  email,
  //confirm_email,
  //phone,
  password,
  //confirm_password,
  terms
) => {
  return {
    firstname: firstname.length === 0,
    lastname: lastname.length === 0,
    //company: company.length === 0,
    email: email.length === 0 || ValidateEmail(email),
    //confirm_email: confirm_email.length === 0 || email !== confirm_email,
    //phone: phone.length === 0,
    password: password.length < 6,
    //confirm_password:
    //confirm_password.length === 0 || password !== confirm_password,
    terms: terms === false,
  };
};

export const shouldMarkError = (field, errors, state) => {
  const hasError = errors[field];
  const shouldShow = state[field];
  return hasError ? shouldShow : false;
};

function ValidateEmail(mail) {
  if (emailValidation.test(mail)) {
    return false;
  }
  return true;
}
